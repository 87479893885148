<div *ngIf="announcement.isEvent" [matTooltip]="styleName">
  <mat-icon>calendar_today</mat-icon>
</div>
<div *ngIf="announcement.isAnnouncement" [matTooltip]="styleName">
  <mat-icon>chat_bubble</mat-icon>
</div>
<div *ngIf="announcement.isIndividualSpotlight" [matTooltip]="styleName">
  <mat-icon>person_pin</mat-icon>
</div>
<div *ngIf="announcement.isSpecialAnnouncement" [matTooltip]="styleName">
  <mat-icon>announcement</mat-icon>
</div>
<div *ngIf="announcement.isTitleSlide" [matTooltip]="styleName">
  <mat-icon>insert_photo</mat-icon>
</div>
<div *ngIf="announcement.isGif" [matTooltip]="styleName">
  <mat-icon>gif_box</mat-icon>
</div>
<div *ngIf="announcement.isNameInLights" [matTooltip]="styleName">
  <mat-icon>highlight</mat-icon>
</div>
<div *ngIf="announcement.isCollage" [matTooltip]="styleName">
  <mat-icon>auto_awesome_mosaic</mat-icon>
</div>
