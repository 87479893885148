<div [formGroup]="form" [ngClass]="{ 'visually-hidden': form.disabled }">
  <div class="form-row">
    <div class="error-wrapper">
      <mat-form-field class="small-input" appearance="outline" color="accent">
        <mat-label color="accent">Frequency</mat-label>

        <mat-select
          formControlName="freq"
          data-qa="recurrence-select-time-frame"
        >
          <mat-option
            [value]="freqOptions.Daily"
            data-qa="recurrence-select-time-frame-option"
          >
            Daily
          </mat-option>
          <mat-option
            [value]="freqOptions.Weekly"
            data-qa="recurrence-select-time-frame-option"
          >
            Weekly
          </mat-option>
          <mat-option
            [value]="freqOptions.Monthly"
            data-qa="recurrence-select-time-frame-option"
          >
            Monthly
          </mat-option>
        </mat-select>
        <mat-hint
          ><span
            *ngIf="
              form?.controls['freq']?.hasError('required') &&
              form?.controls['freq']?.touched
            "
            class="error"
          >
            Please select a timeframe
          </span></mat-hint
        >
      </mat-form-field>
    </div>

    <!-- Daily -->
    <div
      class="error-wrapper"
      *ngIf="form.controls['freq'].value === freqOptions.Daily"
    >
      <mat-form-field class="small-input" appearance="outline" color="accent">
        <mat-label color="accent">Interval (days)</mat-label>
        <input
          matInput
          type="number"
          min="1"
          formControlName="interval"
          placeholder="increment"
          data-qa="recurrence-input-days"
        />
        <mat-hint
          ><span
            *ngIf="
              form?.controls['interval']?.hasError('required') &&
              form?.controls['interval']?.touched
            "
            class="error"
          >
            Please select an interval
          </span>
          <span
            *ngIf="
              form?.controls['interval']?.hasError('min') &&
              form?.controls['interval']?.touched
            "
            class="error"
          >
            Interval must be at least 1
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
    <!-- Weekly -->

    <div
      class="error-wrapper"
      *ngIf="form.controls['freq'].value === freqOptions.Weekly"
    >
      <mat-form-field class="small-input" appearance="outline" color="accent">
        <mat-label color="accent">Day(s)</mat-label>

        <mat-select
          formControlName="byweekday"
          multiple
          data-qa="recurrence-multiselect-days-of-the-week"
        >
          <mat-option
            *ngFor="let day of dayOptions"
            [value]="day.value"
            data-qa="recurrence-multiselect-days-of-the-week-option"
          >
            {{ day.label }}
          </mat-option>
        </mat-select>
        <mat-hint
          ><span
            *ngIf="
              form?.controls['byweekday']?.hasError('required') &&
              form?.controls['byweekday']?.touched
            "
            class="error"
          >
            Please select day(s) of the week
          </span></mat-hint
        >
      </mat-form-field>
    </div>
    <div
      class="error-wrapper"
      *ngIf="form.controls['freq'].value === freqOptions.Weekly"
    >
      <mat-form-field class="small-input" appearance="outline" color="accent">
        <mat-label color="accent">Interval (weeks)</mat-label>
        <input
          matInput
          type="number"
          formControlName="interval"
          min="1"
          placeholder="increment"
          data-qa="recurrence-input-weeks"
        />
        <mat-hint
          ><span
            *ngIf="
              form?.controls['interval']?.hasError('required') &&
              form?.controls['interval']?.touched
            "
            class="error"
          >
            Please select interval
          </span>
          <span
            *ngIf="
              form?.controls['interval']?.hasError('min') &&
              form?.controls['interval']?.touched
            "
            class="error"
          >
            Interval must be at least 1
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
    <!-- Monthly -->
    <div
      class="error-wrapper"
      *ngIf="form.controls['freq'].value === freqOptions.Monthly"
    >
      <mat-form-field class="small-input" appearance="outline" color="accent">
        <mat-label color="accent">Occurrence in the month</mat-label>

        <mat-select
          formControlName="nbyweekday"
          data-qa="recurrence-select-nth-day-of-the-month"
        >
          <mat-option
            *ngFor="let day of dayOfMonthOptions"
            [value]="day.value"
            data-qa="recurrence-select-nth-day-of-the-month-option"
          >
            {{ day.label }}
          </mat-option>
        </mat-select>
        <mat-hint
          ><span
            *ngIf="
              form?.controls['nbyweekday']?.hasError('required') &&
              form?.controls['nbyweekday']?.touched
            "
            class="error"
          >
            Please select day of the month
          </span></mat-hint
        >
      </mat-form-field>
    </div>
    <div
      class="error-wrapper"
      *ngIf="form.controls['freq'].value === freqOptions.Monthly"
    >
      <mat-form-field class="small-input" appearance="outline" color="accent">
        <mat-label color="accent">Day(s)</mat-label>

        <mat-select
          formControlName="byweekday"
          multiple
          data-qa="recurrence-multiselect-weekdays-of-the-month"
        >
          <mat-option
            *ngFor="let day of dayOptions"
            [value]="day?.value"
            data-qa="recurrence-multiselect-weekdays-of-the-month-option"
          >
            {{ day.label }}
          </mat-option>
        </mat-select>
        <mat-hint
          ><span
            *ngIf="
              form?.controls['byweekday']?.hasError('required') &&
              form?.controls['byweekday']?.touched
            "
            class="error"
          >
            Please select day of the month
          </span></mat-hint
        >
      </mat-form-field>
    </div>
    <div
      class="error-wrapper"
      *ngIf="form.controls['freq'].value === freqOptions.Monthly"
    >
      <mat-form-field class="small-input" appearance="outline" color="accent">
        <mat-label color="accent">Interval (months)</mat-label>
        <input
          matInput
          min="1"
          type="number"
          formControlName="interval"
          placeholder="increment"
          data-qa="recurrence-input-months"
        />
        <mat-hint
          ><span
            *ngIf="
              form?.controls['interval']?.hasError('required') &&
              form?.controls['interval']?.touched
            "
            class="error"
          >
            Please select interval
          </span>
          <span
            *ngIf="
              form?.controls['interval']?.hasError('min') &&
              form?.controls['interval']?.touched
            "
            class="error"
          >
            Interval must be at least 1
          </span></mat-hint
        >
      </mat-form-field>
    </div>
    <!-- Stop condition -->
  </div>
  <div class="form-row">
    <div class="error-wrapper">
      <mat-form-field class="small-input" appearance="outline" color="accent">
        <mat-label color="accent">Repeat until</mat-label>

        <mat-select
          formControlName="stopCondition"
          data-qa="recurrence-select-stop-condition"
        >
          <mat-option
            *ngFor="let condition of stopConditionOptions"
            [value]="condition.value"
            data-qa="recurrence-select-stop-condition-option"
          >
            {{ condition.label }}
          </mat-option>
        </mat-select>
        <mat-hint
          ><span
            *ngIf="
              form?.controls['stopCondition']?.hasError('required') &&
              form?.controls['stopCondition']?.touched
            "
            class="error"
          >
            Please select a stop condition
          </span></mat-hint
        >
      </mat-form-field>
      <div
        class="pl-2"
        *ngIf="form.controls['stopCondition'].value === 'never'"
      >
        <span class="text-sm text-gray-400">
          This will schedule one year of events. Additional events will be added
          automatically.
        </span>
      </div>
    </div>
    <div class="error-wrapper pb-4">
      <mat-form-field appearance="outline" color="accent">
        <mat-label color="accent">End Date</mat-label>
        <input
          matInput
          type="date"
          formControlName="until"
          data-qa="recurrence-input-until-date"
          [max]="maxDate"
          [min]="minDate"
          [matDatepicker]="recurrencePicker"
        />
        <mat-hint
          ><span
            *ngIf="
              form?.controls['until']?.hasError('required') &&
              form?.controls['until']?.touched
            "
            class="error"
          >
            Please select a stop date
          </span>
          <span
            *ngIf="
              form?.controls['until']?.hasError('dateAfterValidator') &&
              form?.controls['until']?.touched
            "
            class="error"
          >
            End date must be after start date
          </span>
          <span
            *ngIf="
              form?.controls['until']?.hasError('dateBeforeValidator') &&
              form?.controls['until']?.touched
            "
            class="error"
          >
            Select a date within one year from start date.
          </span>
        </mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="recurrencePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #recurrencePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div
      class="error-wrapper"
      *ngIf="
        form.controls['freq'].value &&
        form.controls['stopCondition'].value === 'count'
      "
    >
      <mat-form-field class="small-input" appearance="outline" color="accent">
        <mat-label color="accent">Repetitions</mat-label>
        <input
          matInput
          type="number"
          min="2"
          max="52"
          formControlName="count"
          data-qa="recurrence-input-number-of-occurrences"
        />
        <mat-hint
          ><span
            *ngIf="
              form?.controls['count']?.hasError('required') &&
              form?.controls['count']?.touched
            "
            class="error"
          >
            Please select how many times to repeat
          </span>
          <span
            *ngIf="
              form?.controls['count']?.hasError('min') &&
              form?.controls['count']?.touched
            "
            class="error"
          >
            Event must occur at least twice to repeat
          </span>
          <span
            *ngIf="
              form?.controls['count']?.hasError('max') &&
              form?.controls['count']?.touched
            "
            class="error"
          >
            Event can't occur more than 52 times.
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
</div>
