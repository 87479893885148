<h1 mat-dialog-title>
  {{ title }}
</h1>

<div mat-dialog-content>
  <p>{{ message }}</p>
  <div class="flex jusitfy-end gap-2">
    <button mat-button (click)="onDismiss()">{{ textDismiss }}</button>
    <button mat-raised-button [color]="confirmColor" (click)="onConfirm()">
      {{ textConfirm }}
    </button>
  </div>
</div>
