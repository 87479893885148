import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard  {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isDev = environment.environmentName === 'development';
    const path = next.routeConfig.path;

    // Only enforce redirect routing for deployed apps
    if (isDev) {
      return true;
    }

    // Display URLs only at TV subdomain
    if (path.includes('display')) {
      if (!environment.cms) {
        return true;
      }
      const siteId = next.url.length > 1 ? next.url[1].path : null;
      window.location.href = `${environment.deployedTvUrl}/display${
        siteId ? `/${siteId}` : ''
      }`;
      return false;
    }

    // Manage URLs only at CMS subdomain
    if (path.includes('manage')) {
      if (environment.cms) {
        return true;
      }
      window.location.href = `${environment.deployedCmsUrl}/manage`;
      return false;
    }

    if (path.includes('session')) {
      if (environment.cms) {
        return true;
      }
      // Hardcoding sign-in for easy since it's the only session page we have.
      // Will need to refactor if we add more.
      window.location.href = `${environment.deployedCmsUrl}/session/sign-in`;
      return false;
    }

    return true;
  }
}
