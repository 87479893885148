<div class="wrapper">
  <button
    class="toggle-open"
    color="primary"
    *ngIf="!dashboardOpen"
    matTooltip="Open sidebar"
    (click)="toggleDashboard()"
  >
    <mat-icon color="secondary">chevron_right</mat-icon>
  </button>
  <app-nav></app-nav>

  <div class="dash-content">
    <mat-sidenav-container autosize>
      <mat-sidenav
        color="accent"
        fixedInViewport="true"
        mode="side"
        [opened]="dashboardOpen"
      >
        <app-nav-drawer (closeDashboard)="toggleDashboard()"></app-nav-drawer>
      </mat-sidenav>

      <mat-sidenav-content>
        <section class="section mb-16">
          <div class="cont">
            <router-outlet></router-outlet>
          </div>
        </section>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
