<div class="loading-wrapper">
  <div class="loading-spinner" *ngIf="paginatedDataSource.loading$ | async">
    <app-material-loader [fillContainer]="true"></app-material-loader>
  </div>
  <div
    class="loading-overlay"
    *ngIf="paginatedDataSource.loading$ | async"
  ></div>
  <table
    mat-table
    matSort
    matSortActive="updatedOn"
    matSortDirection="desc"
    [dataSource]="paginatedDataSource"
  >
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        <i>{{
          this.searchFilter$.value?.length > 0
            ? 'No images match this search.'
            : (paginatedDataSource.loading$ | async)
            ? ''
            : 'No images available.'
        }}</i>
      </td>
    </tr>
    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef>Thumbnail</th>
      <td mat-cell *matCellDef="let image">
        <div class="image-loading">
          <img
            *ngIf="image.downloadUrl; else loading"
            [src]="image.downloadUrl"
            width="100"
          />
        </div>
        <!-- TODO: loading state -->
        <ng-template #loading>
          <div>loading</div>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Default Background Image
      </th>
      <td mat-cell *matCellDef="let image">
        {{ getCategoryName(image) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="site">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Access
      </th>
      <td mat-cell *matCellDef="let image">
        {{ getSiteNames(image) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="updatedOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Date Updated
      </th>
      <td mat-cell *matCellDef="let image">{{ image.updatedOn | date }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let image">
        <div>
          <button
            mat-icon-button
            color="accent"
            (click)="handleEditImage(image)"
            matTooltip="Edit Image Details"
          >
            <mat-icon>edit_pencil</mat-icon>
          </button>
          <button
            mat-icon-button
            color="accent"
            (click)="handleDeleteImage(image)"
            matTooltip="Delete Image"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [length]="paginatedDataSource?.totalCount"
    [pageSize]="10"
    [pageSizeOptions]="[10, 20, 30, 50]"
    showFirstLastButtons
  ></mat-paginator>
</div>
