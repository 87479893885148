import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MenuSlide, MenuSlidePayload } from '../interfaces/api';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuSlideService {
  constructor(private http: HttpClient) {}

  public getMenuSlideBySiteId(siteId: number) {
    const url = `${environment.apiv3Url}/menu-slide/site/${siteId}`;
    return this.http.get<MenuSlide>(url);
  }

  public updateMenuSlide(id: number, data: Partial<MenuSlide>) {
    const url = `${environment.apiv3Url}/menu-slide/${id}`;
    return lastValueFrom(this.http.put<MenuSlide>(url, data));
  }

  public createMenuSlide(data: MenuSlidePayload) {
    const url = `${environment.apiv3Url}/menu-slide`;
    return lastValueFrom(this.http.post<MenuSlide>(url, data));
  }
}
