<div [formGroup]="form" class="flex flex-col gap-12">
  <div class="flex flex-col gap-1.5">
    <div class="flex flex-col gap-1.5">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Question</mat-label>
        <input matInput autofocus required formControlName="question" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Answer</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize="true"
          required
          formControlName="answer"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
