import { Component, OnDestroy, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

import { DisplayService } from 'src/app/core/services/display.service';
import { SiteService } from 'src/app/core/services/site.service';
import { Announcement } from '@models/announcement';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { SlideStyleType } from '../../../../../core/enums/slide-style-type';
@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0.85 }),
        animate(650, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class DisplayComponent implements OnInit, OnDestroy {
  public propertyId: number;
  public browserIsChrome: boolean;
  private _subscriptions$: Array<Subscription> = [];
  public activeAnnouncement: Announcement = null;
  public style = SlideStyleType;

  constructor(
    public displayService: DisplayService,
    private route: ActivatedRoute,
    private siteService: SiteService,
  ) {}

  ngOnInit() {
    this._subscriptions$.push(
      this.route.params.subscribe((params) => {
        this.propertyId = params['propertyId'];
        this.displayService.loadData(this.propertyId);
        this.siteService.setActiveSite(this.propertyId);
      }),
    );

    this._subscriptions$.push(
      this.displayService.announcement
        .pipe(
          filter((announcement: Announcement) => !!announcement),
          distinctUntilChanged((prev, next) => {
            return prev?.id === next?.id;
          }),
        )
        .subscribe((announcement: Announcement) => {
          this.activeAnnouncement = announcement;
        }),
    );
  }

  ngOnDestroy() {
    if (this._subscriptions$.length > 0) {
      this._subscriptions$.forEach((subscription) =>
        subscription.unsubscribe(),
      );
    }
  }
}
