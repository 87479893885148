<div class="loading-wrapper">
  <div
    class="loading-spinner"
    *ngIf="(paginatedDataSource.loading$ | async) || loading$.value"
  >
    <app-material-loader [fillContainer]="true"></app-material-loader>
  </div>
  <div
    class="loading-overlay"
    *ngIf="(paginatedDataSource.loading$ | async) || loading$.value"
  ></div>
  <table
    mat-table
    matSort
    [dataSource]="paginatedDataSource"
    matSortActive="startShowing"
    matSortDirection="asc"
  >
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        <i>{{
          this.filters.controls.search?.value?.length > 0
            ? 'No slides match this search.'
            : (paginatedDataSource.loading$ | async)
            ? ''
            : 'No slides available.'
        }}</i>
      </td>
    </tr>
    <ng-container matColumnDef="type">
      <th
        class="slide-table-header"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        disableClear
      >
        Type
      </th>
      <td mat-cell *matCellDef="let announcement">
        <app-slide-type-icon
          [announcement]="announcement"
        ></app-slide-type-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Title
      </th>
      <td mat-cell *matCellDef="let announcement">{{ announcement.title }}</td>
    </ng-container>

    <ng-container matColumnDef="startShowing">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Start Showing
      </th>
      <td
        mat-cell
        *matCellDef="let announcement"
        [matTooltip]="
          announcement.startShowing
            ? announcement.startShowing.toLocaleString(DateTime.DATETIME_FULL)
            : ''
        "
      >
        {{
          announcement.startShowing
            ? announcement.startShowing.toLocaleString()
            : ''
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="stopShowing">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Stop Showing
      </th>
      <td
        mat-cell
        *matCellDef="let announcement"
        [matTooltip]="
          announcement.stopShowing < permanentStopShowingDateTime
            ? announcement.stopShowing.toLocaleString(DateTime.DATETIME_FULL)
            : ''
        "
        [ngClass]="{
          italic: announcement.stopShowing >= permanentStopShowingDateTime
        }"
      >
        <ng-container
          *ngIf="
            announcement.stopShowing < permanentStopShowingDateTime;
            else never
          "
        >
          {{
            announcement.stopShowing
              ? announcement.stopShowing.toLocaleString()
              : ''
          }}
        </ng-container>
        <ng-template #never>Never</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Description
      </th>
      <td mat-cell *matCellDef="let announcement">
        <ng-container *ngIf="announcement.content">
          <markdown
            *ngIf="announcement.isIndividualSpotlight"
            [data]="announcement.content"
          ></markdown>
          {{
            !announcement.isIndividualSpotlight ? announcement.content : null
          }}
        </ng-container>
        <i *ngIf="!announcement.content">No description</i>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let announcement" class="action-wrapper">
        <ng-container *ngIf="!announcement.isGenerated">
          <div class="buttons" *ngIf="!announcement.isEvent">
            <button
              mat-icon-button
              (click)="edit.emit(announcement)"
              color="accent"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="delete.emit(announcement)"
              color="accent"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <div
            class="buttons"
            *ngIf="announcement.isEvent"
            matTooltip="Events can be edited and deleted in the Event Calendar"
          >
            <button mat-icon-button disabled color="accent">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button disabled color="accent">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="announcement.isGenerated">
          <span class="system-generated">System Generated</span>
        </ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [length]="paginatedDataSource?.totalCount"
    [pageSize]="10"
    [pageSizeOptions]="[10, 20, 30, 50]"
    showFirstLastButtons
  ></mat-paginator>
</div>
