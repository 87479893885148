import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Room } from '@models/room';

export interface RoomFormValues {
  name: string;
  shortName: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-room-form',
  templateUrl: './room-form.component.html',
  styleUrls: ['./room-form.component.scss'],
})
export class RoomFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup;

  static formModel(formValues = null) {
    return new UntypedFormGroup({
      name: new UntypedFormControl(formValues ? formValues.name : null, [
        Validators.required,
        Validators.maxLength(21),
      ]),
      // tslint:disable-next-line:max-line-length
      shortName: new UntypedFormControl(
        formValues ? formValues.shortName : null,
        Validators.compose([Validators.required, Validators.maxLength(3)])
      ),
    });
  }

  static serialize(
    formValues: RoomFormValues,
    room: Partial<Room> = {}
  ): Partial<Room> {
    const serializedForm = {
      name: formValues.name,
      shortName: formValues.shortName,
    };

    return Object.assign(room, serializedForm);
  }

  static deserialize(room: Room): RoomFormValues {
    return {
      name: room.name,
      shortName: room.shortName,
    };
  }

  constructor() {}

  ngOnInit() {}
}
