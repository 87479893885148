import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { EventPageAgendaComponent } from './views/event-page-agenda/event-page-agenda.component';
import { DailyAgendaComponent } from './views/daily-agenda/daily-agenda.component';
import { CalendarDashboardComponent } from './views/event-page-agenda/calendar-dashboard/calendar-dashboard.component';
// import { MbscEventcalendarModule } from '@mobiscroll/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EventPreviewCalendarComponent } from './views/event-preview-calendar/event-preview-calendar.component';
import { MaterialModule } from '../../../shared/material/material.module';
import { CalendarRecurrenceComponent } from '../../../shared/recurrence/recurrence.component';
import { SharedModule } from '../../../shared/shared.module';
import { EventPageAgendaComponentWrapper } from './views/event-page-agenda/event-page-agenda';
import { DailyAgendaComponentWrapper } from './views/daily-agenda/daily-agenda-wrapper';
import { EventPreviewCalendarComponentWrapper } from './views/event-preview-calendar/event-preview-calendar-wrapper';

@NgModule({
  declarations: [
    EventPageAgendaComponent,
    DailyAgendaComponent,
    EventPreviewCalendarComponent,
    CalendarRecurrenceComponent,
    CalendarDashboardComponent,
    EventPageAgendaComponentWrapper,
    DailyAgendaComponentWrapper,
    EventPreviewCalendarComponentWrapper,
  ],
  imports: [
    CommonModule,
    SharedModule,
    // MbscEventcalendarModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
  ],
  exports: [
    EventPageAgendaComponent,
    DailyAgendaComponent,
    EventPreviewCalendarComponent,
    CalendarRecurrenceComponent,
    CalendarDashboardComponent,
    EventPageAgendaComponentWrapper,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CalendarModule {}
