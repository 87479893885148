<mat-card
  appearance="outlined"
  color="primary"
  style="overflow-y: auto; max-height: 85vh"
>
  <mat-card-header class="flex justify-between items-center">
    <mat-card-title
      ><span class="title-capped">{{ timeframe }}</span> Slides ({{
        this.totalCount
      }})</mat-card-title
    >
    <div
      class="control-buttons buttons flex justify-end items-center gap-[10px]"
    >
      <button
        *ngFor="let timeframeOption of timeframes"
        class="button is-black is-outlined filter-btns"
        (click)="updateTimeframe(timeframeOption)"
        [ngClass]="{ active: timeframe === timeframeOption }"
      >
        {{ timeframeOption | uppercase
        }}<span *ngIf="timeframeOption === 'all'" class="pre-wrap"> TIMES</span>
      </button>
      <button
        mat-raised-button
        matTooltip="Add new slide"
        color="accent"
        data-qa="announcements-button-add-slide"
        (click)="goToAddAnnouncement()"
      >
        Add Slide
      </button>
      <button
        mat-raised-button
        matTooltip="Manage Site Slides"
        [matMenuTriggerFor]="manageMenu"
        color="accent"
        data-qa="manage-site-slides-menu-button"
      >
        Manage Site Slides
      </button>
      <mat-menu #manageMenu="matMenu">
        <button
          mat-menu-item
          (click)="manageMenuSlide()"
          data-qa="manage-menu-slide-button"
        >
          Menu Slide
        </button>
        <button
          mat-menu-item
          (click)="manageEventSlide()"
          data-qa="manage-menu-event-slide-button"
        >
          Event Slide
        </button>
      </mat-menu>
      <a
        mat-raised-button
        matTooltip="View live slideshow"
        color="accent"
        href="/display/{{ siteId }}"
        target="_blank"
        data-qa="announcements-button-view-slideshow"
      >
        <mat-icon>play_arrow</mat-icon></a
      >
      <button
        mat-raised-button
        matTooltip="View content in card format"
        color="accent"
        (click)="toggleTableGrid()"
        *ngIf="isTable"
      >
        <mat-icon>view_module</mat-icon>
      </button>
      <button
        mat-raised-button
        matTooltip="View content in table format"
        color="accent"
        (click)="toggleTableGrid()"
        *ngIf="!isTable"
      >
        <mat-icon>view_headline</mat-icon>
      </button>

      <mat-form-field color="accent">
        <input
          matInput
          placeholder="Search"
          (input)="search($event.target.value)"
          [value]="searchFilter"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </mat-card-header>
  <mat-card-content style="padding-top: 16px">
    <!-- TABLE VIEW -->
    <ng-container *ngIf="isTable">
      <app-slide-table
        [filter]="searchFilter"
        [refresh]="incrementRefresh"
        (edit)="goToEditAnnouncement($event)"
        (delete)="deleteAnnouncement($event)"
        [timeframe]="timeframe"
        (count)="updateCount($event)"
      ></app-slide-table>
    </ng-container>

    <!-- GRID VIEW -->
    <ng-container *ngIf="!isTable">
      <app-slide-grid
        [filter]="searchFilter"
        [refresh]="incrementRefresh"
        (edit)="goToEditAnnouncement($event)"
        (delete)="deleteAnnouncement($event)"
        [timeframe]="timeframe"
        (count)="updateCount($event)"
      ></app-slide-grid>
    </ng-container>

    <!-- Slideshow duration. Only display for Active Content. -->
    <ng-container
      *ngIf="
        (manage.announcements | async)?.length &&
        timeframe === 'active' &&
        !sysGenSlidesOnly(manage.announcements | async)
      "
    >
      <p class="slideshow-duration">
        <!-- If exclusively a Special Announcemnt -->
        <ng-container
          *ngIf="
            (manage.announcements | async).length === 1 &&
            (manage.announcements | async)[0].isSpecialAnnouncement
          "
        >
          <span>Special Announcement active.</span> No other slides display
          while a Special Announcement is active.
        </ng-container>

        <ng-container
          *ngIf="
            (manage.announcements | async).length !== 1 ||
            !(manage.announcements | async)[0].isSpecialAnnouncement
          "
        >
          <!-- Calculate duration in seconds, then convert to readable duration via Angular Date Pipe -->
          Slideshow duration:
          <span
            >{{
              manage.calcSlideshowDuration(manage.announcements | async).hours
            }}
            hour<span
              *ngIf="
                manage.calcSlideshowDuration(manage.announcements | async)
                  .hours !== 1
              "
              >s</span
            ></span
          >,
          <span
            >{{
              manage.calcSlideshowDuration(manage.announcements | async).minutes
            }}
            minute<span
              *ngIf="
                manage.calcSlideshowDuration(manage.announcements | async)
                  .minutes !== 1
              "
              >s</span
            ></span
          >,
          <span
            >{{
              manage.calcSlideshowDuration(manage.announcements | async).seconds
            }}
            second<span
              *ngIf="
                manage.calcSlideshowDuration(manage.announcements | async)
                  .seconds !== 1
              "
              >s</span
            ></span
          >.
        </ng-container>
      </p>
    </ng-container>

    <ng-template #zeroState>
      No {{ timeframe === 'all' ? '' : timeframe }} content added yet.
    </ng-template>
  </mat-card-content>
</mat-card>
