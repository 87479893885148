import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

import { Announcement } from '@models/announcement';
import { ImageService } from 'src/app/core/services/image.service';
import { ManageService } from 'src/app/core/services/manage.service';
import { Image } from '@models/image';
import { AlertService } from 'src/app/core/services/alert.service';

export interface FormValues {
  title: string;
  factOne: string;
  factTwo: string;
  factThree: string;
  factFour: string;
  factFive: string;
  image: Image;
}

@Component({
  selector: 'app-slide-description-individual-spotlight',
  templateUrl: './slide-description-individual-spotlight.component.html',
  styleUrls: ['./slide-description-individual-spotlight.component.scss'],
})
export class SlideDescriptionIndividualSpotlightComponent {
  @Input() public form: UntypedFormGroup;
  @Input() set announcement(announcement: Announcement) {
    if (announcement && announcement.styleId) {
      this.siteId = announcement.siteId;
      this.styleId = announcement.styleId;
      this.maxTitleLength = this.manageService.getMaxTitleChars(
        announcement.styleId,
      );
    }
  }
  public maxTitleLength: number;
  public siteId: number;
  public styleId: number;

  static deserializeToForm(data: Announcement): FormValues {
    const facts = descriptionToFacts(data.content);
    return {
      title: data.title,
      factOne: facts[0],
      factTwo: facts[1],
      factThree: facts[2],
      factFour: facts[3],
      factFive: facts[4],
      image: data.image,
    };

    // Put these helper functions inside the function because static functions can't access
    // the class methods.

    // Convert the announcement.content field to three strings
    // Works with both non-spotlights and spotlights (any number of facts)
    function descriptionToFacts(description: string): string[] {
      const bullets = description.split('- ');
      const indexFirstStart = description.indexOf('- ');
      if (indexFirstStart === -1) {
        // No bullets found in description, set first fact as description
        return [description, '', ''];
      }

      const indexSecondStart = description.indexOf('- ', indexFirstStart + 1);
      if (indexSecondStart === -1) {
        // Only one bullet found in description, set first fact as description
        return createBulletArray(description, [indexFirstStart]);
      }

      const indexThirdStart = description.indexOf('- ', indexSecondStart + 1);
      if (indexThirdStart === -1) {
        // Two bullets found in description, set first two facts
        return createBulletArray(description, [
          indexFirstStart,
          indexSecondStart,
        ]);
      }

      const indexFourthStart = description.indexOf('- ', indexThirdStart + 1);
      if (indexFourthStart === -1) {
        // Three bullets found in description, set first three facts
        return createBulletArray(description, [
          indexFirstStart,
          indexSecondStart,
          indexThirdStart,
        ]);
      }

      const indexFifthStart = description.indexOf('- ', indexFourthStart + 1);
      if (indexThirdStart === -1) {
        // Four bullets found in description, set first four facts
        return createBulletArray(description, [
          indexFirstStart,
          indexSecondStart,
          indexThirdStart,
          indexFourthStart,
        ]);
      }

      // Set all five facts
      return createBulletArray(description, [
        indexFirstStart,
        indexSecondStart,
        indexThirdStart,
        indexFourthStart,
        indexFifthStart,
      ]);
    }

    function createBulletArray(
      description: string,
      bulletIndexes: number[],
    ): string[] {
      const bulletArray = [];
      for (let i = 0; i < 5; i++) {
        if (bulletIndexes[i] >= 0 && bulletIndexes[i + 1]) {
          bulletArray.push(
            stripMarkdown(
              description.substring(bulletIndexes[i], bulletIndexes[i + 1]),
            ),
          );
        } else if (bulletIndexes[i]) {
          bulletArray.push(
            stripMarkdown(
              description.substring(bulletIndexes[i], description.length),
            ),
          );
        } else {
          bulletArray.push('');
        }
      }
      return bulletArray;
    }

    function stripMarkdown(string): string {
      return string.replace('- ', '').replace('\n', '').trim();
    }
  }

  static serialize(form: FormValues) {
    // TODO: allow images to be deleted
    return {
      title: form.title,
      description: factsToDescription([
        form.factOne,
        form.factTwo,
        form.factThree,
        form.factFour,
        form.factFive,
      ]),
      image: form.image,
      imageId: form.image?.id,
    };

    // Put this helper functions inside the function because static functions can't access
    // the class methods.
    // @TODO I'm sure there's a better way to do this?
    function factsToDescription(facts: string[]): string {
      let description = '';
      facts.forEach((fact) => {
        if (fact) {
          description += `- ${fact}\n`;
        }
      });
      return description;
    }
  }

  static formModel() {
    return {
      title: new UntypedFormControl(''),
      factOne: new UntypedFormControl(''),
      factTwo: new UntypedFormControl(''),
      factThree: new UntypedFormControl(''),
      factFour: new UntypedFormControl(''),
      factFive: new UntypedFormControl(''),
      image: new UntypedFormControl(null),
    };
  }

  constructor(
    private imageService: ImageService,
    public manageService: ManageService,
    private alertService: AlertService,
  ) {}

  public dropped(files: NgxFileDropEntry[]) {
    const droppedFile = files[0]; // only support one file upload
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.handleImageFileChange(file);
      });
    }
  }

  uploadInputChanged(event) {
    const file: File = event.target.files[0];
    this.handleImageFileChange(file);
  }

  get downloadUrl() {
    return this.form.value.image?.downloadUrl;
  }

  public deleteImage() {
    return this.form.patchValue({ image: null });
  }

  public progress = 0;
  // image resize accounts for roughly 2/3rd of the upload time
  public handleLoading = (value) => {
    this.progress = value * (2 / 3);
  };

  async handleImageFileChange(file: File) {
    if (await this.imageService.isAllowedFileType(file)) {
      const myReader: FileReader = new FileReader();
      const resize = await this.imageService.resizeFile(file, {
        maxWidthOrHeight: 3840, // should not be a higher resolution than 4k for digital signage
        maxSizeMB: 4,
        onProgress: this.handleLoading,
      });
      myReader.readAsDataURL(resize);

      myReader.onloadend = async (loadEvent: any) => {
        this.progress = 80;

        const imageBlob = this.imageService.dataURItoBlob(
          loadEvent.currentTarget.result,
        );

        const path = await this.imageService.uploadToFirestore(
          this.imageService.getSiteBackgroundImageFilePath(this.siteId),
          imageBlob,
        );
        if (path) {
          const downloadUrl = await this.imageService.getDownloadUrl(path);
          const response = await this.manageService.createImageRecord(
            downloadUrl,
            path,
            this.siteId,
            this.styleId,
          );
          this.form.patchValue({ image: response });
          this.progress = 100;
          setTimeout(() => (this.progress = 0), 100);
        }
      };
    } else {
      this.progress = 0;

      this.alertService.error(
        'Please choose an allowed file type: jpg, png, gif or tif.',
      );
    }
  }
}
