import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'img[hideOnFail]',
  host: {
    '(error)': 'onError()',
    '(load)': 'onLoad()',
  },
})
export class ImageHideOnFailDirective {
  constructor(private el: ElementRef) {}

  public onError() {
    this.el.nativeElement.classList.add('display-none');
  }

  public onLoad() {
    this.el.nativeElement.classList.remove('display-none');
  }
}
