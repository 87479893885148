import { Injectable } from '@angular/core';
import { ReplaySubject, lastValueFrom } from 'rxjs';
import { SiteService } from './site.service';
import { environment } from 'src/environments/environment';
import { Faq } from '@models/faq';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  private _faqs = new ReplaySubject<Faq[]>();
  public readonly faqs = this._faqs.asObservable();

  constructor(private http: HttpClient, private siteService: SiteService) {}

  /**
   * Manually force FAQ refresh. They're only referenced in the EditSite modal,
   * so no reason to always sync them, even when properties are changing.
   */
  public refreshFaqs(): void {
    this.siteService.siteId.pipe(take(1)).subscribe((siteId: number) => {
      if (!siteId) {
        this.updateActiveFaqs([]);
        return;
      }

      this.loadFaqs(siteId).then((faqs: Faq[]) => this.updateActiveFaqs(faqs));
    });
  }

  public updateFaq(faq: Partial<Faq>): Promise<Faq> {
    const url = environment.apiUrl.concat('/api/v1/faq');

    try {
      return lastValueFrom(this.http.post<Faq>(url, faq));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  private updateActiveFaqs(faqs: Faq[]): void {
    const f = faqs && faqs.length ? faqs : [];
    this._faqs.next(f);
  }

  public loadFaqs(siteId: number): Promise<Faq[]> {
    const url = `${environment.apiv3Url}/faqs/site/${siteId}`;

    try {
      return lastValueFrom(this.http.get<Faq[]>(url));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public deleteFaq(faqId: number): Promise<any> {
    const url = `${environment.apiv3Url}/faqs/${faqId}`;

    try {
      return lastValueFrom(this.http.put(url, {}));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }
}
