import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import isEqual from 'lodash/isEqual';

import { Site } from '@models/site';
import { Image as ImageModel } from '@models/image';
import { OrgService } from 'src/app/core/services/org.service';
import { SiteService } from 'src/app/core/services/site.service';
import { CategoryService } from 'src/app/core/services/category.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ImageService } from 'src/app/core/services/image.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface ImageFormValues {
  image: string;
  name: string;
  siteIds: number;
  category: number;
}

@Component({
  selector: 'app-image-form',
  templateUrl: './image-form.component.html',
  styleUrls: ['./image-form.component.scss'],
})
export class ImageFormComponent implements OnInit, OnDestroy {
  @Input() public form: UntypedFormGroup;
  @Input() public imagePreview: string;
  @Input() public allSites: Array<Number>;
  @Input() public multiSelect = false;
  @Output() imageFileChanged = new EventEmitter();

  @ViewChild('imageInput') imageInput;

  public sites: Site[];
  public data: {
    image: any;
    editMode?: boolean;
    existingSubcategory?: boolean;
  } = { image: '', editMode: false };
  private defaultFormValues = {
    image: '',
    category: null,
    siteIds: null,
    name: '',
    subcategoryId: null,
  };
  private destroyed$ = new Subject();

  static formModel(formValues = null) {
    // Category and name(subcategory) are optional, but if you select one, the other is required
    const categoryConditionallyRequiredValidator = function (
      formGroup: UntypedFormGroup,
    ) {
      if (formGroup.value.name) {
        return Validators.required(formGroup.get('category'))
          ? {
              categoryConditionallyRequired: true,
            }
          : null;
      }
      return null;
    };

    const nameConditionallyRequiredValidator = function (
      formGroup: UntypedFormGroup,
    ) {
      if (formGroup.value.category) {
        return Validators.required(formGroup.get('name'))
          ? {
              nameConditionallyRequired: true,
            }
          : null;
      }
      return null;
    };

    return new UntypedFormGroup(
      {
        image: new UntypedFormControl(formValues ? formValues.image : null), // needs to be optional to support edit mode
        name: new UntypedFormControl(formValues ? formValues.name : null),
        category: new UntypedFormControl(
          formValues ? formValues.categoryId : null,
        ),
        siteIds: new UntypedFormControl(
          formValues ? formValues.siteIds : null,
          Validators.required,
        ),
        subcategoryId: new UntypedFormControl(
          formValues && formValues.subcategoryId
            ? formValues.subcategoryId
            : null,
        ),
      },
      {
        validators: [
          nameConditionallyRequiredValidator,
          categoryConditionallyRequiredValidator,
        ],
      },
    );
  }

  static deserialize = (image: ImageModel) => {
    return {
      data: {
        photo: image.downloadUrl,
      },
      formValue: {
        image: '', // can't programmatically set file input values to anything but an empty string
        category: image.categoryId ? image.categoryId : null,
        siteIds: image.site ? image.site.id : null,
        name: image.subcategory ? image.subcategory.name : null,
        subcategoryId: image.subcategoryId ? image.subcategoryId : null,
      },
    };
  };

  constructor(
    public orgService: OrgService,
    public siteService: SiteService,
    public categoryService: CategoryService,
    public imageService: ImageService,
    public alertService: AlertService,
  ) {}

  ngOnInit() {
    if (!isEqual(this.form.value, this.defaultFormValues)) {
      // initial non-default form values on load indicates edit mode
      this.data.editMode = true;
      this.data.existingSubcategory = this.form.value.name ? true : false;
    }

    if (this.multiSelect) {
      // Allows user to 'select all' to set permissions at the org level
      this.form
        .get('siteIds')
        .valueChanges.pipe(takeUntil(this.destroyed$))
        .subscribe((value) => {
          if (value?.includes(null)) {
            this.form.get('siteIds').setValue(this.allSites);
            this.form.updateValueAndValidity();
          }
        });
    }

    this.categoryService.categories.subscribe((categories) =>
      this.updateFormName(categories),
    );
  }

  ngAfterViewInit() {
    if (this.data.editMode) {
      this.data.image = this.imagePreview;
      this.imageInput.nativeElement.disabled = true;
      // this.form.get('siteIds').disable();
    }
  }

  private updateFormName(categories: any[]): void {
    const categoryId = this.form.get('category').value;
    const subCategory = this.form.get('subcategoryId').value;

    if (!categoryId || !subCategory) {
      return;
    }

    const category = categories.find(
      (category) => category.id === Number(categoryId),
    );
    if (!category) {
      return;
    }

    const subcategory = category.subcategories.find(
      (subcategory) => subcategory.id === Number(subCategory),
    );
    if (!subcategory) {
      return;
    }

    this.form.get('name').setValue(subcategory.name);
  }

  // TODO: supporting drop behavior inside a angular-material modal requires further investigation
  // public dropped(files: NgxFileDropEntry[]) {
  //   const droppedFile = files[0]; // only support one file upload
  //   if (droppedFile.fileEntry.isFile) {
  //     const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  //     fileEntry.file((file: File) => {
  //       this.readImageFile(file);
  //     });
  //   }
  // }

  ngOnDestroy() {
    this.destroyed$.next(true);
  }

  public fileChangeListener = ($event) => {
    const file: File = $event.target.files[0];

    this.readImageFile(file);
  };

  private readImageFile = async (file: File) => {
    const myReader: FileReader = new FileReader();

    try {
      const allowed = await this.imageService.isAllowedFileType(file);

      myReader.onloadend = (loadEvent: any) => {
        this.data.image = loadEvent.target.result;
        this.emitImageUpdate(file, loadEvent.target.result);
      };
      myReader.readAsDataURL(file);
    } catch (err) {
      this.alertService.error(
        'Please choose an allowed file type: jpg, png, gif or tif.',
      );
    }
  };

  /* The form value of the file input is a fake path. To maintain image file handling within this form
  and to communicate the blob value we actually need for image upload, emit the value and original file to subscribed components
  */
  private emitImageUpdate = (file: File, image) => {
    this.imageFileChanged.emit({ file, image });
  };
}
