import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NewsletterService } from 'src/app/core/services/newsletter.service';
import { AddNewsletterComponent } from './add-newsletter/add-newsletter.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { SiteService } from 'src/app/core/services/site.service';
import { Site } from '@models/site';

@Component({
  selector: 'app-newsletters',
  templateUrl: './newsletters.component.html',
  styleUrls: ['./newsletters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewslettersComponent implements OnInit {
  public isRefreshing = false;
  public searchTerm = '';
  public newsletterCount = 0;
  public site: Site;

  constructor(
    public authService: AuthService,
    private dialog: MatDialog,
    public newsletterService: NewsletterService,
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
  ) {}

  ngOnInit() {
    this.isRefreshing = true;
    this.newsletterService.refreshNewsletters().then(() => {
      this.isRefreshing = false;
    });
    this.siteService.site.subscribe((site) => {
      this.site = site;
    });
  }

  public addNewsletter() {
    const dialogRef = this.dialog.open(AddNewsletterComponent, {
      minWidth: 400,
    });

    // Upon save success, navigate to newly created newsletter
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.router.navigate([`${result.newsletterId}`], {
          relativeTo: this.route,
        });
      }
    });
  }

  public refresh() {
    this.isRefreshing = true;
    this.newsletterService.refreshNewsletters().then(() => {
      this.isRefreshing = false;
    });
  }

  public setNewsletterCount(value) {
    this.newsletterCount = value;
  }

  public openNewsletterTemplates() {
    this.newsletterService.openNewsletterTemplates(this.site.trainingGuideUrl);
  }
}
