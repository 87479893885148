<button
  mat-raised-button
  [color]="color"
  [disabled]="disabled || submitting"
  (click)="onSubmit()"
  type="submit"
  class="flex justify-center items-center content-center"
>
  <span *ngIf="!submitting">{{ text }}</span>
  <mat-spinner *ngIf="submitting" diameter="32"></mat-spinner>
</button>
