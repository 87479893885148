import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageDto } from '@interfaces/image';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ImageService } from 'src/app/core/services/image.service';
import {
  ConfirmationDialogValues,
  ConfirmationDialogComponent,
} from '../modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-attachment-card',
  templateUrl: './attachment-card.component.html',
  styleUrls: ['./attachment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentCardComponent implements OnInit {
  @Input() attachment: ImageDto;
  @Input() marginTopClass = false;
  @Output() attachmentDeleted: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private alertService: AlertService,
    public authService: AuthService,
    private dialog: MatDialog,
    public imageService: ImageService
  ) {}

  ngOnInit(): void {}

  public openAttachment(attachment: ImageDto): void {
    window.open(attachment.downloadUrl, '_blank');
  }

  public deleteAttachment(image: ImageDto) {
    const confirmData: ConfirmationDialogValues = {
      message: 'Do you want to delete this attachment? This cannot be undone.',
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        image.deleted = true;
        this.imageService
          .setBackgroundImageDeleted(image)
          .then((image) => {
            this.attachmentDeleted.next(true);
          })
          .catch((error) =>
            this.alertService.error(
              'Error deleting attachment. Please try again.'
            )
          );
      }
    });
  }
}
