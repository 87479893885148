import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from '@models/user';
import { SiteService } from 'src/app/core/services/site.service';
import { Router } from '@angular/router';
import packageJson from '../../../../package.json';
const { version } = packageJson;
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BehaviorSubject, Subject } from 'rxjs';
import { SiteDto } from '@interfaces/site';
import { takeUntil } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  public user: User;
  public clientVersion = version;
  public sites = [];
  public filteredSites: BehaviorSubject<SiteDto[]> = new BehaviorSubject([]);
  @ViewChild('siteSearchInput') siteSearchInput: ElementRef;

  private destroyed$ = new Subject();
  public tempSite = '';

  constructor(
    public auth: AuthService,
    public siteService: SiteService,
    private router: Router
  ) {}

  ngOnInit() {
    this.auth.user.pipe(takeUntil(this.destroyed$)).subscribe((user: User) => {
      this.user = user;
      this.filteredSites.next(user.sites);
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
  }

  public siteSelected(option): void {
    this.router.navigate([`/manage/content/${option.value.id}`]);
  }

  getOptionName(site: SiteDto): string {
    if (site) {
      return site.name;
    } else {
      return '';
    }
  }

  clearOnFocus() {
    this.tempSite = this.siteSearchInput.nativeElement.value;
    this.siteSearchInput.nativeElement.value = '';
  }

  setOnBlur() {
    if (this.siteSearchInput.nativeElement.value.trim() === '') {
      this.siteSearchInput.nativeElement.value = this.tempSite;
    }
  }

  public filterSites = (site: String) => {
    if (site) {
      const filterValue = site.toLowerCase();
      this.filteredSites.next(
        this.user.sites.filter((site) =>
          site.name.toLowerCase().includes(filterValue)
        )
      );
    } else {
      // if query is empty, return full list of sites
      this.filteredSites.next(this.user.sites);
    }
  };
}
