import { get } from 'src/app/core/utils/fetch-utils';
import { environment } from 'src/environments/environment';
import { Event } from '../../../../../../../core/interfaces/event';

export function getEventsForCalendar(
  siteId: number,
  startDate: string,
  endDate: string,
): Promise<{ [day: number]: Array<Event> }> {
  const url = `${environment.apiUrl}/api/v1/events/all/${siteId}/${startDate}/${endDate}`;
  return get(url);
}
