import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Announcement } from '@models/announcement';
import { SiteService } from 'src/app/core/services/site.service';
import { Style } from '@models/style';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-slide-type-icon',
  templateUrl: './slide-type-icon.component.html',
  styleUrls: ['./slide-type-icon.component.scss'],
})
export class SlideTypeIconComponent implements OnInit, OnDestroy {
  @Input() announcement: Announcement;
  public styles: Style[] = [];

  constructor(private siteService: SiteService) {}

  ngOnInit() {
    this.siteService.styles.subscribe((styles: Style[]) => {
      this.styles = styles;
    });
  }

  ngOnDestroy() {}

  get styleName() {
    const style = this.styles.find((s) => s.id === this.announcement.styleId);
    return style ? style.name : null;
  }
}
