import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import {
  ConfirmationDialogValues,
  ConfirmationDialogComponent,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import { EditRoomComponent } from '../forms/edit-room/edit-room.component';
import { AddRoomComponent } from '../forms/add-room/add-room.component';
import { Room } from '@models/room';
import { AlertService } from 'src/app/core/services/alert.service';
import { RoomService } from 'src/app/core/services/room.service';
import { GenericFEDataSource } from '../pagination-client-side/datasources/genericFE.datasource';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import bootstrapTable from '../pagination-server-side/bootstrap-table';

interface RoomRow {
  id: number;
  name: string;
  shortName: string;
}

@Component({
  selector: 'app-rooms-table',
  templateUrl: './rooms-table.component.html',
  styleUrls: ['./rooms-table.component.scss'],
})
export class RoomsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() set filter(filterValue: string) {
    if (this.dataSource) {
      this.searchFilter$.next(filterValue);
    }
  }

  private searchFilter$ = new Subject();
  private destroyed = new Subject<boolean>();

  public dataSource: MatTableDataSource<RoomRow>;
  public paginatedDataSource: GenericFEDataSource<Room>;

  public displayedColumns = ['id', 'name', 'shortName', 'actions'];
  public roomsList: Room[] = [];

  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(''),
    verified: new UntypedFormControl(false),
    showDeleted: new UntypedFormControl(false),
  });

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    public roomService: RoomService,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);

    this.paginatedDataSource = new GenericFEDataSource<Room>(
      this.roomService.rooms,
      {
        sortingDataAccessor: this.sortingDataAccessor,
        filterBySearch: this.searchFilter,
        // Remove system generated rooms (ids 1 and 2)
        alwaysFilter: {
          id: { values: [1, 2], convertType: 'numeric' },
        },
      },
    );
  }

  ngAfterViewInit(): void {
    bootstrapTable<Room[]>(
      this.sort,
      this.paginator,
      this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
      this.filters,
    )
      .pipe(takeUntil(this.destroyed))
      .subscribe();

    this.searchFilter$.pipe(takeUntil(this.destroyed)).subscribe((value) => {
      this.filters.controls['search'].setValue(value);
    });
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  public editRoom(room: Room): void {
    const dialogRef = this.dialog.open(EditRoomComponent, {
      data: room,
    });
  }

  public deleteRoom(room: Room): void {
    const confirmData: ConfirmationDialogValues = {
      message: 'Do you want to delete this room? This cannot be undone.',
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.roomService.deleteRoom(room.id).then(() => {
          this.roomService.refreshRooms();
          this.alertService.success('Room deleted.');
        });
      }
    });
  }

  public addRoom(): void {
    const dialogRef = this.dialog.open(AddRoomComponent, {});
  }

  private searchFilter(data, filter): boolean {
    if (filter.length === 0) {
      return true;
    }
    const roomNameMatch = data.name
      ? data.name.toLowerCase().indexOf(filter.toLowerCase())
      : -1;
    const roomShortNameMatch = data.shortName
      ? data.shortName.toLowerCase().indexOf(filter.toLowerCase())
      : -1;
    const idMatch = data.id
      ? data.id.toString().indexOf(filter.toString())
      : -1;

    return roomNameMatch !== -1 || roomShortNameMatch !== -1 || idMatch !== -1;
  }

  sortingDataAccessor = (item, property) => {
    switch (property) {
      case 'id': {
        return item.id ? Number(item.id) : 0;
      }
      case 'name': {
        return `${item.name ? item.name.toLowerCase() : ''}`;
      }
      case 'shortName': {
        return `${item.shortName ? item.shortName.toLowerCase() : ''}`;
      }
      default:
        return item[property];
    }
  };
}
