<mat-card
  appearance="outlined"
  color="primary"
  style="overflow-y: auto; max-height: 85vh"
>
  <mat-card-header class="flex justify-between items-center content-center">
    <mat-card-title>Newsletters ({{ newsletterCount }})</mat-card-title>
    <div
      class="control-buttons buttons flex justify-between items-baseline place-content-stretch gap-[10px]"
    >
      <button mat-raised-button color="accent" (click)="addNewsletter()">
        Create Newsletter
      </button>
      <button
        mat-raised-button
        color="accent"
        (click)="openNewsletterTemplates()"
        *ngIf="site.trainingGuideUrl"
      >
        Training Guide
      </button>
      <mat-form-field color="accent">
        <input matInput placeholder="Search" [(ngModel)]="searchTerm" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </mat-card-header>
  <mat-card-content style="padding-top: 16px">
    <app-newsletter-table
      (updated)="refresh()"
      (newsletterCount)="setNewsletterCount($event)"
      class="flex flex-col"
      [showProperty]="true"
      [filter]="searchTerm"
      dataStyle="org"
    ></app-newsletter-table>
  </mat-card-content>
</mat-card>
