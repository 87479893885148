<div class="form" [formGroup]="eventDetailsForm">
  <div *ngIf="imageDeleted" class="image-warning">
    <span
      >Please contact the administrator to add a new background image for this
      subcategory or select another option.</span
    >
  </div>
  <h2 class="subheading">Event Details</h2>
  <div class="form-row form-wrapper">
    <div class="form-row event-detail-row">
      <div class="form-column questions">
        <div class="overview">
          <div class="form-category">
            <div class="error-wrapper">
              <app-category-select
                [isRequired]="true"
                [categoryControl]="eventDetailsForm.controls['subcategory']"
                (changeHandler)="onSubcatSelect($event)"
              >
              </app-category-select>
            </div>
          </div>
          <div class="error-wrapper">
            <mat-form-field appearance="outline" color="accent">
              <mat-label color="accent">Event Title</mat-label>
              <input
                autofocus
                matInput
                required
                formControlName="title"
                type="text"
                [maxLength]="45"
                data-qa="event-input-title"
              />

              <mat-hint
                ><span
                  >{{
                    eventDetailsForm?.controls?.title?.value?.length || 0
                  }}/45 </span
                ><span
                  *ngIf="
                    titleControl.touched && titleControl.hasError('required')
                  "
                  class="error"
                >
                  Title is required
                </span></mat-hint
              >
            </mat-form-field>
          </div>
          <div class="error-wrapper">
            <mat-form-field appearance="outline" color="accent">
              <mat-label color="accent">Event Details</mat-label>
              <input
                autofocus
                matInput
                formControlName="content"
                type="text"
                [maxLength]="200"
                data-qa="event-input-details"
              />
              <mat-hint
                >{{
                  eventDetailsForm?.controls?.content?.value?.length || 0
                }}/200</mat-hint
              >
            </mat-form-field>
          </div>
        </div>
        <div id="event-type-select">
          <app-event-type-select
            [eventTypeControl]="eventDetailsForm.controls['eventType']"
          >
          </app-event-type-select>
        </div>

        <div class="form-room">
          <app-room-select
            palette="grey"
            direction="column"
            [roomControl]="eventDetailsForm.controls['roomId']"
            [locationControl]="eventDetailsForm.controls['location']"
            [videoUrlControl]="eventDetailsForm.controls['videoUrl']"
          >
          </app-room-select>
        </div>

        <div class="row">
          <mat-checkbox formControlName="showOnPrintCalendar"
            >Show on Print Calendar</mat-checkbox
          >
        </div>
        <div class="row">
          <mat-checkbox formControlName="showOnDigitalSignage"
            >Show on Digital Signage</mat-checkbox
          >
        </div>
      </div>

      <div class="form-column displays">
        <div class="form-column">
          <div>Gigi Mobile App Icon</div>

          <div class="icon-container">
            <img
              [src]="'assets/icons/event-tag/' + categoryIcon + '.svg'"
              *ngIf="categoryIcon; else iconPlaceholder"
            />
            <ng-template #iconPlaceholder>
              <div class="icon-placeholder"></div
            ></ng-template>
          </div>
        </div>
        <div class="form-column">
          <h2>Digital Signage</h2>
          <div>
            <div
              class="thumbnail-container form-column"
              *ngIf="placeHolderImageUrl; else imagePlaceholder"
            >
              <img [src]="placeHolderImageUrl" />
            </div>
            <ng-template #imagePlaceholder>
              <div class="thumbnail-container form-column image-placeholder">
                {{
                  imageDeleted
                    ? 'The image for the selected subcategory has been removed.'
                    : 'Select a category to preview the event image.'
                }}
              </div></ng-template
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
