import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Announcement } from '@models/announcement';
import { Image } from '@models/image';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { AlertService } from 'src/app/core/services/alert.service';
import { ImageService } from 'src/app/core/services/image.service';
import { ManageService } from 'src/app/core/services/manage.service';

export interface FormValues {
  title: string;
  description: string;
  image: Image;
}
@Component({
  selector: 'app-slide-description-gif',
  templateUrl: './slide-description-gif.component.html',
  styleUrls: ['./slide-description-gif.component.scss'],
})
export class SlideDescriptionGifComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  @Input() public announcement: Announcement;
  public maxTitleLength: number;
  public maxDescriptionLength: number;
  public progress = 0;

  static deserializeToForm(data: Announcement): FormValues {
    return {
      title: data.title,
      description: data.content,
      image: data.secondaryImages[0],
    };
  }

  static serialize(form: FormValues) {
    return {
      title: form.title,
      description: form.description,
      image: form.image,
    };
  }

  static formModel(announcementToEdit?: Announcement) {
    return {
      title: new UntypedFormControl(announcementToEdit?.title || ''),
      description: new UntypedFormControl(announcementToEdit?.content || ''),
      image: new UntypedFormControl(
        announcementToEdit?.secondaryImages[0] || '',
        Validators.required,
      ),
    };
  }
  constructor(
    private imageService: ImageService,
    public manageService: ManageService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.maxTitleLength = this.manageService.getMaxTitleChars(
      this.announcement.styleId,
    );
    this.maxDescriptionLength = this.manageService.getMaxDescriptionChars(
      this.announcement.styleId,
    );
    const existingImages = this.announcement?.secondaryImages?.length;
    if (existingImages) {
      this.form.get('image').setValue(this.announcement.secondaryImages[0]);
    }
  }

  // Image resize accounts for roughly 2/3rd of the upload time
  public handleLoading = (value) => {
    this.progress = value * (2 / 3);
  };

  public dropped(files: NgxFileDropEntry[]) {
    const droppedFile = files[0]; // Only support one file upload
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.handleImageFileChange(file);
      });
    }
  }

  public uploadInputChanged(event) {
    const file: File = event.target.files[0];
    this.handleImageFileChange(file);
  }

  async handleImageFileChange(file: File) {
    if (await this.imageService.isAllowedFileType(file)) {
      this.progress = 20;
      const path = await this.imageService.uploadToFirestore(
        this.imageService.getSiteBackgroundImageFilePath(
          this.announcement.siteId,
        ),
        file,
      );
      this.progress = 80;
      const downloadUrl = await this.imageService.getDownloadUrl(path);
      const response = await this.manageService.createImageRecord(
        downloadUrl,
        path,
        this.announcement.siteId,
        this.announcement.styleId,
        this.announcement.id,
      );
      const formFieldName = `image`;
      this.form.patchValue({ [formFieldName]: response });
      this.progress = 100;
      setTimeout(() => (this.progress = 0), 100);
    } else {
      this.progress = 0;
      this.alertService.error(
        'Please choose an allowed file type: jpg, png, gif or tif.',
      );
    }
  }

  async deleteImage() {
    this.form.patchValue({ image: null });
    this.form.controls['image'].updateValueAndValidity();
  }
}
