<ng-container *ngIf="!fillContainer">
  <mat-spinner diameter="60" color="accent"></mat-spinner>
</ng-container>

<ng-container *ngIf="fillContainer">
  <div
    class="flex flex-col justify-center items-center content-center w-full min-w-full h-full min-h-full m-0"
  >
    <app-material-loader></app-material-loader>
  </div>
</ng-container>
