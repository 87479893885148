<div class="flex justify-between items-start content-start">
  <h1 mat-dialog-title class="mat-dialog-title text-2xl">Edit Participant</h1>
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="p-4 w-[400px]">
    <h2 class="text-lg pb-4">Authentication Details</h2>
    <app-email-form [form]="emailForm"></app-email-form>
    <app-progress-button
      color="accent"
      text="Save"
      (submit)="onSave()"
      [disabled]="
        this.emailForm.invalid ||
        this.participant?.user?.email === this.emailForm?.value?.email
      "
      [submitting]="submitting"
    >
    </app-progress-button>
  </div>
</mat-dialog-content>
