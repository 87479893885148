export const AnnouncementType = {
  IndividualSpotlight: 1,
  Event: 3,
  Announcement: 4,
  SpecialAnnouncement: 5,
  NameInLights: 7,
  ImageCentric: 12,
  Collage: 13,
  GifCentric: 30,
};
