import { Component, OnInit, Input } from '@angular/core';
import { Announcement } from '@models/announcement';

@Component({
  selector: 'app-display-individual-spotlight',
  templateUrl: './display-individual-spotlight.component.html',
  styleUrls: ['./display-individual-spotlight.component.scss'],
})
export class DisplayIndividualSpotlightComponent implements OnInit {
  @Input() spotlight: Announcement;
  @Input() showClock = false;

  constructor() {}

  ngOnInit() {}
}
