import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class HolidayService {
  private _holidayData: Array<DayHolidays> = Object.entries({
    // 2022 Holidays
    '2022-12-18': [{ name: 'Hanukkah Begins', date: '2022-12-18' }],
    '2022-12-21': [{ name: 'Winter Begins', date: '2022-12-21' }],
    '2022-12-25': [{ name: 'Christmas Day', date: '2022-12-25' }],
    '2022-12-31': [{ name: "New Year's Eve", date: '2022-12-31' }],

    // 2023 Holidays
    '2023-01-01': [{ name: "New Year's Day", date: '2023-01-01' }],
    '2023-01-16': [{ name: 'M.L. King, Jr. Day', date: '2023-01-16' }],
    '2023-02-14': [{ name: "Valentine's Day", date: '2023-02-14' }],
    '2023-02-20': [{ name: "Presidents' Day", date: '2023-02-20' }],
    '2023-02-22': [{ name: 'Ash Wednesday', date: '2023-02-22' }],
    '2023-03-12': [{ name: 'Daylight Saving Begins', date: '2023-03-12' }],
    '2023-03-17': [{ name: "St. Patrick's Day", date: '2023-03-17' }],
    '2023-03-20': [{ name: 'Spring Begins', date: '2023-03-20' }],
    '2023-04-05': [{ name: 'Passover Begins', date: '2023-04-05' }],
    '2023-04-07': [{ name: 'Good Friday', date: '2023-04-07' }],
    '2023-04-09': [{ name: 'Easter', date: '2023-04-09' }],
    '2023-04-22': [{ name: 'Earth Day', date: '2023-04-22' }],
    '2023-05-01': [{ name: 'May Day', date: '2023-05-01' }],
    '2023-05-14': [{ name: "Mother's Day", date: '2023-05-14' }],
    '2023-05-29': [{ name: 'Memorial Day', date: '2023-05-29' }],
    '2023-06-14': [{ name: 'Flag Day', date: '2023-06-14' }],
    '2023-06-18': [{ name: "Father's Day", date: '2023-06-18' }],
    '2023-06-19': [{ name: 'Juneteenth', date: '2023-06-19' }],
    '2023-06-21': [{ name: 'Summer Begins', date: '2023-06-21' }],
    '2023-07-04': [{ name: 'Independence Day', date: '2023-07-04' }],
    '2023-09-04': [{ name: 'Labor Day', date: '2023-09-04' }],
    '2023-09-11': [{ name: 'Patriot Day', date: '2023-09-11' }],
    '2023-09-15': [{ name: 'Rosh Hashanah Begins', date: '2023-09-15' }],
    '2023-09-23': [{ name: 'Autumn Begins', date: '2023-09-23' }],
    '2023-09-24': [{ name: 'Yom Kippur Begins', date: '2023-09-24' }],
    '2023-10-09': [{ name: 'Columbus Day', date: '2023-10-09' }],
    '2023-10-31': [{ name: 'Halloween', date: '2023-10-31' }],
    '2023-11-05': [{ name: 'Daylight Saving Ends', date: '2023-11-05' }],
    '2023-11-11': [{ name: 'Veterans Day', date: '2023-11-11' }],
    '2023-11-23': [{ name: 'Thanksgiving Day', date: '2023-11-23' }],
    '2023-12-07': [{ name: 'Hanukkah Begins', date: '2023-12-07' }],
    '2023-12-22': [{ name: 'Winter Begins', date: '2023-12-22' }],
    '2023-12-25': [{ name: 'Christmas Day', date: '2023-12-25' }],
    '2023-12-31': [{ name: "New Year's Eve", date: '2023-12-31' }],

    // 2024 Holidays
    '2024-01-01': [{ name: "New Year's Day", date: '2024-01-01' }],
    '2024-01-15': [{ name: 'M.L. King, Jr. Day', date: '2024-01-15' }],
    '2024-02-14': [
      { name: 'Ash Wednesday', date: '2024-02-14' },
      { name: "Valentine's Day", date: '2024-02-14' },
    ],
    '2024-02-19': [{ name: "Presidents' Day", date: '2024-02-19' }],
    '2024-03-10': [{ name: 'Daylight Saving Begins', date: '2024-03-10' }],
    '2024-03-17': [{ name: "St. Patrick's Day", date: '2024-03-17' }],
    '2024-03-19': [{ name: 'Spring Begins', date: '2024-03-19' }],
    '2024-03-29': [{ name: 'Good Friday', date: '2024-03-29' }],
    '2024-03-31': [{ name: 'Easter', date: '2024-03-31' }],
    '2024-04-22': [
      { name: 'Passover Begins', date: '2024-04-22' },
      { name: 'Earth Day', date: '2024-04-22' },
    ],
    '2024-05-01': [{ name: 'May Day', date: '2024-05-01' }],
    '2024-05-12': [{ name: "Mother's Day", date: '2024-05-12' }],
    '2024-05-27': [{ name: 'Memorial Day', date: '2024-05-27' }],
    '2024-06-14': [{ name: 'Flag Day', date: '2024-06-14' }],
    '2024-06-16': [{ name: "Father's Day", date: '2024-06-16' }],
    '2024-06-19': [{ name: 'Juneteenth', date: '2024-06-19' }],
    '2024-06-20': [{ name: 'Summer Begins', date: '2024-06-20' }],
    '2024-07-04': [{ name: 'Independence Day', date: '2024-07-04' }],
    '2024-09-02': [{ name: 'Labor Day', date: '2024-09-02' }],
    '2024-09-11': [{ name: 'Patriot Day', date: '2024-09-11' }],
    '2024-09-22': [{ name: 'Autumn Begins', date: '2024-09-22' }],
    '2024-10-02': [{ name: 'Rosh Hashanah Begins', date: '2024-10-02' }],
    '2024-10-11': [{ name: 'Yom Kippur Begins', date: '2024-10-11' }],
    '2024-10-14': [{ name: 'Columbus Day', date: '2024-10-14' }],
    '2024-10-31': [{ name: 'Halloween', date: '2024-10-31' }],
    '2024-11-03': [{ name: 'Daylight Saving Ends', date: '2024-11-03' }],
    '2024-11-05': [{ name: 'Election Day', date: '2024-11-05' }],
    '2024-11-11': [{ name: 'Veterans Day', date: '2024-11-11' }],
    '2024-11-28': [{ name: 'Thanksgiving Day', date: '2024-11-28' }],
    '2024-12-21': [{ name: 'Winter Begins', date: '2024-12-21' }],
    '2024-12-25': [
      { name: 'Christmas Day', date: '2024-12-25' },
      { name: 'Hanukkah Begins', date: '2024-12-25' },
    ],
    '2024-12-31': [{ name: "New Year's Eve", date: '2024-12-31' }],

    // 2025 Holidays
    '2025-01-01': [{ name: "New Year's Day", date: '2025-01-01' }],
    '2025-01-20': [{ name: 'M.L. King, Jr. Day', date: '2025-01-20' }],
    '2025-02-14': [{ name: "Valentine's Day", date: '2025-02-14' }],
    '2025-02-17': [{ name: "Presidents' Day", date: '2025-02-17' }],
    '2025-03-05': [{ name: 'Ash Wednesday', date: '2025-03-05' }],
    '2025-03-09': [{ name: 'Daylight Saving Begins', date: '2025-03-09' }],
    '2025-03-17': [{ name: "St. Patrick's Day", date: '2025-03-17' }],
    '2025-03-20': [{ name: 'Spring Begins', date: '2025-03-20' }],
    '2025-04-12': [{ name: 'Passover Begins', date: '2025-04-12' }],
    '2025-04-18': [{ name: 'Good Friday', date: '2025-04-18' }],
    '2025-04-20': [{ name: 'Easter', date: '2025-04-20' }],
    '2025-04-22': [{ name: 'Earth Day', date: '2025-04-22' }],
    '2025-05-01': [{ name: 'May Day', date: '2025-05-01' }],
    '2025-05-11': [{ name: "Mother's Day", date: '2025-05-11' }],
    '2025-05-26': [{ name: 'Memorial Day', date: '2025-05-26' }],
    '2025-06-14': [{ name: 'Flag Day', date: '2025-06-14' }],
    '2025-06-15': [{ name: "Father's Day", date: '2025-06-15' }],
    '2025-06-19': [{ name: 'Juneteenth', date: '2025-06-19' }],
    '2025-06-21': [{ name: 'Summer Begins', date: '2025-06-21' }],
    '2025-07-04': [{ name: 'Independence Day', date: '2025-07-04' }],
    '2025-09-01': [{ name: 'Labor Day', date: '2025-09-01' }],
    '2025-09-11': [{ name: 'Patriot Day', date: '2025-09-11' }],
    '2025-09-22': [
      { name: 'Rosh Hashanah Begins', date: '2025-09-22' },
      { name: 'Autumn Begins', date: '2025-09-22' },
    ],
    '2025-10-01': [{ name: 'Yom Kippur Begins', date: '2025-10-01' }],
    '2025-10-13': [{ name: 'Columbus Day', date: '2025-10-13' }],
    '2025-10-31': [{ name: 'Halloween', date: '2025-10-31' }],
    '2025-11-02': [{ name: 'Daylight Saving Ends', date: '2025-11-02' }],
    '2025-11-11': [{ name: 'Veterans Day', date: '2025-11-11' }],
    '2025-11-27': [{ name: 'Thanksgiving Day', date: '2025-11-27' }],
    '2025-12-14': [{ name: 'Hanukkah Begins', date: '2025-12-14' }],
    '2025-12-21': [{ name: 'Winter Begins', date: '2025-12-21' }],
    '2025-12-25': [{ name: 'Christmas Day', date: '2025-12-25' }],
    '2025-12-31': [{ name: "New Year's Eve", date: '2025-12-31' }],

    // 2026 Holidays
    '2026-01-01': [{ name: "New Year's Day", date: '2026-01-01' }],
    '2026-01-19': [{ name: 'M.L. King, Jr. Day', date: '2026-01-19' }],
    '2026-02-14': [{ name: "Valentine's Day", date: '2026-02-14' }],
    '2026-02-16': [{ name: "Presidents' Day", date: '2026-02-16' }],
    '2026-02-18': [{ name: 'Ash Wednesday', date: '2026-02-18' }],
    '2026-03-08': [{ name: 'Daylight Saving Begins', date: '2026-03-08' }],
    '2026-03-17': [{ name: "St. Patrick's Day", date: '2026-03-17' }],
    '2026-03-20': [{ name: 'Spring Begins', date: '2026-03-20' }],
    '2026-04-01': [{ name: 'Passover Begins', date: '2026-04-01' }],
    '2026-04-03': [{ name: 'Good Friday', date: '2026-04-03' }],
    '2026-04-05': [{ name: 'Easter', date: '2026-04-05' }],
    '2026-04-22': [{ name: 'Earth Day', date: '2026-04-22' }],
    '2026-05-01': [{ name: 'May Day', date: '2026-05-01' }],
    '2026-05-10': [{ name: "Mother's Day", date: '2026-05-10' }],
    '2026-05-25': [{ name: 'Memorial Day', date: '2026-05-25' }],
    '2026-06-14': [{ name: 'Flag Day', date: '2026-06-14' }],
    '2026-06-19': [{ name: 'Juneteenth', date: '2026-06-19' }],
    '2026-06-21': [
      { name: "Father's Day", date: '2026-06-21' },
      { name: 'Summer Begins', date: '2026-06-21' },
    ],
    '2026-07-04': [{ name: 'Independence Day', date: '2026-07-04' }],
    '2026-09-07': [{ name: 'Labor Day', date: '2026-09-07' }],
    '2026-09-11': [
      { name: 'Rosh Hashanah Begins', date: '2026-09-11' },
      { name: 'Patriot Day', date: '2026-09-11' },
    ],
    '2026-09-20': [{ name: 'Yom Kippur Begins', date: '2026-09-20' }],
    '2026-09-23': [{ name: 'Autumn Begins', date: '2026-09-23' }],
    '2026-10-12': [{ name: 'Columbus Day', date: '2026-10-12' }],
    '2026-10-31': [{ name: 'Halloween', date: '2026-10-31' }],
    '2026-11-01': [{ name: 'Daylight Saving Ends', date: '2026-11-01' }],
    '2026-11-11': [{ name: 'Veterans Day', date: '2026-11-11' }],
    '2026-11-26': [{ name: 'Thanksgiving Day', date: '2026-11-26' }],
    '2026-12-04': [{ name: 'Hanukkah Begins', date: '2026-12-04' }],
    '2026-12-21': [{ name: 'Winter Begins', date: '2026-12-21' }],
    '2026-12-25': [{ name: 'Christmas Day', date: '2026-12-25' }],
    '2026-12-31': [{ name: "New Year's Eve", date: '2026-12-31' }],

    // 2027 Holidays
    '2027-01-01': [{ name: "New Year's Day", date: '2027-01-01' }],
    '2027-01-18': [{ name: 'M.L. King, Jr. Day', date: '2027-01-18' }],
    '2027-02-10': [{ name: 'Ash Wednesday', date: '2027-02-10' }],
    '2027-02-14': [{ name: "Valentine's Day", date: '2027-02-14' }],
    '2027-02-15': [{ name: "Presidents' Day", date: '2027-02-15' }],
    '2027-03-14': [{ name: 'Daylight Saving Begins', date: '2027-03-14' }],
    '2027-03-17': [{ name: "St. Patrick's Day", date: '2027-03-17' }],
    '2027-03-20': [{ name: 'Spring Begins', date: '2027-03-20' }],
    '2027-03-26': [{ name: 'Good Friday', date: '2027-03-26' }],
    '2027-03-28': [{ name: 'Easter', date: '2027-03-28' }],
    '2027-04-21': [{ name: 'Passover Begins', date: '2027-04-21' }],
    '2027-04-22': [{ name: 'Earth Day', date: '2027-04-22' }],
    '2027-05-01': [{ name: 'May Day', date: '2027-05-01' }],
    '2027-05-09': [{ name: "Mother's Day", date: '2027-05-09' }],
    '2027-05-31': [{ name: 'Memorial Day', date: '2027-05-31' }],
    '2027-06-14': [{ name: 'Flag Day', date: '2027-06-14' }],
    '2027-06-19': [{ name: 'Juneteenth', date: '2027-06-19' }],
    '2027-06-20': [{ name: "Father's Day", date: '2027-06-20' }],
    '2027-06-21': [{ name: 'Summer Begins', date: '2027-06-21' }],
    '2027-07-04': [{ name: 'Independence Day', date: '2027-07-04' }],
    '2027-09-06': [{ name: 'Labor Day', date: '2027-09-06' }],
    '2027-09-11': [{ name: 'Patriot Day', date: '2027-09-11' }],
    '2027-09-23': [{ name: 'Autumn Begins', date: '2027-09-23' }],
    '2027-10-01': [{ name: 'Rosh Hashanah Begins', date: '2027-10-01' }],
    '2027-10-10': [{ name: 'Yom Kippur Begins', date: '2027-10-10' }],
    '2027-10-11': [{ name: 'Columbus Day', date: '2027-10-11' }],
    '2027-10-31': [{ name: 'Halloween', date: '2027-10-31' }],
    '2027-11-07': [{ name: 'Daylight Saving Ends', date: '2027-11-07' }],
    '2027-11-11': [{ name: 'Veterans Day', date: '2027-11-11' }],
    '2027-11-25': [{ name: 'Thanksgiving Day', date: '2027-11-25' }],
    '2027-12-22': [{ name: 'Winter Begins', date: '2027-12-22' }],
    '2027-12-24': [{ name: 'Hanukkah Begins', date: '2027-12-24' }],
    '2027-12-25': [{ name: 'Christmas Day', date: '2027-12-25' }],
    '2027-12-31': [{ name: "New Year's Eve", date: '2027-12-31' }],

    // 2028 Holidays
    '2028-01-01': [{ name: "New Year's Day", date: '2028-01-01' }],
    '2028-01-17': [{ name: 'M.L. King, Jr. Day', date: '2028-01-17' }],
    '2028-02-14': [{ name: "Valentine's Day", date: '2028-02-14' }],
    '2028-02-21': [{ name: "Presidents' Day", date: '2028-02-21' }],
    '2028-03-01': [{ name: 'Ash Wednesday', date: '2028-03-01' }],
    '2028-03-12': [{ name: 'Daylight Saving Begins', date: '2028-03-12' }],
    '2028-03-14': [{ name: 'Good Friday', date: '2028-03-14' }],
    '2028-03-17': [{ name: "St. Patrick's Day", date: '2028-03-17' }],
    '2028-03-20': [{ name: 'Spring Begins', date: '2028-03-20' }],
    '2028-04-10': [{ name: 'Passover Begins', date: '2028-04-10' }],
    '2028-04-16': [{ name: 'Easter', date: '2028-04-16' }],
    '2028-04-22': [{ name: 'Earth Day', date: '2028-04-22' }],
    '2028-05-01': [{ name: 'May Day', date: '2028-05-01' }],
    '2028-05-14': [{ name: "Mother's Day", date: '2028-05-14' }],
    '2028-05-29': [{ name: 'Memorial Day', date: '2028-05-29' }],
    '2028-06-14': [{ name: 'Flag Day', date: '2028-06-14' }],
    '2028-06-18': [{ name: "Father's Day", date: '2028-06-18' }],
    '2028-06-19': [{ name: 'Juneteenth', date: '2028-06-19' }],
    '2028-06-20': [{ name: 'Summer Begins', date: '2028-06-20' }],
    '2028-07-04': [{ name: 'Independence Day', date: '2028-07-04' }],
    '2028-09-04': [{ name: 'Labor Day', date: '2028-09-04' }],
    '2028-09-11': [{ name: 'Patriot Day', date: '2028-09-11' }],
    '2028-09-20': [{ name: 'Rosh Hashanah Begins', date: '2028-09-20' }],
    '2028-09-22': [{ name: 'Autumn Begins', date: '2028-09-22' }],
    '2028-09-29': [{ name: 'Yom Kippur Begins', date: '2028-09-29' }],
    '2028-10-09': [{ name: 'Columbus Day', date: '2028-10-09' }],
    '2028-10-31': [{ name: 'Halloween', date: '2028-10-31' }],
    '2028-11-05': [{ name: 'Daylight Saving Ends', date: '2028-11-05' }],
    '2028-11-07': [{ name: 'Election Day', date: '2028-11-07' }],
    '2028-11-11': [{ name: 'Veterans Day', date: '2028-11-11' }],
    '2028-11-23': [{ name: 'Thanksgiving Day', date: '2028-11-23' }],
    '2028-12-12': [{ name: 'Hanukkah Begins', date: '2028-12-12' }],
    '2028-12-21': [{ name: 'Winter Begins', date: '2028-12-21' }],
    '2028-12-25': [{ name: 'Christmas Day', date: '2028-12-25' }],
    '2028-12-31': [{ name: "New Year's Eve", date: '2028-12-31' }],

    // 2029 Holidays
    '2029-01-01': [{ name: "New Year's Day", date: '2029-01-01' }],
    '2029-01-15': [{ name: 'M.L. King, Jr. Day', date: '2029-01-15' }],
    '2029-02-14': [
      { name: 'Ash Wednesday', date: '2029-02-14' },
      { name: "Valentine's Day", date: '2029-02-14' },
    ],
    '2029-02-19': [{ name: "Presidents' Day", date: '2029-02-19' }],
    '2029-03-11': [{ name: 'Daylight Saving Begins', date: '2029-03-11' }],
    '2029-03-17': [{ name: "St. Patrick's Day", date: '2029-03-17' }],
    '2029-03-20': [{ name: 'Spring Begins', date: '2029-03-20' }],
    '2029-03-30': [
      { name: 'Passover Begins', date: '2029-03-30' },
      { name: 'Good Friday', date: '2029-03-30' },
    ],
    '2029-04-01': [{ name: 'Easter', date: '2029-04-01' }],
    '2029-04-22': [{ name: 'Earth Day', date: '2029-04-22' }],
    '2029-05-01': [{ name: 'May Day', date: '2029-05-01' }],
    '2029-05-13': [{ name: "Mother's Day", date: '2029-05-13' }],
    '2029-05-28': [{ name: 'Memorial Day', date: '2029-05-28' }],
    '2029-06-14': [{ name: 'Flag Day', date: '2029-06-14' }],
    '2029-06-17': [{ name: "Father's Day", date: '2029-06-17' }],
    '2029-06-19': [{ name: 'Juneteenth', date: '2029-06-19' }],
    '2029-06-21': [{ name: 'Summer Begins', date: '2029-06-21' }],
    '2029-07-04': [{ name: 'Independence Day', date: '2029-07-04' }],
    '2029-09-03': [{ name: 'Labor Day', date: '2029-09-03' }],
    '2029-09-09': [{ name: 'Rosh Hashanah Begins', date: '2029-09-09' }],
    '2029-09-11': [{ name: 'Patriot Day', date: '2029-09-11' }],
    '2029-09-18': [{ name: 'Yom Kippur Begins', date: '2029-09-18' }],
    '2029-09-22': [{ name: 'Autumn Begins', date: '2029-09-22' }],
    '2029-10-08': [{ name: 'Columbus Day', date: '2029-10-08' }],
    '2029-10-31': [{ name: 'Halloween', date: '2029-10-31' }],
    '2029-11-04': [{ name: 'Daylight Saving Ends', date: '2029-11-04' }],
    '2029-11-11': [{ name: 'Veterans Day', date: '2029-11-11' }],
    '2029-11-22': [{ name: 'Thanksgiving Day', date: '2029-11-22' }],
    '2029-12-01': [{ name: 'Hanukkah Begins', date: '2029-12-01' }],
    '2029-12-21': [{ name: 'Winter Begins', date: '2029-12-21' }],
    '2029-12-25': [{ name: 'Christmas Day', date: '2029-12-25' }],
    '2029-12-31': [{ name: "New Year's Eve", date: '2029-12-31' }],

    // 2030 Holidays
    '2030-01-01': [{ name: "New Year's Day", date: '2030-01-01' }],
    '2030-01-21': [{ name: 'M.L. King, Jr. Day', date: '2030-01-21' }],
    '2030-02-14': [{ name: "Valentine's Day", date: '2030-02-14' }],
    '2030-02-18': [{ name: "Presidents' Day", date: '2030-02-18' }],
    '2030-03-06': [{ name: 'Ash Wednesday', date: '2030-03-06' }],
    '2030-03-10': [{ name: 'Daylight Saving Begins', date: '2030-03-10' }],
    '2030-03-20': [{ name: 'Spring Begins', date: '2030-03-20' }],
    '2030-04-17': [{ name: 'Passover Begins', date: '2030-04-17' }],
    '2030-04-19': [{ name: 'Good Friday', date: '2030-04-19' }],
    '2030-04-21': [{ name: 'Easter', date: '2030-04-21' }],
    '2030-04-22': [{ name: 'Earth Day', date: '2030-04-22' }],
    '2030-05-01': [{ name: 'May Day', date: '2030-05-01' }],
    '2030-05-12': [{ name: "Mother's Day", date: '2030-05-12' }],
    '2030-05-27': [{ name: 'Memorial Day', date: '2030-05-27' }],
    '2030-06-06': [{ name: "Father's Day", date: '2030-06-06' }],
    '2030-06-19': [{ name: 'Juneteenth', date: '2030-06-19' }],
    '2030-06-14': [{ name: 'Flag Day', date: '2030-06-14' }],
    '2030-06-21': [{ name: 'Summer Begins', date: '2030-06-21' }],
    '2030-07-04': [{ name: 'Independence Day', date: '2030-07-04' }],
    '2030-09-02': [{ name: 'Labor Day', date: '2030-09-02' }],
    '2030-09-11': [{ name: 'Patriot Day', date: '2030-09-11' }],
    '2030-09-22': [{ name: 'Autumn Begins', date: '2030-09-22' }],
    '2030-09-27': [{ name: 'Rosh Hashanah Begins', date: '2030-09-27' }],
    '2030-10-06': [{ name: 'Yom Kippur Begins', date: '2030-10-06' }],
    '2030-10-14': [{ name: 'Columbus Day', date: '2030-10-14' }],
    '2030-10-31': [{ name: 'Halloween', date: '2030-10-31' }],
    '2030-11-03': [{ name: 'Daylight Saving Ends', date: '2030-11-03' }],
    '2030-11-11': [{ name: 'Veterans Day', date: '2030-11-11' }],
    '2030-11-28': [{ name: 'Thanksgiving Day', date: '2030-11-28' }],
    '2030-12-20': [{ name: 'Hanukkah Begins', date: '2030-12-20' }],
    '2030-12-21': [{ name: 'Winter Begins', date: '2030-12-21' }],
    '2030-12-25': [{ name: 'Christmas Day', date: '2030-12-25' }],
    '2030-12-31': [{ name: "New Year's Eve", date: '2030-12-31' }],

    // 2031 Holidays
    '2031-01-01': [{ name: "New Year's Day", date: '2031-01-01' }],
    '2031-01-20': [{ name: 'M.L. King, Jr. Day', date: '2031-01-20' }],
    '2031-02-14': [{ name: "Valentine's Day", date: '2031-02-14' }],
    '2031-02-17': [{ name: "Presidents' Day", date: '2031-02-17' }],
    '2031-02-26': [{ name: 'Ash Wednesday', date: '2031-02-26' }],
    '2031-03-09': [{ name: 'Daylight Saving Begins', date: '2031-03-09' }],
    '2031-03-17': [{ name: "St. Patrick's Day", date: '2031-03-17' }],
    '2031-03-20': [{ name: 'Spring Begins', date: '2031-03-20' }],
    '2031-04-07': [{ name: 'Passover Begins', date: '2031-04-07' }],
    '2031-04-11': [{ name: 'Good Friday', date: '2031-04-11' }],
    '2031-04-13': [{ name: 'Easter', date: '2031-04-13' }],
    '2031-04-22': [{ name: 'Earth Day', date: '2031-04-22' }],
    '2031-05-01': [{ name: 'May Day', date: '2031-05-01' }],
    '2031-05-11': [{ name: "Mother's Day", date: '2031-05-11' }],
    '2031-05-26': [{ name: 'Memorial Day', date: '2031-05-26' }],
    '2031-06-14': [{ name: 'Flag Day', date: '2031-06-14' }],
    '2031-06-15': [{ name: "Father's Day", date: '2031-06-15' }],
    '2031-06-19': [{ name: 'Juneteenth', date: '2031-06-19' }],
    '2031-06-21': [{ name: 'Summer Begins', date: '2031-06-21' }],
    '2031-07-04': [{ name: 'Independence Day', date: '2031-07-04' }],
    '2031-09-01': [{ name: 'Labor Day', date: '2031-09-01' }],
    '2031-09-11': [{ name: 'Patriot Day', date: '2031-09-11' }],
    '2031-09-17': [{ name: 'Rosh Hashanah Begins', date: '2031-09-17' }],
    '2031-09-23': [{ name: 'Autumn Begins', date: '2031-09-23' }],
    '2031-09-26': [{ name: 'Yom Kippur Begins', date: '2031-09-26' }],
    '2031-10-13': [{ name: 'Columbus Day', date: '2031-10-13' }],
    '2031-10-31': [{ name: 'Halloween', date: '2031-10-31' }],
    '2031-11-02': [{ name: 'Daylight Saving Ends', date: '2031-11-02' }],
    '2031-11-11': [{ name: 'Veterans Day', date: '2031-11-11' }],
    '2031-11-27': [{ name: 'Thanksgiving Day', date: '2031-11-27' }],
    '2031-12-09': [{ name: 'Hanukkah Begins', date: '2031-12-09' }],
    '2031-12-22': [{ name: 'Winter Begins', date: '2031-12-22' }],
    '2031-12-25': [{ name: 'Christmas Day', date: '2031-12-25' }],
    '2031-12-31': [{ name: "New Year's Eve", date: '2031-12-31' }],
  }).map((entry) => {
    return { date: entry[0], holidays: entry[1] };
  });

  private _holidays = new BehaviorSubject<any>(this._holidayData);
  public holidays = this._holidays.asObservable();

  constructor() {}

  public getHolidaysForDate(
    date: DateTime,
    filterPhrase?: string
  ): Array<HolidayInfo> {
    const matches: Array<Array<HolidayInfo>> = this._holidayData
      .filter((dayHoliday) => {
        return dayHoliday.date === date.toFormat('yyyy-MM-dd');
      })
      .map((dayHoliday) => {
        if (filterPhrase) {
          let filteredHolidays = {
            date: dayHoliday.date,
            holidays: [],
          };
          filteredHolidays.holidays = dayHoliday.holidays.filter(
            (holiday) => !holiday?.name.includes(filterPhrase)
          );
          return filteredHolidays;
        }
        return dayHoliday;
      })
      .map((dayHoliday) => {
        return dayHoliday.holidays;
      });

    return [].concat(...matches);
  }
}

export interface HolidayInfo {
  name: string;
  date: string;
  country?: string;
  public?: boolean;
}

export interface DayHolidays {
  date: string;
  holidays: Array<HolidayInfo>;
}
