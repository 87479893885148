import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageOrgRoutingModule } from './manage-org-routing.module';
import { SharedModule } from '../shared/shared.module';
import { EditSiteComponent } from './edit-site/edit-site.component';
import { SitesTableComponent } from './sites-table/sites-table.component';
import { AddSiteComponent } from './add-site/add-site.component';
import { UsersTableComponent } from './users-table/users-table.component';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { EditParticipantComponent } from './edit-participant/edit-participant.component';
import { FaqsTableComponent } from './faqs-table/faqs-table.component';
import { EditFaqComponent } from './edit-faq/edit-faq.component';
import { AddFaqComponent } from './add-faq/add-faq.component';
import { BackgroundsGridComponent } from './backgrounds-grid/backgrounds-grid.component';
import { SitesComponent } from './sites/sites.component';
import { UsersComponent } from './users/users.component';
import { SummaryComponent } from './images/summary/summary.component';
import { EditImageComponent } from './images/edit-image/edit-image.component';
import { AddImageComponent } from './images/add-image/add-image.component';
import { ImageTableComponent } from './images/components/image-table/image-table.component';
import { OrgMobileUseComponent } from './org-mobile-use/org-mobile-use.component';
import { NewslettersComponent } from './newsletters/newsletters.component';
import { EditStaffComponent } from './edit-staff/edit-staff.component';
import { MenuComponent } from './menu/menu.component';
import { MenuUploadDialogComponent } from './menu/menu-upload-dialog/menu-upload-dialog.component';
import { MenuUploadButtonComponent } from './menu/menu-upload-button/menu-upload-button.component';

@NgModule({
    declarations: [
        EditSiteComponent,
        SitesTableComponent,
        AddSiteComponent,
        UsersTableComponent,
        AddUserComponent,
        AddImageComponent,
        EditUserComponent,
        EditParticipantComponent,
        FaqsTableComponent,
        EditFaqComponent,
        AddFaqComponent,
        BackgroundsGridComponent,
        SitesComponent,
        UsersComponent,
        SummaryComponent,
        EditImageComponent,
        ImageTableComponent,
        OrgMobileUseComponent,
        NewslettersComponent,
        EditStaffComponent,
        MenuComponent,
        MenuUploadButtonComponent,
        MenuUploadDialogComponent,
    ],
    imports: [
        CommonModule,
        ManageOrgRoutingModule,
        SharedModule,
    ]
})
export class ManageOrgModule {}
