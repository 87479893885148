import { Site } from '../interfaces/announcement';
import { RoomDto } from '../interfaces/room';

export class Room implements RoomDto {
  id: number;
  site: Site;
  name: string;
  shortName: string;
  siteId?: number; // when saving new Room we utilize a siteId

  constructor(data: RoomDto) {
    Object.assign(this, data);
  }
}
