import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { DatePickerComponent, IDayCalendarConfig } from 'ng2-date-picker';
import moment from 'moment';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-multi-date-picker',
  templateUrl: './multi-date-picker.component.html',
  styleUrls: ['./multi-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None, // see note in scss file
})
export class MultiDatePickerComponent implements OnInit {
  @Input() set displayDate(value: DateTime) {
    // Datepicker relies on Moment, we've moved to Luxon. Translate for backwards compatibility
    // Pass in a Moment object of month to display
    this.displayDateObj = moment(value.toString());
    this.clearSelection();
  }
  @Output() datesSelectionChange: EventEmitter<Array<DateTime>> =
    new EventEmitter();
  @ViewChild('dayPicker') datePicker: DatePickerComponent;

  public displayDateObj: moment.Moment;
  public selectedDates: Array<moment.Moment>;

  // Config documentation: https://github.com/vlio20/angular-datepicker#readme
  public config: IDayCalendarConfig = {
    enableMonthSelector: false,
    allowMultiSelect: true,
    showNearMonthDays: false,
    firstDayOfWeek: 'su',
  };

  constructor() {}

  ngOnInit() {}

  selectionChange() {
    const dateTimes: Array<DateTime> = this.selectedDates.map((date) => {
      return DateTime.fromJSDate(date.toDate());
    });

    this.datesSelectionChange.next(dateTimes);
  }

  public clearSelection() {
    this.selectedDates = [];
    this.selectionChange();
  }
}
