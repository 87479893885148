import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SitesComponent } from './sites/sites.component';
import { UsersComponent } from './users/users.component';
import { SummaryComponent } from './images/summary/summary.component';
import { OrgMobileUseComponent } from './org-mobile-use/org-mobile-use.component';
import { NewslettersComponent } from './newsletters/newsletters.component';
import { MenuComponent } from './menu/menu.component';

const routes: Routes = [
  {
    path: 'properties',
    component: SitesComponent,
  },
  {
    path: 'users',
    component: UsersComponent,
  },
  {
    path: 'images',
    component: SummaryComponent,
  },
  {
    path: 'mobile-use',
    component: OrgMobileUseComponent,
  },
  {
    path: 'newsletters',
    component: NewslettersComponent,
  },
  {
    path: 'menus',
    component: MenuComponent,
  },
  {
    path: '',
    redirectTo: 'properties',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManageOrgRoutingModule {}
