import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { SubcategoryDto } from '@interfaces/subcategory';
import { Subcategory } from '@models/subcategory';
import { Image } from '@models/image';
import { ImageService } from './image.service';

export interface SubcategoryUpdate {
  name: string;
  parent: number;
}

export interface CategoryListItem {
  name: string;
  id: string;
  subcategories: Array<{
    name: string;
    id: number;
  }>;
}

@Injectable({
  providedIn: 'root',
})
export class SubcategoryService {
  private _subcategories = new BehaviorSubject<Subcategory[]>([]);
  public readonly subcategories = this._subcategories.asObservable();

  // category list with nested subcategories, FILTERED by site access
  private _categoryList = new BehaviorSubject([]);
  public readonly categoryList = this._categoryList.asObservable();

  constructor(private http: HttpClient, private imageService: ImageService) {
    this._subcategorySubscriptions();
  }

  get currentSubcategories(): SubcategoryDto[] {
    return this._subcategories.getValue();
  }

  get currentCategoryList() {
    return this._categoryList.getValue();
  }

  private _subcategorySubscriptions(): void {
    this._listenForImagesChange();
  }

  // 7/21: images currently represent the only relationship between subcategories and site access
  // when images change, update category representations
  private _listenForImagesChange(): void {
    this.imageService.backgrounds.subscribe((images) => {
      if (images.length > 0) {
        this.getSubcategoryList(images);
        this.getCategoryMapByImagePermission(images);
      }
    });
  }

  // This list includes site specific and org-wide subcategories
  public getSubcategoryList(images): void {
    let subcategories = images.map((image) => {
      return image.subcategory;
    });
    subcategories = subcategories.filter((subcat) => subcat !== null);
    this._subcategories.next(subcategories);
  }

  public getSubcategoryById(id): Subcategory {
    if (!id || this.currentSubcategories.length === 0) {
      return;
    }
    return this.currentSubcategories.find((element) => {
      if (!element) {
        return false;
      }
      return element.id === id;
    });
  }

  public saveSubcategory(
    subcategory: Partial<Subcategory>,
  ): Promise<Subcategory> {
    const url = environment.apiUrl.concat('/api/v1/subcategory');

    try {
      return lastValueFrom(this.http.post<Subcategory>(url, subcategory));
    } catch (err) {
      throw err;
    }
  }

  public updateSubcategory(id: number, data: Partial<SubcategoryUpdate>) {
    const url = environment.apiUrl.concat(`/api/v1/subcategory/update/${id}`);

    const body: Partial<SubcategoryUpdate> = {};

    if (data.name) {
      body.name = data.name;
    }
    if (data.parent) {
      body.parent = data.parent;
    }

    try {
      return <Promise<String>>lastValueFrom(
        this.http.put(url, JSON.stringify({ id, ...body }), {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }),
      );
    } catch (err) {
      throw err;
    }
  }

  // Compose a sorted list of categories which contains child subcategories
  getCategoryMapByImagePermission(images: Image[]): void {
    const dictionaryOfCategories = {};

    /* If a background image is associated with a category and subcategory, then it is considered the
     *  'default background image' for that subcategory. We include it in this list
     *  to generate the category/subcategory list (with image access filtering in place)
     */
    const defaultBackgroundImages = images.filter((image) => {
      return image.category && image.subcategory;
    });

    defaultBackgroundImages.forEach((image) => {
      if (!dictionaryOfCategories[image.category.name]) {
        dictionaryOfCategories[image.category.name] = {
          name: image.category.name,
          id: image.category.id,
          subcategories: [],
        };
      }
      const subcat = { ...image.subcategory, parent: image.category };
      dictionaryOfCategories[image.category.name].subcategories.push(subcat);
    });

    const alphabetizedList = Object.values(dictionaryOfCategories)
      .sort((a: CategoryListItem, b: CategoryListItem) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      )
      .map((category: CategoryListItem) => {
        category.subcategories = category.subcategories.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
        );
        return category;
      });

    this._categoryList.next(alphabetizedList);
  }
}
