import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { Announcement } from '@models/announcement';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  GenericBEDataSource,
  PaginationInformation,
} from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { ManageService } from 'src/app/core/services/manage.service';
import bootstrapTable from 'src/app/shared/pagination-server-side/bootstrap-table';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { SiteService } from 'src/app/core/services/site.service';
import { DateTime } from 'luxon';
import { HttpParams } from '@angular/common/http';

interface AnnouncementRow {
  type: string;
  title: string;
  startShowing: Date;
  stopShowing: Date;
  description: string;
}

@Component({
  selector: 'app-slide-table',
  templateUrl: './slide-table.component.html',
  styleUrls: ['./slide-table.component.scss'],
})
export class SlideTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() data: Announcement[];
  @Input() set filter(filterValue: string) {
    this.filters.controls['search'].setValue(filterValue);
  }
  @Input() set timeframe(timeframe: string) {
    this.filters.controls['status'].setValue(timeframe);
  }
  @Input() set refresh(refresh: number) {
    this.filters.controls['refreshTrigger'].setValue(refresh);
  }
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() count = new EventEmitter();

  public loading$ = new BehaviorSubject(true);
  private destroyed = new Subject<boolean>();

  public dataSource: MatTableDataSource<AnnouncementRow>;
  public paginatedDataSource: GenericBEDataSource<Announcement>;
  public displayedColumns: string[] = [
    'type',
    'title',
    'startShowing',
    'stopShowing',
    'description',
    'actions',
  ];
  public DateTime = DateTime;

  /**
   * Used by UI to check for 'permanent' slides.
   * Permanent slides are just statically set for 50 years in the future.
   * Chose 30 years for this to give us some wiggle room until then.
   */
  public permanentStopShowingDateTime = DateTime.now().plus({ years: 30 });

  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(''),
    type: new UntypedFormControl(false),
    status: new UntypedFormControl('active'),
    refreshTrigger: new UntypedFormControl(0),
  });

  constructor(
    public manageService: ManageService,
    public siteService: SiteService,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);

    this.paginatedDataSource = new GenericBEDataSource<Announcement>(
      this.getAnnouncementsPaginated.bind(this),
    );

    this.paginatedDataSource.$totalCount
      .pipe(
        distinctUntilChanged(),
        tap((count) => {
          this.count.next(count);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe();
  }

  private getAnnouncementsPaginated(
    queryParams: HttpParams,
    pagination: PaginationInformation,
  ) {
    return this.manageService.getSiteAnnouncementsPaginated(
      queryParams,
      pagination,
    );
  }

  ngAfterViewInit(): void {
    // Must wait until we have an active site to load this data
    this.siteService.site
      .pipe(
        filter((site) => site !== null),
        tap((site) => {
          bootstrapTable<Announcement[]>(
            this.sort,
            this.paginator,
            this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
            this.filters,
          )
            .pipe(takeUntil(this.destroyed))
            .subscribe((response) => {
              this.loading$.next(false);
            });

          this.loading$.next(false);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }
}
