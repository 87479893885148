import { ImageDto } from "../interfaces/image";
import { StyleDto } from "../interfaces/style";
import { SiteDto } from "../interfaces/site";
import { CategoryDto } from "../interfaces/category";
import { SubcategoryDto } from "../interfaces/subcategory";
import { OrganizationDto } from "../interfaces/organization";
import { AnnouncementDto } from "../interfaces/announcement";

export class Image implements ImageDto {
    // Base properties
    id: number;
    createdOn: Date;
    updatedOn: Date;
    deleted: boolean;

    // Specific properties
    downloadUrl: string;
    path: string;

    // Optional, pending image configuration
    caption?: string;
    siteId?: number;
    orgId?: number;
    categoryId?: number;
    styleId?: number;
    subcategoryId?: number;
    articleId?: number; // for article images
    newsletterId?: number; // for newsletter attachments
    announcementId?: number; // for announcements with multiple images
    position?: number; // the image's position in an announcement with multiple images
    /**
     * Flag to set if this record is the primary record in a collection of images.
     * Being implemented specifically for tracking the primary Newsletter image,
     * but naming it more ambiguously so it might be used in future scenarios as well,
     * since we're already using this table for a variety of use cases.
    */
    isPrimary?: boolean;

    // Joined properties
    category?: CategoryDto;
    subcategory?: SubcategoryDto;
    org?: OrganizationDto;
    site?: SiteDto;
    style?: StyleDto;
    announcement?: AnnouncementDto;

    constructor(data: any) {
      Object.assign(this, data);
    }

  }
  