<div class="flex justify-between items-start">
  <h1 mat-dialog-title class="mat-dialog-title">
    Select Category for Calendar
  </h1>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="modal-content">
  <mat-form-field appearance="fill" class="w-full">
    <mat-label>Categories</mat-label>
    <mat-select [formControl]="selectedCategories" multiple>
      <mat-option value="All">All Categories</mat-option>
      <mat-optgroup *ngFor="let category of categories" [label]="category.name">
        <mat-option
          *ngFor="let subcategory of category.subcategories"
          [value]="subcategory.id"
        >
          {{ subcategory.name }}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close mat-raised-button>Cancel</button>
  <button
    mat-button
    [mat-dialog-close]="selectedCategories.value || []"
    mat-raised-button
    color="accent"
  >
    Ok
  </button>
</div>
