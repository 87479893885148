<div
  [formGroup]="form"
  class="flex flex-col gap-12"
  [ngClass]="{ 'edit-mode': this.data.editMode }"
>
  <div class="flex flex-col gap-1.5">
    <div class="center flex flex-col gap-1.5">
      <h2 *ngIf="!this.data.editMode" class="mat-h2 has-y-padding">
        Select Image
      </h2>
      <div class="block relative image-upload">
        <input
          #imageInput
          class="input"
          type="file"
          accept=".png,.jpg,.gif,.tif"
          (change)="fileChangeListener($event)"
          formControlName="image"
          id="imageUpload"
        />
        <label for="imageUpload">
          <ng-container *ngIf="!this.data.editMode; else staticImage">
            <ngx-file-drop
              [dropZoneClassName]="'background-image_full'"
              multiple="false"
              directory="false"
              accept=".png,.jpg,.gif,.tif"
            >
              <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector"
              >
                <mat-icon
                  *ngIf="!this.data?.image"
                  class="large image-icon"
                  color="accent"
                  >cloud_upload</mat-icon
                >
                <img
                  *ngIf="this.data?.image"
                  [src]="this.data.image ? this.data.image : ''"
                  [width]="600"
                  class="image-preview"
                />
              </ng-template>
            </ngx-file-drop>
          </ng-container>
          <ng-template #staticImage>
            <img
              *ngIf="this.data?.image"
              [src]="this.data.image ? this.data.image : ''"
              [width]="600"
              class="image-preview"
            />
          </ng-template>
        </label>
      </div>

      <h2 class="mat-h2">Who can use this image?</h2>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Site</mat-label>
        <mat-select formControlName="siteIds" [multiple]="multiSelect">
          <!-- Edit mode needs to be able to initially populate 'all sites', can't programmatically set select value to null and see a UI update -->
          <mat-option [value]="this.data.editMode ? 0 : null">
            {{ this.data.editMode ? 'All Sites' : 'Select All' }}
          </mat-option>
          <mat-option
            *ngFor="let site of siteService.sites | async"
            [value]="site.id"
          >
            {{ site.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="!this.data.editMode; else editSubcategory">
        <h2 class="mat-h2">Make this a default background image? (Optional)</h2>
        <div>
          Create a new subcategory with this image as a default background
          image.
        </div>
        <div>
          If you add a new subcategory it will be available when you create new
          digital signage and events.
        </div>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Subcategory Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </ng-container>

      <ng-template #editSubcategory>
        <ng-container
          *ngIf="this.data.existingSubcategory; else newSubcategory"
        >
          <h2 class="mat-h2 has-y-padding">
            This is a default background image
          </h2>
          <span
            >Edit the subcategory name or category association for this
            subcategory.</span
          >
        </ng-container>
        <ng-template #newSubcategory>
          <h2 class="mat-h2 has-y-padding">
            Make this a default background image?
          </h2>
          <div>
            Create a new subcategory with this image as the default background
            image.
          </div>
          <div>
            These subcategories are available when creating new digital signage
            and events.
          </div>
        </ng-template>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Subcategory Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </ng-template>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>Category</mat-label>
        <mat-select formControlName="category">
          <mat-option [value]="''">No Category</mat-option>
          <mat-option
            *ngFor="let category of categoryService.categories | async"
            [value]="category.id"
          >
            {{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
