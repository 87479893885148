import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// todo: change fonts
const theme = createTheme({
  palette: {
    primary: {
      main: '#f0f0f0',
      light: '#f0f0f0',
      dark: '#d4d4d4',
      contrastText: '#0a0a0a',
    },
    secondary: {
      main: '#0a0a0a',
      light: '#0a0a0a',
      dark: '#3d3d3d',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '16px',
        },
      },
    },
  },
});

export function ReactProvider({ children }: { children: React.ReactNode }) {
  // add other providers here as needed
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
