import React from 'react';
import { Eventcalendar as MbscEventCalendar } from '@mobiscroll/react';

type EventPreviewCalendarProps = {
  events: any[];
  selectedDate: Date;
  activeMonthIndex: number;
  lastRecurrenceMessage: string;
  months: any[];
};
export function EventPreviewCalendar({
  events,
  selectedDate,
  activeMonthIndex,
  lastRecurrenceMessage,
  months,
}: EventPreviewCalendarProps) {
  function renderHeaderTemplate() {
    return (
      <div className="calendar-heading">
        <div className="calendar-heading-date">
          {months && months[activeMonthIndex]
            ? months[activeMonthIndex].toFormat('MMMM')
            : ''}
          <span> </span>
          {months && months[activeMonthIndex]
            ? months[activeMonthIndex].toFormat('yyyy')
            : ''}
        </div>
        {lastRecurrenceMessage.length > 0 && (
          <div className="calendar-heading-disclaimer">
            (last event is on {lastRecurrenceMessage})
          </div>
        )}
      </div>
    );
  }
  function renderEventCalendarTemplate() {}
  return (
    <div className="recurrence-display">
      <MbscEventCalendar
        data={events}
        selectedDate={selectedDate}
        renderHeader={renderHeaderTemplate}
        className="w-full"
      />
      {/* <div class="recurrence-display">
  <mbsc-eventcalendar
    [data]="events.value"
    [options]="options"
    [selectedDate]="activeDay"
    (onCellClick)="selectDay($event)"
    [headerTemplate]="customHeader"
  >
    <ng-template #myTemplate let-data>
      <div class="my-start">{{ data.start }}</div>
      <div class="my-end">{{ data.end }}</div>
      <div class="my-title">{{ data.title }}</div>
      <div class="my-custom-field">{{ data.original.description }}</div>
      <div class="my-custom-field">{{ data.original.location }}</div>
    </ng-template>
    <ng-template #customHeader>
      <div class="calendar-heading">
        <div class="calendar-heading-date">
          {{
            months && months[activeMonthIndex]
              ? months[activeMonthIndex].toFormat('MMMM')
              : ''
          }}
          {{
            months && months[activeMonthIndex]
              ? months[activeMonthIndex].toFormat('yyyy')
              : ''
          }}
        </div>
        <div
          *ngIf="lastRecurrenceMessage.value?.length > 0"
          class="calendar-heading-disclaimer"
        >
          (last event is on {{ lastRecurrenceMessage.value }})
        </div>
      </div>
    </ng-template>
  </mbsc-eventcalendar>
</div> */}
    </div>
  );
}
