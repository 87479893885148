<form [formGroup]="form">
  <div class="form-group">
    <label class="form-question">Title</label>
    <div
      class="label-icon"
      matTooltip="This is the headline on your slide. Limit {{
        maxTitleLength
      }} characters."
    >
      <mat-icon>help</mat-icon>
    </div>
    <mat-form-field appearance="outline" color="accent">
      <textarea
        matInput
        formControlName="title"
        placeholder="Enter title"
        type="text"
        [maxLength]="maxTitleLength"
        data-qa="slide-description-input-title"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="form-group">
    <label class="form-question">Description</label>
    <div
      class="label-icon"
      matTooltip="This is the descriptor text under your headline. Limit {{
        maxDescriptionLength
      }} characters."
    >
      <mat-icon>help</mat-icon>
    </div>
    <mat-form-field appearance="outline" color="accent">
      <input
        matInput
        formControlName="description"
        placeholder="Enter description"
        type="text"
        [maxLength]="maxDescriptionLength"
        data-qa="slide-description-input-description"
      />
    </mat-form-field>
  </div>

  <div class="form-group">
    <label class="form-question">Number of Images</label>
    <div class="label-icon" matTooltip="You can add 1 to 4 images">
      <mat-icon>help</mat-icon>
    </div>
    <mat-form-field appearance="outline" color="accent">
      <mat-select
        (selectionChange)="imageNumberChanged($event)"
        required
        formControlName="numberOfImages"
      >
        <mat-option
          *ngFor="let number of numberOfImagesOptions"
          [value]="number"
        >
          {{ number }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-group">
    <div>
      <label *ngIf="form.valid" class="form-question">Uploaded Image/s</label>
      <label *ngIf="form.invalid" class="form-question"
        >Upload Image {{ firstMissingImage }}</label
      >
      <div class="label-icon" matTooltip="Select the images to collage">
        <mat-icon>help</mat-icon>
      </div>
      <div class="form-group image-upload">
        <div class="field">
          <div *ngIf="!form.valid" class="control">
            <input
              class="input"
              type="file"
              id="file"
              accept=".png,.jpg,.gif,.tif"
              (change)="uploadInputChanged($event)"
            />
            <label class="image-label" for="file">
              <ngx-file-drop
                [dropZoneClassName]="'ngx-file-drop-zone-rectangle'"
                (onFileDrop)="dropped($event)"
                multiple="false"
                directory="false"
                accept=".png,.jpg,.gif,.tif"
              >
                <ng-template
                  ngx-file-drop-content-tmp
                  let-openFileSelector="openFileSelector"
                >
                  <mat-icon class="large" color="accent">cloud_upload</mat-icon>
                </ng-template>
              </ngx-file-drop>
              <mat-progress-bar
                *ngIf="progress !== 0"
                [value]="progress"
                color="accent"
              ></mat-progress-bar>
            </label>
          </div>
          <div>
            <div *ngIf="form.controls['image1'].value" class="image-container">
              <img [src]="form.controls['image1'].value.downloadUrl" />
              <mat-icon
                class="remove-icon"
                matTooltip="Remove this image"
                (click)="deleteImage(form.controls['image1'].value.position)"
                >close</mat-icon
              >
            </div>
            <div *ngIf="form.controls['image2'].value" class="image-container">
              <img [src]="form.controls['image2'].value.downloadUrl" />
              <mat-icon
                class="remove-icon"
                matTooltip="Remove this image"
                (click)="deleteImage(form.controls['image2'].value.position)"
                >close</mat-icon
              >
            </div>
            <div *ngIf="form.controls['image3'].value" class="image-container">
              <img [src]="form.controls['image3'].value.downloadUrl" />
              <mat-icon
                class="remove-icon"
                matTooltip="Remove this image"
                (click)="deleteImage(form.controls['image3'].value.position)"
                >close</mat-icon
              >
            </div>
            <div *ngIf="form.controls['image4'].value" class="image-container">
              <img [src]="form.controls['image4'].value.downloadUrl" />
              <mat-icon
                class="remove-icon"
                matTooltip="Remove this image"
                (click)="deleteImage(form.controls['image4'].value.position)"
                >close</mat-icon
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
