import { Frequency, RRule, RRuleSet, Weekday } from 'rrule';
import { DateTime } from 'luxon';
import { addYears } from 'date-fns';

/**
 * Recurrence is tricky: all recurrence dates must be generated in the context of their relevant timezone
 * (to offload correctly identifying days of the week/time as they interact with daylight savings etc to RRUle)
 * but we will need to save/utilize all dates as UTC.
 *
 */
export class Recurrence {
  // Base properties
  id: number;
  createdOn: Date;
  updatedOn: Date;
  deleted: boolean;

  // Rrule set properties
  exdates: Date[];

  // RRule properties
  freq: Frequency;
  dtstart: Date;
  interval: number;
  wkst: Weekday | number;
  count?: number;
  until?: Date | null;
  byweekday?: Weekday[] | null;
  tzid: string;

  // Combined with byweekday for targeting nth weekdays of the month
  nbyweekday?: number | null; // for monthly recurrence, numeric value for 'first/last/etc' weekday of the month

  // Calculated
  rruleSet?: RRuleSet;
  translatedUtcDates?: DateTime[];
  formattedJsDates?: string[]; // DISPLAYED IN TIMEZONE: formatted for simple 'yyyy MM dd' format

  constructor(data: any) {
    // if ((!data.count && !data.until) || !data.interval) {
    //   // 'Recurrence requires either a set count or "until" date and a selected interval to prevent rrule generating effectively endless dates.'
    //   return;
    // }
    // if nbyweekday value, map week days with numeric reference
    if (data.byweekday?.length > 0) {
      if (typeof data.byweekday[0] === 'object') {
        data.nbyweekday = data.byweekday[0].n;
      } else if (data.nbyweekday) {
        data.byweekday = data.byweekday.map((day: string | number) => {
          let rruleDay: string | Weekday;

          if (isNaN(Number(day))) {
            // @ts-ignore
            rruleDay = RRule[day];
          } else {
            switch (Number(day)) {
              case RRule.MO.weekday:
                rruleDay = RRule.MO;
                break;
              case RRule.TU.weekday:
                rruleDay = RRule.TU;
                break;
              case RRule.WE.weekday:
                rruleDay = RRule.WE;
                break;
              case RRule.TH.weekday:
                rruleDay = RRule.TH;
                break;
              case RRule.FR.weekday:
                rruleDay = RRule.FR;
                break;
              case RRule.SA.weekday:
                rruleDay = RRule.SA;
                break;
              case RRule.SU.weekday:
                rruleDay = RRule.SU;
                break;
            }
          }

          return (rruleDay as Weekday).nth(data.nbyweekday);
        });
      } else if (
        typeof data.byweekday[0] === 'string' &&
        !isNaN(Number(data.byweekday[0]))
      ) {
        data.byweekday = data.byweekday = data.byweekday.map((day: string) => {
          return Number(day);
        });
      }
    }

    if (data.stopCondition) {
      delete data.stopCondition;
    }

    // Dates must be JS Dates for creating new rrule / rruleSets
    if (data.dtstart && !(data.dtstart instanceof Date)) {
      data.dtstart = DateTime.fromFormat(data.dtstart, 'yyyy-MM-dd')
        .startOf('day')
        .toJSDate();
    }
    if (data.until && !(data.until instanceof Date)) {
      data.until = DateTime.fromFormat(`${data.until}`, 'yyyy-MM-dd', {
        zone: data.tzid,
      })
        .toUTC()
        .endOf('day')
        .toJSDate();
    } else if (data.until) {
      const dateFromJS = DateTime.fromJSDate(data.until);

      data.until = DateTime.fromObject({
        year: dateFromJS.year,
        month: dateFromJS.month,
        day: dateFromJS.day,
        zone: data.tzid,
      })
        .toUTC()
        .endOf('day')
        .toJSDate();
    }

    // date coming in as UTC iso string, convert to timezone-friendly, UTC JS Date
    if (data.exdates?.length > 0 && !(data.exdates[0] instanceof Date)) {
      data.exdates = data.exdates.map((date: string) => {
        return DateTime.fromFormat(date, 'yyyy-MM-dd')
          .startOf('day')
          .toJSDate();
      });
    }

    Object.assign(this, data);

    const rruleSet = new RRuleSet();
    const rrule = {
      freq: this.freq,
      dtstart: this.dtstart,
      interval: this.interval,
      count: this.count,
      until: this.until ? this.until : addYears(this.dtstart, 1),
      byweekday: this.byweekday,
    };
    rruleSet.rrule(new RRule(rrule));

    if (data.exdates?.length > 0) {
      this.exdates.forEach((date) => {
        rruleSet.exdate(date);
      });
    }
    this.rruleSet = rruleSet;

    this.formattedJsDates = this.rruleSet.all().map((date) => {
      return DateTime.fromJSDate(date).startOf('day').toFormat('yyyy-MM-dd');
    });
  }

  // FORMATTED RELATIVE TO TIMEZONE
  // primarily used to set value of date inputs 'yyyy MM dd'
  get stringFormattedDates() {
    return this.all.map((date) => {
      return date.toFormat('yyyy-MM-dd');
    });
  }

  // 'all' returns dates in relative local time. If tzid was used during rule creation, this is the correct local time when it is the original time in the selected timezone
  get all() {
    return this.rruleSet.all().map((date) => {
      return DateTime.fromJSDate(date)
        .toUTC()
        .setZone('local', { keepLocalTime: true });
    });
  }

  get stringDtstart() {
    return DateTime.fromJSDate(this.rruleSet.dtstart())
      .toUTC()
      .setZone('local', { keepLocalTime: true })
      .toFormat('yyyy-MM-dd');
  }

  get recipeForm() {
    let weekdays;
    if (this.byweekday?.length > 0) {
      weekdays = this.byweekday.map((weekday: Weekday | number) => {
        let switchcase = weekday;
        if (weekday.hasOwnProperty('weekday')) {
          // weekday value provided to 'day select' of form cannot have 'nth' values, still need to re-map
          switchcase = (weekday as Weekday).weekday;
        }
        let stringDay;

        switch (switchcase) {
          case RRule.MO.weekday:
            stringDay = RRule.MO;
            break;
          case RRule.TU.weekday:
            stringDay = RRule.TU;
            break;
          case RRule.WE.weekday:
            stringDay = RRule.WE;
            break;
          case RRule.TH.weekday:
            stringDay = RRule.TH;
            break;
          case RRule.FR.weekday:
            stringDay = RRule.FR;
            break;
          case RRule.SA.weekday:
            stringDay = RRule.SA;
            break;
          case RRule.SU.weekday:
            stringDay = RRule.SU;
            break;
        }
        return stringDay;
      });
    }

    return {
      freq: this.freq,
      interval: this.interval,
      count: this.count,
      until: this.until
        ? DateTime.fromJSDate(this.until).toFormat('yyyy-MM-dd')
        : null,
      nbyweekday: this.nbyweekday,
      byweekday: weekdays ? weekdays : this.byweekday,
      stopCondition: this.until ? 'date' : 'never',
    };
  }

  // properties used to create an Rrule
  get recipeRrule() {
    return {
      freq: this.freq,
      dtstart: this.dtstart,
      interval: this.interval,
      count: this.count,
      until: this.until,
      nbyweekday: this.nbyweekday,
      byweekday: this.byweekday,
      tzid: this.tzid ? this.tzid : null,
    };
  }

  // FORMATTED RELATIVE TO TIMEZONE
  get stringLastTimezoneRecurrence() {
    return DateTime.fromFormat(
      this.stringFormattedDates[this.stringFormattedDates.length - 1],
      'yyyy-MM-dd',
    ).toFormat('EEEE, MMM d, yyyy');
  }
}
