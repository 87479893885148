import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SessionRoutingModule } from './session-routing.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { SharedModule } from '../shared/shared.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignInFormComponent } from './sign-in-form/sign-in-form.component';

@NgModule({
  imports: [CommonModule, SessionRoutingModule, SharedModule],
  declarations: [SignInComponent, SignInFormComponent, ResetPasswordComponent],
})
export class SessionModule {}
