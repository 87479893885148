<mat-card appearance="outlined" color="primary">
  <mat-card-header class="flex justify-between items-center">
    <mat-card-title color="primary" class="flex">
      Property Menus
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="flex flex-col">
    <ng-container *ngIf="site | async as site">
      <app-menu-table [site]="site"></app-menu-table>
    </ng-container>
  </mat-card-content>
</mat-card>
