<mat-card appearance="outlined" color="primary" [formGroup]="form">
  <div class="flex flex-col gap-4 py-2">
    <!-- First row: Article Type and action buttons -->
    <div class="flex px-2">
      <div>
        <mat-card-subtitle class="has-no-margin"
          >Article Type</mat-card-subtitle
        >
        <p>
          <b>{{ existingArticle?.articleType?.name }}</b>
        </p>
      </div>
    </div>

    <mat-card-subtitle *ngIf="disableEditing" class="italic px-2">
      This Article is currently not editable since it is no longer in Draft.
    </mat-card-subtitle>

    <!-- Second row: form fields: headline, front page -->
    <div class="flex gap-8">
      <div
        class="w-full md:w-3/4 flex justify-between items-center content-center px-2"
      >
        <mat-form-field
          class="newsletter-form"
          appearance="fill"
          color="accent"
          class="flex w-full"
          [ngClass]="{
            readonly: this.existingArticle.optionalArticle(
              this.existingArticle.articleType.name
            )
          }"
        >
          <mat-label>Headline</mat-label>
          <input
            matInput
            spellcheck="true"
            autofocus
            formControlName="headline"
            [readonly]="
              this.existingArticle.optionalArticle(
                this.existingArticle.articleType.name
              )
            "
          />
        </mat-form-field>
        <!-- Commenting out per 3/11/21 client feedback; Kedar requested we keep the code in case they change their mind -->
        <!-- <mat-form-field appearance="fill" color="accent" class="w-1/2">
          <mat-label>Front Page</mat-label>
          <mat-select
            formControlName="frontPage"
            [disabled]="disableFrontPageToggle"
            [matTooltip]="disableFrontPageToggle ? isBackpageArticle ? 'This is a backpage article.' : 'Only two Articles can be Front Page.' : ''">
            <mat-option [value]="false">No</mat-option>
            <mat-option [value]="true">Yes</mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
    </div>

    <!-- Third row: text area, images -->
    <div class="flex">
      <!-- Text area container -->
      <div class="position-relative flex flex-col items-center w-full px-2">
        <!-- Subtitles -->
        <div
          class="absolute top-0 left-0 right-0 flex justify-between items-center gap-4 px-2"
        >
          <mat-card-subtitle class="has-no-margin">Text Area</mat-card-subtitle>
          <div
            class="flex justify-around items-center content-center gap-4"
            matTooltip="Acceptable range is +/- {{
              characterCountLeeway | percent
            }} of target of {{
              existingArticle.idealArticleLength(newsletter.articleCount)
                | number
            }}"
          >
            <mat-card-subtitle class="characters-remaining bold">
              Characters Written:
              <span
                [class.newsletter-warning]="
                  !existingArticle.characterCountValid(
                    newsletter.articleCount,
                    charactersWritten
                  )
                "
              >
                {{ charactersWritten | number }}
              </span>
            </mat-card-subtitle>
            <mat-card-subtitle
              class="characters-remaining"
              *ngIf="existingArticle.minArticleLength(newsletter.articleCount)"
            >
              Minimum:
              {{
                existingArticle.minArticleLength(newsletter.articleCount)
                  | number
              }}
            </mat-card-subtitle>
            <mat-card-subtitle
              class="characters-remaining"
              *ngIf="existingArticle.maxArticleLength(newsletter.articleCount)"
            >
              Target:
              {{
                existingArticle.idealArticleLength(newsletter.articleCount)
                  | number
              }}
            </mat-card-subtitle>
            <mat-card-subtitle
              class="characters-remaining"
              *ngIf="existingArticle.maxArticleLength(newsletter.articleCount)"
            >
              Maximum:
              {{
                existingArticle.maxArticleLength(newsletter.articleCount)
                  | number
              }}
            </mat-card-subtitle>
          </div>
        </div>

        <!-- Textarea -->
        <mat-form-field
          class="form-text min-w-full w-full flex-grow py-6 h-full"
          appearance="fill"
          color="accent"
        >
          <textarea
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="20"
            cdkAutosizeMaxRows="20"
            formControlName="content"
            placeholder="Add your content here"
            spellcheck="true"
          ></textarea>
        </mat-form-field>

        <!-- Buttons -->
        <div class="buttons-cont p-1">
          <button mat-button (click)="triggerHideComponent()">Cancel</button>
          <app-progress-button
            color="accent"
            text="Save Article"
            (click)="saveArticle()"
            [disabled]="!form.valid"
            [submitting]="submitting"
          >
          </app-progress-button>
        </div>
      </div>

      <!-- Image container -->
      <div
        class="flex flex-col justify-start items-start content-start w-1/3 px-2"
        *ngIf="
          !existingArticle.optionalArticle(existingArticle?.articleType?.name)
        "
      >
        <div>
          <mat-card-subtitle>Images</mat-card-subtitle>
          <mat-checkbox class="example-margin" formControlName="customImages">
            Let GiGi choose
          </mat-checkbox>
          <p class="mt-4">
            <i>
              For photo collages, please upload 4 to 8 images with captions.
              Each image removes {{ characterCountPerImage }} characters from
              the MAX character count.
            </i>
          </p>
        </div>

        <div class="image-container flex flex-wrap w-full">
          <!-- Set Images -->
          <ng-container *ngFor="let image of images; index as i">
            <div class="image-card-container">
              <mat-card
                appearance="outlined"
                class="image-card"
                [attr.style]="backgroundImgUrl(image.downloadUrl)"
              >
                <!-- Image buttons -->
                <div
                  class="image-actions absolute bottom-0 left-0 right-0 overflow-hidden h-[30%]"
                >
                  <div class="flex justify-around w-full h-full items-center">
                    <button
                      class="image-actions-button"
                      (click)="downloadImage(image)"
                      color="accent"
                      mat-icon-button
                      [disabled]="form.controls['customImages'].value"
                      matTooltip="Download Image"
                    >
                      <mat-icon>cloud_download</mat-icon>
                    </button>
                    <button
                      (click)="editImageCaption(image)"
                      class="image-actions-button"
                      color="accent"
                      mat-icon-button
                      [disabled]="
                        disableEditing || form.controls['customImages'].value
                      "
                      matTooltip="Edit Caption"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      (click)="deleteImage(image, i)"
                      class="image-actions-button"
                      color="accent"
                      mat-icon-button
                      [disabled]="
                        disableEditing || form.controls['customImages'].value
                      "
                      matTooltip="Delete Image"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
                <!-- Image caption -->
                <div
                  *ngIf="!form.controls['customImages'].value"
                  class="caption"
                >
                  {{ image.caption || 'No caption.' }}
                </div>
                <div
                  *ngIf="form.controls['customImages'].value"
                  class="caption has-height-100"
                ></div>
              </mat-card>
            </div>
          </ng-container>

          <!-- Unset Images -->
          <ng-container
            *ngFor="
              let placeholder of [].constructor(8 - images.length);
              index as i
            "
          >
            <div class="image-card-container">
              <mat-card appearance="outlined" class="image-card">
                <button
                  (click)="addImage()"
                  [disabled]="
                    disableEditing || form.controls['customImages'].value
                  "
                  mat-icon-button
                >
                  <mat-icon>add_photo_alternate</mat-icon>
                </button>
              </mat-card>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</mat-card>
