import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { User } from '@models/user';
import { AlertService } from '../services/alert.service';
import { SiteService } from '../services/site.service';

@Injectable({
  providedIn: 'root',
})
export class PropertyGuard {
  constructor(
    private alertService: AlertService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private siteService: SiteService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.userReplay.pipe(
      take(1),
      map((user: User) => {
        // siteId is referenced as propertyId in all display URLs
        const siteId = Number(next.params.siteId || next.params.propertyId);

        // if no siteId in the URL or if being redirected from /manage (see app-routing.module.ts)
        if (siteId !== 0 && !siteId) {
          // grab user's default siteId
          const defaultSiteId = user.defaultSiteId;
          // if no default siteId, force user to sign out
          // siteId may be zero, so we need to check for that.
          if (defaultSiteId !== 0 && !defaultSiteId) {
            this.navigateToSignIn();
            return false;
          }

          // navigate to user's default siteId, which will kick off this property.guard again
          this.navigateToDefaultSite(user);
          return false;
        }

        // if user doesn't have access to site, force user to sign out
        const userHasAccessToSiteId =
          user.associatedWithSite(siteId) && user.isSiteAdmin;
        if (!userHasAccessToSiteId) {
          this.navigateToSignIn();
          return false;
        }

        // ensure siteService's siteId is up to date with new siteId
        const currentSiteId = this.siteService.currentSiteId;
        if (!currentSiteId || currentSiteId !== siteId) {
          this.siteService.setActiveSite(siteId);
        }

        return true;
      }),
    );
  }

  private navigateToSignIn(): void {
    this.auth.signOut(
      'Unable to access that property. If you believe this is an error, refresh browser and try again.',
    );
  }

  private navigateToDefaultSite(user: User): void {
    this.router.navigate([`/manage/content/${user.defaultSiteId}`]);
  }
}
