<mat-card
  appearance="outlined"
  color="primary"
  style="overflow-y: auto; max-height: 85vh"
>
  <mat-card-header class="flex justify-between items-center content-center">
    <mat-card-title
      >Background Images ({{
        imageTableRef?.paginatedDataSource?.totalCount
          ? imageTableRef?.paginatedDataSource?.totalCount
          : 0
      }})</mat-card-title
    >
    <div
      class="control-buttons buttons flex justiy-end items-center content-center gap-[10px]"
    >
      <button
        mat-raised-button
        matTooltip="Add new image"
        color="accent"
        (click)="addImage()"
      >
        Add Image
      </button>
      <mat-form-field color="accent">
        <input
          matInput
          placeholder="Search"
          (input)="search($event.target.value)"
          [value]="searchFilter"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </mat-card-header>
  <mat-card-content style="padding-top: 16px">
    <!-- TABLE VIEW -->
    <ng-container *ngIf="isTable">
      <app-image-table
        #imageTable
        class="flex flex-col"
        [editImage]="editImage"
        [deleteImage]="markImageDeleted"
        [search]="searchFilter"
        [update]="updateTrigger"
        (imageCount)="setImageCount($event)"
      ></app-image-table>
    </ng-container>

    <ng-template #zeroState> No images added yet. </ng-template>
  </mat-card-content>
</mat-card>
