<h1 mat-dialog-title *ngIf="data.title">{{ data.title }}</h1>
<mat-dialog-content [formGroup]="eventEditForm" class="dialog-content">
  <p>
    {{
      !noRecurrence
        ? data.message
        : 'This change will only impact this event, not the recurring series.'
    }}
  </p>

  <mat-radio-group formControlName="edit">
    <mat-radio-button
      value="single"
      data-qa="edit-recurrence-radio-single"
      *ngIf="!updatingRecurrence || !noRecurrence"
    >
      Update just this event
    </mat-radio-button>
    <mat-radio-button
      value="future"
      data-qa="edit-recurrence-radio-future"
      *ngIf="!noRecurrence"
    >
      Update this and future events
    </mat-radio-button>
    <mat-radio-button
      value="all"
      data-qa="edit-recurrence-radio-all"
      *ngIf="!noRecurrence"
    >
      Update all events in this series</mat-radio-button
    >
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">
    {{ data.noText || 'Cancel' }}
  </button>
  <app-progress-button
    data-qa="edit-recurrence-button-confirm"
    color="accent"
    [text]="data.yesText || 'Confirm'"
    (submit)="onConfirmClick($event)"
    [submitting]="isLoading.getValue()"
  >
  </app-progress-button>
</mat-dialog-actions>
