<mat-card appearance="outlined" color="primary">
  <mat-card-header>
    <mat-card-title> Comments </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-card-subtitle *ngIf="newsletter?.newsletterComment?.length === 0"
      ><i>There are no comments yet on this newsletter.</i></mat-card-subtitle
    >
    <div
      class="comment-cont"
      *ngFor="let comment of newsletter?.newsletterComment"
    >
      <div
        class="name-date-wrapper flex justify-start items-center content-center"
      >
        <p>
          {{ comment.user.firstName }} {{ comment.user.lastName }}
          {{ comment.createdOn | date : 'short' }}
        </p>
        <button
          mat-icon-button
          *ngIf="isCommentAuthor(comment)"
          (click)="confirmDeleteComment(comment)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <p class="content">{{ comment.content }}</p>
    </div>
  </mat-card-content>

  <mat-card-footer class="flex flex-col justify-start items-end content-end">
    <mat-form-field color="accent" class="min-w-full w-full h-full min-h-full">
      <mat-label>Comment</mat-label>
      <textarea matInput [(ngModel)]="commentContent"></textarea>
    </mat-form-field>
    <app-progress-button
      color="accent"
      text="Send"
      (click)="addComment()"
      [disabled]="!trimmedComment.length"
      [submitting]="submitting"
    >
    </app-progress-button>
  </mat-card-footer>
</mat-card>
