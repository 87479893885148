import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { User } from '@models/user';
import { UserService } from 'src/app/core/services/user.service';
import { SiteService } from 'src/app/core/services/site.service';

export interface UserFormValues {
  firstName: string;
  lastName: string;
  roleId: number;
  sites: number[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup;

  constructor(
    public siteService: SiteService,
    public userService: UserService
  ) {}

  ngOnInit() {}

  static formModel(formValues = null) {
    return new UntypedFormGroup({
      firstName: new UntypedFormControl(
        formValues ? formValues.firstName : null,
        Validators.required
      ),
      lastName: new UntypedFormControl(
        formValues ? formValues.lastName : null,
        Validators.required
      ),
      roleId: new UntypedFormControl(
        formValues ? formValues.roleId : 3,
        Validators.required
      ),
      sites: new UntypedFormControl(formValues ? formValues.sites : []),
    });
  }

  // Using any type since add-user expects FormValues and edit-user expects Partial<User>
  // When set to FormValues | Partial<User> that still threw errors, so taking this path for now.
  // -BH 8/29/19
  static serialize(formValues: UserFormValues, user: Partial<User> = {}): any {
    const serializedForm = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      roleId: formValues.roleId,
      sites: formValues.roleId !== 4 ? formValues.sites : [],
    };

    // Need to delete because it was preventing editing working successfully.
    // Seems the API was getting confused between the roleId and role object.
    delete user.role;

    return Object.assign(user, serializedForm);
  }

  static deserialize(user: User): UserFormValues {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      roleId: user.role.id,
      sites: user.isOrgAdmin ? [] : user.siteIds,
    };
  }

  public get isOrgAdmin(): boolean {
    return this.form.get('roleId').value === 4;
  }
}
