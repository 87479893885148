<div [formGroup]="form" class="flex flex-col">
  <mat-form-field appearance="outline" color="accent">
    <mat-label>Name</mat-label>
    <input matInput autofocus required formControlName="name" />
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent">
    <mat-label>Submission Due</mat-label>
    <input
      matInput
      required
      formControlName="submissionDueOn"
      [matDatepicker]="submissionPicker"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="submissionPicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #submissionPicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent">
    <mat-label>Approval Needed</mat-label>
    <input
      matInput
      required
      formControlName="approvalDueOn"
      [matDatepicker]="approvalPicker"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="approvalPicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #approvalPicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent">
    <mat-label>Articles</mat-label>
    <mat-select required formControlName="articleCount">
      <mat-option value="6"> 6 </mat-option>
      <mat-option value="7"> 7 </mat-option>
      <mat-option value="8"> 8 </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent">
    <mat-label>Print Quantity</mat-label>
    <input
      step="25"
      min="25"
      matInput
      required
      formControlName="printQuantity"
      type="number"
    />
  </mat-form-field>
</div>
