import { Injectable } from '@angular/core';
import { UserDto } from '@interfaces/user';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { User } from '@models/user';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { OrgService } from './org.service';
import { Organization } from '@models/organization';
import { AuthService } from './auth.service';
import { UserAuth } from 'src/app/manage-org/add-user/add-user.component';
import { PaginationInformation } from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';
import { SiteService } from './site.service';
import { Participant } from '@models/participant';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _users = new Subject<User[]>();
  public readonly users = this._users.asObservable();

  private _userRoles = new BehaviorSubject<User['role'][]>([]);
  public readonly userRoles = this._userRoles.asObservable();

  constructor(
    private http: HttpClient,
    private orgService: OrgService,
    private authService: AuthService,
    private siteService: SiteService,
  ) {
    this.listenForOrgUpdates();
    this.fetchUserRoles();
  }

  public refreshUsers(): void {
    if (this.authService.currentOrgId) {
      this.getUsers(this.authService.currentOrgId)
        .then((users: User[]) => this.usersUpdated(users))
        .catch((error) => console.error(error));
    }
  }

  private listenForOrgUpdates(): void {
    this.orgService.org.subscribe((org: Organization) => {
      // Ensure user is Org Admin and org id matches orgService's
      if (
        org &&
        this.authService.currentUser &&
        this.authService.currentUser.isOrgAdmin &&
        this.authService.currentOrgId &&
        this.authService.currentOrgId === org.id
      ) {
        this.getUsers(org.id)
          .then((users: User[]) => this.usersUpdated(users))
          .catch((error) => console.error(error));
      } else {
        this.usersUpdated([]);
      }
    });
  }

  private fetchUserRoles(): void {
    this.getUserRoles().then((roles: User['role'][]) =>
      this._userRoles.next(roles),
    );
  }

  private usersUpdated(users: User[]): void {
    this._users.next(users);
  }

  private getUsers(orgId: number): Promise<User[]> {
    const url = environment.apiUrl.concat(`/api/v1/users/${orgId}`);

    try {
      return <Promise<User[]>>(
        lastValueFrom(
          this.http
            .get(url)
            .pipe(
              map((users: User[]) =>
                users
                  .map((user) => new User(user))
                  .filter((user) => user.isOrgAdmin || user.isSiteAdmin),
              ),
            ),
        )
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public getUserRoles(): Promise<User['role'][]> {
    const url = environment.apiUrl.concat('/api/v1/user-roles');

    try {
      return <Promise<User['role'][]>>lastValueFrom(this.http.get(url));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public createUser(userAuth: UserAuth): Promise<User> {
    const url = environment.apiUrl.concat('/api/v1/userFirebase');

    try {
      return <Promise<User>>lastValueFrom(this.http.post(url, userAuth));
    } catch (err) {
      console.error('ERROR', err);
    }
  }

  public updateUser(user: Partial<User>, updateEmail?: boolean): Promise<User> {
    let url;
    // If email update is needed, both Firebase and our DB will be appropriately updated
    if (updateEmail) {
      url = environment.apiUrl.concat('/api/v1/email-update');
    } else {
      url = environment.apiUrl.concat('/api/v1/user/');
    }

    try {
      return <Promise<User>>lastValueFrom(this.http.post(url, user));
    } catch (err) {
      console.error('ERROR', err);
    }
  }

  /**
   * When we update participant emails, we update the User directly.
   * Email addresses live on the User object, not participants
   *  */
  public updateParticipantEmail(
    participant: Partial<Participant>,
    newEmail: string,
  ): Promise<User> {
    let url = environment.apiUrl.concat('/api/v1/email-update');
    // both Firebase and our DB will be appropriately updated
    try {
      return <Promise<User>>(
        lastValueFrom(this.http.post(url, { participant, newEmail }))
      );
    } catch (err) {
      console.error('ERROR', err);
      throw err;
    }
  }

  public deleteUser(id: string): Promise<null> {
    const url = environment.apiUrl.concat(`/api/v1/user/${id}`);

    try {
      return <Promise<null>>lastValueFrom(this.http.put(url, {}));
    } catch (err) {
      console.error('ERROR', err);
    }
  }

  public reactivateUser(id: string): Promise<null> {
    const url = `${environment.apiv3Url}/user/reactivate/${id}`;
    try {
      return <Promise<null>>lastValueFrom(this.http.put(url, {}));
    } catch (err) {
      console.error('ERROR', err);
    }
  }

  public getAllUsers(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
  ) {
    const { search } = pagination.filters;

    if (search.trim().length > 0) {
      queryParams = queryParams.append(
        'searchBy',
        '["firstName", "lastName", "email"]',
      );
    }

    const url = `${environment.apiv3Url}/user/organization/${this.siteService.currentSite.organization.id}`;
    try {
      return this.http
        .get<{ data: User[]; totalCount: number }>(url, { params: queryParams })
        .pipe(
          tap((response) => {
            const data = response.data.map((user: UserDto) => {
              return new User(user);
            });
            response = {
              ...response,
              data,
            };
          }),
        );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public getUsersPaginated(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
  ) {
    const { search, inactive } = pagination.filters;
    let active = null;
    let direction = null;
    if (pagination?.sort) {
      active = pagination.sort.active;
      direction = pagination.sort.direction;
    }
    if (search.trim().length > 0) {
      queryParams = queryParams.append(
        'searchBy',
        '["firstName", "lastName", "email"]',
      );
    }

    queryParams = queryParams.append('inactive', inactive as string);
    queryParams = queryParams.append('sortField', active as string);
    queryParams = queryParams.append('sortOrder', direction.toLowerCase());
    const url = `${environment.apiv3Url}/user/organization/${this.siteService.currentSite.organization.id}`;
    try {
      return this.http.get(url, { params: queryParams }).pipe(
        tap((response: { data: Array<User>; totalCount }) => {
          response.data = response.data.map((user: UserDto) => {
            return new User(user);
          });
        }),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public getPropertyManagersPaginated(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
  ) {
    const { search, siteId } = pagination.filters;
    let active = null;
    let direction = null;
    if (pagination?.sort) {
      active = pagination.sort.active;
      direction = pagination.sort.direction;
    }
    if (search.trim().length > 0) {
      queryParams = queryParams.append(
        'searchBy',
        '["firstName", "lastName", "email"]',
      );
    }
    queryParams = queryParams.append('sortField', active as string);
    queryParams = queryParams.append('admin', 'all');
    queryParams = queryParams.append('sortOrder', direction.toUpperCase());
    const url = `${environment.apiv3Url}/user/site/${siteId}`;
    try {
      return this.http.get(url, { params: queryParams }).pipe(
        tap((response: { data: Array<User>; totalCount }) => {
          response.data = response.data.map((user: UserDto) => {
            return new User(user);
          });
        }),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }
}
