import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { firstValueFrom, lastValueFrom, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import {
  GenericBEDataSource,
  PaginationInformation,
} from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';
import bootstrapTable from 'src/app/shared/pagination-server-side/bootstrap-table';
import { Site } from '@models/site';
import { SiteService } from 'src/app/core/services/site.service';
import { MenuPreviewComponent } from '../menu-preview/menu-preview.component';
import { MenuService } from '../../core/services/menu.service';
import { AlertService } from '../../core/services/alert.service';
import { SiteMealPlanResponse } from '@interfaces/siteMenu';
import { MenuPrintModalComponent } from '../modals/menu-print-modal/menu-print-modal.component';

@Component({
  selector: 'app-menu-table',
  templateUrl: './menu-table.component.html',
  styleUrls: ['./menu-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() showDelete: boolean = false;

  @Input() set filter(filterValue: string) {
    if (!this.filters || !filterValue) return;
    this.filters.controls['search'].setValue(filterValue || '');
  }

  @Input() site: Site;

  private destroyed = new Subject<boolean>();

  public dataSource: MatTableDataSource<Site>;
  public paginatedDataSource: GenericBEDataSource<SiteMealPlanResponse>;
  public displayedColumns = [
    'siteId',
    'name',
    'state',
    'startDate',
    'endDate',
    'daysRemaining',
    'actions',
  ];

  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(''),
    verified: new UntypedFormControl(false), // Not used for menus, but required for paginated data source filters
    showDeleted: new UntypedFormControl(false), // Not used for menus, but required for paginated data source filters
    refreshTrigger: new UntypedFormControl(1),
  });

  constructor(
    private dialog: MatDialog,
    public siteService: SiteService,
    private menuService: MenuService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);

    this.paginatedDataSource = new GenericBEDataSource<SiteMealPlanResponse>(
      this.getSiteMealPlansPaginated.bind(this),
    );
  }

  ngAfterViewInit(): void {
    bootstrapTable<Site[]>(
      this.sort,
      this.paginator,
      this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
      this.filters,
    )
      .pipe(takeUntil(this.destroyed))
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  private getSiteMealPlansPaginated(
    queryParams: HttpParams,
    pagination: PaginationInformation,
  ): Observable<{ data: SiteMealPlanResponse[]; totalCount: number }> {
    return this.siteService.getSiteMealPlans(
      queryParams,
      pagination,
      this.site?.name,
    );
  }

  public refresh(): void {
    const refreshTrigger = this.filters.controls['refreshTrigger'].value;
    this.filters.controls['refreshTrigger'].setValue(refreshTrigger + 1);
  }

  public previewMenus(siteId: number): void {
    this.dialog.open(MenuPreviewComponent, {
      data: { siteId },
    });
  }

  public async deleteSiteMenus(site: SiteMealPlanResponse) {
    const confirm = await this.alertService.confirm({
      title: 'Delete site menus?',
      message: `This will delete all menus associated with ${site.name}`,
      textConfirm: 'Delete',
      warn: true,
    });
    if (confirm) {
      await lastValueFrom(this.menuService.deleteSiteMenus(site.siteId));
      this.refresh();
    }
  }

  public async download(site: SiteMealPlanResponse) {
    const selectedWeek = await this.showPrintDialog(site.daysRemaining);
    if (selectedWeek > -1 && selectedWeek < 2) {
      this.menuService.downloadWagMenu(site, selectedWeek);
    }
  }

  private showPrintDialog(daysRemaining: number) {
    const dialogRef = this.dialog.open(MenuPrintModalComponent, {
      width: '350px',
      maxWidth: '350px',
      height: '200px',
      data: { daysRemaining },
    });

    return firstValueFrom(dialogRef.afterClosed());
  }

  buttonDisabled(site: SiteMealPlanResponse) {
    return site.endDate < new Date();
  }
}
