export enum AllowedImageFileTypes {
  'jpg',
  'jpeg',
  'png',
  'gif',
  'tif',
}

export const AllowedImageFileTypesKeys = Object.keys(
  AllowedImageFileTypes
).filter((k) => typeof AllowedImageFileTypes[k as any] === 'number');
