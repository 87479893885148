<ng-container *ngIf="newsletter; else loading">
  <!-- Newsletter Overview -->
  <app-newsletter-overview
    [newsletter]="newsletter"
    (goBack)="goBack()"
    (updated)="fetchNewsletter()"
  >
  </app-newsletter-overview>

  <br />
  <p
    class="center newsletter-warning"
    *ngIf="
      !authService.currentUser?.isOrgAdmin && newsletter?.isPendingDleApproval
    "
  >
    You have 1 business day to approve.
  </p>
  <!-- Needs extra spacer if text is present -->
  <br
    *ngIf="
      !authService.currentUser?.isOrgAdmin && newsletter?.isPendingDleApproval
    "
  />

  <!-- Articles Table -->
  <mat-card
    appearance="outlined"
    color="primary"
    style="overflow-y: auto; max-height: 85vh"
    id="article-table"
  >
    <ng-container *ngIf="newsletter">
      <mat-card-header class="flex justify-between items-center content-center">
        <mat-card-title
          >Articles ({{ newsletter.articleCount }} + backpage
          articles)</mat-card-title
        >
        <div
          class="control-buttons buttons flex justify-between items-baseline place-content-stretch gap-[10px]"
        >
          <!-- <button mat-raised-button color="accent" (click)="updateEditArticleComponent()">Add New Article</button> -->
          <button
            mat-raised-button
            color="accent"
            (click)="openNewsletterTemplates()"
            *ngIf="site?.trainingGuideUrl"
          >
            Training Guide
          </button>
        </div>
      </mat-card-header>
      <mat-card-content style="padding-top: 16px">
        <app-articles-table
          *ngIf="newsletter?.article"
          class="flex flex-col"
          [articles]="newsletter.article"
          [newsletter]="newsletter"
          (triggerEditArticle)="updateEditArticleComponent($event)"
          (triggerViewArticle)="updateViewArticleComponent($event)"
        ></app-articles-table>
      </mat-card-content>
    </ng-container>
  </mat-card>

  <br />

  <!-- Add/Edit Article -->
  <app-edit-article
    id="edit-article"
    *ngIf="showEditArticle"
    [existingArticle]="selectedArticle"
    [newsletter]="newsletter"
    [siteId]="siteId"
    (hide)="showEditArticle = false"
    (updated)="fetchNewsletter()"
    (hasChanges)="hasChanges($event)"
    [frontPageLimitReached]="newsletter.frontPageLimitReached"
  ></app-edit-article>

  <!-- View Article -->
  <app-view-article
    id="edit-article"
    *ngIf="showViewArticle"
    [article]="selectedArticle"
    (hide)="showViewArticle = false"
  ></app-view-article>

  <br *ngIf="showEditArticle || showViewArticle" />

  <!--
  Row for Comments and Attachments cards.
  Each is a column set to be the same height with internal scrolling.
-->
  <div class="flex gap-2 w-full">
    <app-newsletter-comments
      class="flex w-1/2"
      [newsletter]="newsletter"
      (updated)="fetchNewsletter()"
    ></app-newsletter-comments>
    <app-newsletter-attachments
      class="flex w-1/2"
      [newsletter]="newsletter"
      (updated)="fetchNewsletter()"
    ></app-newsletter-attachments>
  </div>

  <!-- Ensure there's bottom space beneath the cards -->
  <br />
  <br />
</ng-container>
<ng-template #loading>
  <mat-spinner diameter="60" color="accent" class="loader"></mat-spinner>
</ng-template>
