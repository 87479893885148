import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { SiteService } from 'src/app/core/services/site.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Site } from '@models/site';
import { NewsletterService } from 'src/app/core/services/newsletter.service';

@Component({
  selector: 'app-nav-drawer',
  templateUrl: './nav-drawer.component.html',
  styleUrls: ['./nav-drawer.component.scss'],
})
export class NavDrawerComponent implements OnInit, OnDestroy {
  public orgId: number;
  public site: Site;
  public siteId: number;
  public showActivitiesCalendar = false;
  private subscriptions = [];
  @Output() closeDashboard: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    public siteService: SiteService,
    public authService: AuthService,
    public newsletterService: NewsletterService,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.siteService.siteId.subscribe((sideId) => (this.siteId = sideId)),
    );
    this.subscriptions.push(
      this.siteService.site.subscribe((site) => (this.site = site)),
    );
    this.subscriptions.push(
      this.authService.user.subscribe((user) => (this.orgId = user?.orgId)),
    );
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}
