import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-menu-print-modal',
  templateUrl: './menu-print-modal.component.html',
  styleUrls: ['./menu-print-modal.component.scss'],
})
export class MenuPrintModalComponent implements OnInit {
  public canPrintThisWeek: boolean;
  public canPrintNextWeek: boolean;

  constructor(
    public dialogRef: MatDialogRef<MenuPrintModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { daysRemaining: number }
  ) {}

  ngOnInit(): void {
    const nextMonday = DateTime.local().endOf('week');
    const daysRemainingInWeek = nextMonday.diffNow('days').toObject().days;
    this.canPrintThisWeek = this.data.daysRemaining > daysRemainingInWeek ? true : false;
    this.canPrintNextWeek = this.data.daysRemaining > (daysRemainingInWeek + 7) ? true : false;
  }

  onSelect(week: number): void {
    this.dialogRef.close(week);
  }
}
