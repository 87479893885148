import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  takeUntil,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { SiteService } from 'src/app/core/services/site.service';
import { Announcement } from '@models/announcement';
import {
  ActivityService,
  BulkUpdateResponse,
} from 'src/app/core/services/activity.service';
import { ManageService } from 'src/app/core/services/manage.service';
import { AlertService } from 'src/app/core/services/alert.service';
import {
  BatchEvents,
  BatchEventFormComponent,
} from './batch-event-form/batch-event-form.component';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { PdfService } from 'src/app/core/services/pdf.service';
import {
  ConfirmationDialogValues,
  ConfirmationDialogComponent,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import {
  defaultEventKey,
  EventType,
} from '../../../../../core/enums/event-type';
import { EventDownloadModalComponent } from 'src/app/shared/modals/event-download-modal-component/event-download-modal.component';
import { EventFormModalComponent } from 'src/app/manage/activities/event-form-modal-component/event-form-modal.component';
import { DateTime } from 'luxon';
import { CategorySelectModalComponent } from './category-select-modal/category-select-modal.component';
import { CreateAnnouncementPayload } from 'src/app/core/interfaces/api';

interface ScheduleDay {
  dateHeader: DateTime;
  events: Announcement[];
}

@AutoUnsubscribe()
@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  editing = false;
  months: DateTime[] = [];
  selectedDate: DateTime;
  selectedEvent: Announcement;
  selectedMonth: DateTime;
  showForm: DateTime;
  backgroundId: number;
  categoryId: number;
  displayedColumns: string[] = ['day', 'time', 'title', 'room'];
  public showBatchCreator = false;
  private destroyed = new Subject<boolean>();
  public isSaving = new BehaviorSubject<boolean>(false);
  public eventTypes;
  public selectedMonth$ = new Subject<number>();
  public isLoading = true;
  public timezone;
  public printCalendarRestrictions: {
    monthData;
    maxEventRows;
    maxChars;
    eventRowLimits;
  };
  @ViewChild(BatchEventFormComponent) batchEventFormComponent;

  constructor(
    public activityService: ActivityService,
    public siteService: SiteService,
    public manageService: ManageService,
    public calendarService: CalendarService,
    private pdfService: PdfService,
    private alert: AlertService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.eventTypes = EventType;
    this.siteService.site
      .pipe(
        filter((site) => site !== null),
        distinctUntilKeyChanged('id'),
        takeUntil(this.destroyed),
      )
      .subscribe((site) => {
        if (!site) {
          return;
        }
        this.timezone = site.timezone;
        this.months = this.generateDateOptions(site.timezone);

        this.selectedMonth = this.months[12];
        this.updatePrintCalendarRestrictions(this.selectedMonth);

        this.activityService.refreshActivities(this.selectedMonth);
      });

    this.activityService.activities
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed))
      .subscribe((activities: Announcement[]) => {
        this.isLoading = false;
      });
    this.activityService.isGettingEvents
      .pipe(takeUntil(this.destroyed))
      .subscribe((response) => {
        // listen for load start, we'll handle load end after markup resolves
        if (response && !this.isLoading) {
          this.isLoading = response;
        }
      });

    // Debounce changing selected month too quickly
    this.selectedMonth$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this.destroyed),
      )
      .subscribe((month: number) => {
        this.changeSelectedMonth(month);
      });
  }

  ngOnDestroy() {
    // Adding this to manually unsubscribe from the subscriptions in ngOnInit();
    // @AutoUnsubscribe didn't appear to be working for those.
    this.destroyed.next(true);
  }

  //
  public showPrintDialog(monthDateTime: DateTime): void {
    // Keeping for demo purposes/changing paper or font size on the fly
    // const selectedMonthIndex = this.selectedMonth.get('month');
    // const selectedYear = this.selectedMonth.toFormat('yyyy');
    // const dialogRef = this.dialog.open(PrintModalComponent, {
    //   minWidth: '400px',
    //   data: {
    //     selectedMonthIndex: selectedMonthIndex,
    //     selectedYear: selectedYear,
    //   },
    // });
    const dialogRef = this.dialog.open(CategorySelectModalComponent, {
      minWidth: '400px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const subcategoryIds = result.includes('All') ? undefined : result;
        this.pdfService.downloadCalendarPdf(
          this.selectedMonth.month,
          this.selectedMonth.year,
          monthDateTime,
          { subcategoryIds },
        );
      }
    });
  }

  public openCalendarPdf(): void {
    const selectedMonthIndex = this.selectedMonth.month;
    const selectedYear = this.selectedMonth.year;
    this.pdfService.openActivityCalendarPdf(
      selectedMonthIndex,
      Number(selectedYear),
      this.selectedMonth,
    );
  }

  formatEventsForTable(events: Announcement[]): ScheduleDay[] {
    const schedule: ScheduleDay[] = [];
    const lastDayOfMonth: DateTime = this.selectedMonth.endOf('month');
    for (let i = 0; i < lastDayOfMonth.date; i++) {
      const currentDay: DateTime = this.selectedMonth.plus({ days: i });
      const currentDayEvents = events.filter((event) => {
        return DateTime.setZone(event.timezone).date === currentDay.date;
      });
      schedule.push({
        dateHeader: currentDay,
        events: currentDayEvents ? currentDayEvents : [],
      });
    }

    return schedule;
  }

  /**
   * Generate months array for month selector.
   * Moment objects are based on the property's timezone.
   * When used for an API call, the Angular service is responsible for converting to UTC.
   */
  private generateDateOptions(timezone: string) {
    const dateOptions: DateTime[] = [];

    for (let n = -12; n < 13; n++) {
      dateOptions.push(
        DateTime.local().setZone(timezone).startOf('month').plus({ months: n }),
      );
    }

    return dateOptions;
  }

  public deleteActivity(activity: Announcement): void {
    const confirmData: ConfirmationDialogValues = {
      title: `Delete "${activity.title}"?`,
      message: 'This action can not be undone.',
      yesText: 'Delete',
      onConfirm: async () => {
        await Promise.all([
          this.manageService.deleteAnnouncement(activity),
          this.activityService.refreshActivities(this.selectedMonth, false),
        ]);

        this.alert.success(`"${activity.title}" deleted.`);
      },
      onError: (error) => {
        console.error(error);
        this.alert.error(
          `Error deleting "${activity.title}". Please try again.`,
        );
      },
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
  }

  public handleMonthStep(change: number) {
    this.isLoading = true;
    const currentMonthIndex = this.months.indexOf(this.selectedMonth);
    const newMonthIndex = currentMonthIndex + change;
    this.selectedMonth = this.months[newMonthIndex];
    this.selectedMonth$.next(newMonthIndex);
  }

  public handleMonthSelect(newMonthIndex: number) {
    this.isLoading = true;
    this.selectedMonth = this.months[newMonthIndex];
    this.selectedMonth$.next(newMonthIndex);
  }

  public changeSelectedMonth(month: number) {
    this.activityService.refreshActivities(this.selectedMonth, false);
    this.updatePrintCalendarRestrictions(this.selectedMonth);
  }

  public async updatePrintCalendarRestrictions(selectedMonth: DateTime) {
    // Determine how many rows are in this month
    const monthData = await this.calendarService.evaluateCalendarFormatForMonth(
      selectedMonth,
    );

    // how many event title rows will fit in a day
    const maxEventRows = this.pdfService.calculateRowsInCalRow(
      monthData.rowsRequired,
    );

    // given the max characters that fit in a row based on default page width...
    const maxChars = this.pdfService.getMaxNumCharacters(
      // @ts-ignore
      this.pdfService.selectedPageSize.width,
    );

    // limits are based on the 'small' font size because
    // a quantity of events over a certain threshold will always trigger the smaller font
    const eventRowLimits = maxEventRows.numLinesPerDay.small;
    this.printCalendarRestrictions = {
      monthData,
      maxEventRows,
      maxChars,
      eventRowLimits,
    };
  }

  public addSingleEvent(day: DateTime) {
    this.showForm = day;
    this.editing = false;
    this.selectedDate = day;
  }

  public editActivity(event: Announcement, day: DateTime) {
    this.selectedDate = day;
    this.selectedEvent = event;
    this.editing = true;
    this.showForm = day;
  }

  public closeForm() {
    this.isSaving.next(false);
    this.editing = false;
    this.showForm = null;
    this.selectedEvent = null;
  }

  public addEvent(): void {
    this.dialog.open(EventFormModalComponent, {
      width: '80vw',
      height: '660px',
      data: {
        selectedMonth: this.selectedMonth,
        months: this.months,
      },
    });
  }

  private formToAnnouncement(form, date: DateTime): CreateAnnouncementPayload {
    const { value } = form;

    let startShowing: Date;
    const dateFormatted = date.toFormat('yyyy-MM-dd');
    const timezone = this.siteService.currentSite.timezone;
    const eventStart = DateTime.fromFormat(
      `${dateFormatted} ${value.eventStart}`,
      'yyyy-MM-dd HH:mm',
    ).setZone(timezone);

    // moment.tz(
    //   `${dateFormatted} ${value.eventStart}`,
    //   timezone
    // );
    let eventEnd: DateTime;

    // Need to check if event end should be the subsequent day (e.g. an event running from 11:30PM to 12:30AM)
    // If the eventEnd is earlier than eventStart (e.g. 12:30AM will come through as "00:30" and 11:30PM as "11:30")
    // then assume the event is set to end the following day.
    if (value.eventEnd < value.eventStart) {
      eventEnd = DateTime.fromFormat(
        `${dateFormatted} ${value.eventEnd}`,
        'yyyy-MM-dd HH:mm',
      )
        .setZone(this.timezone)
        .plus({ days: 1 });

      // moment
      //   .tz(`${dateFormatted} ${value.eventEnd}`, timezone)
      //   .add(1, 'day');
    } else {
      eventEnd = DateTime.fromFormat(
        `${dateFormatted} ${value.eventEnd}`,
        'yyyy-MM-dd HH:mm',
      ).setZone(this.timezone);
      // moment.tz(`${dateFormatted} ${value.eventEnd}`, timezone);
    }

    const stopShowing: DateTime = eventEnd;
    if (EventType[value.eventType] === EventType.SpecialEvent) {
      startShowing = eventStart.minus({ days: 1 });
      // moment(eventStart).subtract(1, 'weeks').toDate();
    } else {
      startShowing = eventStart.minus({ weeks: 1 });
      // moment(eventStart).subtract(1, 'day').toDate();
    }

    return {
      ...this.selectedEvent,
      startShowing: startShowing.toISOString(),
      stopShowing: stopShowing.toISOString(),
      roomId: value.room.id ? parseInt(value.room.id) : undefined,
      location: value.location,
      eventStart: eventStart.toISOString(),
      eventEnd: eventEnd.toISOString(),
      siteId: this.siteService.currentSiteId,
      styleId: 3,
      title: value.title.trim(),
      content: value.content,
      backgroundId: value.background.id,
      eventTags: value.eventTags,
      eventType: value.eventType ? value.eventType : defaultEventKey,
      categoryId: value.category,
      subcategoryId: value.subcategory,
      videoUrl: value.videoUrl,
      recurrenceId: value.recurrenceId || null,
      allDay: value.allDay ? value.allDay : false,
      showOnPrintCalendar: value.showOnPrintCalendar,
      showOnDigitalSignage: value.showOnDigitalSignage,
      secondaryImages: value.secondaryImaged?.map((image) => image.id),
    };
  }

  public async saveBatch(batch: BatchEvents) {
    try {
      const events = [];
      this.isSaving.next(true);
      batch.dates.forEach((date: DateTime) => {
        events.push(this.formToAnnouncement(batch.form, date));
      });

      const result = await this.manageService.saveBulkAnnouncements(events);
      await this.activityService.refreshActivities(this.selectedMonth, false);
      this.batchEventFormComponent.clear();
      this.alert.success(`${events.length} events saved!`);
      this.toggleShowBatchEvents();
    } catch (e) {
      console.error('error saving batch events', e);
      throw new Error(e);
    }
  }

  public async saveSingle(singleForm) {
    this.isSaving.next(true);
    const eventDate = singleForm.value.date
      ? DateTime(singleForm.value.date)
      : this.selectedDate;
    const event = this.formToAnnouncement(singleForm, eventDate);
    const result = await this.manageService.saveAnnouncement(event);
    await this.activityService.refreshActivities(this.selectedMonth, false);
    this.closeForm();
    this.alert.success(`${event.title} saved!`);
  }

  public async copyPreviousMonthsActivities() {
    const prevMonth = DateTime(this.selectedMonth).minus({ months: 1 });

    // moment(this.selectedMonth).subtract(1, 'month');
    const confirmData: ConfirmationDialogValues = {
      title: `Copy repeating activities into ${this.selectedMonth.toFormat(
        'MMMM yyyy',
      )}?`,
      message: `All repeating activities in from ${prevMonth.toFormat(
        'MMMM yyyy',
      )} will be
        copied into ${this.selectedMonth.toFormat(
          'MMMM yyyy',
        )}. This will result in duplicates
        if you have already performed this operation.`,
      yesText: 'Copy',
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        // tslint:disable-next-line:max-line-length
        const insertResult: BulkUpdateResponse =
          await this.activityService.copyPreviousMonthsEvents(
            this.siteService.currentSiteId,
            this.selectedMonth,
          );
        if (insertResult && insertResult.raw) {
          if (insertResult.raw.length) {
            this.alert.success(
              `${
                insertResult.raw.length
              } events copied from ${prevMonth.toFormat('MMMM yyyy')}.`,
            );
            this.activityService.refreshActivities(this.selectedMonth, false);
          } else {
            this.alert.success(
              `No events found in ${prevMonth.toFormat('MMMM yyyy')} to copy.`,
            );
          }
        }
      }
    });
  }

  public toggleShowBatchEvents() {
    if (!this.showBatchCreator) {
      // If opening batch event form, scroll to top
      const page = document.getElementsByClassName('dash-content');
      page[0].scrollTop = 0;
    }
    this.isSaving.next(false);
    this.showBatchCreator = !this.showBatchCreator;
  }

  public deleteMonthsActivities(): void {
    const confirmData: ConfirmationDialogValues = {
      title: `Delete all activities for ${this.selectedMonth.toFormat(
        'MMMM yyyy',
      )}?`,
      message: `All activities in ${this.selectedMonth.toFormat(
        'MMMM yyyy',
      )} will no longer be displayed or accessbile by administrators.`,
      yesText: 'Delete',
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.activityService
          .deleteMonthsEvents(
            this.siteService.currentSiteId,
            this.selectedMonth,
          )
          .then((res) => {
            this.activityService.refreshActivities(this.selectedMonth, false);
            this.alert.success(
              `All events in ${this.selectedMonth.toFormat(
                'MMMM yyyy',
              )} deleted.`,
            );
          })
          .catch((err) => {
            console.error(err);
            this.alert.error(
              `Error deleting events in ${this.selectedMonth.toFormat(
                'MMMM yyyy',
              )}. Please try again.`,
            );
          });
      }
    });
  }

  public trackByFn(index, item) {
    return item && item.id ? item.id : null;
  }

  public showDownloadDialog(): void {
    const selectedMonthIndex = this.selectedMonth.month;
    const selectedYear = this.selectedMonth.year;
    const site = this.siteService.currentSite;

    this.dialog.open(EventDownloadModalComponent, {
      width: '500px',
      data: {
        selectedMonthIndex: selectedMonthIndex,
        selectedYear: selectedYear,
        site: site,
        timezone: site.timezone,
      },
    });
  }
}
