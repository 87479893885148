import { Component, OnInit, Inject } from '@angular/core';
import { UserFormComponent } from 'src/app/shared/forms/user-form/user-form.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user.service';
import { User } from '@models/user';
import { EmailFormComponent } from 'src/app/shared/forms/email-form/email-form.component';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  public userForm = UserFormComponent.formModel();
  public emailForm = EmailFormComponent.formModel();
  public submitting = false;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<EditUserComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public user: User
  ) {}

  ngOnInit() {
    this.userForm.setValue(UserFormComponent.deserialize(this.user));
    this.emailForm.setValue({ email: this.user.email });
  }

  public async onSave(): Promise<void> {
    this.submitting = true;
    const updatedUser = UserFormComponent.serialize(
      this.userForm.value,
      this.user
    );
    const updatedEmail = this.user.email !== this.emailForm.value.email;

    if (updatedEmail) {
      updatedUser.email = this.emailForm.value.email;
    }

    this.userService
      .updateUser(updatedUser, updatedEmail)
      .then(() => {
        this.alertService.success('User saved.');
        if (updatedUser.id === this.authService.currentUser.id) {
        }
        this.onCancel();
      })
      .catch((error) => {
        const message =
          error?.error?.code === 'auth/email-already-exists'
            ? 'This email is already associated with an account.'
            : 'Unable to add user. Please try again.';
        this.alertService.error(message);
        console.error(error);
      })
      .finally(() => (this.submitting = false));
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
