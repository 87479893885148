<h1 mat-dialog-title>{{ config.name }}</h1>
<mat-dialog-content [formGroup]="form" class="flex flex-col">
  <mat-form-field appearance="outline" color="accent">
    <mat-label *ngIf="config.label">{{ config.label }}</mat-label>
    <input
      *ngIf="config.inputType === 'input'"
      [type]="config.inputItemType"
      matInput
      autofocus
      [step]="config.step ? config.step : 1"
      [required]="config.required"
      formControlName="item"
      [maxlength]="config.maxLength"
    />
    <ng-container *ngIf="config.inputType === 'datepicker'">
      <input
        matInput
        [matDatepicker]="picker"
        [required]="config.required"
        formControlName="item"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </ng-container>
    <textarea
      *ngIf="config.inputType === 'textarea'"
      matInput
      autofocus
      cdkTextareaAutosize="true"
      formControlName="item"
      [required]="config.required"
      [maxlength]="config.maxLength"
    ></textarea>
    <mat-select
      *ngIf="config.inputType === 'select'"
      [required]="config.required"
      formControlName="item"
      autofocus
      color="accent"
    >
      <ng-container *ngIf="config.enumInput">
        <mat-option
          color="accent"
          *ngFor="let key of config.enumKeys"
          [value]="key"
        >
          {{ key | enum : config.enumInput }}
        </mat-option>
      </ng-container>
      <ng-container *ngIf="config.keyValuePairs">
        <mat-option
          color="accent"
          *ngFor="let pair of config.keyValuePairs"
          [value]="pair.key"
        >
          {{ pair.value }}
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-hint *ngIf="config.maxLength"
      >{{ form.controls['item'].value?.length || 0 }}/{{
        config.maxLength
      }}
      characters</mat-hint
    >
  </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <app-progress-button
    mat-raised-button
    color="accent"
    [disabled]="!form.valid"
    (click)="save()"
    [submitting]="submitting"
  >
    Save
  </app-progress-button>
</div>
