import { SiteTypeDto } from '../interfaces/siteType';
import { OrganizationDto } from '../interfaces/organization';
import { StyleDto } from '../interfaces/style';

export class SiteType implements SiteTypeDto {
  public id: number;
  public createdOn: Date;
  public updatedOn: Date;
  public deleted: false;
  description: string;
  organization: OrganizationDto;
  styles?: StyleDto[];

  constructor(data: SiteTypeDto) {
    Object.assign(this, data);
  }
}
