import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Newsletter } from '@models/newsletter';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NewsletterService } from 'src/app/core/services/newsletter.service';
import {
  ConfirmationDialogValues,
  ConfirmationDialogComponent,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import {
  SingleItemFormModalComponent,
  SingleItemFormModalConfig,
} from 'src/app/shared/modals/single-item-form-modal/single-item-form-modal.component';
import { NewsletterStatus } from '../../../../../../core/enums/newsletter-status';
import { difference } from 'lodash';

@Component({
  selector: 'app-newsletter-overview',
  templateUrl: './newsletter-overview.component.html',
  styleUrls: ['./newsletter-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterOverviewComponent implements OnInit {
  @Input() newsletter: Newsletter;
  @Output() public goBack: EventEmitter<boolean> = new EventEmitter();
  @Output() updated: EventEmitter<boolean> = new EventEmitter();

  public newsletterStatusKeys = Object.keys(NewsletterStatus);
  public filesProcessingWithImages = null;
  public filesProcessingWithoutImages = null;

  constructor(
    private alertService: AlertService,
    public authService: AuthService,
    private dialog: MatDialog,
    public newsletterService: NewsletterService,
  ) {}

  ngOnInit(): void {}

  /**
   * Get the current action a DLE can take on a Newsletter
   */
  public get dleAction(): {
    action: string;
    enabled: boolean;
    newStatus?: string;
    confirmationMessage?: string;
  } {
    switch (this.newsletter?.statusIndex) {
      case 0:
        return {
          action: 'Submit to Editor',
          newStatus: this.newsletterStatusKeys[1],
          enabled: true,
          confirmationMessage: 'Ready to submit this Newsletter for review?',
        };
      case 1:
        return {
          action: NewsletterStatus.submitted,
          enabled: false,
        };
      case 2:
        return {
          action: 'Approve to Print',
          enabled: true,
          newStatus: this.newsletterStatusKeys[3],
          confirmationMessage: 'Ready to approve this Newsletter for print?',
        };
      case 3:
        return {
          action: NewsletterStatus.approved,
          enabled: false,
        };
    }
  }

  public back(): void {
    this.goBack.next(true);
  }

  checkNewsLetterStatus() {
    if (this.newsletter.status === NewsletterStatus.draft.toLowerCase()) {
      return false;
    }
    return true;
  }

  public deleteNewsletter(): void {
    const confirmData: ConfirmationDialogValues = {
      message: 'Do you want to delete this Newsletter? This cannot be undone.',
      onConfirm: async () => {
        await this.processUpdate('deleted', true, 'Newsletter deleted.');

        await this.goBack.next(true);
      },
      onError: (error) => {
        this.alertService.error(
          'Unable to delete Newsletter. Please try again.',
        );
        console.error(error);
      },
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
  }

  public editName(): void {
    const config: SingleItemFormModalConfig = {
      name: 'Edit Newsletter',
      value: this.newsletter.name || '',
      inputType: 'input',
      inputItemType: 'text',
      label: 'Name',
      required: true,
      onConfirm: async (name: string) => {
        await this.processUpdate('name', name, 'Newsletter name updated.');
      },
      onError: (error) => {
        this.alertService.error(
          'Unable to save Newsletter name. Please try again.',
        );
        console.error(error);
      },
    };

    const dialogRef = this.dialog.open(SingleItemFormModalComponent, {
      data: config,
    });
  }

  public editSubmissionDate(): void {
    const config: SingleItemFormModalConfig = {
      name: 'Edit Newsletter',
      value: this.newsletter.submissionDueOn,
      inputType: 'datepicker',
      label: 'Submission Due Date',
      required: true,
      onConfirm: async (date: string) => {
        await this.processUpdate(
          'submissionDueOn',
          date,
          'Newsletter submission due date updated.',
        );
      },
      onError: (error) => {
        this.alertService.error(
          'Unable to save Newsletter submission due date. Please try again.',
        );
        console.error(error);
      },
    };

    const dialogRef = this.dialog.open(SingleItemFormModalComponent, {
      data: config,
    });
  }

  public editPrintQuantity(): void {
    const config: SingleItemFormModalConfig = {
      name: 'Edit Newsletter',
      value: this.newsletter.printQuantity,
      step: 25,
      inputType: 'input',
      inputItemType: 'number',
      label: 'Print Quantity',
      required: true,
      onConfirm: async (count: number) => {
        await this.processUpdate(
          'printQuantity',
          count,
          'Newsletter print quantity updated.',
        );
      },
      onError: (error) => {
        this.alertService.error(
          'Unable to save Newsletter print quantity. Please try again.',
        );
        console.error(error);
      },
    };

    const dialogRef = this.dialog.open(SingleItemFormModalComponent, {
      data: config,
    });
  }

  public editArticleCount(): void {
    const config: SingleItemFormModalConfig = {
      name: 'Edit Newsletter',
      value: this.newsletter.articleCount,
      inputType: 'select',
      required: true,
      label: 'Article Count',
      keyValuePairs: [
        {
          key: 6,
          value: 6,
        },
        {
          key: 7,
          value: 7,
        },
        {
          key: 8,
          value: 8,
        },
      ],
      onConfirm: async (count: number) => {
        this.confirmArticleCountChange(count);
      },
      onError: (error) => {
        this.alertService.error(
          'Unable to update Newsletter article count. Please try again.',
        );
        console.error(error);
      },
    };

    const dialogRef = this.dialog.open(SingleItemFormModalComponent, {
      data: config,
    });
  }

  public openStatusModal(): void {
    const config: SingleItemFormModalConfig = {
      name: 'Edit Newsletter',
      value: this.newsletter.status,
      inputType: 'select',
      required: true,
      label: 'Status',
      enumInput: 'newsletterStatus',
      enumKeys: this.newsletterStatusKeys,
      onConfirm: async (result: any) => {
        await this.processUpdate(
          'status',
          result,
          'Newsletter status updated.',
        );
      },
      onError: (error) => {
        this.alertService.error(
          'Unable to save updated status. Please try again.',
        );
        console.error(error);
      },
    };

    const dialogRef = this.dialog.open(SingleItemFormModalComponent, {
      data: config,
    });
  }

  public modifyNewsletterStatus(newStatus: any, confirmMessage: string): void {
    const confirmData: ConfirmationDialogValues = {
      message: confirmMessage,
      onConfirm: async () => {
        const successMessage =
          newStatus === 'submitted'
            ? 'Newsletter submitted!'
            : 'Newsletter status updated.';
        await this.processUpdate('status', newStatus, successMessage);
      },
      onError: (error) => {
        this.alertService.error(
          'Unable to update Newsletter status. Please try again.',
        );
        console.error(error);
      },
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
  }

  private async processUpdate(
    property: string,
    value: any,
    successMessage: string,
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const newsletter = Object.assign({}, this.newsletter);
        newsletter[property] = value;
        await this.newsletterService.saveNewsletter(
          newsletter,
          this.newsletter,
        );
        this.updated.next(true);
        this.alertService.success(successMessage);
        return resolve({});
      } catch {
        return reject();
      }
    });
  }

  private async processUpdateForArticleCount(
    property: string,
    value: any,
    successMessage: string,
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const newsletter = Object.assign({}, this.newsletter);
        newsletter[property] = value;
        await this.newsletterService.saveNewsletterArticleCount(newsletter);
        this.updated.next(true);
        this.alertService.success(successMessage);
        return resolve({});
      } catch {
        return reject();
      }
    });
  }

  private confirmArticleCountChange(updatedCount: number): void {
    const verb = updatedCount > this.newsletter.articleCount ? 'add' : 'delete';
    const affectedArticles = this.affectedArticleTypes(updatedCount);
    const message = `You are switching from ${
      this.newsletter.articleCount
    } to ${updatedCount} articles.
      This will change character limits and automatically ${verb} the ${affectedArticles.join(
      ', ',
    )}
      article${affectedArticles.length === 1 ? '' : 's'}.
      <br /><br />
      There is no way to undo this action once it has been completed.
      <br /><br />
      Proceed anyway?`;

    const confirmData: ConfirmationDialogValues = {
      message: message,
      yesText: 'Proceed',
      onConfirm: async () => {
        await this.processUpdateForArticleCount(
          'articleCount',
          updatedCount,
          'Newsletter article count updated.',
        );
      },
      onError: (error) => {
        console.error(error);
        this.alertService.error(
          'Unable to update Newsletter article count. Please try again.',
        );
      },
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
  }

  /**
   * Find which articleTypes are affected by the articleCount shift.
   */
  private affectedArticleTypes(newCount: number): string[] {
    const relevantTypesForNewCount = this.filterArticleTypes(newCount);
    const relevantTypesForOldCount = this.filterArticleTypes(
      this.newsletter.articleCount,
    );

    return relevantTypesForOldCount.length > relevantTypesForNewCount.length
      ? difference(relevantTypesForOldCount, relevantTypesForNewCount)
      : difference(relevantTypesForNewCount, relevantTypesForOldCount);
  }

  /**
   * Filter articleTypes down to the relevant items for a given articleCount
   * Note this filter logic is also used in the API for Newsletters.
   */
  private filterArticleTypes(count: number) {
    return this.newsletterService.articleTypes
      .filter((articleType) => {
        if (
          articleType.backpage ||
          count === 8 ||
          (count === 7 && articleType.sevenArticleCharacterCount) ||
          (count === 6 && articleType.sixArticleCharacterCount)
        ) {
          return true;
        } else {
          return false;
        }
      })
      .map((articleType) => articleType.name);
  }

  public exportNewsletterFiles = (includeImages: boolean) => {
    if (includeImages) {
      this.filesProcessingWithImages = this.newsletter.id;
    } else {
      this.filesProcessingWithoutImages = this.newsletter.id;
    }

    this.newsletterService
      .exportNewsletterFiles(this.newsletter.id, includeImages)
      .then((response) => {
        this.alertService.success(
          'Request processing. Please check your email in a few minutes.',
        );
      })
      .catch((error) => {
        this.alertService.error(
          'There was a problem processing your request. Please try again.',
        );
      })
      .finally(() => {
        // Update loading spinner on UI
        this.filesProcessingWithImages = null;
        this.filesProcessingWithoutImages = null;
        this.updated.next(true);
      });
  };
}
