import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { SiteService } from './site.service';

interface OpenWeathermapApiResponse {
  list?: any[];
}
interface OpenWeathermapDailyForecast {
  dt: Date;
  temp: {
    day: number;
    min: number;
    max: number;
    night: number;
    eve: number;
    morn: number;
  };
  pressure: number;
  humidity: number;
}

export interface DailyForecast {
  high: number;
  low: number;
  icon: string;
}

export interface ThreeDayForecast {
  today: DailyForecast;
  tomorrow: DailyForecast;
  overmorrow: DailyForecast; // two days from now
}
@Injectable({
  providedIn: 'root',
})
export class WeatherService {
  private lastWeatherRequestSiteId: number = null;

  private _threeDayForecast = new BehaviorSubject<ThreeDayForecast | null>(
    null,
  );
  public readonly threeDayForecast = this._threeDayForecast.asObservable();

  public nullWeather = {
    // When no weather data
    today: null,
    tomorrow: null,
    overmorrow: null,
  };
  public dummyWeather = {
    // For local use to avoid hitting weather API
    today: {
      high: 55,
      low: 55,
      icon: 'sunny',
    },
    tomorrow: {
      high: 60,
      low: 60,
      icon: 'sunny',
    },
    overmorrow: {
      high: 65,
      low: 65,
      icon: 'sunny',
    },
  };

  constructor(private http: HttpClient, private siteService: SiteService) {
    this.listenForSiteUpdate();
  }

  /**
   * Update forecast upon siteId change.
   * In the case that the same siteId is pushed through back to back,
   * discard the request to prevent unecessary Weather API requests.
   */
  private listenForSiteUpdate(): void {
    // Set to only request on production to limit Weather API requests.
    // Feel free to comment out if helpful for testing/developing.
    if (!environment.production) {
      this._threeDayForecast.next(this.dummyWeather);
      return;
    }

    this.siteService.siteId.subscribe((siteId) => {
      if (
        typeof siteId !== 'number' ||
        siteId === this.lastWeatherRequestSiteId
      ) {
        this._threeDayForecast.next(this.nullWeather);
      } else {
        this.lastWeatherRequestSiteId = siteId;
        this.fetchWeatherData(siteId);
      }
    });
  }

  /**
   * Fetch weather data and update every two hours (how often Open Weather updates the data)
   * @param siteId
   */
  public fetchWeatherData(siteId: number) {
    this.siteService.getSite(siteId).subscribe((site) => {
      const siteZip = site.zip;
      this.updateWeatherData(siteZip);
      setInterval(() => {
        this.updateWeatherData(siteZip);
      }, 7200000);
    });
  }

  public async updateWeatherData(zipCode: string) {
    try {
      const weatherUrl = `${environment.apiUrl}/api/v1/weather/${zipCode}`;
      const weatherData: OpenWeathermapApiResponse = await lastValueFrom(
        this.http.get(weatherUrl),
      );
      // If no data, push null and return out of function
      if (!weatherData || !weatherData.list || !weatherData.list.length) {
        this._threeDayForecast.next(this.nullWeather);
        return;
      }

      const weatherToPublish: ThreeDayForecast = {
        today: this.transformApiData(weatherData, 0),
        tomorrow: this.transformApiData(weatherData, 1),
        overmorrow: this.transformApiData(weatherData, 2),
      };
      this._threeDayForecast.next(weatherToPublish);
    } catch (err) {
      console.error('FAILED FETCHING WEATHER DATA', err);
      this._threeDayForecast.next(this.nullWeather);
    }
  }

  private transformApiData(
    weatherList: OpenWeathermapApiResponse,
    index: number,
  ): DailyForecast | null {
    const day = weatherList.list[index];

    if (!day || !day.temp || !day.weather || !day.weather[0]) {
      return null;
    }

    return {
      high: this.getFahrenheit(day.temp.max),
      low: this.getFahrenheit(day.temp.min),
      icon: this.getIconName(day.weather[0].icon),
    };
  }

  // Method duplicated in apiv3 weather service
  // Can be deleted after admin app is migrated to featch weather from apiv3
  private getFahrenheit(kelvinTemp: number): number {
    return Math.round((kelvinTemp * 9) / 5 - 459.67);
  }

  // Method duplicated in apiv3 weather service
  // Can be deleted after admin app is migrated to featch weather from apiv3
  private getIconName(iconId: string): string {
    switch (iconId) {
      case '01d':
      case '01n':
        return 'sunny';
      case '02d':
      case '02n':
        return 'few-clouds';
      case '03d':
      case '03n':
      case '04d':
      case '04n':
      case '50d': // No mist icons from new TGG iconset, using scattered-clouds instead
      case '50n':
        return 'scattered-clouds';
      case '09d':
      case '09n':
        return 'shower-rain';
      case '10d':
      case '10n':
        return 'sun-shower';
      case '11d':
      case '11n':
        return 'thunderstorm';
      case '13d':
      case '13n':
        return 'snowfall';
      default:
        return null;
    }
  }
}
