import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { User } from '@models/user';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public form: UntypedFormGroup;
  public loading = false;

  constructor(
    private alert: AlertService,
    public auth: AuthService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  ngOnInit() {}

  public resetPassword() {
    this.loading = true;
    const email = this.form.value.email;
    this.auth
      .forgotPassword(email)
      .then(() => {
        this.router.navigate(['']);
        this.alert.success('Check your email for a password reset link!');
      })
      .catch((error) => {
        this.loading = false;
        this.alert.warning(error.message);
      });
  }
}
