<mat-card appearance="outlined" color="primary">
  <mat-card-header class="row justify-between items-center content-center">
    <mat-card-title color="primary" class="flex">
      Community Updates ({{ paginatedDataSource?.totalCount || 0 }})
    </mat-card-title>
    <div class="flex justify-end items-center content-center w-1/2 gap-[30px]">
      <button mat-raised-button color="accent" (click)="addNotification()">
        Send Community Update
      </button>
      <mat-form-field>
        <input matInput placeholder="Search" [(ngModel)]="filter" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </mat-card-header>
  <mat-card-content class="flex flex-col">
    <div class="loading-wrapper">
      <div class="loading-spinner" *ngIf="paginatedDataSource.loading$ | async">
        <app-material-loader [fillContainer]="true"></app-material-loader>
      </div>
      <div
        class="loading-overlay"
        *ngIf="paginatedDataSource.loading$ | async"
      ></div>
      <table
        mat-table
        matSort
        matSortActive="createdOn"
        matSortDirection="desc"
        [dataSource]="paginatedDataSource"
      >
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9999">
            <i>{{
              this.filters.controls.search?.value?.length > 0
                ? 'No matches for this search.'
                : (paginatedDataSource.loading$ | async)
                ? ''
                : 'No notifications for this property.'
            }}</i>
          </td>
        </tr>
        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Date Sent
          </th>
          <td mat-cell *matCellDef="let notification">
            {{
              notification
                .getSentAt(siteService.currentSite?.timezone)
                .toFormat('M/d/yyyy h:mma')
                .toLowerCase()
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Title
          </th>
          <td mat-cell *matCellDef="let notification">
            {{ notification.title }}
          </td>
        </ng-container>

        <ng-container matColumnDef="text">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Text
          </th>
          <td mat-cell *matCellDef="let notification">
            {{ notification.text }}
          </td>
        </ng-container>
        /*
        <ng-container matColumnDef="expiration">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Expiration
          </th>
          <td mat-cell *matCellDef="let notification">
            {{ notification.expiration | date : 'short' }}
          </td>
        </ng-container>
        */

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let notification">
            <div>
              <button
                mat-icon-button
                color="accent"
                (click)="editNotification(notification)"
                matTooltip="Edit Notification"
              >
                <mat-icon>edit_pencil</mat-icon>
              </button>
              <button
                mat-icon-button
                color="accent"
                (click)="deleteNotification(notification)"
                matTooltip="Delete Notification"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [length]="paginatedDataSource?.totalCount"
        [pageSize]="10"
        [pageSizeOptions]="[10, 20, 30, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
