<form [formGroup]="form">
  <label class="form-question">Publish Slide Now</label>
  <div class="form-group">
    <mat-checkbox
      color="accent"
      formControlName="publishNow"
      (change)="togglePublishImmediately()"
      >Make available immediately</mat-checkbox
    >
  </div>

  <div *ngIf="!form.value.publishNow">
    <div class="row">
      <label class="form-question">Publish Starting</label>
      <div
        class="label-icon"
        matTooltip="When should the slide be published to the digital signage?"
      >
        <mat-icon>help</mat-icon>
      </div>
    </div>
    <div class="row">
      <div class="half left">
        <mat-form-field appearance="outline" color="accent">
          <input
            matInput
            required
            formControlName="startShowing"
            placeholder="Enter date"
            type="date"
            min="{{ today | date : 'yyyy-MM-dd' }}"
            [matDatepicker]="startShowingPicker"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="startShowingPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startShowingPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="half right">
        <mat-form-field appearance="outline" color="accent">
          <input
            matInput
            required
            formControlName="startShowingTime"
            placeholder="00:00 PM"
            type="time"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row">
    <label class="form-question">Publish Until</label>
    <div
      class="label-icon"
      matTooltip="When should the slide no longer be published to the digital signage?"
    >
      <mat-icon>help</mat-icon>
    </div>
  </div>
  <div *ngIf="!publishForever">
    <div class="row">
      <div class="half left">
        <mat-form-field appearance="outline" color="accent">
          <input
            matInput
            required
            formControlName="stopShowing"
            placeholder="Enter date"
            type="date"
            min="{{ today | date : 'yyyy-MM-dd' }}"
            [matDatepicker]="stopShowingPicker"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="stopShowingPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #stopShowingPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="half right">
        <mat-form-field appearance="outline" color="accent">
          <input
            matInput
            required
            formControlName="stopShowingTime"
            placeholder="00:00 PM"
            type="time"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="form-group">
    <mat-checkbox
      color="accent"
      [checked]="publishForever"
      (change)="togglePublishForever()"
      >Make permanent</mat-checkbox
    >
  </div>

  <div class="row">
    <label class="form-question">Display Duration</label>
    <div
      class="label-icon"
      matTooltip="How many seconds should the slide show, each time it displays?"
    >
      <mat-icon>help</mat-icon>
    </div>
  </div>
  <div class="half left">
    <mat-form-field appearance="outline" color="accent">
      <input matInput required formControlName="durationInSecs" type="number" />
    </mat-form-field>
  </div>
</form>
