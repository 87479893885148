/**
 * Note there are areas in the Newsletter class and in the web app based on these in an unfortuantely loose way;
 * If there is any notable change to these ensure it doesn't inadvertently break something else.
*/
export enum NewsletterStatus {
  draft = 'Draft',
  submitted = 'Submitted to Editor',
  pending = 'Pending DLE Approval',
  approved = 'Approved to Print',
};
