import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  OnDestroy,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Article, naCopy } from '@models/article';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject, BehaviorSubject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from 'src/app/core/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { NewsletterService } from 'src/app/core/services/newsletter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {
  ConfirmationDialogValues,
  ConfirmationDialogComponent,
} from '../modals/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { Newsletter } from '@models/newsletter';
import { GenericFEDataSource } from '../pagination-client-side/datasources/genericFE.datasource';
import bootstrapTable from '../pagination-server-side/bootstrap-table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NewsletterStatus } from '../../../../../core/enums/newsletter-status';

interface ArticleRow {
  type: string;
  requiredType: string;
  headline: string;
  characterCount: number;
  imageCount: number;
  // 'frontPage',
}

@Component({
  selector: 'app-articles-table',
  templateUrl: './articles-table.component.html',
  styleUrls: ['./articles-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlesTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() public newsletter: Newsletter;
  @Input() public isDraftNewsletter: boolean;
  @Input() set articles(articles: Article[]) {
    if (articles === null || articles === undefined || !articles.length) {
      this._articles.next([]);
    } else {
      articles = articles.filter((article) => article.deleted === false);
      this._articles.next(articles.map((article) => new Article(article)));
    }
  }
  @Output()
  public triggerEditArticle: EventEmitter<Article> = new EventEmitter();
  @Output()
  public triggerViewArticle: EventEmitter<Article> = new EventEmitter();

  private destroyed = new Subject<boolean>();

  private _articles = new BehaviorSubject<Article[]>([]);

  public dataSource: MatTableDataSource<ArticleRow>;
  public paginatedDataSource: GenericFEDataSource<Article>;

  public displayedColumns = [
    'type',
    'requiredType',
    'headline',
    'characterCount',
    'imageCount',
    // 'frontPage',
    'actions',
  ];
  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(''),
    verified: new UntypedFormControl(false),
    showDeleted: new UntypedFormControl(false),
  });
  public naCopy = naCopy;

  constructor(
    private alertService: AlertService,
    public authService: AuthService,
    private dialog: MatDialog,
    public newsletterService: NewsletterService,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);
    this.paginatedDataSource = new GenericFEDataSource<Article>(
      this._articles,
      {
        sortingDataAccessor: this.sortingDataAccessor,
      },
    );
  }

  ngAfterViewInit(): void {
    bootstrapTable<Article[]>(
      this.sort,
      this.paginator,
      this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
      this.filters,
    )
      .pipe(takeUntil(this.destroyed))
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next(true);
    this._articles.complete();
  }

  checkNewsLetterStatus() {
    return this.newsletter.status !== NewsletterStatus.draft.toLowerCase();
  }

  public editArticle(article: Article) {
    this.triggerEditArticle.next(article);
    // Scroll to #edit-article
    setTimeout(
      () => document.getElementById('edit-article').scrollIntoView(),
      0,
    );
  }

  public viewArticle(article: Article) {
    this.triggerViewArticle.next(article);
    // Scroll to #view-article
    setTimeout(
      () => document.getElementById('edit-article').scrollIntoView(),
      0,
    );
  }

  public deleteArticle(article: Article) {
    const confirmData: ConfirmationDialogValues = {
      message: 'Do you want to delete this Article? This cannot be undone.',
      onConfirm: async () => {
        article.deleted = true;
        await this.newsletterService.saveArticle(article);
        await this.newsletterService.refreshNewsletters();
        this.alertService.success('Article deleted.');
      },
      onError: (error) => {
        this.alertService.error('Unable to delete Article. Please try again.');
        console.error(error);
      },
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
  }

  public toggleArticleFrontPage(article: Article): void {
    article.frontPage = !article.frontPage;
    this.newsletterService
      .saveArticle(article)
      .then(() => this.newsletterService.refreshNewsletters())
      .then(() => this.alertService.success('Article updated.'));
  }

  sortingDataAccessor = (item, property) => {
    switch (property) {
      case 'characterCount': {
        return item.characterCount ? Number(item.characterCount) : 0;
      }
      case 'headline': {
        const headline = item.headline
          ? item.headline
          : item.headlineNullText
          ? item.headlineNullText
          : '';

        return headline.toLowerCase();
      }
      case 'requiredType': {
        return item.articleType ? item.articleType.required : false;
      }
      case 'imageCount': {
        return item.imageCount ? Number(item.imageCount) : 0;
      }
      default:
        return item.articleType ? item.articleType.id : 0;
    }
  };
}
