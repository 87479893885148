import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';

import { States } from '../../../../../../core/enums/states';
import { Timezones } from '../../../../../../core/enums/timezones';
import { Site } from '@models/site';
import { SiteType } from '@models/siteType';
import { OrgService } from 'src/app/core/services/org.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { urlRegex } from 'src/app/core/constants/regex';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { createMask } from '@ngneat/input-mask';

export interface SiteFormValues {
  name: string;
  description?: string;
  phone: string;
  email: string;
  questionEmail: string;
  warmWishesEmail: string;
  websiteUrl?: string;
  tourUrl?: string;
  covidUrl?: string;
  newsletterUrl?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  timezone: string;
  siteType: SiteType;
  activeForMobile: boolean;
  trainingGuideUrl?: string;
}

@AutoUnsubscribe()
@Component({
  selector: 'app-site-form',
  templateUrl: './site-form.component.html',
  styleUrls: ['./site-form.component.scss'],
})
export class SiteFormComponent implements OnInit, OnDestroy {
  @Input() public form: UntypedFormGroup;
  public stateEnumKeys = [];
  public timezoneEnumKeys = [];
  public telMask = createMask({ mask: '(999) 999-9999' });
  public siteTypes: SiteType[];
  private destroyed$ = new Subject();

  static formModel(formValues = null) {
    return new UntypedFormGroup({
      name: new UntypedFormControl(
        formValues ? formValues.name : null,
        Validators.required,
      ),
      description: new UntypedFormControl(
        formValues ? formValues.description : null,
      ),
      phone: new UntypedFormControl(formValues ? formValues.phone : null, [
        Validators.required,
        Validators.required,
      ]),
      email: new UntypedFormControl(formValues ? formValues.email : null, [
        Validators.email,
        Validators.required,
      ]),
      questionEmail: new UntypedFormControl(
        formValues ? formValues.questionEmail : null,
        [Validators.email],
      ),
      warmWishesEmail: new UntypedFormControl(
        formValues ? formValues.warmWishesEmail : null,
        [Validators.email],
      ),
      websiteUrl: new UntypedFormControl(
        formValues ? formValues.websiteUrl : null,
        Validators.pattern(urlRegex),
      ),
      tourUrl: new UntypedFormControl(
        formValues ? formValues.websiteUrl : null,
        Validators.pattern(urlRegex),
      ),
      covidUrl: new UntypedFormControl(
        formValues ? formValues.websiteUrl : null,
        Validators.pattern(urlRegex),
      ),
      newsletterUrl: new UntypedFormControl(
        formValues ? formValues.newsletterUrl : null,
        Validators.pattern(urlRegex),
      ),
      address1: new UntypedFormControl(
        formValues ? formValues.address1 : null,
        Validators.required,
      ),
      address2: new UntypedFormControl(formValues ? formValues.address2 : null),
      city: new UntypedFormControl(
        formValues ? formValues.city : null,
        Validators.required,
      ),
      state: new UntypedFormControl(
        formValues ? formValues.state : null,
        Validators.required,
      ),
      zip: new UntypedFormControl(
        formValues ? formValues.zip : null,
        Validators.required,
      ),
      timezone: new UntypedFormControl(
        formValues ? formValues.timezone : null,
        Validators.required,
      ),
      siteType: new UntypedFormControl(
        formValues ? formValues.siteType : null,
        Validators.required,
      ),
      activeForMobile: new UntypedFormControl(
        formValues ? formValues.activeForMobile : false,
        Validators.required,
      ),
      trainingGuideUrl: new UntypedFormControl(
        formValues ? formValues.trainingGuideUrl : null,
        Validators.pattern(urlRegex),
      ),
    });
  }

  static serialize(formValues: SiteFormValues, site = {}): Partial<Site> {
    const serializedForm = {
      name: formValues.name,
      description: formValues.description,
      phone: formValues.phone,
      email: formValues.email,
      questionEmail: formValues?.questionEmail,
      warmWishesEmail: formValues?.warmWishesEmail,
      websiteUrl: formValues.websiteUrl,
      tourUrl: formValues.tourUrl,
      covidUrl: formValues.covidUrl,
      newsletterUrl: formValues.newsletterUrl,
      address1: formValues.address1,
      address2: formValues.address2,
      city: formValues.city,
      state: formValues.state,
      zip: formValues.zip,
      timezone: formValues.timezone,
      siteType: formValues.siteType,
      siteTypeId: formValues.siteType, // this is a hack to get around the fact that the API expects siteTypeId, but the form expects siteType
      activeForMobile: formValues.activeForMobile,
      trainingGuideUrl: formValues.trainingGuideUrl,
    };
    return Object.assign(site, serializedForm);
  }

  static deserialize(site: Site): SiteFormValues {
    return {
      name: site.name,
      description: site.description,
      phone: site.phone,
      email: site.email,
      questionEmail: site.questionEmail,
      warmWishesEmail: site.warmWishesEmail,
      websiteUrl: site.websiteUrl,
      tourUrl: site.tourUrl,
      covidUrl: site.covidUrl,
      newsletterUrl: site.newsletterUrl,
      address1: site.address1,
      address2: site.address2,
      city: site.city,
      state: site.state,
      zip: site.zip,
      timezone: site.timezone,
      siteType: site.siteType,
      activeForMobile: site.activeForMobile,
      trainingGuideUrl: site.trainingGuideUrl,
    };
  }

  constructor(public orgService: OrgService) {}

  ngOnInit() {
    this.stateEnumKeys = Object.keys(States);
    this.timezoneEnumKeys = Object.keys(Timezones);
    this.orgService.siteTypes
      .pipe(takeUntil(this.destroyed$))
      .subscribe((types) => (this.siteTypes = types));
    if (this.form.controls.siteType.value) {
      const siteTypeId = this.form.controls.siteType.value.id;
      this.form.controls.siteType.setValue(siteTypeId);
    }

    if (this.form.controls.activeForMobile.value) {
      this.enableMobileProperties();
    } else {
      this.disableMobileProperties();
    }

    this.form.controls.activeForMobile.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.enableMobileProperties();
        } else {
          this.disableMobileProperties();
        }
      });
  }

  enableMobileProperties() {
    this.form.controls.questionEmail.enable();
    this.form.controls.warmWishesEmail.enable();
    this.form.controls.websiteUrl.enable();
    this.form.controls.covidUrl.enable();
    this.form.controls.tourUrl.enable();
    this.form.controls.newsletterUrl.enable();
  }

  disableMobileProperties() {
    this.form.controls.questionEmail.disable();
    this.form.controls.warmWishesEmail.disable();
    this.form.controls.websiteUrl.disable();
    this.form.controls.covidUrl.disable();
    this.form.controls.tourUrl.disable();
    this.form.controls.newsletterUrl.disable();
  }

  ngOnDestroy() {}
}
