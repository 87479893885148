import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageDto } from '@interfaces/image';
import { Newsletter } from '@models/newsletter';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ImageService } from 'src/app/core/services/image.service';
import { NewsletterService } from 'src/app/core/services/newsletter.service';
import {
  ImageUploadComponent,
  UploadResponse,
} from 'src/app/shared/image-upload/image-upload.component';

@Component({
  providers: [ImageUploadComponent],
  selector: 'app-newsletter-attachments',
  templateUrl: './newsletter-attachments.component.html',
  styleUrls: ['./newsletter-attachments.component.scss'],
})
export class NewsletterAttachmentsComponent implements OnInit {
  @Input() newsletter: Newsletter;
  @Output() updated: EventEmitter<boolean> = new EventEmitter();

  public finalDraft: ImageDto;
  public otherAttachments: ImageDto[] = [];

  constructor(
    private alertService: AlertService,
    public authService: AuthService,
    private dialog: MatDialog,
    private imageUploadComponent: ImageUploadComponent,
    public imageService: ImageService,
    private newsletterService: NewsletterService
  ) {}

  ngOnInit(): void {}

  /**
   * Using the same infrastructure in place as image storage (in the `image` table)
   * since uploading a PDF is no different than a .jpg. Made more sense to use the
   * existing approach than creating a new one for attachments.
   *
   * Passing in newsletter instead of referencing this.newsletter because this function
   * is referenced in other components (newsletter-table, to begin with).
   */
  public async addAttachment(newsletter: Newsletter, isFinalDraft: boolean) {
    const imageUploadDialogRef = this.imageUploadComponent.editNewsletterAsset(
      newsletter.site.id,
      newsletter.id,
      'Newsletter Attachment',
      'Attachment',
      ['*'] // accept all file types
    );
    imageUploadDialogRef
      .afterClosed()
      .subscribe(async (result: UploadResponse) => {
        if (result.success) {
          const path = result.path;
          const caption = result.imageCaption;
          const downloadUrl = await this.imageService.getDownloadUrl(path);
          const imageBody = {
            path: `${this.imageService.getNewsletterAssetFilePath(
              newsletter.site.id,
              newsletter.id
            )}/${result.fileName}`,
            caption,
            downloadUrl,
            newsletter: newsletter.id,
            isPrimary: isFinalDraft,
          };
          this.imageService
            .createImageRecord(imageBody, this.newsletter)
            .then((image) => {
              this.updated.next(true);
            })
            .catch((error) =>
              this.alertService.error(
                'Error uploading attachment. Please try again.'
              )
            );
        }
      });
  }

  public attachmentDeleted(): void {
    this.updated.next(true);
    this.alertService.success('Attachment deleted.');
  }
}
