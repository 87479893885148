import { FaqDto } from "../interfaces/faq";

export class Faq implements FaqDto {
  id: number;
  createdOn: Date;
  updatedOn: Date;
  deleted: boolean;
  question: string;
  answer: string;
  siteId?: number;

  constructor(data: any) {
    Object.assign(this, data);
  }

}
