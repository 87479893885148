import { Component, OnInit } from '@angular/core';
import * as semver from 'semver';
import packageJson from '../../package.json';
import { environment } from '../environments/environment';
import { UtilityService } from './core/services/utility.service';
import { Router, NavigationEnd } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { TraceService } from '@sentry/angular-ivy';
const { version } = packageJson;

declare let ga: Function;
export interface IConfig {
  minVersion: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private firestore: Firestore,
    private router: Router,
    private utilityService: UtilityService,
    trace: TraceService,
  ) {}

  ngOnInit() {
    this.checkVersion();
    this.utilityService.setSentryVersion();
    this.initiatlizeGoogleAnalytics();
  }

  private checkVersion(): void {
    const configRef = doc(this.firestore, `config/ionic`);
    docData(configRef)
      .pipe(
        distinctUntilChanged((prev: IConfig | any, next: IConfig | any) => {
          return prev?.minVersion === next?.minVersion;
        }),
        filter((config) => config !== null),
      )
      .subscribe((config: IConfig | any) => {
        const clientVersion = version;
        const requiredVersion = config.minVersion;

        // Check that both versions are properly formatted
        if (!semver.valid(clientVersion) || !semver.valid(requiredVersion)) {
          return;
        }

        // Leave these in for troubleshooting
        console.info('CLIENT VERSION: ', clientVersion);
        console.info('REQUIRED VERSION: ', requiredVersion);
        console.info('FIREBASE CONFIG:', environment.firebaseConfig);

        // Check if client version meets required version
        // If not, refresh page
        if (semver.lt(clientVersion, requiredVersion)) {
          window.location.reload();
        }
      });
  }

  /**
   * Had to implement GA manually since we're using Firebase/Google Analytics
   * for the mobile app and it doesn't look like you can associated multiple GA acounts
   * with a single project. Otherwise it would be more ideal to use AngularFire's Analytics.
   */
  private initiatlizeGoogleAnalytics(): void {
    ga('create', environment.googleAnalyticsId, 'auto');
    ga('send', 'pageview');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }
}
