import { Component, OnInit, Inject } from '@angular/core';
import {
  UserFormComponent,
  UserFormValues,
} from 'src/app/shared/forms/user-form/user-form.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user.service';
import { AuthFormComponent } from 'src/app/shared/forms/auth-form/auth-form.component';

export interface UserAuth extends UserFormValues {
  password: string;
}
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  public userForm = UserFormComponent.formModel();
  public authForm = AuthFormComponent.formModel();
  public submitting = false;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<AddUserComponent>,
    private userService: UserService
  ) {}

  ngOnInit() {}

  public async onSave(): Promise<void> {
    this.submitting = true;
    const user = UserFormComponent.serialize(this.userForm.value);
    const auth = AuthFormComponent.serialize(this.authForm.value);
    const userAuth = Object.assign(user, auth, {
      organizationId: this.authService.currentOrgId,
    });
    this.userService
      .createUser(userAuth)
      .then(() => {
        this.alertService.success('User added.');
        this.userService.refreshUsers();
        this.onCancel();
      })
      .catch((error) => {
        const message =
          error?.error?.code === 'auth/email-already-exists'
            ? 'This email is already associated with an account.'
            : 'Unable to add user. Please try again.';
        this.alertService.error(message);
        console.error(error);
      })
      .finally(() => (this.submitting = false));
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
