import { Component, OnInit } from '@angular/core';
import {
  SiteFormComponent,
  SiteFormValues,
} from 'src/app/shared/forms/site-form/site-form.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { SiteService } from 'src/app/core/services/site.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CreateSitePayload } from 'src/app/core/interfaces/api';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss'],
})
export class AddSiteComponent implements OnInit {
  public siteForm = SiteFormComponent.formModel();
  public submitting = false;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private siteService: SiteService,
    private dialogRef: MatDialogRef<AddSiteComponent>,
  ) {}

  ngOnInit() {}

  public onSave(): Promise<void> {
    if (this.siteForm.invalid) {
      return;
    }
    this.submitting = true;
    const serializedForm = SiteFormComponent.serialize(this.siteForm.value);
    const { siteType, trainingGuideUrl, ...rest } =
      serializedForm as SiteFormValues;
    const payload: CreateSitePayload = {
      ...rest,
      siteTypeId: siteType as unknown as number,
      organizationId: this.authService.currentUser.orgId,
      trainingGuideUrl: trainingGuideUrl || undefined,
    };
    this.siteService
      .createSite({
        ...payload,
      })
      .subscribe({
        next: (site) => {
          if (site) {
            this.siteService.refreshSites();
            this.authService.refreshUser();
            this.alertService.success('Property saved.');
            this.onCancel();
            this.submitting = false;
          }
        },
        error: (error) => {
          this.alertService.error('Unable to save property. Please try again.');
          console.error(error);
          this.submitting = false;
        },
      });
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
