import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';

import { ManageService } from 'src/app/core/services/manage.service';
import { Announcement } from '@models/announcement';
import { AlertService } from 'src/app/core/services/alert.service';
import { SiteService } from 'src/app/core/services/site.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import {
  ConfirmationDialogValues,
  ConfirmationDialogComponent,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import { ManageMenuSlideModalComponent } from '../components/manage-menu-slide-modal/manage-menu-slide-modal.component';
import { ManageEventSlideModalComponent } from '../components/manage-event-slide-modal/manage-event-slide-modal.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
  public siteId: number;
  public timeframe = 'active';
  public cropperOptions = {
    height: 200,
    width: 200,
    noFileInput: true,
  };
  public isTable = true; // GRID VS TABLE
  public timeframes = ['all', 'active', 'past', 'future'];
  public searchFilter = '';
  public totalCount = 0;
  public incrementRefresh = 0;
  constructor(
    private alert: AlertService,
    private dialog: MatDialog,
    public manage: ManageService,
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.siteService.siteId.subscribe((siteId: number) => {
      this.siteId = siteId;
      if (siteId) {
        this.refresh();
      }
    });
  }

  ngOnDestroy() {}

  public toggleTableGrid(): void {
    this.isTable = !this.isTable;
    this.changeDetectorRef.detectChanges();
  }

  public refresh() {
    this.incrementRefresh = this.incrementRefresh + 1;
  }

  public updateTimeframe(timeframe: string) {
    this.timeframe = timeframe;
  }

  public goToAddAnnouncement(): void {
    this.router.navigate([`add`], { relativeTo: this.route });
  }

  public goToEditAnnouncement(announcement: Announcement): void {
    this.router.navigate([`edit/${announcement.id}`], {
      relativeTo: this.route,
    });
  }

  public updateCount(value) {
    this.totalCount = value;
  }

  public deleteAnnouncement(announcement: Announcement): void {
    const confirmData: ConfirmationDialogValues = {
      title: `Delete "${announcement.title}"?`,
      message:
        'The announcement will no longer be displayed or accessible by administrators.',
      yesText: 'Delete',
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.manage
          .deleteAnnouncement(announcement)
          .then((res) => {
            this.refresh();
            this.alert.success(
              `"${announcement.title ? announcement.title : 'Slide'}" deleted.`,
            );
          })
          .catch((err) => {
            console.error(err);
            this.alert.error(
              `Error deleting "${
                announcement.title ? announcement.title : 'slide'
              }". Please try again.`,
            );
          });
      }
    });
  }

  public getAnnouncementDate(announcement: Announcement) {
    return announcement.eventStart
      ? moment(announcement.eventStart).format('MMMM Do')
      : null;
  }

  public search(value) {
    this.searchFilter = value;
  }

  /**
   * Return true if all announcements are system generated.
   * This happens when there's no Active announcements because the system generates a
   * "Have a great day!" announcement.
   */
  public sysGenSlidesOnly(announcements: Announcement[] = []): boolean {
    // Filter out all generated slides, then see if any slides remain
    // If so, there must be at least one non-generated slide
    return (
      announcements.filter((announcement) => announcement.isGenerated).length >
      0
    );
  }

  public manageMenuSlide() {
    const dialogRef = this.dialog.open(ManageMenuSlideModalComponent, {
      data: {
        siteId: this.siteId,
      },
    });
  }

  public manageEventSlide() {
    const dialogRef = this.dialog.open(ManageEventSlideModalComponent, {
      data: {
        siteId: this.siteId,
      },
    });
  }
}
