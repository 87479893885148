import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventSlide, EventSlidePayload } from '../interfaces/api';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventSlideService {
  constructor(private http: HttpClient) {}

  public getEventSlideBySiteId(siteId: number) {
    const url = `${environment.apiv3Url}/event-slide/site/${siteId}`;
    return this.http.get<EventSlide>(url);
  }

  public updateEventSlide(id: number, data: Partial<EventSlide>) {
    const url = `${environment.apiv3Url}/event-slide/${id}`;
    return lastValueFrom(this.http.put<EventSlide>(url, data));
  }

  public createEventSlide(data: EventSlidePayload) {
    const url = `${environment.apiv3Url}/event-slide`;
    return lastValueFrom(this.http.post<EventSlide>(url, data));
  }
}
