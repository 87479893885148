import { Component, OnInit, Inject } from '@angular/core';
import { NotificationFormComponent } from 'src/app/shared/forms/notification-form/notification-form.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/core/services/alert.service';
import { SiteService } from 'src/app/core/services/site.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Notification } from '@models/notification';

@Component({
  selector: 'app-edit-notification',
  templateUrl: './edit-notification.component.html',
  styleUrls: ['./edit-notification.component.scss'],
})
export class EditNotificationComponent implements OnInit {
  public notificationForm = NotificationFormComponent.formModel();
  public submitting = false;

  constructor(
    private alertService: AlertService,
    private notificationService: NotificationService,
    private siteService: SiteService,
    private dialogRef: MatDialogRef<EditNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public notification: Notification
  ) {}

  ngOnInit() {
    this.notificationForm.setValue(
      NotificationFormComponent.deserialize(this.notification)
    );
  }

  public onSave(): void {
    this.submitting = true;
    const notification = NotificationFormComponent.serialize(
      this.notificationForm.value,
      { site: this.siteService.currentSiteId }
    );
    notification.id = this.notification.id;
    this.notificationService
      .updateNotification(notification)
      .then(() => {
        this.notificationService.refreshNotifications();
        this.alertService.success('Notification updated.');
        this.close();
      })
      .catch((error) => {
        this.alertService.error(
          'Unable to update notification. Please try again.'
        );
        console.error(error);
      })
      .finally(() => (this.submitting = false));
  }

  private close(): void {
    this.dialogRef.close();
  }
}
