import { Component, OnInit, Input } from '@angular/core';
import { Announcement } from '@models/announcement';
import moment from 'moment-timezone';
import { DisplayService } from 'src/app/core/services/display.service';
import { SiteService } from 'src/app/core/services/site.service';

@Component({
  selector: 'app-display-event',
  templateUrl: './display-event.component.html',
  styleUrls: ['./display-event.component.scss'],
})
export class DisplayEventComponent implements OnInit {
  @Input() announcement: Announcement;
  @Input() showClock = false;

  constructor(
    public displayService: DisplayService,
    private siteService: SiteService
  ) {}

  ngOnInit() {}

  get date() {
    if (!this.isValid('eventStart') || !this.siteService.currentSite) {
      return null;
    }
    return moment
      .tz(this.announcement.eventStart, this.siteService.currentSite.timezone)
      .format('dddd, MMMM Do');
  }

  private isValid(prop: string) {
    return (
      this.announcement &&
      this.announcement[prop] &&
      moment(this.announcement[prop]).isValid()
    );
  }
}
