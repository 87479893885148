<section class="notes-wrapper">
  <form [formGroup]="calendarForm">
    <mat-form-field
      class="small-input notes-input"
      appearance="outline"
      color="accent"
    >
      <mat-label color="accent"
        >Print Calendar Notes
        <span
          >({{
            calendarForm?.controls?.printCalendarMessage?.value?.length || 0
          }}/500)
        </span></mat-label
      >
      <textarea
        matInput
        type="text"
        rows="2"
        maxlength="500"
        formControlName="printCalendarMessage"
      ></textarea>
    </mat-form-field>
    <span class="button-row-wrapper">
      <app-progress-button
        color="accent"
        text="Save Note"
        (click)="save()"
        [disabled]="calendarForm.invalid"
        [submitting]="saving"
      >
      </app-progress-button>
      <span class="button-wrapper">
        <app-progress-button
          color="accent"
          text="Save & Notify Editor"
          (click)="saveAndNotify()"
          [disabled]="calendarForm.invalid"
          [submitting]="notifying"
        >
        </app-progress-button>
        <button
          mat-icon-button
          matTooltip="Clicking this button will let the Editor know your calendar is ready to submit. Any print calendar notes will also be sent. You will be notified when your calendar proofs are ready for review."
        >
          <mat-icon class="help-icon" matListItemIcon>help</mat-icon>
        </button>
      </span>
    </span>
  </form>
</section>
