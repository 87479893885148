<div class="flex flex-col justify-center items-center pt-4 px-4 gap-4">
  <h1 class="mat-dialog-title text-2xl">Create Newsletter</h1>
  <p><i>Don't worry, you can make updates to these fields later.</i></p>
  <br />
</div>

<mat-dialog-content>
  <app-newsletter-form [form]="newsletterForm"></app-newsletter-form>
  <mat-form-field
    appearance="outline"
    color="accent"
    class="w-full"
    *ngIf="batchCreate"
  >
    <mat-label>Properties</mat-label>
    <mat-select
      [(ngModel)]="sites"
      multiple
      (selectionChange)="propertySelectChange($event.value)"
    >
      <mat-option [value]="selectAllKey"> Select All </mat-option>
      <mat-option
        *ngFor="let site of siteService.sites | async"
        [value]="site.id"
      >
        {{ site.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<p *ngIf="batchCreate">
  <i>Note: batch newsletter creation can take a short while to process.</i>
</p>
<mat-dialog-actions class="flex !justify-center items-end content-end pb-4">
  <button mat-button mat-dialog-close>Cancel</button>
  <app-progress-button
    color="accent"
    text="Save"
    (submit)="onSave()"
    [disabled]="!newsletterForm.valid || (batchCreate && !sites?.length)"
    [submitting]="submitting"
  >
  </app-progress-button>
</mat-dialog-actions>
