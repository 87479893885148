// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: IEnvironment = {
  environmentName: 'testing',
  production: false,
  cms: true,
  deployedCmsUrl: 'https://testing.cms.ednalabs.com',
  deployedTvUrl: 'https://testing.tv.ednalabs.com',
  firebaseConfig: {
    apiKey: 'AIzaSyAYMvYv7jf4tJwWyXdSaSDvG-1gLfWSHVo',
    authDomain: 'goodman-dev-testing-258820.firebaseapp.com',
    databaseURL: 'https://goodman-dev-testing-258820.firebaseio.com',
    projectId: 'goodman-dev-testing-258820',
    storageBucket: 'goodman-dev-testing-258820.appspot.com',
    messagingSenderId: '224257439272',
  },
  apiUrl: 'https://api-service-x5ozib4raq-uc.a.run.app',
  apiv3Url: 'https://apiv3-service-x5ozib4raq-uc.a.run.app',
  storageUrl:
    'https://firebasestorage.googleapis.com/v0/b/goodman-dev-testing-258820.appspot.com',
  googleAnalyticsId: 'UA-130115714-2',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.import { IEnvironment } from './environment.interface';
import { IEnvironment } from './environment.interface';
