import { Component, OnInit } from '@angular/core';
import { NotificationFormComponent } from 'src/app/shared/forms/notification-form/notification-form.component';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/core/services/alert.service';
import { SiteService } from 'src/app/core/services/site.service';
import { NotificationService } from 'src/app/core/services/notification.service';
@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.scss'],
})
export class AddNotificationComponent implements OnInit {
  public notificationForm = NotificationFormComponent.formModel();
  public submitting = false;

  constructor(
    private alertService: AlertService,
    private notificationService: NotificationService,
    private siteService: SiteService,
    private dialogRef: MatDialogRef<AddNotificationComponent>,
  ) {}

  ngOnInit() {}

  public onSave(): void {
    this.submitting = true;
    const notification = NotificationFormComponent.serialize(
      this.notificationForm.value,
      { siteId: this.siteService.currentSiteId },
    );
    this.notificationService
      .addNotification(notification)
      .then(() => {
        this.notificationService.refreshNotifications();
        this.alertService.success('Notification sent.');
        this.close();
      })
      .catch((error) => {
        this.alertService.error(
          'Unable to send notification. Please try again.',
        );
        console.error(error);
      })
      .finally(() => (this.submitting = false));
  }

  private close(): void {
    this.dialogRef.close();
  }
}
