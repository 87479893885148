<div class="flex justify-between items-center content-center">
  <h1 mat-dialog-title class="text-2xl">Edit Image</h1>
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <app-image-form
    [form]="imageForm"
    [imagePreview]="this.image.downloadUrl"
  ></app-image-form>
  <app-progress-button
    color="accent"
    text="Save"
    (submit)="onSave()"
    [submitting]="submitting"
    [disabled]="!this.imageForm.valid"
  >
  </app-progress-button>
</mat-dialog-content>
