import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

export interface EmailFormValues {
  email: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
})
export class EmailFormComponent implements OnInit {
  public initialEmail = '';
  constructor() {}
  @Input() public form: UntypedFormGroup;
  @Input() public emailLabel = 'Email';

  static formModel(formValues = null) {
    return new UntypedFormGroup({
      email: new UntypedFormControl(
        formValues ? formValues.email : null,
        Validators.compose([Validators.required, Validators.email])
      ),
    });
  }

  ngOnInit() {
    this.initialEmail = this.form.controls['email'].value;
  }
}
