import { Injectable } from '@angular/core';

import { EditArticleComponent } from 'src/app/manage/newsletters/edit-article/edit-article.component';

@Injectable()
export class EditArticleCanDeactivateGuard
  
{
  canDeactivate(component) {
    return component.canDeactivate();
  }
}
