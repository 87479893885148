import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';

import { Participant } from '@models/participant';
import { UserService } from 'src/app/core/services/user.service';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import {
  GenericBEDataSource,
  PaginationInformation,
} from '../pagination-server-side/datasources/genericBE.datasource';
import { ParticipantService } from 'src/app/core/services/participant.service';
import bootstrapTable from '../pagination-server-side/bootstrap-table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { SiteService } from 'src/app/core/services/site.service';
import { download, generateCsv, mkConfig } from 'export-to-csv';

interface MobileUserRow {
  firstName: string;
  lastName: string;
  property?: string;
  role: string;
  email: string;
  phone: string;
  birthday: string;
  lastLogin: any;
  totalLogins: number;
}

@Component({
  selector: 'app-mobile-use-table',
  templateUrl: './mobile-use-table.component.html',
  styleUrls: ['./mobile-use-table.component.scss'],
})
export class MobileUseTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() set search(filterValue: string) {
    if (this.dataSource) {
      this.searchFilter$.next(filterValue);
    }
  }
  @Input() set refresh(refresh: number) {
    if (refresh !== this.manualRefreshTrigger$.value) {
      this.manualRefreshTrigger$.next(refresh);
    }
  }

  // used to select which service observable to subscribe to
  @Input() userStyle: 'site' | 'org';

  @Input() set showProperty(show: boolean) {
    if (show) {
      this.displayedColumns.splice(2, 0, 'property'); // Display after name
      this.displayedColumns.push('actions');
    }
  }

  @Output() public edit = new EventEmitter();

  private manualRefreshTrigger$ = new BehaviorSubject(0);

  private searchFilter$ = new BehaviorSubject('');
  public destroy$ = new Subject();

  public dataSource: MatTableDataSource<MobileUserRow>;
  public paginatedDataSource: GenericBEDataSource<Participant>;

  public displayedColumns = [
    'firstName',
    'lastName',
    // 'property' dynamically added here if @Input() showProperty is set (for org table)
    'role',
    'email',
    'phone',
    'birthday',
    'lastLogin',
    'totalLogins',
    // 'actions', dynamically added here if @Input() showProperty is set (for org table)
  ];
  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(this.searchFilter$.value),
    scope: new UntypedFormControl('all'),
    refreshTrigger: new UntypedFormControl(0),
  });

  constructor(
    private datePipe: DatePipe,
    public userService: UserService,
    private participantService: ParticipantService,
    private siteService: SiteService,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);
    this.paginatedDataSource = new GenericBEDataSource<Participant>(
      this.getMobileUsePaginated.bind(this),
    );
  }

  private getMobileUsePaginated(
    queryParams: HttpParams,
    pagination: PaginationInformation,
  ) {
    return this.participantService.getParticipantsPaginated(
      queryParams,
      pagination,
    );
  }

  ngAfterViewInit(): void {
    this.siteService.site
      .pipe(
        filter((site) => site !== null),
        take(1),
        tap((site) => {
          this.filters.controls['scope'].setValue(this.userStyle);
          bootstrapTable<Participant[]>(
            this.sort,
            this.paginator,
            this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
            this.filters,
          )
            .pipe(takeUntil(this.destroy$))
            .subscribe();

          combineLatest([this.searchFilter$, this.manualRefreshTrigger$])
            .pipe(
              // check to see if search has a value and if refresh is truthy
              filter((val) => val[0].trim().length > 0 || !!val[1]),
              takeUntil(this.destroy$),
            )
            .subscribe(([search, refresh]) => {
              this.filters.controls['search'].setValue(search);
              this.filters.controls['refreshTrigger'].setValue(refresh);
            });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.searchFilter$.complete();
    this.destroy$.complete();
  }

  public editParticipant(participant: Participant) {
    this.edit.emit({
      participant,
      updateTrigger: this.manualRefreshTrigger$.value,
    });
  }
  public async exportCsv() {
    const nowString = moment().format('MM-DD-YYYY');
    const options = mkConfig({
      filename: `GiGi User Export ${nowString}`,
      useBom: true,
      columnHeaders: [
        { key: 'firstName', displayLabel: 'First Name' },
        { key: 'lastName', displayLabel: 'Last Name' },
        { key: 'property', displayLabel: 'Property' },
        { key: 'role', displayLabel: 'Role' },
        { key: 'email', displayLabel: 'Email' },
        { key: 'phone', displayLabel: 'Phone' },
        { key: 'birthday', displayLabel: 'Birthday' },
        { key: 'lastLogin', displayLabel: 'Last Login' },
        { key: 'totalLogins', displayLabel: 'Total Logins' },
      ],
    });

    const dataList = [];

    // get the full data list (unpaginated, search applied)
    let queryParams = new HttpParams({
      fromObject: {
        search: this.filters.controls['search'].value,
      },
    });
    const filterInformation = {
      filters: {
        search: this.filters.controls['search'].value,
        scope: this.filters.controls['scope'].value,
      },
    };
    this.participantService
      .getAllMobileParticipants(queryParams, filterInformation)
      .pipe(take(1))
      .subscribe((participants: Participant[]) => {
        // @ts-ignore
        for (const data of participants) {
          const participant = new Participant(data);
          const formattedData = {
            firstName: participant.user.firstName,
            lastName: participant.user.lastName,
            property: participant.site?.name,
            role: participant.user.role?.name,
            email: participant.user.email,
            phone: participant.phone,
            birthday: participant.birthdate,
            lastLogin: participant.lastLogin
              ? //@ts-ignore
                this.datePipe.transform(participant.lastLogin, 'short')
              : '',
            totalLogins: participant.totalLogins ? participant.totalLogins : 0,
          };
          dataList.push(formattedData);
        }

        const csv = generateCsv(options)(dataList);
        download(options)(csv);
      });
  }
}
