import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import moment from 'moment-timezone';
import {
  WeatherService,
  ThreeDayForecast,
} from '../../core/services/weather.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SiteService } from 'src/app/core/services/site.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
})
export class ClockComponent implements OnInit, OnDestroy {
  @Input() propertyId: number;

  public date: string;
  public time: string;
  public overmorrowDay: string; // 2 days from now, e.g. "Tuesday"
  public weather: ThreeDayForecast;
  public interval;

  constructor(
    private siteService: SiteService,
    public weatherService: WeatherService
  ) {}

  ngOnInit() {
    this.siteService.site.subscribe((site) => {
      if (!site) {
        return;
      }

      // Update clock time 1x/minute
      this.getDateTime(site.timezone);
      this.interval = setInterval(() => {
        this.getDateTime(site.timezone);
      }, 60000);
    });

    this.weatherService.threeDayForecast.subscribe(
      (threeDayForecast: ThreeDayForecast) => {
        this.weather = threeDayForecast;
      }
    );
  }

  ngOnDestroy() {
    // Clear interval on destroy so we don't rack up interval calls over time
    clearInterval(this.interval);
  }

  private getDateTime(timezone): void {
    const m = moment().tz(timezone);
    this.date = m.format('dddd, MMM D, YYYY');
    this.time = m.format('h:mm a').replace('am', 'a.m.').replace('pm', 'p.m.');
    this.overmorrowDay = m.add(2, 'day').format('dddd');
  }
}
