import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Announcement } from '@models/announcement';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-display-title-slide',
  templateUrl: './display-title-slide.component.html',
  styleUrls: ['./display-title-slide.component.scss'],
})
export class DisplayTitleSlideComponent implements OnInit {
  @Input() announcement: Announcement;
  @Input() showClock = false;
  constructor() {}

  ngOnInit() {}
}
