import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SiteMenuDto } from '@interfaces/siteMenu';
import { MenuService } from 'src/app/core/services/menu.service';
import { DateTime } from 'luxon';
import { MatDatepicker } from '@angular/material/datepicker';
import { UntypedFormControl } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-menu-preview',
  templateUrl: './menu-preview.component.html',
  styleUrls: ['./menu-preview.component.scss'],
})
export class MenuPreviewComponent implements OnInit {
  @ViewChild('picker') datePicker: MatDatepicker<Date>;
  public selectedDate = DateTime.local();
  public displayDate = this.selectedDate.toISO();
  public siteMenu: SiteMenuDto;
  public loading = true;

  public dateInput = new UntypedFormControl(this.selectedDate.toJSDate(), null);

  constructor(
    public menuService: MenuService,
    @Inject(MAT_DIALOG_DATA) public data: { siteId: number },
  ) {}

  ngOnInit() {
    this.getMenu();
    this.dateInput.valueChanges.subscribe((value) => {
      this.selectedDate = DateTime.fromJSDate(value);
      this.displayDate = this.selectedDate.toISO();
      this.getMenu();
    });
  }

  public dayChange(days: number) {
    this.loading = true;
    this.selectedDate = this.selectedDate.plus({ days });
    this.displayDate = this.selectedDate.toISO();
    this.dateInput.setValue(this.selectedDate.toJSDate(), { emitEvent: false });
    this.getMenu();
  }

  public openDatePicker() {
    this.datePicker.open();
  }

  async getMenu(): Promise<void> {
    const date = this.selectedDate.toJSDate();
    this.siteMenu = await firstValueFrom(
      this.menuService.getSiteMenu(this.data.siteId, date),
    );
    this.loading = false;
  }
}
