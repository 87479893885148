import { DataSource } from '@angular/cdk/collections';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { delay, map, switchMap, takeUntil } from 'rxjs/operators';

/**
 * A reusable datasource for combining a subscription to a FE service with the mat table sorting/filtering/paginating behavior.
 */

export interface PaginatedResponse<T> {
  totalCount: number;
  data: T[];
}

interface AlwaysFilter {
  [key: string]: {
    convertType?: 'string' | 'numeric';
    values: Array<any>;
  };
}

export interface PaginationInformation {
  filters: {
    search?: string;
    searchBy?: { key: string; value: string }[];
    [key: string]: string | boolean | { key: string; value: string }[];
  };
  sort?: Sort;
  pagination?: PageEvent;
  time: any;
}

export type ServiceMethod<T> = (
  queryParams: HttpParams,
  PaginationInformation: PaginationInformation,
  additionalParams?: {
    sortingDataAccessor: Function;
    filterBySearch?: Function;
    alwaysFilter?: AlwaysFilter;
  },
) => Observable<PaginatedResponse<T>>;

export class GenericFEDataSource<T> implements DataSource<T> {
  // This dataSubject is used by the table to get the data
  private dataSubject = new BehaviorSubject<T[]>([]);

  // Loading subject to trigger loading states
  private loadingSubject = new BehaviorSubject<boolean>(true);

  // Observable to watch for data
  public dataObservable = new BehaviorSubject<T[]>([]);

  // Subject for changes to pagination
  private latestPaginationInformation: BehaviorSubject<any> =
    new BehaviorSubject(null);

  // Additional params from our component that our service method needs to know about
  private additionalParams: {
    filterBySearch?: Function;
    sortingDataAccessor?: Function;
    alwaysFilter?: AlwaysFilter;
  };

  private disconnected$ = new Subject();

  // Loading observable for use with async pipe in template
  public loading$ = this.loadingSubject.asObservable().pipe(
    switchMap((isLoading) => {
      return isLoading ? of(isLoading) : of(isLoading).pipe(delay(250));
    }),
    takeUntil(this.disconnected$),
  );

  // Total count is updated when we get a response from the API
  public totalCount = 0;
  public $totalCount = new BehaviorSubject(0);

  // Error Response
  public error: HttpErrorResponse;

  constructor(
    dataObservable,
    additionalParams?: {
      sortingDataAccessor: Function;
      filterBySearch?: Function;
      alwaysFilter?: AlwaysFilter;
    },
  ) {
    this.dataObservable = dataObservable;
    this.additionalParams = additionalParams;

    this.subscribeToDataObservable();
  }

  subscribeToDataObservable() {
    combineLatest([this.dataObservable, this.latestPaginationInformation])
      .pipe(
        map((response: Array<T[] | PaginationInformation>) => {
          const data = response[0] as Array<T>;
          const paginationInformation = response[1] as PaginationInformation;

          // Filter by search query
          if (
            !paginationInformation?.filters?.search &&
            !this.additionalParams?.alwaysFilter
          ) {
            return response;
          }

          return [
            data.filter((dataItem) => {
              // Check for specific values that should always be excluded
              if (this.additionalParams?.alwaysFilter) {
                const properties = Object.keys(
                  this.additionalParams.alwaysFilter,
                );

                // map properties to matching indices
                const alwaysFilter = properties.map((target) => {
                  const filterValues =
                    this.additionalParams.alwaysFilter[target].values;
                  let found;
                  if (
                    this.additionalParams.alwaysFilter[target].convertType ===
                    'numeric'
                  ) {
                    found = filterValues.indexOf(Number(dataItem[target]));
                  } else if (
                    this.additionalParams.alwaysFilter[target].convertType ===
                    'string'
                  ) {
                    found = filterValues.indexOf(dataItem[target].toString());
                  } else {
                    found = filterValues.indexOf(dataItem[target]);
                  }
                  return found;
                });

                // if an item matches on any property, filter
                if (alwaysFilter.some((value) => value > -1)) {
                  return false;
                }
              }

              // if passes always filter check and has no search value, don't filter
              if (!paginationInformation?.filters?.search) {
                return true;
              }

              return this.additionalParams?.filterBySearch(
                dataItem,
                paginationInformation?.filters?.search,
              );
            }),
            paginationInformation,
          ];
        }),
        map((response: Array<T[] | PaginationInformation>) => {
          const data = response[0] as Array<T>;
          const paginationInformation = response[1] as PaginationInformation;
          const sortDirection =
            paginationInformation?.sort?.direction.toUpperCase();
          const sortBy = paginationInformation?.sort?.active;
          // Sort
          if (
            (data.length > 0 && sortDirection === 'ASC') ||
            sortDirection === 'DESC'
          ) {
            // sort by selected value in order asc or desc
            const sorted = data.sort((prev, next) => {
              const nextSort = this.additionalParams.sortingDataAccessor(
                next,
                sortBy,
              );
              const prevSort = this.additionalParams.sortingDataAccessor(
                prev,
                sortBy,
              );
              if (sortDirection === 'ASC') {
                return nextSort < prevSort ? 1 : -1;
              }
              if (sortDirection === 'DESC') {
                return nextSort > prevSort ? 1 : -1;
              }
              return 0;
            });

            this.totalCount = sorted.length;
            this.$totalCount.next(sorted.length);
            return [sorted, paginationInformation];
          } else if (data.length === 0) {
            this.totalCount = 0;
            this.$totalCount.next(0);
          }
          return [data, paginationInformation];
        }),
        map((response: Array<T[] | PaginationInformation>) => {
          const data = response[0] as Array<T>;
          const paginationInformation = response[1] as PaginationInformation;
          const pageSize: number = Number(
            paginationInformation?.pagination?.pageSize,
          );
          // Paginate
          if (data.length === 0 || !paginationInformation) {
            return [data, paginationInformation];
          }

          const sorted = data.reduce((acc, val: T, i) => {
            const pageNumber = Math.floor(i / pageSize);
            const page = acc[pageNumber] || (acc[pageNumber] = []);
            page.push(val);

            return acc;
          }, []);

          return [sorted, paginationInformation];
        }),
        map((response: Array<T[] | PaginationInformation>) => {
          const data = response[0] as Array<T>;
          const paginationInformation = response[1] as PaginationInformation;
          const pageIndex =
            paginationInformation?.pagination?.pageIndex.toString();
          if (data.length === 0 || !paginationInformation) {
            return [data, paginationInformation];
          }
          return [data[pageIndex], paginationInformation];
        }),
        takeUntil(this.disconnected$),
      )
      .subscribe((response: Array<T[] | PaginationInformation>) => {
        // don't load until pagination information is available
        if (response[1]) {
          const data = response[0] as Array<T>;
          this.dataSubject.next(data);
          this.loadingSubject.next(false);
        }
      });
  }

  loadData(paginationInformation: PaginationInformation) {
    this.loadingSubject.next(true);
    this.latestPaginationInformation.next(paginationInformation);
  }

  refreshData() {
    this.loadData(this.latestPaginationInformation.value);
  }

  // Used by the material table to connect to the datasource
  connect(): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  // Used by the material table to perform cleanup when the table is destroyed
  disconnect(): void {
    this.dataSubject.complete();
    this.latestPaginationInformation.complete();
    this.disconnected$.next(true);
    this.disconnected$.complete();
  }
}
