<section
  class="display-cont is-success event is-bold image-centric"
  [ngStyle]="
    announcement?.background &&
    !announcement?.background.deleted && {
      background: 'url(' + announcement.background.downloadUrl + ')'
    }
  "
>
  <ng-container *ngIf="announcement?.title || announcement?.content">
    <div class="dark-area">
      <div class="slide-text">
        <h1>
          <markdown [data]="announcement?.title"></markdown>
        </h1>
        <h2>
          <markdown [data]="announcement?.content"></markdown>
        </h2>
      </div>
    </div>
  </ng-container>
</section>
