import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { NewsletterFormComponent } from 'src/app/shared/forms/newsletter-form/newsletter-form.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SiteService } from 'src/app/core/services/site.service';
import { NewsletterService } from 'src/app/core/services/newsletter.service';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-add-newsletter',
  templateUrl: './add-newsletter.component.html',
  styleUrls: ['./add-newsletter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewsletterComponent implements OnInit {
  public selectAllKey = 'all'; // for multi property selector "Select All"
  public newsletterForm = NewsletterFormComponent.formModel();
  public submitting = false;
  public sites: number[] = []; // site id array

  constructor(
    private alertService: AlertService,
    private dialogRef: MatDialogRef<NewsletterFormComponent>,
    private newsletterService: NewsletterService,
    public siteService: SiteService,
    @Inject(MAT_DIALOG_DATA) public batchCreate: boolean = false
  ) {}

  ngOnInit() {}

  public async propertySelectChange(updatedVal: any[]) {
    const selectAllSelected =
      updatedVal.findIndex((val) => val === this.selectAllKey) !== -1;
    if (selectAllSelected) {
      this.sites = await this.siteService.siteIds;
    }
  }

  public async onSave(): Promise<void> {
    this.submitting = true;
    this.batchCreate ? this.saveBatch() : this.saveSingle();
  }

  private async saveSingle() {
    const newsletter = NewsletterFormComponent.serialize(
      this.newsletterForm.value,
      { siteId: this.siteService.currentSiteId }
    );
    this.newsletterService
      .saveNewsletter(newsletter)
      .then(async (savedNewsletter) => {
        await this.newsletterService.refreshNewsletters();
        this.dialogRef.close({
          success: true,
          newsletterId: savedNewsletter.id,
        });
      })
      .catch((error) => {
        this.alertService.error('Unable to save Newsletter. Please try again.');
        console.error(error);
      })
      .finally(() => (this.submitting = false));
  }

  private async saveBatch() {
    if (!this.sites?.length) {
      return;
    }

    try {
      for (const index in this.sites) {
        const newsletter = NewsletterFormComponent.serialize(
          this.newsletterForm.value,
          { siteId: this.sites[index] }
        );
        await this.newsletterService.saveNewsletter(newsletter);
      }

      await this.newsletterService.refreshNewsletters();
      this.submitting = false;
      this.dialogRef.close({ success: true });
    } catch (error) {
      this.submitting = false;
      this.alertService.error(
        'Error creating batch of Newsletters. Please try again.'
      );
      console.error(error);
    }
  }
}
