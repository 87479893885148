import { SiteDto } from '../interfaces/site';
import { OrganizationDto } from '../interfaces/organization';
import { SiteType } from './siteType';

export class Site implements SiteDto {
  public id: number;
  public createdOn: Date;
  public updatedOn: Date;
  public deleted: false;
  public name: string;
  public description: string;
  public phone: string;
  public email: string;
  public questionEmail?: string; // optional email address used for mobile 'ask a question' feature
  public warmWishesEmail?: string; // optional email address used for mobile 'warm wishes' feature
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public zip: string;
  public timezone: string;
  public organization: OrganizationDto;
  public photo: string;
  public activeForMobile: boolean;
  public siteType: SiteType;
  public websiteUrl?: string; // primary public facing website
  public tourUrl?: string; // virtual tour public facing website
  public covidUrl?: string; // covid-19 website
  public newsletterUrl?: string; // where site's current newsletter is hosted
  public printCalendarMessage?: string; // temporary setting until calendar workflow is built out
  public startDate?: Date; // Start date of the current meal plan
  public daysRemaining?: number; // How many days until there is a date with no menu
  public showMenu?: boolean;
  public showEventSlide?: boolean;
  public trainingGuideUrl?: string;

  constructor(data: any) {
    Object.assign(this, data);
  }

  get activitiesEnabled(): boolean {
    return this.siteType.description.includes('Senior Living');
  }

  get notificationsEnabled(): boolean {
    return this.activeForMobile;
  }

  get backgroundImageUploadEnabled(): boolean {
    return (
      this.siteType.description.includes('Corporate') ||
      this.siteType.description.includes('Senior Living/Residential')
    );
  }

  get announcementCategoriesEnabled(): boolean {
    return this.siteType.description.includes('Senior Living');
  }

  public getSiteLogoUrl(environmentUrl: string, siteId: number): string {
    return `${environmentUrl}/o/site-logos%2F${siteId}.png?alt=media`;
  }

  public getSitePrintLogoUrl(environmentUrl: string, siteId: number): string {
    return `${environmentUrl}/o/site-logos%2F${siteId}_print.png?alt=media`;
  }

  public getSiteImageUrl(environmentUrl: string, siteId: number): string {
    return `${environmentUrl}/o/site-images%2F${siteId}.png?alt=media`;
  }
}
