<mat-card appearance="outlined" color="primary">
  <mat-card-header class="flex justify-between items-center content-center">
    <mat-card-title color="primary" class="flex">
      Rooms ({{ paginatedDataSource?.totalCount || 0 }})
    </mat-card-title>
    <div class="flex w-3/4 justify-end items-center content-center gap-[15px]">
      <button mat-raised-button color="accent" (click)="addRoom()">
        Add Room
      </button>
      <mat-form-field color="accent">
        <input
          #search="ngModel"
          matInput
          placeholder="Search"
          [(ngModel)]="filter"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </mat-card-header>
  <mat-card-content class="flex flex-col">
    <div class="loading-wrapper flex flex-col">
      <div class="loading-spinner" *ngIf="paginatedDataSource.loading$ | async">
        <app-material-loader [fillContainer]="true"></app-material-loader>
      </div>
      <div
        class="loading-overlay"
        *ngIf="paginatedDataSource.loading$ | async"
      ></div>
      <table
        mat-table
        matSort
        matSortActive="name"
        matSortDirection="asc"
        [dataSource]="paginatedDataSource"
      >
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9999">
            <i>{{
              search?.value?.length
                ? 'No matches for this search.'
                : (paginatedDataSource.loading$ | async)
                ? ''
                : 'No rooms have been created.'
            }}</i>
          </td>
        </tr>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            ID
          </th>
          <td mat-cell *matCellDef="let room">{{ room.id }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Name
          </th>
          <td mat-cell *matCellDef="let room">{{ room.name }}</td>
        </ng-container>

        <ng-container matColumnDef="shortName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Short Name
          </th>
          <td mat-cell *matCellDef="let room">{{ room.shortName }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let room">
            <div>
              <button
                mat-icon-button
                color="accent"
                (click)="editRoom(room)"
                matTooltip="Edit Room"
              >
                <mat-icon>edit_pencil</mat-icon>
              </button>
              <button
                mat-icon-button
                color="accent"
                (click)="deleteRoom(room)"
                matTooltip="Delete Room"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [length]="paginatedDataSource?.totalCount"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
