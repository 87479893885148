import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  deleteObject,
  getDownloadURL,
  ref,
  Storage,
  uploadBytes,
} from '@angular/fire/storage';
import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, EMPTY, of } from 'rxjs';
import { map, distinctUntilChanged, tap } from 'rxjs/operators';
import FileType from 'file-type/browser';
import imageCompression from 'browser-image-compression';

import { environment } from '../../../environments/environment';
import { Image } from '@models/image';
import { ImageDto } from '@interfaces/image';
import { AuthService } from './auth.service';
import { SiteService } from './site.service';
import { AllowedImageFileTypes } from '../../../../../core/enums/allowed-image-file-types';
import { PaginationInformation } from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';

export interface ImageUpdate {
  site: number;
  organization: number;
  category: number;
  subcategory: number;
}

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private _backgrounds = new BehaviorSubject<Image[]>([]);
  public readonly backgrounds = this._backgrounds.asObservable();

  private _orgBackgrounds = new BehaviorSubject<Image[]>([]);
  public readonly orgBackgrounds = this._orgBackgrounds.asObservable(); // full admin list of background images for org

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private authService: AuthService,
    private siteService: SiteService,
  ) {
    this._imageSubscriptions();
  }

  private _imageSubscriptions(): void {
    this._listenForSiteChange();
  }

  get currentBackgrounds(): Image[] {
    return this._backgrounds.getValue();
  }

  // repeated auth check
  private _checkIfOrgAdmin(): Boolean {
    if (
      this.authService.currentUser &&
      this.authService.currentUser.isOrgAdmin &&
      this.authService.currentOrgId
    ) {
      return true;
    } else {
      return false;
    }
  }

  private _listenForSiteChange(): void {
    this.siteService.siteId
      .pipe(distinctUntilChanged())
      .subscribe((siteId: number) => {
        if (siteId) {
          this._refreshBackgrounds(siteId);
        }
      });
  }

  private _refreshBackgrounds(
    siteId?: number,
    hardOrgRefresh?: boolean,
  ): Promise<void> {
    if (this._checkIfOrgAdmin()) {
      const images = this._orgBackgrounds.getValue();

      // if org admin but no images found yet, refresh to get starting values
      if (images.length === 0 || hardOrgRefresh) {
        // use org endpoint for list
        return this._refreshOrgBackgrounds(this.authService.currentOrgId);
      } else {
        // already have all org values, filter for site-specific updates
        return this._imageUpdate(this._orgBackgrounds.getValue(), siteId);
      }
    } else if (siteId) {
      // use site endpoint for list
      return this._refreshSiteBackgrounds(siteId);
    }
  }

  private _refreshSiteBackgrounds(siteId: number): Promise<void> {
    if (siteId && this.authService.currentUser) {
      return this._getSiteBackgrounds(siteId)
        .then((backgrounds: Image[]) => {
          this._imageUpdate(backgrounds, siteId);
        })
        .catch((error) => console.error(error));
    }
  }

  private _imageUpdate(images: Image[], siteId?: number): Promise<void> {
    if (images.length === 0) {
      this._backgrounds.next([]);
      return;
    }

    const siteImages = images.filter((image) => {
      if (image.site) {
        return (
          image.site.id === (siteId ? siteId : this.siteService.currentSiteId)
        );
      }
      // if site is null, image is available accross all sites in this organization
      return true;
    });

    // if org admin, we expect the results from the endpoint to be org-wide
    if (this._checkIfOrgAdmin()) {
      this._orgBackgrounds.next(images);
    }

    // regardless of auth status, everyone should get site-relevant images
    this._backgrounds.next(siteImages);

    return Promise.resolve();
  }

  private _refreshOrgBackgrounds(orgId: number): Promise<void> {
    if (
      orgId &&
      this._checkIfOrgAdmin() &&
      this.authService.currentOrgId === orgId
    ) {
      return this._getOrgBackgrounds(orgId)
        .then((backgrounds: Image[]) => {
          this._imageUpdate(backgrounds);
        })
        .catch((error) => console.error(error));
    }
  }

  public refreshAllBackgrounds(): Promise<any> {
    return this._refreshBackgrounds(this.siteService.currentSiteId, true);
  }

  public getBackgroundForSubcategory(id: number) {
    const subcatBackground = this.currentBackgrounds.find((bg) => {
      return bg.subcategory?.id === id;
    });
    return subcatBackground ? subcatBackground : null;
  }

  public getSiteLogoImageFilePath(siteId: number): string {
    return `site-logos/${siteId}.png`;
  }

  public getSiteLogoPrintImageFilePath(siteId: number): string {
    return `site-logos/${siteId}_print.png`;
  }

  public getSitePrimaryImageFilePath(siteId: number): string {
    return `site-images/${siteId}.png`;
  }

  // naming with subcategory + UUID since subcategories may have the same name
  public getBackgroundImageFilePath(subcategory): string {
    return `backgrounds/${subcategory}_${UUID.UUID()}`;
  }

  public getSiteBackgroundImageFilePath(siteId: number): string {
    return `${siteId}/${UUID.UUID()}`;
  }

  public getNewsletterAssetFilePath(
    siteId: number,
    newsletterId: number,
  ): string {
    return `${siteId}/newsletters/${newsletterId}`;
  }

  public async uploadToFirestore(
    filePath: string,
    image: File | Blob,
  ): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      // Cache downloaded images for 72 hours
      const storageRef = ref(this.storage, filePath);
      try {
        await uploadBytes(storageRef, image, {
          cacheControl: 'public,max-age=259200',
        });
        const storedImageResponse = await getDownloadURL(storageRef);

        resolve(storedImageResponse);
      } catch (error) {
        reject();
      }
    });
  }

  public async deleteFromFirestore(filePath: string): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const imageRef = ref(this.storage, filePath);

        await deleteObject(imageRef)
          .then((response) => {
            resolve(null);
          })
          .catch((error) => {
            return of(`Delete Firebase Error: ${error}`);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  public createImageRecord(imageBody, newsletter = null) {
    const url = environment.apiUrl.concat('/api/v1/image');
    const body = {
      image: imageBody,
      user: this.authService.currentUser,
      site: this.siteService.currentSite,
      newsletter: newsletter,
      timestamp: new Date(), // passing in timestamp to ensure it's based on user's timezone
    };
    try {
      return <Promise<Image>>lastValueFrom(
        this.http
          .post(url, JSON.stringify(body), {
            headers: { 'Content-Type': 'application/json' },
          })
          .pipe(map((image) => new Image(image))),
      );
    } catch (err) {
      throw err;
    }
  }

  public getImage(downloadUrl: string) {
    if (!downloadUrl) {
      return firstValueFrom(EMPTY);
    }

    return lastValueFrom(
      this.http.get(downloadUrl, {
        responseType: 'blob',
      }),
    ).then((blob) => this.blobToBase64(blob));
  }

  public blobToBase64(blob: Blob) {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject();
      };

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.readAsDataURL(blob);
    });
  }

  // Returns string download url if one is found
  // Returns empty string if no url found
  public getDownloadUrl(path: string) {
    return new Promise<string>(async (resolve, reject) => {
      const storageRef = ref(this.storage, path);
      try {
        const downloadUrl = await getDownloadURL(storageRef);

        if (downloadUrl) {
          resolve(downloadUrl);
        } else {
          reject();
        }
      } catch (error) {
        if (error.code === 'storage/object-not-found') {
          console.error(`${path} not found`);
          resolve('');
        } else {
          console.error(path, error);
          reject(error);
        }
      }
    });
  }

  private _getOrgBackgrounds(orgId: number): Promise<Image[]> {
    const url = `${environment.apiUrl}/api/v1/images/org/${orgId}`;
    try {
      // tslint:disable-next-line:max-line-length
      return <Promise<Image[]>>(
        lastValueFrom(
          this.http
            .get(url)
            .pipe(
              map((backgrounds: Image[]) =>
                backgrounds.map((image: ImageDto) => new Image(image)),
              ),
            ),
        )
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  private _getSiteBackgrounds(siteId: number): Promise<Image[]> {
    if (this.authService.currentOrgId) {
      const url = `${environment.apiUrl}/api/v1/images/site/${siteId}/${this.authService.currentOrgId}`;
      try {
        // tslint:disable-next-line:max-line-length
        return <Promise<Image[]>>(
          lastValueFrom(
            this.http
              .get(url)
              .pipe(
                map((backgrounds: Image[]) =>
                  backgrounds.map((image: ImageDto) => new Image(image)),
                ),
              ),
          )
        );
      } catch (err) {
        console.error('ERROR', err);
        return;
      }
    }
  }

  public updateBackgroundImage(id: number, data: Partial<ImageUpdate>) {
    const url = environment.apiUrl.concat(`/api/v1/image/update/${id}`);
    const body: Partial<ImageUpdate> = {};

    if (data.site || data.site === null) {
      if (data.site === null) {
        // site restriction was removed, make available org-wide
        body.site = null;
      } else {
        // ensure org is null if setting a site restriction
        body.site = data.site;
      }
      body.organization = this.authService.currentOrgId;
    }
    if (data.category || data.category === null) {
      body.category = data.category;
    }
    if (data.subcategory || data.subcategory === null) {
      body.subcategory = data.subcategory;
    }

    try {
      return <Promise<String>>lastValueFrom(
        this.http.put(url, JSON.stringify({ id, ...body }), {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }),
      );
    } catch (err) {
      throw err;
    }
  }

  public setBackgroundImageDeleted(data: Partial<ImageDto>) {
    // const url = environment.apiUrl.concat(`/api/v1/image/delete/${data.id}`);
    const url = `${environment.apiv3Url}/image/${data.id}`;
    return lastValueFrom(this.http.delete(url));
  }

  // Source: https://stackoverflow.com/a/12300351/7926620
  public dataURItoBlob(dataURI): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  private async getFileTypeFromBlob(
    blob: Blob,
  ): Promise<FileType.FileTypeResult> {
    const fileType = await FileType.fromBlob(blob);
    return fileType;
  }

  public async isAllowedFileType(
    blob: Blob,
    allowFileTypeOverride?: string[],
  ): Promise<boolean> {
    const fileType = await this.getFileTypeFromBlob(blob);

    // If override value passed in, use that, otherwise use the default AlloweImageFileTypes
    const allowedFileTypes =
      allowFileTypeOverride && allowFileTypeOverride.length
        ? allowFileTypeOverride
        : Object.keys(AllowedImageFileTypes);
    if (allowedFileTypes.includes(fileType.ext)) {
      return true;
    }
    return false;
  }

  /**
   * Return the image name from the path
   * E.g. 'firestoreurl.com/123/abc/mypic.png' => 'mypic.png'
   */
  public getImageName(image: Partial<ImageDto | Image>): string {
    return !image || !image.path
      ? ''
      : image.path.substring(image.path.lastIndexOf('/') + 1);
  }

  public getSiteBackgroundImagesPaginated(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
  ): Observable<{
    data: Array<Image>;
    totalCount: number;
  }> {
    const { search, styleId } = pagination.filters;
    let active = 'updatedOn'; // default sorting behavior unless otherwise specified
    let direction = 'desc';

    if (pagination?.sort && pagination.sort.active) {
      active = pagination.sort.active ?? active;
      direction = pagination.sort.direction ?? direction;
    }

    if (search.trim().length > 0) {
      queryParams = queryParams.append(
        'searchBy',
        '["subcategory", "category", "site"]',
      );
    }
    queryParams = queryParams.append('sortField', active as string);
    queryParams = queryParams.append('style', styleId as string);
    queryParams = queryParams.append('sortOrder', direction.toLowerCase());

    const url = `${environment.apiv3Url}/image/site/${this.siteService.currentSite.id}`;

    try {
      return this.http.get(url, { params: queryParams }).pipe(
        tap((response: { data: Array<Image>; totalCount }) => {
          response.data = response.data.map((image: ImageDto) => {
            return new Image(image);
          });
        }),
      );
    } catch (err) {
      console.error('ERROR', err);

      return;
    }
  }

  public getOrganizationBackgroundImagesPaginated(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
  ): Observable<{
    data: Array<Image>;
    totalCount: number;
  }> {
    const { search, styleId } = pagination.filters;
    let active = 'updatedOn'; // default sorting behavior unless otherwise specified
    let direction = 'desc';

    if (pagination?.sort) {
      active = pagination.sort.active;
      direction = pagination.sort.direction;
    }

    if (search.trim().length > 0) {
      queryParams = queryParams.append(
        'searchBy',
        '["subcategory", "category", "site"]',
      );
    }
    queryParams = queryParams.append('sortField', active as string);
    queryParams = queryParams.append('style', styleId as string);
    queryParams = queryParams.append('sortOrder', direction.toLowerCase());

    const url = `${environment.apiv3Url}/image/organization/${this.siteService.currentSite.organization.id}`;

    try {
      return this.http.get(url, { params: queryParams }).pipe(
        tap((response: { data: Array<Image>; totalCount }) => {
          response.data = response.data.map((image: ImageDto) => {
            return new Image(image);
          });
        }),
      );
    } catch (err) {
      console.error('ERROR', err);

      return;
    }
  }

  // compress image file to prevent needlessly costly downloads
  public async resizeFile(
    file: File,
    options: {
      maxSizeMB?: number;
      maxWidthOrHeight?: number;
      useWebWorker?: boolean;
      maxIteration?: number;
      exifOrientation?: number;
      /** A function takes one progress argument (progress from 0 to 100) */
      onProgress?: (progress: number) => void;
      fileType?: string;
      initialQuality?: number;
    } = {
      maxWidthOrHeight: 3840, // should not be a higher resolution than 4k
      maxSizeMB: 4,
    },
  ): Promise<File> {
    return new Promise((resolve, reject) => {
      imageCompression(file, options)
        .then((compressedFile) => {
          resolve(compressedFile);
        })
        .catch((error) => {
          console.error(error.message);
          reject();
        });
    });
  }
}
