import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Site } from '@models/site';
import { NewsletterService } from 'src/app/core/services/newsletter.service';
import { SiteService } from 'src/app/core/services/site.service';
import { AddNewsletterComponent } from 'src/app/manage/newsletters/add-newsletter/add-newsletter.component';

@Component({
  selector: 'app-newsletters',
  templateUrl: './newsletters.component.html',
  styleUrls: ['./newsletters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewslettersComponent implements OnInit {
  public isRefreshing = false;
  public searchTerm = '';
  public newsletterCount = 0;
  public site: Site;

  constructor(
    private dialog: MatDialog,
    public newsletterService: NewsletterService,
    private siteService: SiteService,
  ) {}

  ngOnInit(): void {
    this.isRefreshing = true;
    this.newsletterService.refreshNewsletters().then(() => {
      this.isRefreshing = false;
    });
    this.siteService.site.subscribe((site) => {
      this.site = site;
    });
  }

  public addNewsletter() {
    const dialogRef = this.dialog.open(AddNewsletterComponent, {
      minWidth: 400,
      data: {
        batchCreate: true,
      },
    });
  }

  public refresh() {
    this.isRefreshing = true;
    this.newsletterService.refreshNewsletters().then(() => {
      this.isRefreshing = false;
    });
  }

  public setNewsletterCount(value) {
    this.newsletterCount = value;
  }

  public async openNewsletterTemplates() {
    this.newsletterService.openNewsletterTemplates(this.site.trainingGuideUrl);
  }
}
