import { pdfLayout } from 'src/app/core/interfaces/pdfMake/pdfTables';

export const TABLE_LAYOUT_PADDING_RESET: Partial<pdfLayout> = {
  paddingTop: function (i, node) {
    return 0;
  },
  paddingBottom: function (i, node) {
    return 0;
  },
};

export const LEGEND_DAYS_NEEDED = 3;

export const MAX_EVENTS_PER_DAY_IN_5_ROW_MONTH = 9;
export const MAX_EVENTS_PER_DAY_IN_6_ROW_MONTH = 7;
