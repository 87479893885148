import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Notification } from '@models/notification';
import moment from 'moment';
import { urlRegex } from 'src/app/core/constants/regex';

export interface NotificationFormValues {
  title: string;
  text: string;
  expirationDate: string;
  expirationTime: string; // HH:MM
  moreInfoUrl: string;
}

// tslint:disable-next-line:max-line-length
export const ExpirationHint =
  "At this date/time the notification will be cleared from anyone's unopened notifications on mobile.";
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-notification-form',
  templateUrl: './notification-form.component.html',
  styleUrls: ['./notification-form.component.scss'],
})
export class NotificationFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  @Input() public isEditing = false;
  public expirationHint = ExpirationHint;

  static formModel(formValues = null) {
    return new UntypedFormGroup({
      title: new UntypedFormControl(
        formValues ? formValues.title : null,
        Validators.required,
      ),
      text: new UntypedFormControl(
        formValues ? formValues.text : null,
        Validators.required,
      ),
      moreInfoUrl: new UntypedFormControl(
        formValues ? formValues.moreInfoUrl : null,
        Validators.pattern(urlRegex),
      ),
      // tslint:disable-next-line:max-line-length
      expirationDate: new UntypedFormControl(
        formValues && formValues.expiration
          ? moment(formValues.expiration).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
      ),
      // tslint:disable-next-line:max-line-length
      expirationTime: new UntypedFormControl(
        formValues && formValues.expiration
          ? moment(formValues.expiration).format('HH:mm')
          : moment().hour(23).minute(59).format('HH:mm'),
      ),
    });
  }

  static serialize(
    formValues: NotificationFormValues,
    notification: Partial<any> = {}, // switch to type from api when you have time
  ): Partial<any> {
    const serializedForm = {
      title: formValues.title,
      text: formValues.text,
      expiration: moment(
        `${formValues.expirationDate} ${formValues.expirationTime}`,
      ).toDate(),
      moreInfoUrl: formValues.moreInfoUrl,
    };

    return Object.assign(notification, serializedForm);
  }

  static deserialize(notification: Notification): NotificationFormValues {
    return {
      title: notification.title,
      text: notification.text,
      expirationTime: moment(notification.expiration).format('HH:mm'),
      expirationDate: moment(notification.expiration).format('YYYY-MM-DD'),
      moreInfoUrl: notification.moreInfoUrl,
    };
  }

  constructor() {}

  ngOnInit() {}
}
