<div [formGroup]="form" class="flex flex-col gap-12">
  <div class="flex flex-col gap-1.5">
    <div class="flex flex-col gap-1.5">
      <div class="flex gap-12">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Email</mat-label>
          <input matInput autofocus required formControlName="email" />
          <mat-error
            *ngIf="
              form.controls['email'].hasError('email') &&
              form.controls['email'].touched
            "
          >
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input
            matInput
            required
            formControlName="password"
            [type]="hide ? 'password' : 'text'"
          />
          <button mat-icon-button matSuffix (click)="hide = !hide">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-hint>Minimum 6 characters</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
