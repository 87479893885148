<form [formGroup]="form">
  <label class="form-question">Title</label>
  <div
    class="label-icon"
    matTooltip="This is the headline on your slide. Limit {{
      maxTitleLength
    }} characters."
  >
    <mat-icon>help</mat-icon>
  </div>
  <div class="form-group">
    <mat-form-field appearance="outline" color="accent">
      <textarea
        matInput
        formControlName="title"
        placeholder="Enter title"
        type="text"
        [maxLength]="maxTitleLength"
        data-qa="slide-description-input-title"
      ></textarea>
    </mat-form-field>
  </div>
  <label class="form-question">Description</label>
  <div
    class="label-icon"
    matTooltip="This is the descriptor text under your headline. Limit {{
      maxDescriptionLength
    }} characters."
  >
    <mat-icon>help</mat-icon>
  </div>
  <div class="form-group">
    <mat-form-field appearance="outline" color="accent">
      <input
        matInput
        formControlName="description"
        placeholder="Enter description"
        type="text"
        [maxLength]="maxDescriptionLength"
        data-qa="slide-description-input-description"
      />
    </mat-form-field>
  </div>
</form>
