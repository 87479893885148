import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SiteService } from './site.service';
import { AuthService } from './auth.service';
import { map, distinctUntilChanged, filter } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, lastValueFrom } from 'rxjs';
import { Newsletter } from '@models/newsletter';
import { NewsletterDto } from '@interfaces/newsletter';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { Article } from '@models/article';
import { ArticleDto } from '@interfaces/article';
import { User } from '@models/user';
import { ArticleTypeDto } from '@interfaces/articleType';
import { UserNewsletterViewedOnDto } from '@interfaces/userNewsletterViewedOn';
import { NewsletterCommentDto } from '@interfaces/newsletterComment';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  private _siteNewsletters = new BehaviorSubject<Newsletter[]>([]);
  public readonly siteNewsletters = this._siteNewsletters.asObservable();

  private _siteNewslettersNotificationCount = new BehaviorSubject<number>(0);
  public readonly siteNewslettersNotificationCount =
    this._siteNewslettersNotificationCount.asObservable();

  private _orgNewslettersNotificationCount = new BehaviorSubject<number>(0);
  public readonly orgNewslettersNotificationCount =
    this._orgNewslettersNotificationCount.asObservable();

  private _orgNewsletters = new BehaviorSubject<Newsletter[]>([]);
  public readonly orgNewsletters = this._orgNewsletters.asObservable();

  private _userNewsletterViewedOn = new BehaviorSubject<
    UserNewsletterViewedOnDto[]
  >([]);
  public readonly userNewsletterViewedOn =
    this._userNewsletterViewedOn.asObservable();

  public articleTypes: ArticleTypeDto[] = [];

  constructor(
    private alertService: AlertService,
    private http: HttpClient,
    private siteService: SiteService,
    private authService: AuthService,
  ) {
    this._listenForSiteUpdates();
    this._listenForAuthUpdates();
    this._loadArticleTypes();
    this.listenForNewsletterUpdatesToUpdateNotificationCounters();
  }

  public openNewsletterTemplates(templateUrl: string) {
    // These ids are associated with Legends on Lake Loraine
    // if (
    //   siteId &&
    //   (siteId === 137 ||
    //     siteId === 138 ||
    //     siteId === 147 ||
    //     siteId === 148 ||
    //     siteId === 149)
    // ) {
    //   window.open(
    //     'https://firebasestorage.googleapis.com/v0/b/goodman-api-prod.appspot.com/o/assets%2FLOL_Newsletter_FinalDesign_v4%5B3%5D.pdf?alt=media&token=3b285294-0af5-49fe-886b-caf0f4afee16&_gl=1*ezju08*_ga*MTg5MDczODkxMi4xNjkwODIxNTE0*_ga_CW55HF8NVT*MTY5NzU3NDMzMi4xNS4xLjE2OTc1NzQ2MjMuNjAuMC4w',
    //     '_blank',
    //   );
    // } else {
    //   window.open(
    //     'https://firebasestorage.googleapis.com/v0/b/goodman-api-prod.appspot.com/o/assets%2FLayout%20and%20character%20counts%20of%20newsletter%20types.pdf?alt=media&token=552389fe-99e0-4de6-a408-34316295829b',
    //     '_blank',
    //   );
    // }
    window.open(templateUrl, '_blank');
  }

  public lastViewedOn(newsletterId: number): Date | null {
    const userNewsletterViewedOn = this._userNewsletterViewedOn
      .getValue()
      .find((userNewsletterViewedOn) => {
        return (
          userNewsletterViewedOn.user.id === this.authService.currentUser.id &&
          userNewsletterViewedOn.newsletter.id === newsletterId
        );
      });
    return userNewsletterViewedOn ? userNewsletterViewedOn.updatedOn : null;
  }

  /**
   * If not an Admin, just load Site's Newsletters;
   * Else, load for the entire Org, and then they will be filtered to the Site downstream
   */
  public async refreshNewsletters(): Promise<void> {
    if (!this.authService.currentUser.isOrgAdmin) {
      return this.refreshSiteNewsletters(this.siteService.currentSiteId);
    }

    return this.refreshOrgNewsletters(this.authService.currentOrgId);
  }

  public async refreshSiteNewsletters(siteId?: number): Promise<void> {
    return this._getNewslettersBySiteId(
      siteId || this.siteService.currentSiteId,
    )
      .then((newsletters) => {
        this._siteNewsletters.next(newsletters || []);
      })
      .catch((error) => {
        this._siteNewsletters.next([]);
        this.alertService.error('Error refreshing Newsletters.');
      });
  }

  public async refreshOrgNewsletters(orgId: number): Promise<void> {
    return this._getNewslettersByOrgId(orgId)
      .then((newsletters) => {
        this._orgNewsletters.next(newsletters || []);
        this._orgToSiteNewsletters();
      })
      .catch((error) => {
        this._orgNewsletters.next([]);
        this.alertService.error('Error refreshing Newsletters.');
      });
  }

  public async refreshUserNewsletterViewedOn() {
    return this._getUserNewsletterViewedOn()
      .then((userNewsletterViewedOn) => {
        this._userNewsletterViewedOn.next(userNewsletterViewedOn || []);
      })
      .catch((error) => {
        this._userNewsletterViewedOn.next([]);
      });
  }

  /**
   * Pass in original Newsletter object if editing a Newsletter so the API can process changes
   */
  public saveNewsletter(
    updatedNewsletter: Partial<Newsletter>,
    originalNewsletter: Newsletter = null,
  ): Promise<NewsletterDto> {
    const url = `${environment.apiUrl}/api/v1/newsletters`;
    const body = {
      updatedNewsletter,
      originalNewsletter,
      user: this.authService.currentUser,
      site: this.siteService.currentSite,
      timestamp: new Date(), // passing in timestamp to ensure it's based on user's timezone
    };
    try {
      return lastValueFrom(this.http.post<NewsletterDto>(url, body));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public saveNewsletterArticleCount(
    updatedNewsletter: Partial<Newsletter>,
  ): Promise<NewsletterDto> {
    const url = `${environment.apiv3Url}/newsletter/update-newsletter-count`;
    const body = {
      id: updatedNewsletter.id,
      updatedArticleCount: updatedNewsletter.articleCount,
    };
    try {
      return lastValueFrom(this.http.put<NewsletterDto>(url, body));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public saveArticle(article: Partial<Article>): Promise<ArticleDto> {
    const url = `${environment.apiUrl}/api/v1/articles`;

    try {
      return lastValueFrom(this.http.post<ArticleDto>(url, article));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public saveNewsletterComment(
    comment: NewsletterCommentDto,
    newsletter: Newsletter,
  ): Promise<any> {
    const url = `${environment.apiv3Url}/newsletter/newsletter-comment`;
    const data = {
      comment: comment.content,
      deleted: comment.deleted,
      newsletterId: newsletter.id,
      userId: this.authService.currentUser.id,
      siteId: this.siteService.currentSite.id,
      timestamp: new Date(), // passing in timestamp to ensure it's based on user's timezone
    };
    try {
      return lastValueFrom(this.http.post<any>(url, data));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public deleteComment(commentId: number): Promise<any> {
    const url = `${environment.apiv3Url}/newsletter/newsletter-comment/${commentId}`;
    const data = {
      deleted: true,
    };
    try {
      return lastValueFrom(this.http.put<any>(url, data));
    } catch (err) {
      console.error('Unable to delete comment', err);
      return;
    }
  }

  public saveUserNewsletterViewedOn(
    newsletterId: number,
  ): Promise<UserNewsletterViewedOnDto> {
    const url = `${environment.apiv3Url}/newsletter/viewed-on`;
    const body = {
      newsletter: newsletterId,
      user: this.authService.currentUser.id,
    };

    const existingRecord = this._userNewsletterViewedOn
      .getValue()
      .find((userNewsletterViewedOn) => {
        return (
          userNewsletterViewedOn.newsletter.id === newsletterId &&
          userNewsletterViewedOn.user.id === this.authService.currentUser.id
        );
      });

    if (existingRecord) {
      // @ts-ignore
      body.id = existingRecord.id;
    }

    try {
      return lastValueFrom(
        this.http.post<UserNewsletterViewedOnDto>(url, body),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Update siteNewsletters based on current orgNewsletters being filtered
   */
  private _orgToSiteNewsletters(): void {
    const orgNewsletters = this._orgNewsletters.getValue();
    const activeSiteNewsletters = orgNewsletters.filter(
      (newsletter) => newsletter.site.id === this.siteService.currentSiteId,
    );
    this._siteNewsletters.next(activeSiteNewsletters || []);
  }

  /**
   * If logged in as Admin, since it's just a switch between sites,
   * can simply filter the relevant newsletters from what's already in service memory
   */
  private _listenForSiteUpdates(): void {
    this.siteService.siteId
      .pipe(distinctUntilChanged())
      .subscribe((siteId: number) => {
        if (!siteId) {
          this._siteNewsletters.next([]);
          return;
        }

        if (!this.authService.currentUser.isOrgAdmin) {
          this.refreshSiteNewsletters();
          return;
        }

        this._orgToSiteNewsletters();
      });
  }

  /**
   * Only fetch Org Newsletters if Org Admin
   */
  private _listenForAuthUpdates(): void {
    this.authService.user
      .pipe(distinctUntilChanged())
      .subscribe((user: User) => {
        if (user && user.isOrgAdmin) {
          this.refreshOrgNewsletters(user.orgId);
        } else {
          this._orgNewsletters.next([]);
        }
        if (user) {
          this.refreshUserNewsletterViewedOn();
        }
      });
  }

  private _getUserNewsletterViewedOn(): Promise<UserNewsletterViewedOnDto[]> {
    const url = `${environment.apiv3Url}/newsletter/newsletter-views-for-user/${this.authService.currentUser.id}`;
    try {
      return lastValueFrom(this.http.get<UserNewsletterViewedOnDto[]>(url));
    } catch (err) {
      console.error('ERROR', err);
      return Promise.reject(err);
    }
  }

  /**
   * Fetches a single newsletter by newsletterId
   * @param newsletterId
   * @returns
   */
  public fetchNewsletter(newsletterId: number): Promise<Newsletter> {
    const url = `${environment.apiv3Url}/newsletter/${newsletterId}`;

    try {
      return <Promise<Newsletter>>lastValueFrom(
        this.http.get(url).pipe(
          filter((newsletter) => newsletter !== null),
          map((newsletter: NewsletterDto) => {
            return new Newsletter(newsletter);
          }),
        ),
      );
    } catch (err) {
      console.error('ERROR', err);
      return Promise.reject(err);
    }
  }

  /**
   * Exports newsletter files (zipped folder with docx and images) to current user's email address
   * @param newsletterId
   * @returns
   */
  public exportNewsletterFiles(
    newsletterId: number,
    includeImages: boolean = true,
  ): Promise<any> {
    const url = `${environment.apiv3Url}/newsletter/${newsletterId}/export`;
    const queryParams = new HttpParams({
      fromObject: {
        includeImages,
        emailAddress: this.authService.currentUser.email,
      },
    });
    try {
      return <Promise<any>>lastValueFrom(
        this.http.get(url, { params: queryParams }).pipe(
          map((response) => {
            return new Newsletter(response);
          }),
        ),
      );
    } catch (err) {
      console.error('ERROR', err);
      return Promise.reject(err);
    }
  }

  private _getNewslettersBySiteId(siteId: number): Promise<Newsletter[]> {
    const url = `${environment.apiv3Url}/newsletter/site/${siteId}`;
    try {
      return <Promise<Newsletter[]>>(
        lastValueFrom(
          this.http
            .get(url)
            .pipe(
              map((newsletters: NewsletterDto[]) =>
                newsletters.map((newsletter) => new Newsletter(newsletter)),
              ),
            ),
        )
      );
    } catch (err) {
      console.error('ERROR', err);
      return Promise.reject(err);
    }
  }

  private _getNewslettersByOrgId(orgId: number): Promise<Newsletter[]> {
    const url = `${environment.apiv3Url}/newsletter/org/${orgId}`;
    try {
      return lastValueFrom(
        this.http
          .get(url)
          .pipe(
            map((newsletters: NewsletterDto[]) =>
              newsletters.map((newsletter) => new Newsletter(newsletter)),
            ),
          ),
      );
    } catch (err) {
      console.error('ERROR', err);
      return Promise.reject(err);
    }
  }

  private _getArticleTypes(): Promise<ArticleTypeDto[]> {
    const url = environment.apiUrl.concat(`/api/v1/article-types`);
    try {
      return <Promise<ArticleTypeDto[]>>lastValueFrom(this.http.get(url));
    } catch (err) {
      console.error('ERROR', err);
      return Promise.reject(err);
    }
  }

  /**
   * Loading articleTypes on start and persisting service.
   * No need to make it a BehaviorSubject as these will at most rarely change.
   */
  private async _loadArticleTypes() {
    this.articleTypes = await this._getArticleTypes();
  }

  /**
   * Update notification counters whenever newsletters or userViewedOn refresh
   */
  private listenForNewsletterUpdatesToUpdateNotificationCounters(): void {
    const siteObservables = [this.siteNewsletters, this.userNewsletterViewedOn];
    combineLatest(siteObservables).subscribe(([newsletters, viewedOn]) => {
      this._siteNewslettersNotificationCount.next(
        // @ts-ignore
        newsletters.filter((newsletter) =>
          (newsletter as Newsletter).hasNotification(
            this.authService.currentUser.id,
            this.lastViewedOn(newsletter.id),
          ),
        ).length,
      );
    });

    const orgObservables = [this.orgNewsletters, this.userNewsletterViewedOn];
    combineLatest(orgObservables).subscribe(([newsletters, viewedOn]) => {
      this._orgNewslettersNotificationCount.next(
        // @ts-ignore
        (newsletters as Newsletter).filter((newsletter) =>
          newsletter.hasNotification(
            this.authService.currentUser.id,
            this.lastViewedOn(newsletter.id),
          ),
        ).length,
      );
    });
  }
}
