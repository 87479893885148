import { Component, OnInit } from '@angular/core';
import { FaqFormComponent } from 'src/app/shared/forms/faq-form/faq-form.component';
import { FaqService } from 'src/app/core/services/faq.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SiteService } from 'src/app/core/services/site.service';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.scss'],
})
export class AddFaqComponent implements OnInit {
  public faqForm = FaqFormComponent.formModel();
  public submitting = false;

  constructor(
    private alertService: AlertService,
    private dialogRef: MatDialogRef<AddFaqComponent>,
    private faqService: FaqService,
    private siteService: SiteService
  ) {}

  ngOnInit() {}

  public async onSave(): Promise<void> {
    this.submitting = true;
    const faq = FaqFormComponent.serialize(this.faqForm.value, this.siteService.currentSiteId);

    this.faqService
      .updateFaq(faq)
      .then(() => {
        this.alertService.success('FAQ saved.');
        this.faqService.refreshFaqs();
        this.onCancel();
      })
      .catch((error) => {
        this.alertService.error('Unable to save FAQ. Please try again.');
        console.error(error);
      })
      .finally(() => (this.submitting = false));
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
