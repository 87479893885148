<mat-card appearance="outlined" color="primary">
  <mat-card-header class="flex justify-between items-center content-center">
    <mat-card-title color="primary" class="flex">
      Property Menus
    </mat-card-title>
    <div class="flex justify-end items-center content-center w-3/4 gap-[15px]">
      <app-menu-upload-button
        (refreshTrigger)="refresh()"
      ></app-menu-upload-button>
      <mat-form-field color="accent">
        <input matInput placeholder="Search" [(ngModel)]="searchString" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </mat-card-header>
  <mat-card-content class="flex flex-col">
    <!-- table needs site to be loaded to work, but it should have site passed in -->
    <ng-container *ngIf="site | async as site">
      <app-menu-table
        showDelete="true"
        [filter]="searchString"
      ></app-menu-table>
    </ng-container>
  </mat-card-content>
</mat-card>
