<div class="bottom-area">
  <div class="block date">
    <h5>{{date}}</h5>
    <h4>{{time}}</h4>
  </div>
  <div class="block today">
    <ng-container *ngIf="weather?.today">
      <div>
        <h5>Today</h5>
        <h4>{{weather.today.high}}&#176; / {{weather.today.low}}&#176;</h4>
      </div>
      <img class="weather-icon" *ngIf="weather.today.icon" [src]="'assets/icons/weather/' + weather.today.icon + '.svg'">
    </ng-container>
  </div>
  <div class="block tomorrow">
    <ng-container *ngIf="weather?.tomorrow">
      <div>
        <h5>Tomorrow</h5>
        <h4>{{weather.tomorrow.high}}&#176; / {{weather.tomorrow.low}}&#176;</h4>
      </div>
      <img class="weather-icon" *ngIf="weather.tomorrow.icon" [src]="'assets/icons/weather/' + weather.tomorrow.icon + '.svg'">
    </ng-container>
  </div>
  <div class="block overmorrow">
    <ng-container *ngIf="weather?.overmorrow">
      <div>
        <h5>{{overmorrowDay}}</h5>
        <h4>{{weather.overmorrow.high}}&#176; / {{weather.overmorrow.low}}&#176;</h4>
      </div>
      <img class="weather-icon" *ngIf="weather.overmorrow.icon" [src]="'assets/icons/weather/' + weather.overmorrow.icon + '.svg'">
    </ng-container>
    </div>
</div>
