<div [formGroup]="form" class="flex flex-col gap-12">
  <div class="flex flex-col gap-1.5 justify-start items-center content-center">
    <div class="flex flex-col gap-1.5">
      <h2 class="section-heading">Property Details</h2>

      <div class="flex gap-12">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Name</mat-label>
          <input matInput autofocus required formControlName="name" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <input matInput formControlName="description" />
        </mat-form-field>
      </div>
      <div class="flex gap-12">
        <mat-form-field appearance="outline">
          <mat-label>Contact Phone</mat-label>
          <input
            matInput
            required
            formControlName="phone"
            [inputMask]="telMask"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Contact Email</mat-label>
          <input matInput required formControlName="email" />
          <mat-error
            *ngIf="
              form.controls['email'].hasError('email') &&
              form.controls['email'].touched
            "
          >
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex gap-12">
        <mat-form-field appearance="outline">
          <mat-label>Address Line 1</mat-label>
          <input matInput required formControlName="address1" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Address Line 2</mat-label>
          <input matInput formControlName="address2" />
        </mat-form-field>
      </div>
      <div class="flex gap-12">
        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input matInput required formControlName="city" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>State</mat-label>
          <mat-select placeholder="State" required formControlName="state">
            <mat-option *ngFor="let key of stateEnumKeys" [value]="key">
              {{ key | enum : 'state' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex gap-12">
        <mat-form-field appearance="outline">
          <mat-label>Zip</mat-label>
          <input matInput required formControlName="zip" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Time Zone</mat-label>
          <mat-select
            placeholder="Time Zone"
            required
            formControlName="timezone"
          >
            <mat-option *ngFor="let zone of timezoneEnumKeys" [value]="zone">
              {{ zone | enum : 'timezone' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex gap-12">
        <mat-form-field appearance="outline">
          <mat-label>Property Type</mat-label>
          <mat-select
            placeholder="Site Type"
            required
            formControlName="siteType"
          >
            <mat-option *ngFor="let type of siteTypes" [value]="type.id">
              {{ type.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex gap-12">
        <mat-checkbox
          formControlName="activeForMobile"
          matTooltip="If checked, display this property in the mobile application. This will also enable push notifications for the property."
        >
          Mobile Enabled
        </mat-checkbox>
      </div>
      <h2 class="section-heading">Mobile App Details</h2>
      <div class="flex gap-12">
        <mat-form-field appearance="outline">
          <mat-label>Website URL</mat-label>
          <input matInput formControlName="websiteUrl" type="url" />
          <mat-error
            *ngIf="
              form.controls['websiteUrl'].hasError('pattern') &&
              form.controls['websiteUrl'].touched
            "
          >
            Please insert a valid URL
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Virtual Tour URL</mat-label>
          <input matInput formControlName="tourUrl" type="url" />
          <mat-error
            *ngIf="
              form.controls['tourUrl'].hasError('pattern') &&
              form.controls['tourUrl'].touched
            "
          >
            Please insert a valid URL
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="mobile-settings flex gap-12 justify-start items-center content-center"
      >
        <mat-form-field appearance="outline">
          <mat-label>COVID-19 URL</mat-label>
          <input matInput formControlName="covidUrl" type="url" />
          <mat-error
            *ngIf="
              form.controls['covidUrl'].hasError('pattern') &&
              form.controls['covidUrl'].touched
            "
          >
            Please insert a valid URL
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Newsletter URL</mat-label>
          <input matInput formControlName="newsletterUrl" type="url" />
          <mat-error
            *ngIf="
              form.controls['newsletterUrl'].hasError('pattern') &&
              form.controls['newsletterUrl'].touched
            "
          >
            Please insert a valid URL
          </mat-error>
        </mat-form-field>
      </div>
      <div
        id="email-row "
        class="mobile-settings flex gap-12 justify-start items-center content-center"
      >
        <div class="flex flex-col">
          <mat-icon
            matListItemIcon
            matTooltip="Including an email here will enable the Ask a Question feature in the GiGi Mobile App. All questions will be sent to this email address."
            >help</mat-icon
          >
          <mat-form-field appearance="outline">
            <mat-label>Ask a Question Email</mat-label>
            <input matInput formControlName="questionEmail" />
            <mat-error
              *ngIf="
                form.controls['questionEmail'].hasError('email') &&
                form.controls['questionEmail'].touched
              "
            >
              Please enter a valid email address
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex flex-col">
          <mat-icon
            matListItemIcon
            matTooltip="Including an email here will enable the Warm Wishes feature in the GiGi Mobile App. All wishes will be sent to this email address."
            >help</mat-icon
          >
          <mat-form-field appearance="outline">
            <mat-label>Warm Wishes Email</mat-label>
            <input matInput formControlName="warmWishesEmail" />
            <mat-error
              *ngIf="
                form.controls['warmWishesEmail'].hasError('email') &&
                form.controls['warmWishesEmail'].touched
              "
            >
              Please enter a valid email address
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <h2 class="section-heading">Training Guide</h2>
      <mat-form-field appearance="outline">
        <mat-label>Training Guide Url</mat-label>
        <input matInput formControlName="trainingGuideUrl" />
        <mat-error
          *ngIf="
            form.controls['trainingGuideUrl'].hasError('pattern') &&
            form.controls['trainingGuideUrl'].touched
          "
        >
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
