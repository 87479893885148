<div style="display: flex; flex-wrap: wrap; height: 180px; overflow: scroll">
  <div
    class="image"
    *ngFor="let background of paginatedDataSource?.dataSubject?.value"
  >
    <img
      [src]="background.downloadUrl"
      (click)="setBackgroundImage(background)"
      [ngClass]="{
        'is-selected': this.selectedImage$?.value?.id === background?.id
      }"
      class="pointer"
    />
  </div>
</div>

<mat-paginator
  [length]="paginatedDataSource?.totalCount"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons
></mat-paginator>
