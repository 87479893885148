<h1 mat-dialog-title *ngIf="data.title">{{ data.title }}</h1>
<!-- Pass in as innerHTML so you can pass in line breaks via <br /> -->
<mat-dialog-content
  class="max-w-400"
  [innerHTML]="
    data.message || 'Are you sure you want to proceed? This cannot be undone.'
  "
>
  <ng-container *ngIf="data.messageDisclaimer">
    <div class="italic has-y-padding">{{ data.messageDisclaimer }}</div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">
    {{ data.noText || 'Cancel' }}
  </button>
  <app-progress-button
    data-qa="dialog-confirm-button"
    color="accent"
    [text]="data.yesText || 'Confirm'"
    (submit)="onConfirmClick($event)"
    [submitting]="isLoading.getValue()"
    cdkFocusInitial
  >
  </app-progress-button>
</mat-dialog-actions>
