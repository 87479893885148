<mat-card appearance="outlined" color="primary">
  <mat-card-header class="flex justify-between items-center content-center">
    <mat-card-title color="primary" class="flex">
      Users with Admin Access ({{ paginatedDataSource?.totalCount || 0 }})
    </mat-card-title>
    <div
      class="flex justify-end items-center content-center w-[55%] gap-[15px]"
    >
      <mat-form-field color="accent">
        <input
          #search="ngModel"
          matInput
          placeholder="Search"
          [(ngModel)]="filter"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </mat-card-header>
  <mat-card-content class="flex flex-col">
    <table
      mat-table
      matSort
      matSortActive="last-name"
      matSortDirection="asc"
      [dataSource]="paginatedDataSource"
    >
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999">
          <i>{{
            search?.value?.length
              ? 'No matches for this search.'
              : (paginatedDataSource.loading$ | async)
              ? ''
              : 'No one is an administrator for this property.'
          }}</i>
        </td>
      </tr>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          ID
        </th>
        <td mat-cell *matCellDef="let admin">{{ admin.id }}</td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          First Name
        </th>
        <td mat-cell *matCellDef="let admin">
          {{ admin.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Last Name
        </th>
        <td mat-cell *matCellDef="let admin">
          {{ admin.lastName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Email
        </th>
        <td mat-cell *matCellDef="let admin">
          {{ admin.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Admin Type
        </th>
        <td mat-cell *matCellDef="let admin">
          {{ admin.isOrgAdmin ? 'Organization' : 'Property' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [length]="paginatedDataSource?.totalCount"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25]"
      showFirstLastButtons
    ></mat-paginator>
  </mat-card-content>
</mat-card>
