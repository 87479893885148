import React, { useEffect, useState } from 'react';
import { getCalendarEventsIncludedExcluded } from '../../../utils/calendar-utils';
import { Event } from '@interfaces/event';

type CalendarOverflowWarningProps = {
  events: Event[];
  selectedMonth: any;
  printCalendarRestrictions: any;
};

export function CalendarOverflowWarning({
  events,
  selectedMonth,
  printCalendarRestrictions,
}: CalendarOverflowWarningProps) {
  const [overflow, setOverflow] = useState(0);
  const [warn, setWarn] = useState('none');

  useEffect(() => {
    if (selectedMonth) {
      handleOverflow(events, printCalendarRestrictions);
    }
  }, [selectedMonth, events, printCalendarRestrictions]);

  function handleOverflow(calendarEvents, restrictions) {
    const includedAndExcluded = getCalendarEventsIncludedExcluded(
      calendarEvents as Event[],
      restrictions.maxChars.small,
      restrictions.eventRowLimits,
    );
    const sum = includedAndExcluded.linesPerItem.reduce(
      (total, num) => total + num,
    );
    if (sum > restrictions.maxEventRows.numLinesPerDay.small) {
      setOverflow(includedAndExcluded.sorted.excluded.length);
      setWarn('overflow');
    } else if (sum === restrictions.maxEventRows.numLinesPerDay.small) {
      setWarn('limit');
    } else {
      setWarn('none');
    }
  }

  return (
    <>
      {warn !== 'none' && (
        <p className="warn">
          No additional events will fit on the print calendar for this day, but
          they will display as usual on digital signage.
        </p>
      )}
      {warn === 'overflow' && (
        <p className="warn">
          Event titles exceed the number of rows that will fit in a day:
          {overflow} event(s) will not be displayed on the print calendar.
        </p>
      )}
    </>
  );
}
