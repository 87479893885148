import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject, lastValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SiteService } from './site.service';
import { environment } from 'src/environments/environment';
import { Notification } from '@models/notification';
import { NotificationDto } from '@interfaces/notification';
import { PaginationInformation } from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _notifications = new ReplaySubject<Notification[]>();
  public readonly notifications = this._notifications.asObservable();
  private siteId: number;

  constructor(private http: HttpClient, private siteService: SiteService) {
    this.listenForSiteUpdates();
  }

  private listenForSiteUpdates(): void {
    this.siteService.siteId.subscribe((siteId: number) => {
      this.refreshNotifications();
    });
  }

  public refreshNotifications(): void {
    const siteId = this.siteService.currentSiteId;

    if (!siteId) {
      this._notifications.next([]);
      return;
    }

    this.loadNotifications(siteId)
      .then((notifications: Notification[]) => {
        this._notifications.next(notifications);
      })
      .catch((error) => console.error(error));
  }

  private loadNotifications(siteId: number): Promise<Notification[]> {
    const url = `${environment.apiUrl}/api/v1/notifications/all/${siteId}`;

    try {
      return lastValueFrom(
        this.http
          .get<Notification[]>(url)
          .pipe(
            map((notifications: NotificationDto[]) =>
              notifications.map(
                (notification) => new Notification(notification),
              ),
            ),
          ),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public addNotification(
    notification: any, // switch to type from api when you have time
  ): Promise<Notification> {
    const url = `${environment.apiv3Url}/notifications/sites/send-notification`;

    try {
      return lastValueFrom(this.http.post<Notification>(url, notification));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public updateNotification(
    notification: Partial<Notification>,
  ): Promise<Partial<Notification>> {
    const url = `${environment.apiUrl}/api/v1/notifications/${notification.id}`;

    try {
      return lastValueFrom(this.http.post<Notification>(url, notification));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public deleteNotification(id: number): Promise<null> {
    const url = `${environment.apiUrl}/api/v1/notifications/${id}`;

    try {
      // Set responseType to 'text' as API currently returns 'OK'
      return <Promise<null>>(
        lastValueFrom(this.http.put(url, {}, { responseType: 'text' }))
      );
    } catch (err) {
      console.error('ERROR', err);
    }
  }

  public getSiteNotificationsPaginated(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
  ): Observable<{
    data: Array<Notification>;
    totalCount: number;
  }> {
    const { search } = pagination.filters;
    let active = null;
    let direction = null;

    if (pagination?.sort) {
      active = pagination.sort.active;
      direction = pagination.sort.direction;
    }

    if (search.trim().length > 0) {
      queryParams = queryParams.append('searchBy', '["title", "text"]');
    }

    queryParams = queryParams.append('sortField', active as string);
    queryParams = queryParams.append('sortOrder', direction.toLowerCase());
    const url = `${environment.apiv3Url}/notifications/site/${this.siteService.currentSite.id}`;
    try {
      return this.http.get(url, { params: queryParams }).pipe(
        tap((response: { data: Array<Notification>; totalCount }) => {
          response.data = response.data.map((notification: NotificationDto) => {
            return new Notification(notification);
          });
        }),
      );
    } catch (err) {
      console.error('ERROR', err);

      return;
    }
  }
}
