<div class="flex justify-between items-start content-start">
  <h1 class="mat-dialog-title">Send Community Update</h1>
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <app-notification-form [form]="notificationForm"></app-notification-form>
</mat-dialog-content>
<mat-dialog-actions class="flex justify-center items-end content-end">
  <button mat-button [mat-dialog-close]="true">Cancel</button>
  <app-progress-button
    color="accent"
    text="Send Notification"
    (click)="onSave()"
    [disabled]="!notificationForm.valid"
    [submitting]="submitting"
  >
  </app-progress-button>
</mat-dialog-actions>
