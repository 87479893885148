<form [formGroup]="form">
  <div formArrayName="items">
    <div *ngFor="let item of form.get('items')['controls']; let i = index">
      <div formGroupName="{{ i }}">
        <div
          class="item-row flex items-baseline place-content-baseline"
          *ngIf="!item?.controls?.deleted?.value"
        >
          <mat-form-field appearance="outline" color="accent">
            <mat-label>Job Title</mat-label>
            <input matInput autofocus required formControlName="title" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Team Member Name</mat-label>
            <input matInput required formControlName="name" />
          </mat-form-field>
          <!--
            If deleting a record already recorded in database, mark it as deleted (it will be hidden).
            If it's a new record as of this session (not yet persisted in database), just remove it from form.
          -->
          <button
            mat-icon-button
            color="accent"
            (click)="
              item?.controls?.id?.value ? markDeleted(item) : removeItem(i)
            "
            matTooltip="Remove Team Member"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="flex flex-col justify-center items-center content-center gap-6">
  <app-progress-button
    color="accent"
    text="Save"
    (submit)="save()"
    [disabled]="!form.valid"
    [submitting]="submitting"
  >
  </app-progress-button>
  <button
    mat-button
    color="accent"
    (click)="addItem()"
    class="flex justify-center items-center content-center"
    [disabled]="!form.valid"
  >
    Add Another Team Member
  </button>
</div>
