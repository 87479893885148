import { DateTime } from 'luxon';
import { Event } from '@interfaces/event';
import { CalendarEvent } from '@interfaces/calendar';
import { CalendarFormat } from 'src/app/core/services/calendar.service';
import { getEventsForCalendar } from './calendar-requests';
import {
  LEGEND_DAYS_NEEDED,
  MAX_EVENTS_PER_DAY_IN_5_ROW_MONTH,
  MAX_EVENTS_PER_DAY_IN_6_ROW_MONTH,
} from './calendar-constants';
import { Announcement } from '@models/announcement';

/**
 * Returns a CalendarFormat interface object, describing
 * the number of rows this month's calendar will have
 * and whether the legend and disclaimer text will be
 * displayed at the beginning or end of the calendar
 *
 * @param month - number
 * @param year - number
 */
export async function evaluateCalendarFormatForMonth(
  date: DateTime,
  currentSiteId: number,
): Promise<CalendarFormat> {
  const daysInMonth = date.daysInMonth;
  const startDow = convertDow(date.startOf('month').weekday);
  const endDow = convertDow(date.endOf('month').weekday);
  const daysBefore = startDow;
  const daysAfter = 7 - endDow - 1;
  let trailingDaysNeeded = 4;
  let legendDaysNeeded = LEGEND_DAYS_NEEDED;
  const disclaimerDaysNeeded = 1;
  const totalDays = daysBefore + daysInMonth + daysAfter;
  let rowsRequired: number;
  let legendAtEndOfMonth: boolean;
  let disclaimerAtEndOfMonth: boolean;
  if (totalDays > 7 * 5) {
    rowsRequired = 6;
  } else {
    rowsRequired = 5;
  }

  /**
   * VERY specific scenario: 31 day months that start on a Tuesday have only 2 free squares at the start and end.
   * If there are not many room names in use, we can reduce the size of the legend to a width of 2 cols to avoid
   * adding an additional row to the month (which would mean fewer events fitting on the calendar)
   * [SC-3685](https://cloudburst.atlassian.net/browse/SC-3685)
   **/
  if (
    trailingDaysNeeded > daysAfter &&
    trailingDaysNeeded > daysBefore &&
    legendDaysNeeded > daysBefore &&
    legendDaysNeeded > daysAfter
  ) {
    const firstOfMonth: DateTime = date.startOf('month');
    const lastOfMonth: DateTime = date.endOf('month');

    const calendarEvents = await getEventsForCalendar(
      currentSiteId,
      firstOfMonth.toUTC().toFormat('x'),
      lastOfMonth.toUTC().toFormat('x'),
    );

    let filteredRoomIds = [];
    let activeRooms = [];

    // Determine which rooms are in active use for this month of events
    Object.keys(calendarEvents).forEach((day) => {
      calendarEvents[day].filter((events) => {
        if (filteredRoomIds.includes(events?.fullEvent?.roomId)) {
          return;
        } else if (events?.fullEvent?.roomId) {
          filteredRoomIds.push(events?.fullEvent?.roomId);
          const currentRoom = events.fullEvent.room;
          if (currentRoom) activeRooms.push(currentRoom);
        }
      });
    });
    /**
     * TODO: MAGIC NUMBER SEVEN.
     * Seven rooms fit in one column of the legend in a 5 row month at this font size and paper size.
     * At the time of writing, we never want less than two columns or more than three.
     * May be necessary to determine how many columns are needed more dynamically later on.
     * **/
    legendDaysNeeded = Math.ceil(activeRooms.length / 7) <= 2 ? 2 : 3;

    trailingDaysNeeded = legendDaysNeeded + disclaimerDaysNeeded;
  }

  if (trailingDaysNeeded <= daysAfter) {
    legendAtEndOfMonth = true;
    disclaimerAtEndOfMonth = true;
  } else if (trailingDaysNeeded <= daysBefore) {
    legendAtEndOfMonth = false;
    disclaimerAtEndOfMonth = false;
  } else if (
    legendDaysNeeded <= daysAfter &&
    disclaimerDaysNeeded <= daysBefore
  ) {
    legendAtEndOfMonth = true;
    disclaimerAtEndOfMonth = false;
  } else if (
    legendDaysNeeded <= daysBefore &&
    disclaimerDaysNeeded <= daysAfter
  ) {
    legendAtEndOfMonth = false;
    disclaimerAtEndOfMonth = true;
  } else {
    /**
     * this condition should ONLY happen when:
     * there are less than 3 squares of available space at the start and end of the month
     * AND there are 3 columns worth of active room names to display in the legend
     **/
    rowsRequired = 6;
    legendAtEndOfMonth = true;
    disclaimerAtEndOfMonth = true;
  }
  return { rowsRequired, legendAtEndOfMonth, disclaimerAtEndOfMonth };
}

/**
 * This system was originally built using moment.js but we have since moved to Luxon.
 * Moment weekdays are zero-based while Luxon weekdays start at zero. Convert to zero-base for backwards compatibility.
 */
function convertDow(date): number {
  let weekday;
  switch (date) {
    case 1:
      weekday = 1;
      break;
    case 2:
      weekday = 2;
      break;
    case 3:
      weekday = 3;
      break;
    case 4:
      weekday = 4;
      break;
    case 5:
      weekday = 5;
      break;
    case 6:
      weekday = 6;
      break;
    case 7:
      weekday = 0;
      break;
  }
  return weekday;
}

export function evaluateTextWrapOverflow(numLinesPerEvent): number {
  if (numLinesPerEvent < 1) {
    // all items must take at least one line
    numLinesPerEvent = 1;
  } else {
    numLinesPerEvent = Math.ceil(numLinesPerEvent);
  }

  return numLinesPerEvent;
}

/**
 * Given an array of events (or announcements), character and row restrictions, determine
 * which items will make it to the calendar or not.
 */
export function getCalendarEventsIncludedExcluded(
  events: Array<Announcement | Event>,
  maxNumCharacter,
  maxNumRows,
) {
  let eventLists = {
    included: [],
    excluded: [],
  };
  let linesPerItem = [];
  let tempCount = maxNumRows;
  events.forEach((event) => {
    const details = getCalendarEventListing(event, maxNumCharacter);
    let counter = tempCount - details.numLinesPerItem;

    if (counter >= 0) {
      eventLists.included.push(event);
      tempCount = counter;
    } else {
      eventLists.excluded.push(event);
      tempCount = counter;
    }
    linesPerItem.push(details.numLinesPerItem);
  });

  return { sorted: eventLists, linesPerItem };
}

// given an event (or announcement) and a character limit, determine how many rows it should take
// optionally: return a text collection with superscript styles
export function getCalendarEventListing(
  item: Announcement | Event | CalendarEvent,
  maxNumCharacter: number,
  getCharCollection?: boolean,
): {
  label: string;
  numLinesPerItem: number;
  charCollection: Array<{ text: string; sup?: boolean }>;
} {
  let itemTitle;
  let charCollection = [];

  //@ts-ignore
  if (item.room) {
    const announcement = item as Announcement;

    if (getCharCollection && containsSymbolCharacter(announcement.title)) {
      charCollection = styleSymbols(announcement);
    }
    itemTitle = `${announcement.title}${
      announcement.room.shortName && announcement.room.shortName !== ' - '
        ? ', ' + announcement.room.shortName
        : ''
    }`;
  } else {
    let event;
    //@ts-ignore
    if (item?.original) {
      event = item as CalendarEvent;

      if (getCharCollection && containsSymbolCharacter(event.title)) {
        charCollection = styleSymbols(event.src);
      }

      if (event.locationShortName) {
        itemTitle = `${event.title}${
          event.locationShortName && event.locationShortName !== ' - '
            ? ', ' + event.locationShortName
            : ''
        }`;
      } else {
        itemTitle = `${event.title}${
          event.original?.room?.short && event.original?.room?.short !== ' - '
            ? ', ' + event.original.room.short
            : ''
        }`;
      }

      //@ts-ignore
    } else if (item?.fullEvent) {
      event = item as Event;

      if (getCharCollection && containsSymbolCharacter(event.title)) {
        charCollection = styleSymbols(event.fullEvent);
      }

      if (event.locationShortName) {
        itemTitle = `${event.title}${
          event.locationShortName && event.locationShortName !== ' - '
            ? ', ' + event.locationShortName
            : ''
        }`;
      } else {
        itemTitle = `${event.title}${
          event.fullEvent?.room?.shortName &&
          event.fullEvent?.room?.shortName !== ' - '
            ? ', ' + event.fullEvent.room.shortName
            : ''
        }`;
      }
      itemTitle = `${event.title}${
        event.fullEvent?.room?.shortName &&
        event.fullEvent?.room?.shortName !== ' - '
          ? ', ' + event.fullEvent.room.shortName
          : ''
      }`;
    }
  }

  let numLinesPerItem = evaluateTextWrapOverflow(
    itemTitle.length / maxNumCharacter,
  );

  if (itemTitle.length > maxNumCharacter) {
    const titleChunk = itemTitle.match(
      new RegExp(
        `\\b[\\w+\\s]{${maxNumCharacter - 4},${maxNumCharacter}}.?(?=\\s)|.+$`,
        'g',
      ),
    );
    const titleChunkNoWhitespace = titleChunk.map((line) => line.trim());
    itemTitle = titleChunkNoWhitespace.join('\n');
  }

  return { label: itemTitle, numLinesPerItem, charCollection };
}

/**
 * Returns the max number of events per day in that month,
 * as determined by what will fit in the print calendar
 *
 * @param dayInMonth dateTime representing any day in the month
 */
async function getMaxEventsPerDayInMonth(
  dayInMonth: DateTime,
  currentSiteId: number,
): Promise<number> {
  const rowsThisMonth = await evaluateCalendarFormatForMonth(
    dayInMonth,
    currentSiteId,
  );
  return rowsThisMonth.rowsRequired === 5
    ? MAX_EVENTS_PER_DAY_IN_5_ROW_MONTH
    : MAX_EVENTS_PER_DAY_IN_6_ROW_MONTH;
}

function containsSymbolCharacter(text: string): boolean {
  if (text.includes('®') || text.includes('©') || text.includes('™')) {
    return true;
  }
  return false;
}

function styleSymbols(
  event: Event | Announcement,
): Array<{ text: string; sup?: boolean }> {
  const label = event.title;
  let splitString = [label];
  if (label.includes('®')) {
    splitString = splitString.flatMap((item) => item.split(/(®)/g));
  }
  if (label.includes('©')) {
    splitString = splitString.flatMap((item) => item.split(/(©)/g));
  }
  if (label.includes('™')) {
    splitString = splitString.flatMap((item) => item.split(/(™)/g));
  }
  const charCollection = splitString
    .filter((label) => label.length !== 0)
    .map((label) => {
      if (containsSymbolCharacter(label)) {
        return {
          text: label,
          sup: true,
        };
      } else {
        return {
          text: label,
        };
      }
    });
  // @ts-ignore
  const room = event.room || event.locationShortName;
  if (room) {
    if (room.shortName && room.shortName !== ' - ') {
      charCollection.push({
        text: `, ${room.shortName}`,
      });
    } else if (!room.shortName && room !== ' - ') {
      charCollection.push({
        text: `, ${room}`,
      });
    }
  }
  return charCollection;
}
