import { Component, OnInit } from '@angular/core';
import { SiteService } from 'src/app/core/services/site.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss'],
})
export class SitesComponent implements OnInit {
  constructor(public siteService: SiteService) {}

  ngOnInit() {}
}
