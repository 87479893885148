import { Component, OnInit, Input } from '@angular/core';
import { Announcement } from '@models/announcement';

@Component({
  selector: 'app-display-announcement',
  templateUrl: './display-announcement.component.html',
  styleUrls: ['./display-announcement.component.scss'],
})
export class DisplayAnnouncementComponent implements OnInit {
  @Input() announcement: Announcement;
  @Input() showClock = false;

  constructor() {}

  ngOnInit() {}
}
