import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { BehaviorSubject, combineLatest, Subject } from 'rxjs';

import { Image } from '@models/image';
import { ImageService } from 'src/app/core/services/image.service';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import bootstrapTable from 'src/app/shared/pagination-server-side/bootstrap-table';
import {
  GenericBEDataSource,
  PaginationInformation,
} from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { SiteService } from 'src/app/core/services/site.service';
import { CategoryService } from 'src/app/core/services/category.service';

interface ImageRow {
  image: any;
  category: any;
  sites: any;
  updatedOn: any;
  actions: any;
}
@Component({
  selector: 'app-image-table',
  templateUrl: './image-table.component.html',
  styleUrls: ['./image-table.component.scss'],
})
export class ImageTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() set update(value) {
    if (value) {
      this.triggerDataRefresh();
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() deleteImage: Function;
  @Input() editImage: Function;
  @Input() set search(filterValue: string) {
    if (this.dataSource) {
      this.searchFilter$.next(filterValue);
    }
  }
  @Output() imageCount: EventEmitter<number> = new EventEmitter();

  public searchFilter$ = new BehaviorSubject('');
  public destroy$ = new Subject();
  private manualRefreshTrigger$ = new BehaviorSubject(0);
  public numImages;
  private categories;
  private sites;
  public dataSource: MatTableDataSource<ImageRow>;
  public paginatedDataSource: GenericBEDataSource<Image>;
  public displayedColumns = [
    'image',
    'category',
    'site',
    'updatedOn',
    'actions',
  ];

  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(this.searchFilter$.value),
    styleId: new UntypedFormControl(4), // StyleId 4 = Announcement, which signifies image is a 'background image'
    refreshTrigger: new UntypedFormControl(0),
  });

  constructor(
    public imageService: ImageService,
    public siteService: SiteService,
    public categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.categoryService.categories.subscribe(
      (categories) => (this.categories = categories),
    );
    this.siteService.sites.subscribe((sites) => (this.sites = sites));
    this.paginatedDataSource = new GenericBEDataSource<Image>(
      this.getBackgroundImagesPaginated.bind(this),
    );
  }

  private getBackgroundImagesPaginated(
    queryParams: HttpParams,
    pagination: PaginationInformation,
  ) {
    return this.imageService.getOrganizationBackgroundImagesPaginated(
      queryParams,
      pagination,
    );
  }

  ngAfterViewInit(): void {
    // Must wait until we have an active site to load this data
    this.siteService.site
      .pipe(
        filter((site) => site !== null),
        tap((site) => {
          bootstrapTable<Image[]>(
            this.sort,
            this.paginator,
            this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
            this.filters,
          )
            .pipe(takeUntil(this.destroy$))
            .subscribe();
          combineLatest([this.searchFilter$, this.manualRefreshTrigger$])
            .pipe(
              // check to see if search has a value and if refresh is truthy
              filter((val) => val[0].trim().length > 0 || !!val[1]),
              takeUntil(this.destroy$),
            )
            .subscribe(([search, refresh]) => {
              this.filters.controls['search'].setValue(search);
              this.filters.controls['refreshTrigger'].setValue(refresh);
            });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.searchFilter$.complete();
    this.destroy$.complete();
  }

  handleDeleteImage(image): void {
    this.deleteImage(image);
  }

  handleEditImage(image): void {
    this.editImage(image);
  }

  getCategoryName(image): string {
    if (!this.categories) {
      return '-';
    }

    const category = this.categories.find(
      (eachCategory) => eachCategory.id === Number(image.categoryId),
    );
    if (!category) {
      return '-';
    }

    const subcategory = category.subcategories.find(
      (eachSubCategory) => eachSubCategory.id === Number(image.subcategoryId),
    );
    if (!subcategory) {
      return '-';
    }

    return `${category.name} - ${subcategory.name}`;
  }

  getSiteNames(image) {
    if (!this.sites) {
      return '-';
    }
    if (!image.siteId) {
      return 'Everyone';
    }
    const subcategory = this.sites.find((site) => site.id === image.siteId);
    return subcategory.name;
  }

  public triggerDataRefresh() {
    this.manualRefreshTrigger$.next(this.manualRefreshTrigger$.value + 1);
  }
}
