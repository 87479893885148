<div class="flex justify-between items-start content-start">
  <h1 mat-dialog-title class="mat-dialog-title">Download Event Data</h1>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content [formGroup]="form" class="modal-content">
  <p>Choose a Date Range</p>
  <mat-form-field class="w-full" appearance="outline">
    <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
      <input
        matStartDate
        placeholder="Start date"
        formControlName="startDate"
      />
      <input matEndDate placeholder="End date" formControlName="endDate" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error
      *ngIf="
        form?.controls?.startDate.hasError('matStartDateInvalid') ||
        form?.controls?.startDate.hasError('required')
      "
      >Invalid start date</mat-error
    >
    <mat-error
      *ngIf="
        form?.controls?.endDate.hasError('matEndDateInvalid') ||
        form?.controls?.endDate.hasError('required')
      "
      >Invalid end date</mat-error
    >
  </mat-form-field>

  <div class="download-buttons">
    <app-loading-button
      [onClick]="downloadCSV"
      label="Download CSV"
      [loading]="csvLoading.value"
      color="primary"
      [disabled]="!form.valid"
    ></app-loading-button>
    <app-loading-button
      [onClick]="downloadPdf"
      label="Download PDF"
      [loading]="pdfLoading.value"
      color="accent"
      [disabled]="!form.valid"
    ></app-loading-button>
  </div>
</div>
