import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { EventSlide } from 'src/app/core/interfaces/api';
import { AlertService } from 'src/app/core/services/alert.service';
import { EventSlideService } from 'src/app/core/services/event-slide.service';
import { SiteService } from 'src/app/core/services/site.service';

@Component({
  selector: 'app-manage-event-slide-modal',
  templateUrl: './manage-event-slide-modal.component.html',
  styleUrls: ['./manage-event-slide-modal.component.scss'],
})
export class ManageEventSlideModalComponent implements OnInit {
  destroy$ = new Subject<void>();
  showEventSlide = new FormControl(false);
  siteId: number;
  form = this.eventSlideForm();
  loading = false;
  eventSlide: EventSlide;

  constructor(
    private siteService: SiteService,
    private materialDialogRef: MatDialogRef<ManageEventSlideModalComponent>,
    private alertService: AlertService,
    private eventSlideService: EventSlideService,
    @Inject(MAT_DIALOG_DATA) public data: { siteId: number },
  ) {
    this.siteId = data.siteId;
  }

  ngOnInit(): void {
    this.siteService.getSite(this.siteId).subscribe((site) => {
      if (site) {
        this.showEventSlide.setValue(site.showEventSlide);
      }
    });
    this.eventSlideService
      .getEventSlideBySiteId(this.siteId)
      .subscribe((eventSlide) => {
        if (eventSlide) {
          this.eventSlide = eventSlide;
          this.form = this.eventSlideForm(eventSlide);
        }
      });
  }

  eventSlideForm(eventSlide?: EventSlide) {
    return new FormGroup({
      displayDuration: new FormControl(eventSlide?.displayDuration || 15),
    });
  }

  async saveEventSlide() {
    this.loading = true;
    const currentSite = this.siteService.currentSite;
    const { displayDuration } = this.form.value;

    try {
      await this.siteService.updateSite(currentSite.id, {
        showEventSlide: this.showEventSlide.value,
      });

      const data = {
        displayDuration,
      };

      if (this.eventSlide) {
        await this.eventSlideService.updateEventSlide(this.eventSlide.id, data);
      } else {
        await this.eventSlideService.createEventSlide({
          siteId: this.siteId,
          ...data,
        });
      }
      this.materialDialogRef.close();
    } catch (error) {
      this.alertService.error(error.message || 'Failed to save event slide');
    } finally {
      this.loading = false;
    }
  }
}
