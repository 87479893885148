<mat-card appearance="outlined" *ngIf="newsletter" class="newsletter-form">
  <mat-card-header class="flex justify-between items-start content-start">
    <mat-card-subtitle> Title </mat-card-subtitle>
    <mat-card-title class="flex justify-start items-start content-start">
      <span>{{ newsletter.name }}</span>
      <button
        mat-icon-button
        color="accent"
        (click)="editName()"
        matTooltip="Edit Newsletter Name"
        [disabled]="checkNewsLetterStatus()"
      >
        <mat-icon>edit_pencil</mat-icon>
      </button>
      <button
        *ngIf="authService?.currentUser.isOrgAdmin"
        mat-icon-button
        color="accent"
        (click)="deleteNewsletter()"
        matTooltip="Delete Newsletter"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <button
        *ngIf="!filesProcessingWithImages; else loading"
        mat-icon-button
        color="accent"
        (click)="exportNewsletterFiles(true)"
        matTooltip="Export All Files"
        [disabled]="filesProcessingWithoutImages"
      >
        <mat-icon>send</mat-icon>
      </button>
      <button
        *ngIf="!filesProcessingWithoutImages; else loading"
        mat-icon-button
        color="accent"
        (click)="exportNewsletterFiles(false)"
        matTooltip="Export Text Only"
        [disabled]="filesProcessingWithImages"
      >
        <mat-icon>text_snippet</mat-icon>
      </button>
      <ng-template #loading>
        <button mat-icon-button [disabled]="true" class="spinner">
          <mat-spinner color="accent" diameter="16"></mat-spinner>
        </button>
      </ng-template>
    </mat-card-title>
    <!-- Actions -->
    <div
      class="flex justify-end items-center content-center gap-4"
      [matTooltip]="
        !newsletter.validForDleToSubmit
          ? 'Cannot submit with invalid Article(s).'
          : ''
      "
    >
      <button
        mat-raised-button
        color="accent"
        *ngIf="!authService.currentUser?.isOrgAdmin"
        [disabled]="!dleAction?.enabled || !newsletter.validForDleToSubmit"
        (click)="
          dleAction?.enabled
            ? modifyNewsletterStatus(
                dleAction.newStatus,
                dleAction.confirmationMessage
              )
            : ''
        "
      >
        {{ dleAction?.action }}
      </button>
    </div>
  </mat-card-header>

  <div class="flex justify-between items-start content-start">
    <!-- Back Button -->
    <!-- Commenting out as not in latest designs -->
    <!-- <button mat-icon-button color="accent" (click)="back()" matTooltip="Back to All Newsletters">
      <mat-icon>arrow_back</mat-icon>
    </button> -->

    <!-- Date Created -->
    <div>
      <p class="overview-label">Created</p>
      <p class="overview-info">
        {{ newsletter.createdOn | date : 'shortDate' }}
      </p>
    </div>

    <!-- Submission Date -->
    <div>
      <p class="overview-label">Submission Due</p>
      <p
        class="overview-info"
        [class.editable-field]="authService.currentUser?.isOrgAdmin"
        (click)="
          authService.currentUser?.isOrgAdmin ? editSubmissionDate() : ''
        "
      >
        {{ newsletter.submissionDueOn | date : 'shortDate' }}
      </p>
    </div>

    <!-- Article Count -->
    <div>
      <p class="overview-label">Articles</p>
      <!-- Only editable if in draft mode or is org admin -->
      <p
        *ngIf="authService.currentUser.isOrgAdmin || newsletter.isDraft"
        (click)="editArticleCount()"
        class="overview-info editable-field"
      >
        {{ newsletter.articleCount }}
      </p>

      <!-- If not editable -->
      <p
        *ngIf="!authService.currentUser.isOrgAdmin && !newsletter.isDraft"
        class="overview-info"
      >
        {{ newsletter.articleCount }}
      </p>
    </div>

    <!-- Print Quantity -->
    <div>
      <p class="overview-label">Print Quantity</p>
      <p
        class="overview-info"
        [class.editable-field]="authService.currentUser?.isOrgAdmin"
        (click)="authService.currentUser?.isOrgAdmin ? editPrintQuantity() : ''"
      >
        {{ newsletter.printQuantity | number }}
      </p>
    </div>

    <!-- Newsletter Status; only orgAdmin can edit -->
    <div
      (click)="authService.currentUser?.isOrgAdmin ? openStatusModal() : ''"
      class="center"
    >
      <p class="overview-label">Status</p>
      <p
        [class.editable-field]="authService.currentUser?.isOrgAdmin"
        class="overview-info"
      >
        {{ newsletter.status | enum : 'newsletterStatus' }}
      </p>

      <!-- Status indicator via circles filled/unfiilled -->
      <div class="flex justify-center items-center content-center gap-[10px]">
        <div
          class="status-step"
          *ngFor="let step of newsletterStatusKeys; let i = index"
          [class.completed-step]="newsletter.statusIndex >= i"
        ></div>
      </div>
    </div>
  </div>
</mat-card>
