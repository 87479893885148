import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { CategoryService } from 'src/app/core/services/category.service';
import { SubcategoryService } from 'src/app/core/services/subcategory.service';
import { Image } from '@models/image';
import { ImageService } from 'src/app/core/services/image.service';
import { AnnouncementDto } from '../../../../../../core/interfaces/announcement';
import { DateTime } from 'luxon';
import { MultiDatePickerComponent } from 'src/app/shared/multi-date-picker/multi-date-picker.component';
import { activityTimeOffset } from '../activity-time-offset';
import { defaultEventKey } from '../../../../../../core/enums/event-type';

export interface BatchEvents {
  form: UntypedFormGroup;
  dates: DateTime[];
}
@Component({
  selector: 'app-batch-event-form',
  templateUrl: './batch-event-form.component.html',
  styleUrls: ['./batch-event-form.component.scss'],
})
export class BatchEventFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  @Input() public editing: boolean;
  @Input() public eventToEdit: AnnouncementDto;
  @Input() public isLoading: boolean;
  @Input() public selectedMonth: DateTime;
  @Output() public saveForm: EventEmitter<BatchEvents> = new EventEmitter();
  @Output() public hideForm: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(MultiDatePickerComponent) multiDatePickerComponent;
  public selectedDates: DateTime[] = [];
  private savedStartTime: string;
  public placeHolderImageUrl: string = '';
  public categoryIcon: string = '';

  static formModel(value?) {
    return new UntypedFormGroup(
      {
        title: new UntypedFormControl((value && value.title) || null, [
          Validators.required,
        ]),
        content: new UntypedFormControl((value && value.content) || null),
        eventStart: new UntypedFormControl(
          value && value.eventStart
            ? DateTime.fromISO(value.eventStart).format('HH:mm')
            : null,
          [Validators.required],
        ),
        eventEnd: new UntypedFormControl(
          value && value.eventEnd
            ? DateTime.fromISO(value.eventEnd).format('HH:mm')
            : null,
          [Validators.required],
        ),
        room: new UntypedFormControl((value && value.room) || null, [
          Validators.required,
        ]),
        location: new UntypedFormControl((value && value.location) || null),
        subcategory: new UntypedFormControl(
          (value && value.subcategory) || null,
          [Validators.required],
        ),
        eventType: new UntypedFormControl(
          (value && value.eventType) || defaultEventKey,
        ),
        background: new UntypedFormControl((value && value.background) || null),
        category: new UntypedFormControl((value && value.category) || null),
        eventTags: new UntypedFormControl((value && value.eventTags) || []),
        videoUrl: new UntypedFormControl((value && value.videoUrl) || null),
      },
      {},
    );
  }

  constructor(
    public categoryService: CategoryService,
    public subcategoryService: SubcategoryService,
    public imageService: ImageService,
  ) {}

  ngOnInit() {
    if (this.eventToEdit) {
      this.form = BatchEventFormComponent.formModel(this.eventToEdit);
    } else {
      this.form = BatchEventFormComponent.formModel();
    }

    this.form.controls.subcategory.updateValueAndValidity({ onlySelf: true });
  }

  async onSubcatSelect(subcategory: { name: string; id: number }) {
    if (subcategory) {
      // make sure we have categories
      if (this.categoryService.currentCategories.length === 0) {
        await this.categoryService.refreshCategories();
      }
      const cat = this.categoryService.getCategoryForSubcategory(
        subcategory.id,
      );
      const backgroundImage: Image =
        await this.imageService.getBackgroundForSubcategory(subcategory.id);

      if (this.containsSymbolCharacter(subcategory.name)) {
        this.form.patchValue({
          subcategory: subcategory.id,
          category: cat,
          background: backgroundImage,
          eventTags: cat.tag ? [cat.tag] : [],
          title: subcategory.name,
        });
      } else {
        this.form.patchValue({
          subcategory: subcategory.id,
          category: cat,
          background: backgroundImage,
          eventTags: cat.tag ? [cat.tag] : [],
        });
      }
      this.placeHolderImageUrl = backgroundImage.downloadUrl;
      this.categoryIcon = cat.tag.icon;
    } else {
      // resets subcategory related values
      this.form.patchValue({
        subcategory: null,
        category: null,
        background: null,
        eventTags: [],
      });
      this.placeHolderImageUrl = '';
      this.categoryIcon = '';
    }
  }

  private containsSymbolCharacter(text: string): boolean {
    let containsSymbol = false;
    if (text.includes('®')) {
      containsSymbol = true;
    }
    if (text.includes('™')) {
      containsSymbol = true;
    }
    return containsSymbol;
  }

  public startTimeBlur() {
    const newEndTime = activityTimeOffset(
      this.form.controls.eventStart.value,
      this.form.controls.eventEnd.value,
      this.savedStartTime,
    );
    this.form.controls.eventEnd.setValue(newEndTime);
  }

  public startTimeFocus() {
    this.savedStartTime = this.form.controls.eventStart.value;
  }

  public clear() {
    this.form = BatchEventFormComponent.formModel();
    this.multiDatePickerComponent.clearSelection();
  }

  public onDatesSelectionChange(dates: DateTime[]): void {
    this.selectedDates = dates;
  }

  public save() {
    const batch: BatchEvents = {
      form: this.form,
      dates: this.selectedDates,
    };
    this.saveForm.emit(batch);
  }

  public triggerHideForm() {
    this.hideForm.emit(true);
  }
}
