import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Announcement } from '@models/announcement';
import { Image } from '@models/image';

@Component({
  selector: 'app-display-collage',
  templateUrl: './display-collage.component.html',
  styleUrls: ['./display-collage.component.scss'],
})
export class DisplayCollageComponent implements OnChanges {
  @Input() announcement: Announcement;
  @Input() showClock = false;
  public numberOfImages: number = 1;
  public collageImages: Image[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const images = changes.announcement?.currentValue?.secondaryImages;
    this.numberOfImages = this.getNumberOfImages(changes) || images?.length;
    this.collageImages = this.getSortedImages(images);
  }

  getNumberOfImages(changes: SimpleChanges): number {
    const newValue = changes.announcement.currentValue;
    const previousValue = changes.announcement.previousValue;
    if (!previousValue) {
      const num = newValue.secondaryImages?.length
        ? newValue.secondaryImages.length
        : newValue.numberOfImages;
      return num;
    } else if (newValue && previousValue) {
      const numberOfImagesHasChanged =
        newValue.numberOfImages !== previousValue.numberOfImages;
      const numberOfUploadedImagesHasChanged =
        newValue.secondaryImages?.length !==
        previousValue.secondaryImages?.length;
      if (numberOfImagesHasChanged || numberOfUploadedImagesHasChanged) {
        return newValue.numberOfImages;
      }
    }
    return newValue.secondaryImages?.length || 0;
  }

  getSortedImages(unsortedImages: Image[] = []): Image[] {
    const sortedImages = [];
    for (let i = 1; i < 5; i++) {
      const imageForPosition = unsortedImages.find(
        (image: Image) => image.position === i,
      );
      sortedImages.push(imageForPosition ? imageForPosition : null);
    }
    return sortedImages;
  }
}
