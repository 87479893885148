<mat-card appearance="outlined" color="primary">
  <mat-card-header class="flex justify-between items-center content-center">
    <mat-card-title color="primary" class="flex">
      Properties ({{ paginatedDataSource?.totalCount }})
    </mat-card-title>
    <div class="flex justify-end items-center content-center w-3/4 gap-[15px]">
      <button mat-raised-button color="accent" (click)="addSite()">
        Add New Property
      </button>

      <mat-form-field color="accent">
        <input matInput placeholder="Search" [(ngModel)]="filter" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </mat-card-header>
  <mat-card-content class="flex flex-col">
    <div class="loading-wrapper">
      <div class="loading-spinner" *ngIf="paginatedDataSource.loading$ | async">
        <app-material-loader [fillContainer]="true"></app-material-loader>
      </div>
      <div
        class="loading-overlay"
        *ngIf="paginatedDataSource.loading$ | async"
      ></div>
      <table
        mat-table
        matSort
        matSortActive="name"
        matSortDirection="asc"
        [dataSource]="paginatedDataSource"
      >
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9999">
            <i>{{
              this.filters.controls.search?.value?.length > 0
                ? 'No sites match this search.'
                : (paginatedDataSource.loading$ | async)
                ? ''
                : 'No sites available.'
            }}</i>
          </td>
        </tr>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            ID
          </th>
          <td mat-cell *matCellDef="let site">{{ site.id }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Name
          </th>
          <td mat-cell *matCellDef="let site">{{ site.name }}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            State
          </th>
          <td mat-cell *matCellDef="let site">{{ site.state }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Type
          </th>
          <td mat-cell *matCellDef="let site">
            {{ site.siteType?.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="activeForMobile">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Mobile Enabled
          </th>
          <td mat-cell *matCellDef="let site">
            {{ site.activeForMobile | bool }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let site">
            <div>
              <a
                mat-icon-button
                color="accent"
                target="_blank"
                href="/display/{{ site.id }}"
                matTooltip="View Property's Live Digital Signage"
              >
                <mat-icon>slideshow</mat-icon>
              </a>
              <button
                mat-icon-button
                color="accent"
                (click)="editSite(site)"
                matTooltip="Edit Property Details"
              >
                <mat-icon>edit_pencil</mat-icon>
              </button>
              <button
                mat-icon-button
                color="accent"
                matTooltip="Manage Content"
                [routerLink]="['/manage/content/' + site.id]"
              >
                <mat-icon>panorama</mat-icon>
              </button>
              <button
                mat-icon-button
                color="accent"
                (click)="deleteSite(site)"
                matTooltip="Delete Property"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [length]="paginatedDataSource?.totalCount"
        [pageSize]="10"
        [pageSizeOptions]="[10, 20, 30, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
