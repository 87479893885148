import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ImageDto } from '@interfaces/image';
import {
  Article,
  characterCountLeeway,
  characterCountPerImage,
} from '@models/article';
import { Newsletter } from '@models/newsletter';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ImageService } from 'src/app/core/services/image.service';
import { NewsletterService } from 'src/app/core/services/newsletter.service';
import {
  ImageUploadComponent,
  UploadResponse,
} from 'src/app/shared/image-upload/image-upload.component';
import {
  SingleItemFormModalComponent,
  SingleItemFormModalConfig,
} from 'src/app/shared/modals/single-item-form-modal/single-item-form-modal.component';
import { NewsletterStatus } from '../../../../../../core/enums/newsletter-status';
@Component({
  providers: [ImageUploadComponent],
  selector: 'app-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.scss'],
})
export class EditArticleComponent implements OnInit {
  @Input() existingArticle: Article;
  @Input() newsletter: Newsletter;
  @Input() frontPageLimitReached: boolean = false;
  @Input() siteId: number;
  @Output() public hide: EventEmitter<boolean> = new EventEmitter();
  @Output() public updated: EventEmitter<boolean> = new EventEmitter();
  @Output() public hasChanges: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  public articleImageCaptionMaxLength = 75;
  public customImages = false; // toggle show/hide custom image workflow
  public form: UntypedFormGroup;
  public images: Partial<ImageDto>[] = [];
  public deletedImages: Partial<ImageDto>[] = [];
  public submitting = false;
  public characterCountPerImage = characterCountPerImage;
  public characterCountLeeway = characterCountLeeway;
  public destroy$: Subject<Boolean> = new Subject();

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private dialog: MatDialog,
    public imageService: ImageService,
    private imageUploadComponent: ImageUploadComponent,
    private newsletterService: NewsletterService,
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      content: new UntypedFormControl(
        this.existingArticle ? this.existingArticle.content : null,
      ),
      customImages: new UntypedFormControl(
        this.existingArticle ? !this.existingArticle.customImages : false,
        Validators.required,
      ),
      headline: new UntypedFormControl(
        this.existingArticle
          ? this.existingArticle.optionalArticle(
              this.existingArticle.articleType.name,
            )
            ? this.existingArticle.articleType.name
            : this.existingArticle.headline
          : null,
      ),
      frontPage: new UntypedFormControl(
        this.existingArticle ? this.existingArticle.frontPage : false,
        Validators.required,
      ),
    });

    this.images = this.existingArticle?.image
      ? [...this.existingArticle?.image]
      : [];
    // Disable all form elements here if uneditable
    // Note the buttons are disabled in the template based on this.disableEditing, too
    if (this.disableEditing) {
      this.form.disable();
    }

    this.form.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value) => {
        if (
          (this.existingArticle.headline === null &&
            value.headline?.trim().length === 0) ||
          (this.existingArticle.content === null &&
            value.content?.trim().length === 0)
        ) {
          this.hasChanges.emit(false);
        } else if (
          this.existingArticle &&
          (this.existingArticle.headline?.trim() !== value.headline?.trim() ||
            this.existingArticle.content?.trim()! == value.content?.trim() ||
            this.existingArticle.customImages !== value.customImages)
        ) {
          this.hasChanges.emit(true);
        } else {
          this.hasChanges.emit(false);
        }
      });
    if ((this.newsletter.status as string) === 'approved') {
      this.form.get('headline').disable();
      this.form.get('content').disable();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  public get charactersWritten(): number {
    const contentLength = this.form.controls['content'].value?.length || 0;
    const imageLength = !this.form.controls['customImages'].value
      ? this.characterCountPerImage * this.images.length
      : this.characterCountPerImage;
    return contentLength + imageLength;
  }

  public async addImage(): Promise<any> {
    const imageUploadDialogRef = this.imageUploadComponent.editNewsletterAsset(
      this.siteId,
      this.newsletter.id,
      'Article Image',
      'Image',
      ['image/jpeg', 'image/png'],
      this.articleImageCaptionMaxLength,
    );
    imageUploadDialogRef
      .afterClosed()
      .subscribe(async (result: UploadResponse) => {
        if (result.success) {
          const path = result.path;
          const caption = result.imageCaption;
          const downloadUrl = await this.imageService.getDownloadUrl(path);
          this.images.push({
            caption,
            path: `${this.imageService.getNewsletterAssetFilePath(
              this.siteId,
              this.newsletter.id,
            )}/${result.fileName}`,
            downloadUrl,
          });
          this.hasChanges.next(true);
        }
      });
  }

  /**
   * If the image already exists in the database (via having an id):
   *  1. mark as deleted
   *  2. add to deletedImages array (we need to send the updated image record
   *     to API for persistence once user saves)
   * Either way, remove it from the local array of images to update the UI
   */
  public deleteImage(image: Partial<ImageDto>, index: number): void {
    if (image && image.id) {
      image.deleted = true;
      this.deletedImages.push(image);
    }

    this.images.splice(index, 1);
  }

  public saveArticle(hideEdit: boolean = true, customToast?: string) {
    return new Promise((resolve, reject) => {
      const article = this.form.value;
      article.newsletter = this.newsletter.id;
      article.id = this.existingArticle ? this.existingArticle.id : null;
      // Merge active and deleted images; they all need to be persisted
      article.images = this.images.concat(this.deletedImages);
      // The checkbox is presented as the inverse to the way the data is persisted, so need to flip boolean value
      article.customImages = !article.customImages;
      this.submitting = true;
      this.newsletterService
        .saveArticle(article)
        .then(async (savedArticle) => {
          this.submitting = false;
          this.updated.next(true);
          if (hideEdit) {
            this.triggerHideComponent();
          }
          resolve(savedArticle);
          this.alertService.success(customToast || 'Article saved!');
        })
        .catch((error) => {
          console.error(error);
          this.submitting = false;
          reject(error);
        });
    });
  }

  public triggerHideComponent(): void {
    this.hide.next(true);
    // Scroll to #article-table
    setTimeout(
      () => document.getElementById('article-table').scrollIntoView(),
      0,
    );
  }

  public get isBackpageArticle(): boolean {
    return !!this.existingArticle?.isBackpage;
  }

  public get disableFrontPageToggle(): boolean {
    return (
      this.isBackpageArticle ||
      (this.frontPageLimitReached && !this.existingArticle?.frontPage)
    );
  }

  public backgroundImgUrl(url): string {
    return `background-image: url('${url}') !important`;
  }

  /**
   * Disable form controls if DLE and the Newsletter is past Draft stage.
   */
  public get disableEditing(): boolean {
    return this.authService.currentUser?.isOrgAdmin
      ? false
      : !this.newsletter.dleCanEdit;
  }

  public downloadImage(image: Partial<ImageDto>): void {
    // https://stackoverflow.com/questions/17527713/force-browser-to-download-image-files-on-click
    const xhr = new XMLHttpRequest();
    xhr.open('GET', image.downloadUrl, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(xhr.response);
      const tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = this.imageService.getImageName(image);
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  public editImageCaption(image: Partial<ImageDto>): void {
    const config: SingleItemFormModalConfig = {
      name: 'Edit Image Caption',
      value: image.caption || '',
      inputType: 'input',
      inputItemType: 'text',
      label: 'Caption',
      required: false,
      maxLength: this.articleImageCaptionMaxLength,
      onConfirm: async (caption: string) => {
        image.caption = caption;
        await this.saveArticle(false, 'Caption updated!');
      },
      onError: (error) => {
        this.alertService.error('Unable to update caption. Please try again.');
        console.error(error);
      },
    };

    const dialogRef = this.dialog.open(SingleItemFormModalComponent, {
      data: config,
    });
  }
}
