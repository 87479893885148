<p class="subtitle">Enter your email below to receive a reset password link.</p>
<form
  [formGroup]="form"
  (submit)="resetPassword()"
  class="min-w-full w-full h-full min-h-full m-0"
>
  <mat-form-field
    class="min-w-full w-full h-full min-h-full m-0"
    appearance="outline"
    placeholder="Your email"
    color="accent"
  >
    <mat-label color="accent">Email</mat-label>
    <input
      autofocus
      matInput
      required
      formControlName="email"
      type="email"
      data-qa="reset-password-input-email"
    />
    <mat-error
      *ngIf="
        form.controls['email'].hasError('email') &&
        form.controls['email'].touched
      "
    >
      Incorrect email format.
    </mat-error>
    <mat-error
      *ngIf="
        form.controls['email'].hasError('required') &&
        form.controls['email'].touched
      "
    >
      Email required.
    </mat-error>
  </mat-form-field>
  <button
    mat-raised-button
    color="accent"
    [ngClass]="{ 'is-loading': loading }"
    [disabled]="!form.valid"
    data-qa="reset-password-button-submit"
  >
    Reset Password
  </button>
</form>
<mat-nav-list>
  <mat-list-item
    routerLinkActive="active"
    [routerLink]="['']"
    data-qa="reset-password-navigate-login"
  >
    <a matLine>Back to Login</a>
  </mat-list-item>
</mat-nav-list>
