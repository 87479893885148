<div class="flex">
  <mat-form-field appearance="outline">
    <mat-label color="accent">Event Type</mat-label>
    <mat-select
      [(value)]="selectedType"
      (selectionChange)="eventTypeChanged($event)"
      data-qa="event-select-type"
    >
      <mat-option
        *ngFor="let eventTypeKey of eventTypeKeys"
        [value]="eventTypeKey"
        data-qa="event-select-type-option"
      >
        {{ eventTypes[eventTypeKey] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-hint class="hint"
    >Impact on print calendar:<br />Regular - no special styling<br />Special
    Event - bold</mat-hint
  >
</div>
