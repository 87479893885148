import { Component, OnInit } from '@angular/core';
import { RoomFormComponent } from 'src/app/shared/forms/room-form/room-form.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { MatDialogRef } from '@angular/material/dialog';
import { EditRoomComponent } from '../edit-room/edit-room.component';
import { RoomService } from 'src/app/core/services/room.service';
import { SiteService } from 'src/app/core/services/site.service';

@Component({
  selector: 'app-add-room',
  templateUrl: './add-room.component.html',
  styleUrls: ['./add-room.component.scss'],
})
export class AddRoomComponent implements OnInit {
  public roomForm = RoomFormComponent.formModel();
  public submitting = false;

  constructor(
    private alertService: AlertService,
    private dialogRef: MatDialogRef<EditRoomComponent>,
    private roomService: RoomService,
    private siteService: SiteService
  ) {}

  ngOnInit() {}

  public async onSave(): Promise<void> {
    this.submitting = true;
    const r = RoomFormComponent.serialize(this.roomForm.value, {
      siteId: this.siteService.currentSiteId,
    });

    this.roomService
      .updateRoom(r)
      .then(() => {
        this.alertService.success('Room saved.');
        this.roomService.refreshRooms();
        this.onCancel();
      })
      .catch((error) => {
        this.alertService.error('Unable to save room. Please try again.');
        console.error(error);
      })
      .finally(() => (this.submitting = false));
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
