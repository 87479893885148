<table
  mat-table
  matSort
  matSortDirection="asc"
  [dataSource]="paginatedDataSource"
>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      Article Type
    </th>
    <td mat-cell *matCellDef="let article">
      <div class="flex justify-start items-center content-center">
        {{ article.articleType?.name }}
        <mat-icon
          color="warn"
          *ngIf="!article.articleValid(newsletter.articleCount)"
          >warning</mat-icon
        >
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="requiredType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      Required
    </th>
    <td mat-cell *matCellDef="let article">
      {{ article.articleType?.required | bool | titlecase }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="headline">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      Headline
    </th>
    <td mat-cell *matCellDef="let article" [class.italic]="!article.headline">
      {{ article.headline || article.headlineNullText }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="characterCount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      Characters
    </th>
    <td mat-cell *matCellDef="let article">
      <span *ngIf="article.isBackpage">{{
        article.characterCount | number
      }}</span>
      <span *ngIf="!article.isBackpage">
        <span
          [class.newsletter-warning]="
            !article.characterCountValid(newsletter.articleCount)
          "
          >{{ article.characterCount | number }}</span
        >/{{ article.idealArticleLength(newsletter.articleCount) | number }}
      </span>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      Total: {{ newsletter.characterCount }}
    </td>
  </ng-container>

  <ng-container matColumnDef="imageCount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      Images
    </th>
    <td
      mat-cell
      *matCellDef="let article"
      [class.italic]="article.imageCountSummary === naCopy"
    >
      {{ article.imageCountSummary }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <!-- Commenting out per 3/11/21 client feedback; Kedar requested we keep the code in case they change their mind -->
  <!-- <ng-container matColumnDef="frontPage">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Front Page</th>
    <td
      mat-cell
      *matCellDef="let article"
      [class.front-page-button-cont]="!article.isBackpage"
      [class.actions]="!article.isBackpage"
      [matTooltip]="!article.articleType.backpage && !article.frontPage && newsletter.frontPageLimitReached ? 'Only two Articles can be Front Page.' : ''">
      <button
        mat-icon-button
        color="accent"
        (click)="toggleArticleFrontPage(article)"
        [matTooltip]="article.frontPage ? 'Remove Article as Front Page.' : newsletter.frontPageLimitReached ? '' : 'Set Article as Front Page.'"
        [disabled]="!newsletter.isDraft || (!article.frontPage && newsletter.frontPageLimitReached)"
        [class.add]="!article.frontPage"
        *ngIf="!article.isBackpage"
      >
        <mat-icon>done</mat-icon>
      </button>
      <span *ngIf="article.isBackpage" class="italic">{{naCopy}}</span>
    </td>
  </ng-container> -->

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
      <span class="sticky-heading">Actions</span>
    </th>
    <td mat-cell *matCellDef="let article">
      <div>
        <!-- Show View action if DLE and past Draft stage -->
        <button
          *ngIf="!authService.currentUser.isOrgAdmin && !newsletter.isDraft"
          mat-icon-button
          color="accent"
          (click)="editArticle(article)"
          matTooltip="View Article"
        >
          <mat-icon>visibility</mat-icon>
        </button>

        <!-- Show Edit action if Org Admin or in Draft stage -->
        <button
          *ngIf="authService.currentUser.isOrgAdmin || newsletter.isDraft"
          mat-icon-button
          color="accent"
          (click)="editArticle(article)"
          [matTooltip]="
            checkNewsLetterStatus() ? 'View Article' : 'Edit Article'
          "
        >
          <mat-icon>{{
            checkNewsLetterStatus() ? 'visibility' : 'edit_pencil'
          }}</mat-icon>
        </button>
        <!-- Removing for now as Article are generated programatically (so no need to delete) -->
        <!-- <button mat-icon-button color="accent" (click)="deleteArticle(article)" matTooltip="Delete Article" [disabled]="!newsletter.isDraft">
          <mat-icon>delete</mat-icon>
        </button> -->
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
<mat-paginator
  [length]="paginatedDataSource?.totalCount"
  [pageSize]="15"
  [pageSizeOptions]="[15, 30, 45, 60]"
  showFirstLastButtons
></mat-paginator>
