import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SingleItemFormModalConfig {
  name: string;
  value: any;
  inputType: 'textarea' | 'select' | 'input' | 'datepicker';
  required: boolean;
  label?: string;
  inputItemType?: 'text' | 'number';
  enumInput?: any;
  step?: number;
  enumKeys?: string[];
  keyValuePairs?: { key: any; value: any }[];
  onConfirm?: Function;
  onError?: Function;
  maxLength?: number;
}

@Component({
  selector: 'app-single-item-form-modal',
  templateUrl: './single-item-form-modal.component.html',
  styleUrls: ['./single-item-form-modal.component.scss'],
})
export class SingleItemFormModalComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitting = false;

  constructor(
    private dialogRef: MatDialogRef<SingleItemFormModalComponent>,
    @Inject(MAT_DIALOG_DATA) public config: SingleItemFormModalConfig
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      item: new UntypedFormControl(
        this.config.value,
        this.config.required ? Validators.required : null
      ),
    });
  }

  public async save() {
    const updatedFormVal = this.form.value.item;
    this.submitting = true;
    // check for callback to run before closing the dialog
    if (this.config.onConfirm) {
      this.submitting = true;
      try {
        await this.config.onConfirm(updatedFormVal);
      } catch (error) {
        // check for callback for error handling
        if (this.config.onError) {
          this.config.onError(error);
          this.submitting = false;
          return;
        } else {
          console.error(error);
        }
      }
    }
    this.dialogRef.close(updatedFormVal);
  }
}
