<div [formGroup]="form" class="flex flex-col gap-12">
  <div class="flex flex-col gap-6">
    <mat-form-field appearance="outline" color="accent">
      <mat-label>{{ emailLabel }}</mat-label>
      <input
        matInput
        autofocus
        required
        formControlName="email"
        [placeholder]="initialEmail || 'email'"
      />
      <mat-error
        *ngIf="
          form.controls['email'].hasError('email') &&
          form.controls['email'].touched
        "
      >
        Please enter a valid email address
      </mat-error>
    </mat-form-field>
  </div>
</div>
