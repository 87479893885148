import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { redirectUnauthorizedTo, AuthGuard } from '@angular/fire/auth-guard';

import { OrganizationGuard } from './core/guards/organization.guard';
import { PropertyGuard } from './core/guards/property.guard';
import { environment } from 'src/environments/environment';
import { RedirectGuard } from './core/guards/redirect.guard';

const demoUrl = '/display/4';

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['session/sign-in']);

const routes: Routes = [
  {
    path: 'session',
    loadChildren: () =>
      import('./session/session.module').then((m) => m.SessionModule),
    canActivate: [RedirectGuard],
  },
  {
    path: 'display/:propertyId',
    loadChildren: () =>
      import('./display/display.module').then((m) => m.DisplayModule),
    canActivate: [RedirectGuard],
  },
  {
    path: 'manage',
    component: DashboardComponent,
    canActivate: [AuthGuard, RedirectGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: 'org/:orgId',
        loadChildren: () =>
          import('./manage-org/manage-org.module').then(
            (m) => m.ManageOrgModule
          ),
        canActivate: [OrganizationGuard],
      },
      {
        path: 'content/:siteId',
        loadChildren: () =>
          import('./manage/manage.module').then((m) => m.ManageModule),
        canActivate: [PropertyGuard],
      },
      {
        // On /manage directly, redirect to the content route to trigger PropertygGuard
        // which will redirect to the user.defaultSiteId since 0 will fail the check
        path: '',
        redirectTo: 'content/0',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'demo',
    redirectTo: demoUrl,
  },
  {
    path: 'display',
    redirectTo: demoUrl,
  },
  {
    path: '',
    redirectTo: environment.cms ? 'manage' : demoUrl,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
