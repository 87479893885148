<h1 mat-dialog-title>
  Week-At-A-Glance Menu
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h1>

<div mat-dialog-content>
  <p>Select which week to download.</p>
</div>

<mat-dialog-actions class="flex justify-center items-end content-end gap-2">
  <button
    mat-raised-button
    [disabled]="!canPrintThisWeek"
    color="accent"
    class="mt-12"
    (click)="onSelect(0)"
  >
    Current Week
  </button>
  <button
    mat-raised-button
    [disabled]="!canPrintNextWeek"
    color="accent"
    class="mt-12"
    (click)="onSelect(1)"
  >
    Next Week
  </button>
</mat-dialog-actions>
