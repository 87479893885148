<div class="container">
  <div class="content flex-column justify-start">
    <mat-nav-list>
      <div class="flex items-center justify-between">
        <h3 class="text-sm">MANAGE PROPERTY</h3>
        <button matTooltip="Close sidebar" (click)="closeDashboard.emit()">
          <mat-icon class="text-white">chevron_left</mat-icon>
        </button>
      </div>
      <mat-list-item
        data-qa="navigate-property-event-page"
        routerLinkActive="active"
        [routerLink]="['content', siteId, 'activities']"
        *ngIf="site?.activitiesEnabled"
      >
        <mat-icon matListItemIcon>calendar_today</mat-icon>
        <a matListItemTitle routerLinkActive="active">Event Calendar</a>
      </mat-list-item>
      <mat-list-item
        routerLinkActive="active"
        [routerLink]="['content', siteId, 'announcements']"
        data-qa="navigate-property-digital-signage"
      >
        <mat-icon matListItemIcon>tv</mat-icon>
        <a matListItemTitle>Digital Signage</a>
      </mat-list-item>
      <mat-list-item
        routerLinkActive="active"
        [routerLink]="['content', siteId, 'newsletters']"
        data-qa="navigate-property-newsletters"
      >
        <mat-icon
          matListItemIcon
          [matBadge]="
            newsletterService.siteNewslettersNotificationCount | async
          "
          [matBadgeHidden]="
            !(newsletterService.siteNewslettersNotificationCount | async)
          "
        >
          email
        </mat-icon>
        <a matListItemTitle>Newsletters</a>
      </mat-list-item>
      <mat-list-item
        routerLinkActive="active"
        [routerLink]="['content', siteId, 'notifications']"
        *ngIf="site?.notificationsEnabled"
        data-qa="navigate-property-community-updates"
      >
        <mat-icon matListItemIcon>notifications</mat-icon>
        <a matListItemTitle routerLinkActive="active">Community Updates</a>
      </mat-list-item>
      <mat-list-item
        *ngIf="site?.activeForMobile"
        routerLinkActive="active"
        [routerLink]="['content', siteId, 'mobile-use']"
        data-qa="navigate-property-mobile-activity"
      >
        <mat-icon matListItemIcon>smartphone</mat-icon>
        <a matListItemTitle>Mobile Activity</a>
      </mat-list-item>
      <mat-list-item
        routerLinkActive="active"
        [routerLink]="['content', siteId, 'rooms']"
        data-qa="navigate-property-rooms"
      >
        <mat-icon matListItemIcon>room</mat-icon>
        <a matListItemTitle routerLinkActive="active">Rooms</a>
      </mat-list-item>
      <mat-list-item
        routerLinkActive="active"
        [routerLink]="['content', siteId, 'menus']"
        data-qa="navigate-property-menus"
      >
        <mat-icon matListItemIcon>restaurant</mat-icon>
        <a matListItemTitle routerLinkActive="active">Menus</a>
      </mat-list-item>
    </mat-nav-list>
    <mat-nav-list
      *ngIf="(authService.user | async)?.isOrgAdmin"
      data-qa="navigate-org-images"
    >
      <h3 class="text-sm text-left">ADMIN CONTENT</h3>
      <mat-list-item
        routerLinkActive="active"
        [routerLink]="['org', orgId, 'images']"
      >
        <mat-icon matListItemIcon>panorama</mat-icon>
        <a matListItemTitle routerLinkActive="active">Background Images</a>
      </mat-list-item>
    </mat-nav-list>
    <mat-nav-list *ngIf="(authService.user | async)?.isOrgAdmin">
      <h3 class="text-sm text-left">MANAGE ORGANIZATION</h3>
      <mat-list-item
        routerLinkActive="active"
        [routerLink]="['org', orgId, 'properties']"
        data-qa="navigate-org-properties"
      >
        <mat-icon matListItemIcon>apartment</mat-icon>
        <a matListItemTitle routerLinkActive="active">Properties</a>
      </mat-list-item>
      <mat-list-item
        routerLinkActive="active"
        [routerLink]="['org', orgId, 'menus']"
        data-qa="navigate-org-menus"
      >
        <mat-icon matListItemIcon>restaurant</mat-icon>
        <a matListItemTitle routerLinkActive="active">Menus</a>
      </mat-list-item>
      <mat-list-item
        routerLinkActive="active"
        [routerLink]="['org', orgId, 'users']"
        data-qa="navigate-org-users"
      >
        <mat-icon matListItemIcon>supervised_user_circle</mat-icon>
        <a matListItemTitle routerLinkActive="active">Users</a>
      </mat-list-item>
      <mat-list-item
        routerLinkActive="active"
        [routerLink]="['org', orgId, 'mobile-use']"
        data-qa="navigate-org-mobile-activity"
      >
        <mat-icon matListItemIcon>smartphone</mat-icon>
        <a matListItemTitle>Mobile Activity</a>
      </mat-list-item>
      <mat-list-item
        routerLinkActive="active"
        [routerLink]="['org', orgId, 'newsletters']"
        data-qa="navigate-org-newsletters"
      >
        <mat-icon
          matListItemIcon
          [matBadge]="newsletterService.orgNewslettersNotificationCount | async"
          [matBadgeHidden]="
            !(newsletterService.orgNewslettersNotificationCount | async)
          "
        >
          email
        </mat-icon>
        <a matListItemTitle>Newsletters</a>
      </mat-list-item>
    </mat-nav-list>
  </div>
</div>
