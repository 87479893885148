<div class="flex justify-between items-center">
  <h1 mat-dialog-title class="mat-dialog-title text-2xl">Manage Menu Slide</h1>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="flex">
    <div class="flex flex-col justify-between">
      <div class="flex items-center">
        <div class="w-1/2">
          <mat-slide-toggle [formControl]="showMenuSlide"
            >Display Menu Slide</mat-slide-toggle
          >
        </div>
        <form [formGroup]="form" class="w-1/2">
          <div class="flex flex-col">
            <div class="flex items-center">
              <label>Display Duration</label>
              <div
                class="text-gray-300 ml-[10px] inline-block"
                matTooltip="How many seconds should the slide show each time it displays?"
              >
                <mat-icon class="text-base">help</mat-icon>
              </div>
            </div>
            <mat-form-field color="accent" appearance="outline">
              <input
                matInput
                required
                formControlName="displayDuration"
                type="number"
                class="border-0"
              />
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="flex justify-end gap-4 pb-4">
        <button
          mat-button
          mat-dialog-close
          mat-raised-button
          [disabled]="loading"
        >
          Cancel
        </button>
        <button
          mat-button
          mat-raised-button
          color="accent"
          (click)="saveMenuSlide()"
          [disabled]="loading"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
