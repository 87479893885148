<mat-toolbar color="accent" class="flex gap-[30px]">
  <img class="admin-logo" src="assets/TGG_GiGi_Blue_thumbnail.png" />
  <span>Admin</span>

  <mat-form-field floatLabel="never">
    <input
      (input)="filterSites($event.target.value)"
      type="text"
      #siteSearchInput
      matInput
      required
      [placeholder]="tempSite ? tempSite : 'Site'"
      [matAutocomplete]="auto"
      [value]="(siteService.site | async)?.name"
      (focusin)="clearOnFocus()"
      (focusout)="setOnBlur()"
      data-qa="navigation-input-site"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="siteSelected($event.option)"
      ngDefaultControl
      [displayWith]="getOptionName"
      data-qa="navigation-select-site"
    >
      <mat-option
        *ngFor="let site of this.filteredSites | async"
        [value]="site"
        data-qa="navigation-select-site-option"
      >
        {{ site.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <span class="fill-remaining-space"></span>
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    data-qa="navigation-button-settings"
  >
    <mat-icon>settings</mat-icon>
  </button>
</mat-toolbar>

<!-- Settings drop down menu -->
<mat-menu #menu="matMenu">
  <button mat-menu-item disabled>v{{ clientVersion }}</button>
  <a
    mat-menu-item
    id="privacy_link"
    href="https://thegoodmangroup.com/privacy-policy/"
    target="_blank"
    rel="nopener noreferrer"
    >Privacy Policy</a
  >
  <button
    mat-menu-item
    (click)="auth.signOut()"
    data-qa="navigation-button-sign-out"
  >
    <mat-icon>exit_to_app</mat-icon>
    <span>Sign Out</span>
  </button>
</mat-menu>
