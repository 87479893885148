import { Component, OnInit, Inject } from '@angular/core';
import { RoomFormComponent } from 'src/app/shared/forms/room-form/room-form.component';
import { Room } from '@models/room';
import { RoomService } from 'src/app/core/services/room.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-room',
  templateUrl: './edit-room.component.html',
  styleUrls: ['./edit-room.component.scss'],
})
export class EditRoomComponent implements OnInit {
  public roomForm = RoomFormComponent.formModel();
  public submitting = false;

  constructor(
    private alertService: AlertService,
    private dialogRef: MatDialogRef<EditRoomComponent>,
    private roomService: RoomService,
    @Inject(MAT_DIALOG_DATA) public room: Room
  ) {}

  ngOnInit() {
    this.roomForm.setValue(RoomFormComponent.deserialize(this.room));
  }

  public async onSave(): Promise<void> {
    this.submitting = true;
    const r = RoomFormComponent.serialize(this.roomForm.value, this.room);

    this.roomService
      .updateRoom(r)
      .then(() => {
        this.alertService.success('Room saved.');
        this.roomService.refreshRooms();
        this.onCancel();
      })
      .catch((error) => {
        this.alertService.error('Unable to save room. Please try again.');
        console.error(error);
      })
      .finally(() => (this.submitting = false));
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
