import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  GenericBEDataSource,
  PaginationInformation,
} from '../pagination-server-side/datasources/genericBE.datasource';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import bootstrapTable from '../pagination-server-side/bootstrap-table';
import { HttpParams } from '@angular/common/http';
import { UserService } from 'src/app/core/services/user.service';
import { User } from '@models/user';
import { SiteService } from 'src/app/core/services/site.service';
import { filter, takeUntil, tap } from 'rxjs/operators';

interface PropertyManagerRow {
  id: number;
  firstName: string;
  lastName: string;
  adminType: string;
  email: string;
}

@Component({
  selector: 'app-property-management-table',
  templateUrl: './property-management-table.component.html',
  styleUrls: ['./property-management-table.component.scss'],
})
export class PropertyManagementTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() set filter(filterValue: string) {
    if (this.dataSource) {
      this.searchFilter$.next(filterValue);
    }
  }

  @Input() set site(filterValue: string) {
    this.site$.next(filterValue);
  }

  private searchFilter$ = new BehaviorSubject('');
  private site$ = new BehaviorSubject(null);
  private destroyed = new Subject<boolean>();

  public dataSource: MatTableDataSource<PropertyManagerRow>;
  public paginatedDataSource: GenericBEDataSource<Notification>;
  private manualRefreshTrigger$ = new BehaviorSubject(0);

  public displayedColumns = ['id', 'firstName', 'lastName', 'email', 'role'];
  public usersList: User[] = [];

  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(this.searchFilter$.value),
    showDeleted: new UntypedFormControl(false),
    refreshTrigger: new UntypedFormControl(0),
    siteId: new UntypedFormControl(this.site$.value),
  });

  constructor(
    public userService: UserService,
    public siteService: SiteService,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);

    this.paginatedDataSource = new GenericBEDataSource<Notification>(
      this.getPropertyManagersPaginated.bind(this),
    );
  }

  private getPropertyManagersPaginated(
    queryParams: HttpParams,
    pagination: PaginationInformation,
  ) {
    return this.userService.getPropertyManagersPaginated(
      queryParams,
      pagination,
    );
  }

  ngAfterViewInit(): void {
    // Must wait until we have an active site to load this data
    this.site$
      .pipe(
        filter((site) => site !== null),
        tap((site) => {
          this.filters.controls['siteId'].setValue(site);

          bootstrapTable<User[]>(
            this.sort,
            this.paginator,
            this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
            this.filters,
          )
            .pipe(takeUntil(this.destroyed))
            .subscribe();
          combineLatest([
            this.searchFilter$,
            this.manualRefreshTrigger$,
            this.site$,
          ])
            .pipe(takeUntil(this.destroyed))
            .subscribe(([search, refresh, site]) => {
              this.filters.controls['search'].setValue(search);
              // prevent loading twice on initial load
              if (refresh > 0) {
                this.filters.controls['refreshTrigger'].setValue(refresh);
              }
              this.filters.controls['siteId'].setValue(site);
            });
        }),
        takeUntil(this.destroyed),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }
}
