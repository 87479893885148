<mat-card appearance="outlined" color="primary">
  <mat-card-header class="flex justify-between items-center content-center">
    <mat-card-title color="primary" class="flex">
      FAQs ({{ (faqService.faqs | async)?.length || 0 }})
    </mat-card-title>
    <div class="flex w-3/4 justify-end items-center content-center gap-[30px]">
      <button mat-raised-button color="accent" (click)="addFaq()">
        Add FAQ
      </button>
      <button
        mat-raised-button
        color="accent"
        (click)="faqService.refreshFaqs()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
      <mat-form-field color="accent">
        <input matInput placeholder="Search" [(ngModel)]="filter" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </mat-card-header>
  <mat-card-content class="flex flex-col">
    <table
      mat-table
      matSort
      matSortActive="name"
      matSortDirection="asc"
      [dataSource]="paginatedDataSource"
    >
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          ID
        </th>
        <td mat-cell *matCellDef="let faq" class="data-id">{{ faq.id }}</td>
      </ng-container>

      <ng-container matColumnDef="question">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Question
        </th>
        <td mat-cell *matCellDef="let faq" class="data-question">
          {{ faq.question }}
        </td>
      </ng-container>
      <ng-container matColumnDef="answer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Answer
        </th>
        <td mat-cell *matCellDef="let faq" class="data-answer">
          {{ faq.answer }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let faq" class="data-actions">
          <div>
            <button
              mat-icon-button
              color="accent"
              (click)="editFaq(faq)"
              matTooltip="Edit Faq"
            >
              <mat-icon>edit_pencil</mat-icon>
            </button>
            <button
              mat-icon-button
              color="accent"
              (click)="deleteFaq(faq)"
              matTooltip="Delete Faq"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [length]="paginatedDataSource?.totalCount"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25]"
      showFirstLastButtons
    ></mat-paginator>
  </mat-card-content>
</mat-card>
