import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { User } from '@models/user';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { AddUserComponent } from '../add-user/add-user.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { UserService } from 'src/app/core/services/user.service';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogValues,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import {
  GenericBEDataSource,
  PaginationInformation,
} from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import bootstrapTable from 'src/app/shared/pagination-server-side/bootstrap-table';
import moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { SiteService } from 'src/app/core/services/site.service';
import { generateCsv, download, mkConfig } from 'export-to-csv';
interface UserRow {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  sitesManaged: number;
  adminType: 'Site' | 'Organization';
  totalLogins: number;
  lastLogin: number;
}

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() set filter(filterValue: string) {
    if (this.dataSource) {
      this.searchFilter$.next(filterValue);
    }
  }

  private manualRefreshTrigger$ = new BehaviorSubject(0);

  private searchFilter$ = new BehaviorSubject('');
  private inactiveFilter$ = new BehaviorSubject(false);

  public destroy$ = new Subject();

  public dataSource: MatTableDataSource<UserRow>;
  public paginatedDataSource: GenericBEDataSource<User>;
  public displayedColumns = [
    'id',
    'firstName',
    'lastName',
    'email',
    'sitesManaged',
    'adminType',
    'totalLogins',
    'lastLogin',
    'actions',
  ];
  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(this.searchFilter$.value),
    inactive: new UntypedFormControl(false),
    refreshTrigger: new UntypedFormControl(0),
  });

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    public userService: UserService,
    public siteService: SiteService,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);

    this.paginatedDataSource = new GenericBEDataSource<User>(
      this.getUsersPaginated.bind(this),
    );
  }

  private getUsersPaginated(
    queryParams: HttpParams,
    pagination: PaginationInformation,
  ) {
    return this.userService.getUsersPaginated(queryParams, pagination);
  }

  toggleInactive(value) {
    this.inactiveFilter$.next(value.checked);
  }

  ngAfterViewInit(): void {
    this.siteService.site
      .pipe(
        filter((site) => site !== null),
        take(1),
        tap((site) => {
          bootstrapTable<User[]>(
            this.sort,
            this.paginator,
            this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
            this.filters,
          )
            .pipe(takeUntil(this.destroy$))
            .subscribe();

          combineLatest([
            this.searchFilter$,
            this.inactiveFilter$,
            this.manualRefreshTrigger$,
          ])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([search, inactive, refresh]) => {
              this.filters.controls['inactive'].setValue(inactive);
              this.filters.controls['search'].setValue(search);
              // prevent loading twice on initial load
              if (refresh > 0) {
                this.filters.controls['refreshTrigger'].setValue(refresh);
              }
            });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.searchFilter$.complete();
    this.destroy$.complete();
  }

  public editUser(user: User): void {
    const dialogRef = this.dialog.open(EditUserComponent, {
      data: user,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.manualRefreshTrigger$.next(this.manualRefreshTrigger$.value + 1);
      }
    });
  }

  public deleteUser(user: User): void {
    const confirmData: ConfirmationDialogValues = {
      message: 'Do you want to deactivate this user?',
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.userService.deleteUser(String(user.id)).then(() => {
          this.manualRefreshTrigger$.next(this.manualRefreshTrigger$.value + 1);
          window.setTimeout(
            () => this.alertService.success('User deactivated.'),
            0,
          );
        });
      }
    });
  }

  public reactivateUser(user: User): void {
    const confirmData: ConfirmationDialogValues = {
      message: 'Do you want to reactivate this user?',
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.userService.reactivateUser(String(user.id)).then(() => {
          this.manualRefreshTrigger$.next(this.manualRefreshTrigger$.value + 1);
          window.setTimeout(
            () => this.alertService.success('User reactivated.'),
            0,
          );
        });
      }
    });
  }

  // Used for formatting data for tooltips
  public siteNames(sites) {
    const mapped = sites.map((site) => site.name).join(', ');
    return mapped;
  }

  public addUser(): void {
    const dialogRef = this.dialog.open(AddUserComponent, {});
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.manualRefreshTrigger$.next(this.manualRefreshTrigger$.value + 1);
      }
    });
  }

  public downloadCSV(): void {
    const date = moment().format('MM/DD/YYYY');
    // get the full data list (unpaginated, search applied)
    let queryParams = new HttpParams({
      fromObject: {
        search: this.filters.controls['search'].value,
      },
    });
    const filterInformation = {
      filters: {
        search: this.filters.controls['search'].value,
      },
    };
    this.userService
      .getAllUsers(queryParams, filterInformation)
      .pipe(
        take(1),
        map((dataResponse) => {
          const options = mkConfig({
            filename: `CMS_users_export_${date}`,
            useBom: true,
            columnHeaders: [
              { key: 'firstName', displayLabel: 'First Name' },
              { key: 'lastName', displayLabel: 'Last Name' },
              { key: 'email', displayLabel: 'Email' },
              { key: 'adminType', displayLabel: 'Admin Type' },
              { key: 'totalLogins', displayLabel: 'Total Logins' },
              { key: 'lastLogin', displayLabel: 'Last Login' },
              { key: 'sitesManaged', displayLabel: 'Sites Managed' },
              { key: 'sites', displayLabel: 'Sites' },
            ],
          });

          const dataList = [];

          for (const data of dataResponse.data) {
            const user = new User(data);
            const lastLogin = user.lastLogin
              ? moment(user.lastLogin).clone().format('MM/DD/YYYY')
              : '';
            const formattedData = {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              adminType: user.isOrgAdmin ? 'Organization' : 'Site',
              totalLogins: user?.totalLogins,
              lastLogin,
              sitesManaged: user.sites?.length > 0 ? user.sites?.length : 0,
              sites: user.isOrgAdmin
                ? 'All'
                : user.sites?.length > 0
                ? user.sites.map((site) => site.name).join(', ')
                : '',
            };

            dataList.push(formattedData);
          }
          const csv = generateCsv(options)(dataList);
          download(options)(csv);
        }),
      )
      .subscribe();
  }
}
