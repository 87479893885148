<app-calendar-dashboard
  [events]="events.value"
  [activeMonth]="activeMonth"
></app-calendar-dashboard>
<app-event-page-agenda-wrapper
  [months]="months"
  [timezone]="timezone"
  [activeMonth]="activeMonth"
  [printCalendarRestrictions]="printCalendarRestrictions"
  [loading]="loading"
  [activities]="events.value"
  (changeMonth)="changeMonth.emit($event)"
  (deleteActivity)="deleteActivity($event)"
  (showEditDialog)="showEditDialog($event)"
  (pageLoading)="handlePageLoading($event)"
></app-event-page-agenda-wrapper>
