<section class="display-cont is-success event is-bold gif-centric">
  <div class="h-full w-full bg-black opacity-40"></div>
</section>
<div class="absolute inset-0 overflow-hidden">
  <div class="w-full h-full flex items-center justify-center flex-col">
    <ng-container
      *ngIf="currentAnnouncement?.title || currentAnnouncement?.content"
    >
      <div class="bg-black/60 m-0 p-[3vmin] absolute top-0 left-0 w-[25%]">
        <h1>
          <markdown [data]="currentAnnouncement?.title"></markdown>
        </h1>
        <h2>
          <markdown [data]="currentAnnouncement?.content"></markdown>
        </h2>
      </div>
    </ng-container>
    <img [src]="image" />
  </div>
</div>
