import { Pipe, PipeTransform } from '@angular/core';
import { States } from '../../../../../core/enums/states';
import { Timezones } from '../../../../../core/enums/timezones';
import { NewsletterStatus } from '../../../../../core/enums/newsletter-status';

const enumDictionary = {
  state: States,
  timezone: Timezones,
  newsletterStatus: NewsletterStatus,
};

interface IEnum {
  state: States;
  timezone: Timezones;
  newsletterStatus: NewsletterStatus;
}

export type EnumOption = keyof IEnum;
@Pipe({
  name: 'enum',
})
export class EnumPipe implements PipeTransform {
  transform(value: any, enumOption: EnumOption): any {
    if (enumDictionary[enumOption] === undefined) {
      throw new Error(`enum ${enumOption} is missing from enumDictionary!`);
    }

    return enumDictionary[enumOption][value];
  }
}
