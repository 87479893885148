<mat-card appearance="outlined" class="flex flex-col">
  <mat-card-title>{{ article.headline }}</mat-card-title>
  <p class="overview-label">{{ article.articleType.name }}</p>
  <p>{{ article.content }}</p>

  <p class="overview-label">Article Images</p>
  <ng-container *ngIf="!article.customImages">
    <p>Author requests related images be added.</p>
  </ng-container>
  <ng-container *ngIf="article.customImages">
    <div class="flex flex-col" *ngFor="let image of article.image">
      <img class="newsletter-img" [src]="image.downloadUrl" />
      <p class="overview-label">{{ image.caption }}</p>
    </div>
  </ng-container>
  <mat-divider></mat-divider>
  <div class="flex justify-center items-center content-center">
    <button mat-button (click)="triggerHideComponent()">Cancel</button>
  </div>
</mat-card>
<br /><br /><br />
