<div class="flex justify-between items-start">
  <h1 mat-dialog-title class="mat-dialog-title">{{ heading }}</h1>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="modal-content">
  <form
    [formGroup]="eventForm"
    *ngIf="months && selectedMonth"
    class="event-form"
    #showForm
  >
    <app-event-details
      formControlName="details"
      [editing]="data.editing"
    ></app-event-details>

    <app-event-timing
      formControlName="timing"
      [months]="months"
      [selectedMonth]="selectedMonth"
      [eventPreview]="eventPreview"
      [editing]="data.editing"
      [eventToEdit]="data.event"
    ></app-event-timing>
    <div class="cta-footer">
      <button
        *ngIf="data.event"
        mat-button
        type="button"
        (click)="deleteEvent()"
        mat-raised-button
        color="warn"
      >
        Delete
      </button>
      <div class="modal-actions">
        <button
          mat-button
          (click)="closeModal()"
          type="button"
          mat-raised-button
          data-qa="cancel-event-modal"
        >
          Cancel
        </button>
        <app-progress-button
          color="accent"
          text="Save"
          (submit)="saveEvent($event)"
          [disabled]="eventForm.invalid"
          [submitting]="isSaving.value"
          data-qa="save-event-modal"
        >
        </app-progress-button>
      </div>
    </div>
  </form>
</div>
