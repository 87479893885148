import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../shared/confirm-dialog/confirm-dialog.component';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  /**
   * @param data Title, message, textConfirm?, textDismiss?, warn?, maxWidth?
   */
  public async confirm(data: ConfirmDialogModel): Promise<any> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: data.maxWidth ? data.maxWidth : '300px',
      data,
    });

    return firstValueFrom(dialogRef.afterClosed());
  }

  public error(message: string) {
    this.displayToast(message, 'is-danger', 5000);
  }

  public warning(message: string) {
    this.displayToast(message, 'is-warning', 5000);
  }

  public success(message: string) {
    this.displayToast(message, 'is-success');
  }

  public displayToast(message: string, type: string, duration?: number): void {
    this.snackBar.open(message, null, {
      duration: duration || 3000,
    });
  }
}
