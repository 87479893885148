<div class="flex justify-between items-center content-center">
  <h1 class="mat-dialog-title">Edit FAQ</h1>
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <app-faq-form [form]="faqForm"></app-faq-form>
  <app-progress-button
    color="accent"
    text="Save"
    (submit)="onSave()"
    [disabled]="!faqForm.valid"
    [submitting]="submitting"
  >
  </app-progress-button>
</mat-dialog-content>
