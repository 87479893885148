import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import * as React from 'react';
import { ReactProvider } from 'src/app/shared/react-components/react-provider';
import { EventPreviewCalendar } from './react-components/event-preview-calendar';
import { Root, createRoot } from 'react-dom/client';

const containerElementRef = 'eventPreviewCalendarWrapper';

@Component({
  selector: 'app-event-preview-calendar-wrapper',
  template: `<span #${containerElementRef}></span>`,
  styleUrls: ['./event-preview-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EventPreviewCalendarComponentWrapper
  implements OnChanges, OnDestroy, AfterViewInit
{
  @ViewChild(containerElementRef, { static: true }) containerRef!: ElementRef;

  @Input() events: any[] = [];
  @Input() selectedDate: Date;
  @Input() activeMonthIndex: number;
  @Input() lastRecurrenceMessage: string;
  @Input() months: any[] = [];

  private root: Root;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.root) return;
    this.render();
  }

  ngAfterViewInit() {
    this.root = createRoot(this.containerRef.nativeElement);
    this.render();
  }

  ngOnDestroy() {
    this.root.unmount();
  }

  private render() {
    this.root.render(
      <React.StrictMode>
        <ReactProvider>
          <EventPreviewCalendar
            events={this.events}
            selectedDate={this.selectedDate}
            activeMonthIndex={this.activeMonthIndex}
            lastRecurrenceMessage={this.lastRecurrenceMessage}
            months={this.months}
          />
        </ReactProvider>
      </React.StrictMode>,
    );
  }
}
