import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import * as React from 'react';
import { Root, createRoot } from 'react-dom/client';
import { ReactProvider } from 'src/app/shared/react-components/react-provider';
import { DailyAgenda } from './react-components/daily-agenda';
import { Announcement } from '@models/announcement';

const containerElementRef = 'dailyAgendaWrapper';

@Component({
  selector: 'app-daily-agenda-wrapper',
  template: `<span #${containerElementRef}></span>`,
  // styleUrls: [""],
  encapsulation: ViewEncapsulation.None,
})
export class DailyAgendaComponentWrapper
  implements OnChanges, OnDestroy, AfterViewInit
{
  @ViewChild(containerElementRef, { static: true }) containerRef!: ElementRef;

  @Input() timezone;
  @Input() activeDay;
  @Input() activeEvent: Announcement;
  @Input() selectedDate = new Date();
  @Input() loading = true;
  @Input() events: Announcement[] = [];

  private root: Root;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.root) return;
    this.render();
  }

  ngAfterViewInit() {
    this.root = createRoot(this.containerRef.nativeElement);
    this.render();
  }

  ngOnDestroy() {
    this.root.unmount();
  }

  private render() {
    this.root.render(
      <React.StrictMode>
        <ReactProvider>
          <DailyAgenda
            timezone={this.timezone}
            activeDay={this.activeDay}
            activeEvent={this.activeEvent}
            selectedDate={this.selectedDate}
            loading={this.loading}
            events={this.events}
          />
        </ReactProvider>
      </React.StrictMode>,
    );
  }
}
