import { Component, Input, OnInit } from '@angular/core';
import { MenuItemDto } from '@interfaces/menu';

@Component({
  selector: 'app-menu-preview-line',
  templateUrl: './menu-preview-line.component.html',
  styleUrls: ['./menu-preview-line.component.scss'],
})
export class MenuPreviewLineComponent implements OnInit {
  @Input() title: string;
  @Input() singleItem: MenuItemDto;
  @Input() itemCollection: MenuItemDto[];

  public hasContent = false;

  constructor() {}

  ngOnInit(): void {
    this.hasContent = !!this.singleItem || this.itemCollection.length > 0;
  }
}
