import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { RecurrenceDto } from '@interfaces/recurrence';
import { Announcement } from '@models/announcement';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ByWeekday, RRule, Weekday } from 'rrule';
import { Recurrence } from '@models/recurrence';
import { EventFormValues } from 'src/app/manage/activities/event-form-modal-component/event-form-modal.component';
import { EventTimingFormValues } from 'src/app/manage/activities/event-form-modal-component/forms/event-timing/event-timing.component';
import isEqual from 'lodash/isEqual';
import { format, startOfDay } from 'date-fns';
import { lastValueFrom } from 'rxjs';
import { formatInTimeZone } from 'date-fns-tz';
@Injectable({
  providedIn: 'root',
})
export class RecurrenceService {
  constructor(private http: HttpClient) {}

  // Print human-readable version of rrule
  public prettyPrintRecurrenceRecipe(rule: Partial<RecurrenceDto>): string {
    if (!rule) {
      return '';
    }
    let readableRule = '';

    // Choose daily, weekly monthly
    // monthly on x day
    // @ts-ignore
    if (rule?.freq === RRule.MONTHLY) {
      readableRule = 'month';
    } else if (
      rule.byweekday &&
      (rule.byweekday as Array<ByWeekday>).length > 0
    ) {
      // weekly on [x] days
      readableRule = 'week';
    } else {
      readableRule = 'day';
    }
    if (rule.interval) {
      switch (rule.interval) {
        case 1:
          readableRule = `every ${readableRule}`;
          break;
        case 2:
          readableRule = `every other ${readableRule}`;
          break;
        default:
          readableRule = `every ${rule.interval} ${readableRule}s`;
      }
    }

    // we start the week on Sunday (given index 0)
    if (rule.byweekday && (rule.byweekday as Array<ByWeekday>).length > 0) {
      let days = '';
      let datePosition = '';
      let options = rule.byweekday;
      if (typeof rule.byweekday === 'string') {
        options = [rule.byweekday];
      }
      (options as Array<Weekday>).forEach((day: Weekday | string, index) => {
        let dayString = '';
        let switchValue;
        if ((day as Weekday)?.weekday) {
          switchValue = (day as Weekday).weekday;
        } else if (isNaN(Number(day))) {
          switchValue = this.getWeekdayIntegerFromString(day as string);
        } else {
          switchValue = Number(day);
        }
        switch (switchValue) {
          case RRule.MO.weekday:
            // Sunday
            dayString = `Monday`;
            break;
          case RRule.TU.weekday:
            // Monday
            dayString = `Tuesday`;
            break;
          case RRule.WE.weekday:
            // Tuesday
            dayString = `Wednesday`;
            break;
          case RRule.TH.weekday:
            // Wednesday
            dayString = `Thursday`;
            break;
          case RRule.FR.weekday:
            // Thursday
            dayString = `Friday`;
            break;
          case RRule.SA.weekday:
            // Friday
            dayString = `Saturday`;
            break;
          case RRule.SU.weekday:
            // Saturday
            dayString = `Sunday`;
            break;
        }

        if (rule?.freq === RRule.MONTHLY) {
          switch (rule.nbyweekday) {
            case 1:
              datePosition = `first`;
              break;
            case 2:
              datePosition = `second`;
              break;
            case 3:
              datePosition = `third`;
              break;
            case 4:
              datePosition = `fourth`;
              break;
            case -1:
              datePosition = `last`;
              break;
            case -2:
              datePosition = `second to last`;
              break;
            case -3:
              datePosition = `third to last`;
              break;
            case -4:
              datePosition = `fourth to last`;
              break;
          }
        }

        if (days.length === 0) {
          days = dayString;
        } else if (index === (rule.byweekday as Array<ByWeekday>).length - 1) {
          // there are more than one days and this is the last item
          days = `${days} and ${dayString}`;
        } else {
          // there are more than one days and this is NOT the last item
          days = `${days}, ${dayString}`;
        }
      });

      readableRule = `${readableRule} on ${
        datePosition.length > 0 ? `the ${datePosition} ` : ''
      }${days}`;
    }

    if (rule.count) {
      readableRule = `${readableRule} until it repeats ${rule.count} times`;
    } else if (rule.until) {
      readableRule = `${readableRule} until ${this.dateToTimezoneString(
        rule.until,
        rule.tzid,
      )}`;
    }
    if (rule?.exdates?.length > 0) {
      let exceptions = '';
      rule.exdates.forEach((date, index) => {
        if (index === 0) {
          exceptions = `${this.dateToTimezoneString(date, rule.tzid)}`;
        } else if (index === rule.exdates.length - 1) {
          exceptions = `${exceptions} and ${this.dateToTimezoneString(
            date,
            rule.tzid,
          )}`;
        } else {
          exceptions = `${exceptions}, ${this.dateToTimezoneString(
            date,
            rule.tzid,
          )}`;
        }
      });
      readableRule = `${readableRule} (except on ${exceptions})`;
    }
    // Until?
    return readableRule;
  }

  public dateToTimezoneString(date: Date | string, timezone: string) {
    if (timezone) {
      return formatInTimeZone(new Date(date), 'M-d-yy', timezone);
    }
    return format(new Date(date), 'M-d-yy');
  }

  // converts stored time values on activities to recurrence-friendly timezone strings
  public dateFromActivity(activity) {
    return DateTime.fromISO(activity.eventStart)
      .setZone(activity.site.timezone)
      .toFormat('yyyy-MM-dd');
  }

  // Date MUST BE in timezone-friendly UTC string
  public deleteThisAndFutureRecurringAnnouncements(announcement: Announcement) {
    const url = environment.apiUrl
      .concat('/api/v1/announcement/')
      .concat(String(announcement.site.id))
      .concat('/recurrence/')
      .concat(String(announcement.recurrence.id))
      .concat('/delete/this-and-future/')
      .concat(String(announcement.eventStart));
    try {
      return <Promise<any>>(
        lastValueFrom(this.http.post(url, {}, { responseType: 'text' }))
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  // Gets all announcements by recurrence ID
  getRecurringAnnouncementsByAnnouncement(
    announcement: Announcement,
  ): Promise<Array<Announcement>> {
    const url = environment.apiUrl.concat(
      `/api/v1/announcement/${announcement.site.id}/recurrence/${announcement.recurrence.id}`,
    );
    try {
      return <Promise<any>>lastValueFrom(this.http.get(url));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }
  saveRecurrence(rule: RecurrenceDto) {
    const url = environment.apiUrl.concat('/api/v1/recurrence');
    const body = rule;
    try {
      return <Promise<Recurrence>>lastValueFrom(
        this.http.put(url, JSON.stringify(body), {
          headers: { 'Content-Type': 'application/json' },
        }),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  deleteRecurrence(announcement) {
    const url = environment.apiUrl.concat(
      `/api/v1/recurrence/${announcement.recurrence.id}`,
    );

    try {
      return lastValueFrom(this.http.put(url, {}));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public async updateUntilFromActivity(announcement: Announcement) {
    /**
     * Deleting this and all future instances of this announcement:
     * update the event recurrence (at id) with new 'until' date, delete this and future announcements
     */
    const endOfThePreviousDay = DateTime.fromISO(announcement.eventStart)
      .setZone(announcement.site.timezone)
      .minus({ days: 1 })
      .toFormat('yyyy-MM-dd');
    let start = announcement.recurrence.dtstart;
    if (start instanceof Date) {
      start = DateTime.fromJSDate(announcement.recurrence.dtstart).toFormat(
        'yyyy-MM-dd',
      );
    }

    let weekdays;
    if (
      announcement?.recurrence?.byweekday &&
      typeof announcement?.recurrence?.byweekday[0] !== 'string'
    ) {
      weekdays = announcement?.recurrence?.byweekday.map((day) =>
        this.getWeekdayString(day),
      );
    }
    /**
     * Update recurrence with new 'until' date. If there was previously a count value, null and replace.
     */

    return await this.saveRecurrence({
      ...announcement.recurrence,
      dtstart: start,
      count: null, // if it was a count before, it's and until now
      until: endOfThePreviousDay,
    });
  }

  public newRecurrenceModelFromActivity(announcement: Announcement) {
    return new Recurrence({
      ...announcement.recurrence,
      tzid: announcement.site.timezone,
    });
  }

  public async saveNewRecurrenceFromActivity(
    announcement: Announcement,
    exdates?: string[],
  ): Promise<Recurrence> {
    /**
     * Update recurrence with new 'start' date. Delete unneeded properties.
     */
    const recurrenceToCopy = {
      ...announcement.recurrence,
      exdates: exdates ? exdates : null, // if exdates are not explicitly provided, they are nullified
    };
    delete recurrenceToCopy.createdOn;
    delete recurrenceToCopy.updatedOn;
    delete recurrenceToCopy.id;
    return await this.saveRecurrence({
      ...recurrenceToCopy,
    });
  }

  finalizeRecurrenceDetailsToSave(timing, exdatesToMerge?) {
    let exdates;
    if (timing?.recurrence?.exdates || exdatesToMerge) {
      exdates = exdatesToMerge;
    }
    let byweekday =
      typeof timing?.recurrenceRecipe?.byweekday === 'string'
        ? [timing?.recurrenceRecipe?.byweekday]
        : timing?.recurrenceRecipe?.byweekday;

    if (byweekday?.length > 0 && typeof byweekday[0] !== 'string') {
      byweekday = byweekday.map((value) => {
        return value?.weekday;
      });
    }
    const start = this.getDtstartFromFormValues(timing);
    const until =
      timing?.recurrenceRecipe?.until instanceof Date
        ? format(timing?.recurrenceRecipe?.until, 'yyyy-MM-dd')
        : timing?.recurrenceRecipe?.until;
    const recurrence = {
      ...timing.recurrenceRecipe,
      byweekday: byweekday ? byweekday : null,
      dtstart: start,
      until: until ? until : null,
      exdates: exdates ? exdates : null,
    };
    if (recurrence.stopCondition) {
      delete recurrence.stopCondition;
    }

    return recurrence;
  }

  public getDtstartFromFormValues(form: EventTimingFormValues): string {
    if (form?.date instanceof Date) {
      return format(startOfDay(form?.date), 'yyyy-MM-dd');
    } else if (typeof form?.date === 'string') {
      return form?.date;
    } else {
      return format(startOfDay(new Date()), 'yyyy-MM-dd');
    }
  }

  public async getRecurringBatchFromForm(data: {
    eventForm: EventFormValues;
    recurrenceId?: number;
    exdatesToMerge?;
    timezone: string;
  }): Promise<{
    form;
    dates: DateTime[];
  }> {
    const completeRecurrenceRecipe = this.finalizeRecurrenceDetailsToSave(
      data.eventForm.timing,
      data.exdatesToMerge,
    );
    const recurrenceInstance = new Recurrence({
      ...completeRecurrenceRecipe,
      tzid: data.timezone,
    });
    let newRecurrenceId = data?.recurrenceId;
    if (!data?.recurrenceId) {
      const response = await this.saveRecurrence({
        ...completeRecurrenceRecipe,
        until:
          // hate using any here, but convoluted code made me do it - kd
          (data.eventForm.timing?.recurrenceRecipe as any)?.stopCondition ===
          'never'
            ? null
            : completeRecurrenceRecipe.until,
      });
      newRecurrenceId = response.id;
    }

    return {
      form: {
        ...data.eventForm.timing,
        ...data.eventForm.details,
        recurrenceId: newRecurrenceId ? newRecurrenceId : null,
      },
      dates: recurrenceInstance.stringFormattedDates,
    };
  }

  public testForNewRecurrenceStart(
    savedRecurrence: RecurrenceDto,
    newTimingValues,
  ): boolean {
    const newRecurrence = this.finalizeRecurrenceDetailsToSave(newTimingValues);

    if (savedRecurrence?.dtstart !== newRecurrence?.dtstart) {
      return true;
    }

    return false;
  }

  public testForRecurrenceEquality(
    savedRecurrence,
    newRecurrenceRecipe,
  ): boolean {
    const saved = {
      byweekday: savedRecurrence?.byweekday
        ? savedRecurrence.byweekday.map((string) => Number(string))
        : null,
      count: savedRecurrence?.count ? savedRecurrence.count : null,
      until: savedRecurrence?.until ? savedRecurrence.until : null,
      dtstart: savedRecurrence?.dtstart ? savedRecurrence.dtstart : null,
      exdates: savedRecurrence?.exdates ? savedRecurrence.exdates : null,
      freq: savedRecurrence?.freq ? savedRecurrence.freq : null,
      interval: savedRecurrence?.interval ? savedRecurrence.interval : null,
      nbyweekday: savedRecurrence?.nbyweekday
        ? savedRecurrence.nbyweekday
        : null,
    };

    const newRecurrence = newRecurrenceRecipe;
    return isEqual(saved, newRecurrence);
  }

  public getWeekdayString(day: Weekday | number): string {
    let dayString = '';
    let switchValue;
    if (typeof day === 'number') {
      switchValue = day;
    } else if (day.weekday) {
      switchValue = day.weekday;
    }
    switch (switchValue) {
      case RRule.MO.weekday:
        dayString = 'MO';
        break;
      case RRule.TU.weekday:
        dayString = 'TU';
        break;
      case RRule.WE.weekday:
        dayString = 'WE';
        break;
      case RRule.TH.weekday:
        dayString = 'TH';
        break;
      case RRule.FR.weekday:
        dayString = 'FR';
        break;
      case RRule.SA.weekday:
        dayString = 'SA';
        break;
      case RRule.SU.weekday:
        dayString = 'SU';
        break;
    }
    return dayString;
  }

  public getWeekdayFromString(day: string): Weekday {
    let weekday;
    switch (day) {
      case 'MO':
        weekday = RRule.MO;
        break;
      case 'TU':
        weekday = RRule.TU;
        break;
      case 'WE':
        weekday = RRule.WE;
        break;
      case 'TH':
        weekday = RRule.TH;
        break;
      case 'FR':
        weekday = RRule.FR;
        break;
      case 'SA':
        weekday = RRule.SA;
        break;
      case 'SU':
        weekday = RRule.SU;
        break;
    }
    return weekday;
  }

  public getWeekdayIntegerFromString(day: string): string {
    let dayInteger;
    switch (day) {
      case 'MO':
        dayInteger = RRule.MO.weekday;
        break;
      case 'TU':
        dayInteger = RRule.TU.weekday;
        break;
      case 'WE':
        dayInteger = RRule.WE.weekday;
        break;
      case 'TH':
        dayInteger = RRule.TH.weekday;
        break;
      case 'FR':
        dayInteger = RRule.FR.weekday;
        break;
      case 'SA':
        dayInteger = RRule.SA.weekday;
        break;
      case 'SU':
        dayInteger = RRule.SU.weekday;
        break;
    }
    return dayInteger;
  }

  public getWeekdayStringArray(days: Weekday[]): string[] {
    const dayString = [];
    days.forEach((day: Weekday, index) => {
      switch (day.weekday) {
        case RRule.MO.weekday:
          dayString.push('MO');
          break;
        case RRule.TU.weekday:
          dayString.push('TU');
          break;
        case RRule.WE.weekday:
          dayString.push('WE');
          break;
        case RRule.TH.weekday:
          dayString.push('TH');
          break;
        case RRule.FR.weekday:
          dayString.push('FR');
          break;
        case RRule.SA.weekday:
          dayString.push('SA');
          break;
        case RRule.SU.weekday:
          dayString.push('SU');
          break;
      }
    });
    return dayString;
  }
}
