import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  forwardRef,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormBuilder,
  ControlValueAccessor,
} from '@angular/forms';

import { CategoryService } from 'src/app/core/services/category.service';
import { Image } from '@models/image';
import { ImageService } from 'src/app/core/services/image.service';
import { SiteService } from 'src/app/core/services/site.service';
import { SubcategoryService } from 'src/app/core/services/subcategory.service';
import { SubcategoryDto } from '@interfaces/subcategory';
import { defaultEventKey } from '../../../../../../../../core/enums/event-type';
import { Announcement } from '@models/announcement';
import { Subject } from 'rxjs';
import { EventTag } from '@models/eventTag';
import { takeUntil } from 'rxjs/operators';

export interface EventDetailsFormValues {
  title: string;
  content: string;
  roomId: string;
  location: string;
  subcategory: string;
  eventType: string;
  background: string;
  category: string;
  eventTags: Array<EventTag>;
  videoUrl: string;
}

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EventDetailsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EventDetailsComponent),
      multi: true,
    },
  ],
})
export class EventDetailsComponent implements ControlValueAccessor, OnInit {
  @Input() public editing: boolean;
  @Input() public isLoading: boolean;
  @Input() public eventToEdit: Announcement;
  @Output() public closeForm: EventEmitter<boolean> = new EventEmitter();
  @Output() public saveForm: EventEmitter<UntypedFormGroup> =
    new EventEmitter();
  public placeHolderImageUrl: string = '';
  public categoryIcon: string = '';
  private destroyed$ = new Subject();
  public eventDetailsForm: UntypedFormGroup = this.formBuilder.group({
    title: ['', Validators.required],
    content: [''],
    roomId: ['', Validators.required],
    location: [''],
    subcategory: ['', Validators.required],
    eventType: [defaultEventKey],
    background: [null],
    category: [null],
    eventTags: [null],
    videoUrl: [null],
    showOnPrintCalendar: [true, Validators.required],
    showOnDigitalSignage: [true, Validators.required],
  });

  public imageDeleted;

  get titleControl() {
    return this.eventDetailsForm.controls['title'];
  }

  get roomControl() {
    return this.eventDetailsForm.controls['roomId'];
  }

  get value(): EventDetailsComponent {
    return this.eventDetailsForm.value;
  }

  set value(value: EventDetailsComponent) {
    this.eventDetailsForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  constructor(
    public categoryService: CategoryService,
    public subcategoryService: SubcategoryService,
    public imageService: ImageService,
    public siteService: SiteService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.eventDetailsForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.onChange(value);
        this.onTouched();

        if (
          value?.background?.downloadUrl &&
          value?.background?.downloadUrl !== this.placeHolderImageUrl
        ) {
          if (value?.background?.deleted) {
            this.imageDeleted = true;
          } else {
            this.imageDeleted = false;

            this.placeHolderImageUrl = value.background.downloadUrl;
          }
        }
        if (
          value?.eventTags?.length > 0 &&
          value?.eventTags[0]?.icon !== this.categoryIcon
        ) {
          this.categoryIcon = value.eventTags[0].icon;
        }
        if (
          value &&
          value.background &&
          value.background.subcategoryId &&
          !value.subcategory
        ) {
          this.setDefaultValues(value);
        }
      });
  }

  private setDefaultValues(value) {
    if (value) {
      const subcategory = value.category.subcategory.find(
        (category) => category.id === value.background.subcategoryId,
      );
      this.eventDetailsForm.patchValue({
        subcategory,
        // Set other default values as needed
      });
    }
  }

  async onSubcatSelect(subcategory: Partial<SubcategoryDto>) {
    if (subcategory) {
      // make sure we have categories
      if (this.categoryService.currentCategories.length === 0) {
        await this.categoryService.refreshCategories();
      }

      const cat = this.categoryService.getCategoryForSubcategory(
        subcategory.id,
      );
      const backgroundImage: Image =
        this.imageService.getBackgroundForSubcategory(subcategory.id);
      this.eventDetailsForm.patchValue({
        subcategory,
        category: cat,
        background: backgroundImage,
        eventTags: cat?.tag ? [cat.tag] : [],
      });
      this.placeHolderImageUrl = backgroundImage.downloadUrl;
      this.categoryIcon = cat?.tag?.icon;
    } else {
      // resets subcategory related values
      this.eventDetailsForm.patchValue({
        subcategory: null,
        category: null,
        background: null,
        eventTags: [],
      });
      this.placeHolderImageUrl = '';
      this.categoryIcon = '';
    }
  }

  private containsSymbolCharacter(text: string): boolean {
    let containsSymbol = false;
    if (text.includes('®')) {
      containsSymbol = true;
    }
    if (text.includes('™')) {
      containsSymbol = true;
    }
    return containsSymbol;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
  }

  /**
   * Control Value Accessor Interface
   */

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.eventDetailsForm.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: UntypedFormControl) {
    return this.eventDetailsForm.valid ? null : { details: { valid: false } };
  }
}
