import { getIdToken } from './firebase/firebase-auth';

export async function get(url: string) {
  const token = await getIdToken();
  let headers: HeadersInit = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
}

export async function post(url: string, data: any) {
  const token = await getIdToken();
  let headers: HeadersInit = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
}

export async function put(url: string, data: any) {
  const token = await getIdToken();
  let headers: HeadersInit = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }
  const response = await fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
}

export async function fetchDelete(url: string) {
  const token = await getIdToken();
  let headers: HeadersInit = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }
  const response = await fetch(url, {
    method: 'DELETE',
    headers,
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
}
