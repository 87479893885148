import { MenuInput, MenuItemPayload } from "../interfaces/menu";
import { get } from "lodash";

type MenuInputKey = keyof MenuInput;

export function createMenuItem(
  menu: MenuInput,
  name: MenuInputKey
): MenuItemPayload | null {
  const itemName = get(menu, `${String(name)} Name`, null);
  const itemRecipeId = get(menu, `${String(name)} Num`, null);

  if (itemName) {
    return new MenuItem(itemName, itemRecipeId);
  }

  return null;
}

export function createMenuItemCollection(
  menu: MenuInput,
  name: MenuInputKey[]
): MenuItemPayload[] | null {
  if (Array.isArray(name)) {
    const outputArray: MenuItemPayload[] = name
      .map((subItemName) => {
        const itemName: string = get(menu, `${String(subItemName)} Name`, null);
        const itemRecipeId: string = get(
          menu,
          `${String(subItemName)} Num`,
          null
        );

        if (itemName) {
          return new MenuItem(itemName, itemRecipeId);
        } else {
          return null;
        }
      })
      .filter((value) => value !== null);

    if (outputArray.length > 0) {
      return outputArray;
    }
  }

  return null;
}

export class MenuItem implements MenuItemPayload {
  public name: string;
  public recipeId: string;

  constructor(name: string, recipeId: string) {
    this.name = name;
    this.recipeId = recipeId;
  }
}
