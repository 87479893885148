<div class="flex justify-between items-center content-center">
  <h1 mat-dialog-title class="text-2xl">Add New Background Image</h1>
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <!-- image -->
  <app-image-form
    [form]="imageForm"
    [multiSelect]="true"
    [allSites]="allSites"
    (imageFileChanged)="handleImageFileChange($event)"
  ></app-image-form>

  <app-progress-button
    color="accent"
    text="Save"
    (submit)="onSave()"
    [submitting]="submitting"
    [disabled]="!this.imageForm.valid"
  >
  </app-progress-button>
  <mat-progress-bar [value]="progress" color="accent"></mat-progress-bar>
</mat-dialog-content>
