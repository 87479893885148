export enum SlideStyleType {
  IndividualSpotlight = 1,
  ActivityAndEvents = 3,
  Announcement = 4,
  SpecialAnnouncement = 5,
  NameInLights = 7,
  ImageCentric = 12,
  Collage = 13,
  GifCentric = 30,
}

export const slideStyleKeys = Object.keys(SlideStyleType);
