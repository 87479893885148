import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { Notification } from '@models/notification';
import { AlertService } from 'src/app/core/services/alert.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AddNotificationComponent } from '../add-notification/add-notification.component';
import { SiteService } from 'src/app/core/services/site.service';
import { EditNotificationComponent } from '../edit-notification/edit-notification.component';
import {
  ConfirmationDialogValues,
  ConfirmationDialogComponent,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import {
  GenericBEDataSource,
  PaginationInformation,
} from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import bootstrapTable from 'src/app/shared/pagination-server-side/bootstrap-table';
import { HttpParams } from '@angular/common/http';

interface NotificationRow {
  createdOn: string;
  title: string;
  text: string;
  // 'expiration',
}
@Component({
  selector: 'app-notification-table',
  templateUrl: './notification-table.component.html',
  styleUrls: ['./notification-table.component.scss'],
})
export class NotificationTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() set filter(filterValue: string) {
    if (this.dataSource) {
      this.searchFilter$.next(filterValue);
    }
  }

  private destroyed = new Subject<boolean>();
  private searchFilter$ = new BehaviorSubject('');

  // @TODO Add this as a matToolitp for the "Expiration" header to clarify what it represents
  // public expirationHint = ExpirationHint;

  public dataSource: MatTableDataSource<NotificationRow>;
  public paginatedDataSource: GenericBEDataSource<Notification>;
  private manualRefreshTrigger$ = new BehaviorSubject(0);

  public displayedColumns = [
    'createdOn',
    'title',
    'text',
    // 'expiration',
    'actions',
  ];

  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(this.searchFilter$.value),
    verified: new UntypedFormControl(false),
    showDeleted: new UntypedFormControl(false),
    refreshTrigger: new UntypedFormControl(0),
  });

  constructor(
    public notificationService: NotificationService,
    private alertService: AlertService,
    private dialog: MatDialog,
    public siteService: SiteService,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);

    this.paginatedDataSource = new GenericBEDataSource<Notification>(
      this.getNotificationsPaginated.bind(this),
    );
  }

  private getNotificationsPaginated(
    queryParams: HttpParams,
    pagination: PaginationInformation,
  ) {
    return this.notificationService.getSiteNotificationsPaginated(
      queryParams,
      pagination,
    );
  }

  ngAfterViewInit(): void {
    // Must wait until we have an active site to load this data
    this.siteService.site
      .pipe(
        filter((site) => site !== null),
        tap((site) => {
          bootstrapTable<Notification[]>(
            this.sort,
            this.paginator,
            this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
            this.filters,
          )
            .pipe(takeUntil(this.destroyed))
            .subscribe();

          combineLatest([this.searchFilter$, this.manualRefreshTrigger$])
            .pipe(
              // check to see if search has a value and if refresh is truthy
              filter((val) => val[0].trim().length > 0 || !!val[1]),
              takeUntil(this.destroyed),
            )
            .subscribe(([search, refresh]) => {
              this.filters.controls['search'].setValue(search);
              this.filters.controls['refreshTrigger'].setValue(refresh);
            });
        }),
        takeUntil(this.destroyed),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  public deleteNotification(notification: Notification): void {
    const confirmData: ConfirmationDialogValues = {
      title: 'Delete Notification',
      // tslint:disable-next-line:max-line-length
      message:
        'Do you want to delete this notification? This cannot be undone. Note this will only delete this notification from within the mobile app; push notifications have already been sent.',
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
      minWidth: 400,
      maxWidth: 550,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.notificationService
          .deleteNotification(notification.id)
          .then(() => {
            this.alertService.success('Notification deleted.');
            this.manualRefreshTrigger$.next(
              this.manualRefreshTrigger$.value + 1,
            );
          });
      }
    });
  }

  public addNotification(): void {
    const dialogRef = this.dialog.open(AddNotificationComponent, {
      minWidth: 400,
      maxWidth: 550,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.manualRefreshTrigger$.next(this.manualRefreshTrigger$.value + 1);
      });
  }

  public editNotification(notification: Notification): void {
    const dialogRef = this.dialog.open(EditNotificationComponent, {
      minWidth: 400,
      maxWidth: 550,
      data: notification,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.manualRefreshTrigger$.next(this.manualRefreshTrigger$.value + 1);
      });
  }
}
