import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
  MealPlanPayload,
  MenuPayload,
} from '../../../../../../core/interfaces/menu';
import { SiteService } from '../../../core/services/site.service';
import { MenuService } from 'src/app/core/services/menu.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-menu-upload-dialog',
  templateUrl: './menu-upload-dialog.component.html',
  styleUrls: ['./menu-upload-dialog.component.scss'],
})
export class MenuUploadDialogComponent implements OnInit, OnDestroy {
  public sites = this.siteService.sites;

  public form = new UntypedFormGroup({
    siteIds: new UntypedFormControl(null, Validators.required),
    startDate: new UntypedFormControl(null, Validators.required),
    cycleCount: new UntypedFormControl(1, Validators.required),
  });

  private destroyed = new Subject();
  public cycleEndDate: string = null;
  public submitting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      menus: MenuPayload[];
    },
    private siteService: SiteService,
    public menuService: MenuService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<MenuUploadDialogComponent>
  ) {}

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe((values) => {
        if (values.startDate !== null) {
          this.cycleEndDate = DateTime.fromJSDate(values.startDate)
            .plus({ days: 35 * values.cycleCount })
            .minus({ days: 1 })
            .toISO();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  public async clickSubmit(): Promise<void> {
    try {
      const startDate = DateTime.fromJSDate(
        this.form.value.startDate
      ).toISO();
      const payload: MealPlanPayload = {
        menus: this.data.menus,
        siteIds: this.form.value.siteIds,
        cycleCount: this.form.value.cycleCount,
        startDate,
      };

      this.submitting = true;
      this.form.disable();

      await this.menuService
        .createMealPlan(payload)
        .then(() => {
          this.alertService.success('Menu cycle saved.');
        })
        .catch((error) => {
          const message = 'Unable to save menu cycle. Please try again.';
          this.alertService.error(message);
          console.error(error);
        });

      this.dialogRef.close({
        refresh: true,
      });
    } catch (err) {
      this.alertService.error('There was an error uploading the meal plan.');
    } finally {
      this.submitting = false;
      this.form.enable();
    }
  }
}
