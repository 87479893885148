<div style="display: flex; flex-wrap: wrap">
  <div
    class="card"
    *ngFor="let announcement of paginatedDataSource?.dataSubject?.value"
  >
    <app-display-title-slide
      [announcement]="announcement"
      *ngIf="announcement?.isTitleSlide"
    ></app-display-title-slide>
    <app-display-announcement
      [announcement]="announcement"
      [showClock]="false"
      *ngIf="
        announcement?.isAnnouncement ||
        announcement?.isSpecialAnnouncement ||
        announcement?.isCustom
      "
    ></app-display-announcement>
    <app-display-event
      [showClock]="false"
      [announcement]="announcement"
      *ngIf="announcement?.isEvent"
    ></app-display-event>
    <app-display-individual-spotlight
      [showClock]="false"
      [spotlight]="announcement"
      *ngIf="announcement.isIndividualSpotlight"
    ></app-display-individual-spotlight>
    <app-display-collage
      [announcement]="announcement"
      [showClock]="false"
      *ngIf="announcement.isCollage"
    ></app-display-collage>
    <app-display-name-in-lights-slide
      [announcement]="announcement"
      *ngIf="announcement.isNameInLights"
    ></app-display-name-in-lights-slide>
    <app-display-gif-slide
      [announcement]="announcement"
      *ngIf="announcement.isGif"
    ></app-display-gif-slide>

    <footer
      class="card-footer z-50 bg-neutral-400"
      *ngIf="!announcement.isGenerated && !announcement.isEvent"
    >
      <a class="card-footer-item" (click)="edit.emit(announcement)">Edit</a>
      <div class="card-footer-item">
        <app-slide-type-icon
          [announcement]="announcement"
        ></app-slide-type-icon>
      </div>
      <a class="card-footer-item" (click)="deleteSlide(announcement)">Delete</a>
    </footer>

    <footer
      *ngIf="announcement.isEvent"
      class="card-footer"
      matTooltip="Events can be edited and deleted in the Event Calendar"
    >
      <div class="card-footer-item">
        <app-slide-type-icon
          [announcement]="announcement"
        ></app-slide-type-icon>
      </div>
    </footer>
  </div>
</div>

<mat-paginator
  [length]="paginatedDataSource?.totalCount"
  [pageSize]="10"
  [pageSizeOptions]="[10, 20, 30, 50]"
  showFirstLastButtons
></mat-paginator>
