import { Injectable } from '@angular/core';
import {
  ReplaySubject,
  BehaviorSubject,
  Observable,
  lastValueFrom,
} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { take } from 'rxjs/operators';

import { Organization } from '@models/organization';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { User } from '@models/user';
import { Site } from '@models/site';
import {
  CreateSitePayload,
  Site as SiteDto,
} from 'src/app/core/interfaces/api';
import { OrgService } from './org.service';
import { Style } from '@models/style';
import { UtilityService } from './utility.service';
import { PaginationInformation } from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';
import { SiteMealPlanResponse } from '@interfaces/siteMenu';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  private _sites = new ReplaySubject<Site[]>();
  public readonly sites = this._sites.asObservable();

  private _site = new BehaviorSubject<Site>(null); // active site
  public readonly site = this._site.asObservable();

  private _siteId = new BehaviorSubject<number>(null);
  public readonly siteId = this._siteId.asObservable(); // active site id

  // Info is available on this.site
  // TODO: Refactor to remove this variable
  // Only used in slide.format.component
  private _siteType = new BehaviorSubject<string>(null);
  public readonly siteType = this._siteType.asObservable(); // active site type

  private _styles = new ReplaySubject<Style[]>();
  public readonly styles = this._styles.asObservable();

  private _announcementStyle$;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private orgService: OrgService,
    private utilityService: UtilityService,
  ) {
    this.listenForOrgUpdates();
    this.listenForUserUpdates();
  }

  public get currentSite(): Site {
    return this._site.getValue();
  }

  public get currentSiteId(): number {
    return this._siteId.getValue();
  }

  public get currentSiteType(): string {
    return this._siteType.getValue();
  }

  /**
   * Return array of all siteIds
   * Made it a Promise since it was setup as a ReplaySubject and only BehaviorSubjects have .getValue(),
   * and I didn't want to accidentally break downstream functionality by switching to BehaviorSubject.
   */
  public get siteIds(): Promise<number[]> {
    return new Promise((resolve, reject) => {
      this.sites
        .pipe(
          take(1),
          map((sites) => sites.map((site) => site.id)),
        )
        .subscribe((siteIds) => {
          resolve(siteIds);
        });
    });
  }

  private listenForOrgUpdates(): void {
    this.orgService.org.subscribe((org: Organization) => {
      this.refreshSites();
    });
  }

  private listenForUserUpdates(): void {
    this.authService.user.subscribe((user: User) => {
      this.refreshSites();
    });
  }

  public setActiveSite(siteId: number): void {
    this._siteId.next(Number(siteId));
    this.setSiteDetails(siteId);

    // prevent open subscription on site change
    if (this._announcementStyle$) {
      this._announcementStyle$.unsubscribe();
    }

    this._announcementStyle$ = this.getAnnouncementStyles(siteId).subscribe(
      (styles) => {
        this._styles.next(styles);
      },
    );
    this.utilityService.setSentrySiteId(siteId);
  }

  public refreshSites(): void {
    if (!this.authService.currentOrgId) {
      return;
    }

    this.getSitesByOrgId(String(this.authService.currentOrgId))
      .then((sites: Site[]) => {
        this._sites.next(sites);
      })
      .catch((error) => console.error(error));
  }

  public updateSite(id: number, site: Partial<SiteDto>): Promise<SiteDto> {
    const url = environment.apiv3Url.concat(`/site/${id}`);
    try {
      return <Promise<SiteDto>>lastValueFrom(this.http.put(url, site));
    } catch (err) {
      console.error('ERROR', err);
    }
  }

  public createSite(site: CreateSitePayload) {
    const url = environment.apiv3Url.concat(`/site`);
    try {
      return this.http.post<SiteDto>(url, site);
    } catch (err) {
      console.error('ERROR', err);
    }
  }

  public deleteSite(id: string): Promise<null> {
    const url = environment.apiUrl.concat(`/api/v1/site/${id}`);

    try {
      // Set responseType to 'text' as API currently returns 'OK'
      return <Promise<null>>(
        lastValueFrom(this.http.put(url, {}, { responseType: 'text' }))
      );
    } catch (err) {
      console.error('ERROR', err);
    }
  }

  public getSitesByOrgId(id: string): Promise<Site[]> {
    const url = `${environment.apiv3Url}/site/organization/${id}/all`;
    try {
      return <Promise<Site[]>>lastValueFrom(
        this.http.get(url).pipe(
          map((sites: SiteDto[]) => {
            return sites.map((site: SiteDto) => new Site(site));
          }),
        ),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public getSite(id: number): Observable<Site> {
    const url = `${environment.apiv3Url}/site/${id}`;

    try {
      return this.http.get<SiteDto>(url).pipe(map((site) => new Site(site)));
    } catch (err) {
      console.error('ERROR', err);
    }
  }

  public getAnnouncementStyles(siteId) {
    const url = environment.apiUrl.concat(`/api/v1/styles/${siteId}`);

    try {
      return this.http
        .get(url)
        .pipe(
          map((styles: Style[]) => styles.map((style) => new Style(style))),
        );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public setSiteDetails(id: number) {
    this.getSite(id).subscribe((site) => {
      if (site) {
        this._siteType.next(site.siteType.description);
        this._site.next(site);
      } else {
        this._siteType.next(null);
        this._site.next(null);
      }
    });
  }

  public setSite(id: number) {
    this.getSite(id).subscribe((site) => {
      if (site) {
        this._site.next(site);
      } else {
        this._site.next(null);
      }
    });
  }

  public getSitesPaginated(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
  ): Observable<{
    data: Site[];
    totalCount: number;
  }> {
    const { search } = pagination.filters;
    let active = null;
    let direction = null;

    if (pagination?.sort) {
      active = pagination.sort.active;
      direction = pagination.sort.direction;
    }

    if (search.trim().length > 0) {
      queryParams = queryParams.append('searchBy', '["name", "state"]');
    }

    queryParams = queryParams.append('sortField', active as string);
    queryParams = queryParams.append('sortOrder', direction.toLowerCase());
    const url = `${environment.apiv3Url}/site/organization/${this.currentSite.organization.id}`;

    try {
      return this.http.get<{ data: Site[]; totalCount: number }>(url, {
        params: queryParams,
      });
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public getSiteMealPlans(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
    siteName?: string,
  ): Observable<{
    data: SiteMealPlanResponse[];
    totalCount: number;
  }> {
    const { search } = pagination.filters;
    let active = null;
    let direction = null;

    if (pagination?.sort) {
      active = pagination.sort.active;
      direction = pagination.sort.direction;
    }
    queryParams = queryParams.append('sortField', active as string);
    queryParams = queryParams.append('sortOrder', direction.toLowerCase());
    if (siteName) {
      queryParams = queryParams.append('search', siteName);
    }

    const url = `${environment.apiv3Url}/menu/organization/${this.currentSite.organization.id}/sites`;
    try {
      return this.http.get<{
        data: SiteMealPlanResponse[];
        totalCount: number;
      }>(url, { params: queryParams });
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }
}
