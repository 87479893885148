<div class="flex justify-between items-center content-center">
  <h1 class="mat-dialog-title">Edit Room</h1>
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="has-padding-bottom bold">
    <p>Room names appear in digital signage and the mobile app.</p>
    <p>Room short names appear on the printed calendar.</p>
  </div>
  <!-- <h2 class="mat-h2">User Details</h2> -->
  <app-room-form [form]="roomForm"></app-room-form>
  <app-progress-button
    color="accent"
    text="Save"
    (submit)="onSave()"
    [disabled]="!roomForm.valid"
    [submitting]="submitting"
  >
  </app-progress-button>
</mat-dialog-content>
