import { OrganizationDto } from '../interfaces/organization';
import { SiteDto } from '../interfaces/site';

export class Organization implements OrganizationDto {
    public id: number;
    public createdOn: Date;
    public updatedOn: Date;
    public deleted: boolean;
    public name: string;
    public sites?: SiteDto[];

    constructor(data: OrganizationDto) {
      Object.assign(this, data);
    }

}
  