import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageService } from 'src/app/core/services/image.service';
import { SubcategoryService } from 'src/app/core/services/subcategory.service';
import { ImageFormComponent } from 'src/app/shared/forms/image-form/image-form.component';
import { Subcategory } from '@models/subcategory';
import { AuthService } from 'src/app/core/services/auth.service';
import { SiteService } from 'src/app/core/services/site.service';
import { take } from 'rxjs/operators';

interface ImageUpload {
  fbPath: string;
  subcategory: Subcategory;
}

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss'],
})
export class AddImageComponent implements OnInit {
  public imageForm = ImageFormComponent.formModel();
  public submitting = false;
  public data: { image?: any; original?: any } = {};

  public uploading = false;
  public allSites: Array<Number> = [];

  public progress = 0;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<AddImageComponent>,
    private imageService: ImageService,
    private subcategoryService: SubcategoryService,
    private siteService: SiteService,
  ) {}

  ngOnInit() {
    this.imageForm.setValue({
      image: '',
      name: '',
      siteIds: null,
      category: null,
      subcategoryId: null,
    });

    this.siteService.sites.pipe(take(1)).subscribe((value) => {
      this.allSites = value.map((site) => site.id);
    });
  }

  public handleImageFileChange = (image: File) => {
    this.data.image = image;
  };

  public onCancel(): void {
    this.dialogRef.close();
  }

  // image resize accounts for roughly 2/3rd of the upload time
  public handleResizeLoading = (value) => {
    this.progress = value * (2 / 3);
  };

  public async onSave(): Promise<void> {
    if (this.imageForm.value.image === '') {
      this.alertService.error('Please select an image.');
      return;
    }

    this.submitting = true;
    const formData = this.imageForm.value;

    let imageBlob = this.imageService.dataURItoBlob(this.data.image.image);
    if (await this.imageService.isAllowedFileType(imageBlob)) {
      const myReader: FileReader = new FileReader();

      const resize = await this.imageService.resizeFile(this.data.image.file, {
        maxWidthOrHeight: 3840, // should not be a higher resolution than 4k for digital signage
        maxSizeMB: 4,
        onProgress: this.handleResizeLoading,
      });
      myReader.readAsDataURL(resize);

      // read the resized image file for upload
      myReader.onloadend = (loadEvent: any) => {
        imageBlob = this.imageService.dataURItoBlob(
          loadEvent.currentTarget.result,
        );

        const path = this.imageService.getBackgroundImageFilePath(
          this.imageForm.value.name,
        );

        this.imageService
          .uploadToFirestore(path, imageBlob)
          .then(async (fbPath) => {
            // If there's a new subcategory, create new subcategory associated with the chosen category
            if (formData.name && formData.name.length > 0) {
              return this.addNewSubcategory(fbPath);
            }
            return { fbPath };
          })
          .then(async (data: ImageUpload) => {
            return this.addImageToDB(data);
          })
          .then(async () => {
            this.progress = 100;
            await this.imageService.refreshAllBackgrounds(); // necessary to provide new subcategories for slide creation
            this.alertService.success('Image uploaded.');
            this.dialogRef.close({ success: true });
          })
          .catch((error) => {
            console.error(error);
            this.alertService.error('Error uploading image. Please try again.');
          })
          .finally(() => {
            this.submitting = false;
            this.progress = 0;
          });
      };
    } else {
      this.alertService.error(
        'Please choose an allowed file type: jpg, png, gif or tif.',
      );
      this.progress = 0;
    }
  }

  private addNewSubcategory = async (fbPath) => {
    const formData = this.imageForm.value;

    const subcategory = await this.subcategoryService.saveSubcategory({
      name: formData.name,
      parent: formData.category,
    });

    return { fbPath, subcategory };
  };

  private addImageToDB = async (data: ImageUpload) => {
    try {
      const formData = this.imageForm.value;
      const downloadUrl = await this.imageService.getDownloadUrl(data.fbPath);
      let site = formData.siteIds;
      const categoryId = formData.category ? formData.category : null;
      const subcategoryId = data.subcategory ? data.subcategory.id : null;
      const path = data.fbPath;
      const styleId = 4; // StyleId for background images is always id #4, 'Announcement'
      let orgId = null;
      // if no specific site is specified or all sites are selected image is available to the entire organization
      if (!site || site.length === this.allSites.length) {
        site = [];
      }
      orgId = this.authService.currentOrgId;
      if (site?.length > 1) {
        const imagePromises = [];

        site.forEach((site) => {
          const imageBody = {
            downloadUrl,
            path,
            site,
            category: categoryId,
            subcategory: subcategoryId,
            style: styleId,
            organization: orgId,
          };

          imagePromises.push(this.imageService.createImageRecord(imageBody));
        });
        await Promise.all(imagePromises);
      } else {
        const imageBody = {
          downloadUrl,
          path,
          site: site?.length === 1 ? site[0] : null,
          category: categoryId,
          subcategory: subcategoryId,
          style: styleId,
          organization: orgId,
        };
        this.progress = 90;
        await this.imageService.createImageRecord(imageBody);
      }
    } catch (error) {
      throw error;
    }
  };
}
