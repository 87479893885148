import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StaffDto } from '@interfaces/staff';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/core/services/alert.service';
import { SiteService } from 'src/app/core/services/site.service';
import { StaffService } from 'src/app/core/services/staff.service';

@Component({
  selector: 'app-edit-staff',
  templateUrl: './edit-staff.component.html',
  styleUrls: ['./edit-staff.component.scss'],
})
export class EditStaffComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitting = false;
  private destroyed = new Subject<boolean>();

  constructor(
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private siteService: SiteService,
    private staffService: StaffService
  ) {}

  ngOnInit(): void {
    this.resetForm();
    this.staffService.refreshStaff();
    this.staffService.staff
      .pipe(takeUntil(this.destroyed))
      .subscribe((staff: StaffDto[]) => {
        this.resetForm();
        // If no existing Staff entries, add empty item
        // Else, add entry for each Staff
        if (!staff || !staff.length) {
          this.addItem();
        } else {
          staff.forEach((staff) => {
            this.addItem(staff);
          });
        }
      });
  }

  private resetForm(): void {
    this.form = new UntypedFormGroup({
      items: this.formBuilder.array([]),
    });
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  private initStaffItem(staffItem?: StaffDto) {
    return this.formBuilder.group({
      title: [staffItem ? staffItem.title : '', Validators.required],
      name: [staffItem ? staffItem.name : '', Validators.required],
      deleted: [staffItem ? staffItem.deleted : false],
      id: [staffItem ? staffItem.id : null],
    });
  }

  public addItem(staffItem?: StaffDto): void {
    const control = this.form.controls['items'] as UntypedFormArray;
    control.push(this.initStaffItem(staffItem));
  }

  public removeItem(i: number): void {
    const control = this.form.controls['items'] as UntypedFormArray;
    control.removeAt(i);
  }

  public markDeleted(staffItemControl): void {
    const deletedControl = staffItemControl.controls['deleted'];
    deletedControl.patchValue(true);
  }

  public save(): void {
    this.submitting = true;
    const formValues = this.form.value.items || [];

    // Need to set siteId to associate with Site
    formValues.forEach((element: StaffDto) => {
      return (element.site = this.siteService.currentSiteId);
    });

    this.staffService
      .updateStaff(formValues)
      .then(() => {
        this.staffService.refreshStaff();
        this.alertService.success('Team member saved.');
      })
      .catch((error) => {
        this.alertService.error(
          'Unable to save team member. Please try again.'
        );
      })
      .finally(() => (this.submitting = false));
  }
}
