<p class="subtitle flex w-full justify-center">Please login to proceed.</p>

<form
  [formGroup]="form"
  (submit)="signIn()"
  class="min-w-full w-full h-full min-h-full m-0"
>
  <mat-form-field
    class="min-w-full w-full h-full min-h-full"
    appearance="outline"
    placeholder="Your email"
    color="accent"
  >
    <mat-label color="accent">Email</mat-label>
    <input
      autofocus
      matInput
      required
      formControlName="email"
      type="text"
      data-qa="login-input"
    />
    <mat-error
      *ngIf="
        form.controls['email'].hasError('email') &&
        form.controls['email'].touched
      "
    >
      Incorrect email format.
    </mat-error>
    <mat-error
      *ngIf="
        form.controls['email'].hasError('required') &&
        form.controls['email'].touched
      "
    >
      Email required.
    </mat-error>
  </mat-form-field>
  <mat-form-field
    class="min-w-full w-full h-full min-h-full m-0"
    appearance="outline"
    placeholder="Your password"
    color="accent"
  >
    <mat-label color="accent">Password</mat-label>
    <input
      autofocus
      matInput
      required
      formControlName="password"
      type="password"
      data-qa="password-input"
    />
    <mat-error
      *ngIf="
        form.controls['password'].hasError('required') &&
        form.controls['password'].touched
      "
    >
      Password required.
    </mat-error>
  </mat-form-field>
  <button
    mat-raised-button
    color="accent"
    [ngClass]="{ 'is-loading': loading }"
    [disabled]="!form.valid"
    data-qa="login-button"
  >
    Login
  </button>
</form>
<mat-nav-list>
  <mat-list-item
    routerLinkActive="active"
    [routerLink]="['reset-password']"
    data-qa="login-navigate-reset-password"
  >
    <div class="flex items-center justify-center">
      <a matListItemLine>Reset Password</a>
    </div>
  </mat-list-item>
</mat-nav-list>
