import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

export interface ConfirmationDialogValues {
  message?: string;
  messageDisclaimer?: string;
  title?: string;
  noText?: string;
  yesText?: string;
  onConfirm?: Function;
  onError?: Function;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  public isLoading = new BehaviorSubject<boolean>(false);
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogValues,
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onConfirmClick = async ($event) => {
    // check for callback to run before closing the dialog
    if (this.data.onConfirm) {
      this.isLoading.next(true);
      try {
        await this.data.onConfirm();
      } catch (error) {
        // check for callback for error handling
        if (this.data.onError) {
          this.data.onError();
          this.isLoading.next(false);
          return;
        } else {
          console.error(error);
        }
      }
    }
    this.dialogRef.close($event);
  };

  ngOnInit() {}
}
