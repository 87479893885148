export function activityTimeOffset(startTime, endTime, savedStartTime) {
  // This autofills eventEnd to be an hour after eventStart if eventEnd has no previous value OR if eventEnd is before eventStart
  if (!startTime) {
    return null;
  }

  const startArray: number[] = splitTime(startTime);

  if (!endTime) {
    // Set end time 1 hour later if not current end time
    startArray[0] = (startArray[0] + 1) % 24;

    return joinTime(startArray);
  } else if (savedStartTime) {
    // Maintain event duration when start time is altered
    const endArray: number[] = splitTime(endTime);
    const savedArray: number[] = splitTime(savedStartTime);

    const hourDif = startArray[0] - savedArray[0];
    const minuteDif = startArray[1] - savedArray[1];

    // Overflow allows changes that take the minutes over 60 or under 0 to affect the hour outcome
    const minuteOverflow = Math.floor((endArray[1] + minuteDif) / 60);

    endArray[0] = mod(endArray[0] + hourDif + minuteOverflow, 24);
    endArray[1] = mod(endArray[1] + minuteDif, 60);

    return joinTime(endArray);
  }
}

// Modulo that accounts for negative numbers
function mod(value: number, n: number) {
  return ((value % n) + n) % n;
}

// Breaks times as strings to be split into usable numbers
function splitTime(time: string) {
  return time.split(':').map((num) => Number(num));
}

// Joins together the number array into a usable string format to represent time
function joinTime(array: number[]) {
  return array
    .map((num) => (num.toString().length < 2 ? '0' + num : num))
    .join(':');
}
