<div [formGroup]="form" class="flex flex-col gap-12">
  <div class="flex flex-col gap-1.5">
    <div class="flex flex-col gap-1.5">
      <div class="flex gap-12">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Name</mat-label>
          <input
            maxLength="21"
            matInput
            autofocus
            required
            formControlName="name"
          />
          <mat-hint>{{ form?.controls?.name.value?.length || 0 }}/21</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Short Name</mat-label>
          <input maxLength="3" matInput required formControlName="shortName" />
          <mat-hint
            >{{ form?.controls?.shortName.value?.length || 0 }}/3</mat-hint
          >
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
