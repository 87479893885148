import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

import { Faq } from '@models/faq';
import { AlertService } from 'src/app/core/services/alert.service';
import { FaqService } from 'src/app/core/services/faq.service';
import { EditFaqComponent } from '../edit-faq/edit-faq.component';
import { AddFaqComponent } from '../add-faq/add-faq.component';
import {
  ConfirmationDialogValues,
  ConfirmationDialogComponent,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import { GenericFEDataSource } from 'src/app/shared/pagination-client-side/datasources/genericFE.datasource';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import bootstrapTable from 'src/app/shared/pagination-server-side/bootstrap-table';

interface FaqRow {
  id: string;
  question: string;
  answer: string;
}

@Component({
  selector: 'app-faqs-table',
  templateUrl: './faqs-table.component.html',
  styleUrls: ['./faqs-table.component.scss'],
})
export class FaqsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() set filter(filterValue: string) {
    if (this.dataSource) {
      this.searchFilter$.next(filterValue);
    }
  }
  private searchFilter$ = new Subject();
  private destroyed = new Subject<boolean>();

  public dataSource: MatTableDataSource<FaqRow>;
  public paginatedDataSource: GenericFEDataSource<Faq>;
  public displayedColumns = ['id', 'question', 'answer', 'actions'];

  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(''),
    verified: new UntypedFormControl(false),
    showDeleted: new UntypedFormControl(false),
  });

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    public faqService: FaqService,
  ) {}

  ngOnInit() {
    // Ensure latest FAQs for active Site are loaded
    this.faqService.refreshFaqs();
    this.dataSource = new MatTableDataSource([]);

    this.paginatedDataSource = new GenericFEDataSource<Faq>(
      this.faqService.faqs,
      {
        sortingDataAccessor: this.sortingDataAccessor,
        filterBySearch: this.filterBySearch,
      },
    );
  }

  ngAfterViewInit(): void {
    bootstrapTable<Faq[]>(
      this.sort,
      this.paginator,
      this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
      this.filters,
    )
      .pipe(takeUntil(this.destroyed))
      .subscribe();

    this.searchFilter$.pipe(takeUntil(this.destroyed)).subscribe((value) => {
      this.filters.controls['search'].setValue(value);
    });
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  public editFaq(faq: Faq): void {
    const dialogRef = this.dialog.open(EditFaqComponent, {
      data: faq,
      minWidth: 400,
    });
  }

  public deleteFaq(faq: Faq): void {
    const confirmData: ConfirmationDialogValues = {
      message: 'Do you want to delete this FAQ? This cannot be undone.',
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.faqService.deleteFaq(faq.id).then(() => {
          this.faqService.refreshFaqs();
          this.alertService.success('Faq deleted.');
        });
      }
    });
  }

  public addFaq(): void {
    const dialogRef = this.dialog.open(AddFaqComponent, {
      minWidth: 400,
    });
  }

  sortingDataAccessor(item, property): string | number {
    switch (property) {
      case 'id': {
        return item.id ? Number(item.id) : 0;
      }
      case 'question': {
        // Custom sort: default sort by category, secondary sort by subcategory
        return `${item.question ? item.question.toLowerCase() : ''}`;
      }
      case 'answer': {
        // Custom sort: default sort by category, secondary sort by subcategory
        return `${item.answer ? item.answer.toLowerCase() : ''}`;
      }
      default:
        return item[property];
    }
  }

  // search by id, question or answer
  filterBySearch(data, filter): boolean {
    const lowercaseFilter = filter.toLowerCase();
    const idMatch = data.id ? data.id.toString().indexOf(lowercaseFilter) : -1;
    const questionMatch = data.question
      ? data.question.toLowerCase().indexOf(lowercaseFilter)
      : -1;
    const answerMatch = data.answer
      ? data?.answer.toLowerCase().indexOf(lowercaseFilter)
      : -1;

    return idMatch !== -1 || questionMatch !== -1 || answerMatch !== -1;
  }
}
