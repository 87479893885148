import { DataSource } from '@angular/cdk/collections';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, finalize, switchMap } from 'rxjs/operators';

export interface PaginatedResponse<T> {
  totalCount: number;
  data: T[];
}

export interface PaginationInformation {
  filters: {
    search?: string;
    searchBy?: { key: string; value: string }[];
    [key: string]: string | boolean | { key: string; value: string }[];
  };
  sort?: Sort;
  pagination?: PageEvent;
}

export type ServiceMethod<T> = (
  queryParams: HttpParams,
  PaginationInformation: PaginationInformation,
  additionalParams?: unknown,
) => Observable<PaginatedResponse<T>>;

export class GenericBEDataSource<T> implements DataSource<T> {
  // This dataSubject is used by the table to get the data
  public dataSubject = new BehaviorSubject<T[]>([]);

  // Loading subject to trigger loading states
  private loadingSubject = new BehaviorSubject<boolean>(true);

  // Method in a service to get our data
  private serviceMethod: ServiceMethod<T>;

  // Additional params from our component that our service method needs to know about
  private additionalParams: unknown;

  // Previous pagination information
  private previousPaginationInformation: PaginationInformation;

  // Loading observable for use with async pipe in template
  public loading$ = this.loadingSubject.asObservable().pipe(
    switchMap((isLoading) => {
      return isLoading ? of(isLoading) : of(isLoading).pipe(delay(250));
    }),
  );

  // Total count is updated when we get a response from the API
  public totalCount = 0;
  public $totalCount = new BehaviorSubject(0);

  // Error Response
  public error: HttpErrorResponse;

  constructor(serviceMethod: ServiceMethod<T>, additionalParams?: unknown) {
    this.serviceMethod = serviceMethod;
    this.additionalParams = additionalParams;
  }

  // The workhorse function of the class
  // We call our service method, handle loading state, and push data through our dataSubject
  loadData(paginationInformation: PaginationInformation) {
    this.loadingSubject.next(true);
    this.previousPaginationInformation = paginationInformation;

    let queryParams = new HttpParams({
      fromObject: {
        sortBy: paginationInformation.sort?.active,
        sortDirection: paginationInformation.sort?.direction,
        offset: paginationInformation.pagination.pageIndex.toString(),
        limit: paginationInformation.pagination.pageSize.toString(),
      },
    });

    if (paginationInformation.filters.search) {
      queryParams = queryParams.append(
        'search',
        paginationInformation.filters.search,
      );
    }

    if (
      paginationInformation.filters.search &&
      paginationInformation.filters.searchBy
    ) {
      paginationInformation.filters.searchBy.forEach((searchBy) => {
        queryParams = queryParams.append(`searchBy[]`, searchBy.key);
      });
    }

    this.serviceMethod(
      queryParams,
      paginationInformation,
      this.additionalParams,
    )
      .pipe(
        catchError((error) => {
          this.error = error;
          return of([]);
        }),
        finalize(() => this.loadingSubject.next(false)),
      )
      .subscribe((response: PaginatedResponse<T>) => {
        this.totalCount = response.totalCount;
        this.$totalCount.next(response.totalCount);
        this.dataSubject.next(response.data);
      });
  }

  refreshData() {
    this.loadData(this.previousPaginationInformation);
  }

  // Used by the material table to connect to the datasource
  connect(): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  // Used by the material table to perform cleanup when the table is destroyed
  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }
}
