import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

import { AlertService } from '../../../core/services/alert.service';
import { MenuService } from '../../../core/services/menu.service';
import { MenuUploadDialogComponent } from '../menu-upload-dialog/menu-upload-dialog.component';

@Component({
  selector: 'app-menu-upload-button',
  templateUrl: './menu-upload-button.component.html',
  styleUrls: ['./menu-upload-button.component.scss'],
})
export class MenuUploadButtonComponent implements OnInit {
  @Output() refreshTrigger = new EventEmitter<boolean>();
  @ViewChild('fileInput', { static: true }) fileInput;

  constructor(
    private menuService: MenuService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  /**
   * Reacts to file selected from the input, calls the parse method, then passes it off to the
   * appropriate handling method
   */
  public async onFileAdded() {
    try {
      const files = this.fileInput.nativeElement.files;
      const menus = await this.menuService.parseCsv(files[0]);
      if (menus.length > 0) {
        const dialogRef = this.dialog.open(MenuUploadDialogComponent, {
          data: { menus },
          width: '600px',
        });
        dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe((result) => {
            if (result.refresh) {
              this.refreshTrigger.emit(true);
            }
          });
      } else {
        this.alertService.error('CSV was empty or did not contain valid data.');
      }
    } catch (error) {
      this.alertService.error('There was an error parsing the CSV');
      console.error(error);
    }
  }

  public clickUpload() {
    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.click();
  }
}
