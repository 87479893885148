import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { ParticipantService } from 'src/app/core/services/participant.service';
import { MobileUseTableComponent } from 'src/app/shared/mobile-use-table/mobile-use-table.component';
import { EditParticipantComponent } from '../edit-participant/edit-participant.component';
import { EditUserComponent } from '../edit-user/edit-user.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-org-mobile-use',
  templateUrl: './org-mobile-use.component.html',
  styleUrls: ['./org-mobile-use.component.scss'],
})
export class OrgMobileUseComponent implements OnInit, OnDestroy {
  @ViewChild('orgTable') set tableRef(ref: MobileUseTableComponent) {
    if (ref) {
      this.mobileUseTableRef = ref;
    }
  }
  public mobileUseTableRef: MobileUseTableComponent;

  public searchFilter = '';
  public isRefreshing = false;
  public refreshTrigger = 0;
  constructor(
    public participantService: ParticipantService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  public exportCsv() {
    this.mobileUseTableRef.exportCsv();
  }

  public search(value) {
    this.searchFilter = value;
  }

  public editParticipant(data) {
    const dialogRef = this.dialog.open(EditParticipantComponent, {
      data: data.participant,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.refreshTrigger = data.updateTrigger + 1; // refreshed nested table data
        this.refresh();
      }
    });
  }

  public refresh = () => {
    this.isRefreshing = true;
    this.participantService.refreshParticipants().then(() => {
      this.isRefreshing = false;
    });
  };
}
