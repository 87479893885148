import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { map, skip, distinctUntilChanged } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Category } from '@models/category';
import { Organization } from '@models/organization';
import { CategoryDto } from '@interfaces/category';
import { OrgService } from './org.service';
import { AuthService } from './auth.service';
import { SubcategoryService } from './subcategory.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private _categories = new BehaviorSubject<Category[]>([]);
  public readonly categories = this._categories.asObservable();

  constructor(
    private http: HttpClient,
    private orgService: OrgService,
    private authService: AuthService,
    private subcategoryService: SubcategoryService,
  ) {
    this._categorySubscriptions();
  }

  private _categorySubscriptions(): void {
    this._listenForOrgUpdates();
    this._listenForSubcategoryUpdates();
  }

  get currentCategories(): CategoryDto[] {
    return this._categories.getValue();
  }

  public async refreshCategories() {
    await this.getCategories(this.authService.currentOrgId)
      .then((categories: Category[]) => {
        this._categories.next(categories);
      })
      .catch((error) => console.error(error));
  }

  private _listenForOrgUpdates(): void {
    this.orgService.org.subscribe((org: Organization) => {
      this.refreshCategories();
    });
  }

  private _listenForSubcategoryUpdates(): void {
    this.subcategoryService.subcategories
      .pipe(
        distinctUntilChanged(),
        skip(1), // initial load should not trigger refresh, only worry about newly created subcategories
      )
      .subscribe(() => {
        this.refreshCategories();
      });
  }

  private getCategories(orgId: number): Promise<Category[]> {
    const url = `${environment.apiv3Url}/categories/organization/${orgId}`;

    try {
      return <Promise<Category[]>>(
        lastValueFrom(
          this.http
            .get(url)
            .pipe(
              map((backgrounds: Category[]) =>
                backgrounds.map((category) => new Category(category)),
              ),
            ),
        )
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  // Used in batch event form and event details component
  getCategoryForSubcategory(id: number): CategoryDto {
    const category = this.currentCategories.find((cat) => {
      return cat.subcategories.find((subcategory) => subcategory.id === id);
    });
    if (category) {
      return category;
    } else {
      return this.subcategoryService.currentCategoryList.find((cat) => {
        return cat.subcategories.find((subcategory) => subcategory.id === id);
      });
    }
  }
}
