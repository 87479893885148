import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { Announcement } from '@models/announcement';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from 'src/app/core/services/alert.service';
import { ManageService } from 'src/app/core/services/manage.service';
import { RecurrenceService } from 'src/app/core/services/recurrence.service';

export interface EventDeleteDialogValues {
  message?: string;
  title?: string;
  noText?: string;
  yesText?: string;
  onError?: Function;
  onSuccess?: Function;
  activity: Announcement;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delete-recurrence-dialog',
  templateUrl: './delete-recurrence-dialog.component.html',
  styleUrls: ['./delete-recurrence-dialog.component.scss'],
})
export class DeleteRecurrenceDialogComponent implements OnInit {
  public isLoading = new BehaviorSubject<boolean>(false);
  public eventDeleteForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DeleteRecurrenceDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private manageService: ManageService,
    private recurrenceService: RecurrenceService,
    private alertService: AlertService,

    @Inject(MAT_DIALOG_DATA) public data: EventDeleteDialogValues,
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onConfirmClick = async ($event) => {
    this.isLoading.next(true);
    try {
      await this.handleDelete();
    } catch (error) {
      // check for callback for error handling
      if (this.data.onError) {
        if (this.data.onError) {
          this.data.onError();
        }
        this.isLoading.next(false);
        return;
      } else {
        console.error(error);
      }
    }
    this.dialogRef.close($event);
  };

  public updateRecurrence(rule) {}

  public async handleDelete() {
    if (this.eventDeleteForm.controls['delete'].value === 'single') {
      /**
       * Deleting one instance of this announcement, not all in the series:
       * update the event recurrence (at id) with this new exception, delete this announcement
       */
      const startFromISO = DateTime.fromISO(this.data.activity.eventStart)
        .setZone(this.data.activity.site.timezone)
        .toFormat('yyyy-MM-dd');

      // add new date to existing recurrence exception list
      let exdates: string[] = [];
      if (this.data.activity.recurrence.exdates) {
        exdates.push(...this.data.activity.recurrence.exdates);
        exdates.push(startFromISO);
      } else {
        exdates = [startFromISO];
      }

      // update recurrence with new exception
      const recurrence = await this.recurrenceService.saveRecurrence({
        ...this.data.activity.recurrence,
        exdates,
      });

      // delete this announcement
      await Promise.all([
        this.manageService.deleteAnnouncement(this.data.activity),
        // this.activityService.refreshActivities(this.data.selectedMonth, false),
      ]);
      this.alertService.success(`"${this.data.activity.title}" deleted.`);
      this.dialogRef.close();
    } else if (this.eventDeleteForm.controls['delete'].value === 'future') {
      // update recurrence with new cut-off date
      await this.recurrenceService.updateUntilFromActivity(this.data.activity);
      // delete relevant announcements
      await this.recurrenceService.deleteThisAndFutureRecurringAnnouncements(
        this.data.activity,
      );
      this.alertService.success(`"${this.data.activity.title}" deleted.`);
      this.dialogRef.close();
    } else {
      try {
        // get all events with this recurring ID
        const response: Array<Announcement> =
          await this.recurrenceService.getRecurringAnnouncementsByAnnouncement(
            this.data.activity,
          );
        await Promise.all([
          this.manageService.deleteBulkAnnouncements(response),
          this.recurrenceService.deleteRecurrence(this.data.activity),
        ]);
        this.alertService.success(
          `"${this.data.activity.title}" recurring event deleted.`,
        );
      } catch (error) {
        console.error(error);
      }

      this.dialogRef.close();
    }
    if (this.data.onSuccess) {
      this.data.onSuccess();
    }
    // if delete single, update recurring events with exception, delete event as usual
    // if delete all, delete all events with this recurrence id
  }

  ngOnInit() {
    this.eventDeleteForm = this.formBuilder.group({
      delete: ['single', Validators.required],
    });
  }
}
