import { Component, OnInit, ViewChild } from '@angular/core';
import { filter } from 'rxjs';
import { SiteService } from 'src/app/core/services/site.service';
import { MenuTableComponent } from 'src/app/shared/menu-table/menu-table.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @ViewChild(MenuTableComponent) menuTable: MenuTableComponent;
  public site = this.siteService.site.pipe(filter((site) => site !== null));
  public searchString: string;

  constructor(private siteService: SiteService) {}

  ngOnInit(): void {}

  refresh() {
    this.menuTable.refresh();
  }
}
