import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { User } from '@models/user';
import { filter, take } from 'rxjs/operators';
@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss'],
})
export class SignInFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public loading = false;

  constructor(
    private alert: AlertService,
    public auth: AuthService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
  ) {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {}

  public signIn() {
    this.loading = true;
    const email = this.form.value.email;
    const password = this.form.value.password;
    this.auth
      .signIn(email, password)
      .then((val) => {
        this.auth.user
          .pipe(
            filter((user) => user !== null),
            take(1),
          )
          .subscribe((user: User) => {
            this.loading = false;
            this.router.navigate([`/manage/content/${user.defaultSiteId}`]);
          });
      })
      .catch((error) => {
        this.loading = false;
        this.alert.warning(error.message);
      });
  }
}
