import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { ParticipantService } from 'src/app/core/services/participant.service';
import { MobileUseTableComponent } from 'src/app/shared/mobile-use-table/mobile-use-table.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-summary',
  templateUrl: './mobile-use.component.html',
  styleUrls: ['./mobile-use.component.scss'],
})
export class MobileUseComponent implements OnInit, OnDestroy {
  @ViewChild('siteTable') set tableRef(ref: MobileUseTableComponent) {
    if (ref) {
      this.mobileUseTableRef = ref;
    }
  }
  public mobileUseTableRef: MobileUseTableComponent;

  public searchFilter = '';
  public isRefreshing = false;
  constructor(public participantService: ParticipantService) {}

  ngOnInit() {}

  ngOnDestroy() {}

  public exportCsv() {
    this.mobileUseTableRef.exportCsv();
  }

  public search(value) {
    this.searchFilter = value;
  }

  public refresh = () => {
    this.isRefreshing = true;
    this.participantService.refreshParticipants().then(() => {
      this.isRefreshing = false;
    });
  };
}
