import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';

import { ImageService } from 'src/app/core/services/image.service';
import { AlertService } from 'src/app/core/services/alert.service';

export interface UploadOptions {
  filePath: string;
  fileTypeOverride: Array<string>; // If you want to restrict which file types are acceptable, else []
  intermediarySteps: Array<'fileName' | 'caption'>; // Optional extra steps before uploading
  imageType: 'siteLogo' | 'sitePrintLogo' | 'siteImage' | 'newsletterAsset';
  siteId: number;
  uploadName:
    | 'GiGi Mobile App Logo'
    | 'Property Image'
    | 'Article Image'
    | 'Print Calendar Logo'
    | 'Newsletter Attachment'; // used for modal title
  uploadHelper?: string;
  assetType: 'Image' | 'Attachment'; // used for UI copy, doesn't change workflow
  captionRequired?: boolean;
  captionMaxLength?: number;
}

export interface UploadResponse {
  success: boolean;
  path: string;
  fileName: string;
  imageCaption?: string;
}

@Component({
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  public image: string; // base64 string
  public imageCaption: string;
  public fileName: string;
  public uploading = false;

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ImageUploadComponent>,
    private imageService: ImageService,
    @Inject(MAT_DIALOG_DATA) public uploadOptions: UploadOptions
  ) {}

  ngOnInit() {}

  /**
   * Dynamically populate the <input type=file>'s accept attribute
   */
  get inputTypesAllows() {
    // By default, all image types
    if (
      !this.uploadOptions ||
      !this.uploadOptions.fileTypeOverride ||
      !this.uploadOptions.fileTypeOverride.length
    ) {
      return 'image/*';
    }

    return this.uploadOptions.fileTypeOverride;
  }

  get intermediaryStepsCount() {
    return this.uploadOptions && this.uploadOptions.intermediarySteps
      ? this.uploadOptions.intermediarySteps.length
      : 0;
  }

  get fileNameStepEnabled() {
    return (
      this.uploadOptions &&
      this.uploadOptions.intermediarySteps &&
      this.uploadOptions.intermediarySteps.includes('fileName')
    );
  }

  get captionStepEnabled() {
    return (
      this.uploadOptions &&
      this.uploadOptions.intermediarySteps &&
      this.uploadOptions.intermediarySteps.includes('caption')
    );
  }

  public fileChangeListener($event) {
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();
    const that = this;
    myReader.onloadend = function (loadEvent: any) {
      that.image = loadEvent.target.result;
      that.fileName = file.name;
    };

    myReader.readAsDataURL(file);
  }

  public async save() {
    this.uploading = true;
    const blob = this.imageService.dataURItoBlob(this.image);

    if (
      await !this.imageService.isAllowedFileType(
        blob,
        this.uploadOptions.fileTypeOverride
      )
    ) {
      this.uploading = false;
      this.alertService.error(
        `Please choose a ${this.uploadOptions.fileTypeOverride.join(', ')} file`
      );
      return;
    }

    let path = this.uploadOptions.filePath;
    if (this.uploadOptions.imageType === 'newsletterAsset') {
      path = `${path}/${this.fileName}`;
    }
    this.imageService
      .uploadToFirestore(path, blob)
      .then((fullPath: string) => {
        this.alertService.success(`${this.uploadOptions.assetType} uploaded.`);
        const uploadResponse: UploadResponse = {
          success: true,
          path: fullPath,
          fileName: this.fileName,
          imageCaption: this.imageCaption,
        };
        this.dialogRef.close(uploadResponse);
      })
      .catch((error) => {
        console.error(error);
        this.alertService.error(
          `Error uploading ${this.uploadOptions.assetType.toLocaleLowerCase()}. Please try again.`
        );
      })
      .finally(() => (this.uploading = false));
  }

  public editSiteLogo(siteId: number) {
    // Enforce that the image is a .png; historically the mobile app has been hardcoded
    // to look specifically for .pngs in Firebase Storage for Site Logos
    const uploadOptions: UploadOptions = {
      filePath: this.imageService.getSiteLogoImageFilePath(siteId),
      fileTypeOverride: ['image/png'],
      imageType: 'siteLogo',
      intermediarySteps: [],
      siteId: siteId,
      uploadName: 'GiGi Mobile App Logo',
      uploadHelper: 'For best results, use a 600x152 transparent .png image',
      assetType: 'Image',
    };

    const dialogRef = this.dialog.open(ImageUploadComponent, {
      data: uploadOptions,
    });
    return dialogRef;
  }

  public editSitePrintLogo(siteId: number) {
    // Enforce that the image is a .png; historically the mobile app has been hardcoded
    // to look specifically for .pngs in Firebase Storage for Site Logos
    const uploadOptions: UploadOptions = {
      filePath: this.imageService.getSiteLogoPrintImageFilePath(siteId),
      fileTypeOverride: ['image/png'],
      imageType: 'sitePrintLogo',
      intermediarySteps: [],
      siteId: siteId,
      uploadName: 'Print Calendar Logo',
      uploadHelper:
        'For best results, use a transparent .png image with a height of 96 pixels and a max width of 870 pixels',
      assetType: 'Image',
    };

    const dialogRef = this.dialog.open(ImageUploadComponent, {
      data: uploadOptions,
    });
    return dialogRef;
  }

  public editImage(siteId: number) {
    // Enforce that the image is a .png; historically the mobile app has been hardcoded
    // to look specifically for .pngs in Firebase Storage for Site Images
    const uploadOptions: UploadOptions = {
      filePath: this.imageService.getSitePrimaryImageFilePath(siteId),
      fileTypeOverride: ['image/png'],
      imageType: 'siteImage',
      intermediarySteps: [],
      siteId: siteId,
      uploadName: 'Property Image',
      uploadHelper: 'For best results, use a 1000x600 .png image',
      assetType: 'Image',
    };

    const dialogRef = this.dialog.open(ImageUploadComponent, {
      data: uploadOptions,
    });
    return dialogRef;
  }

  public editNewsletterAsset(
    siteId: number,
    newsletterId: number,
    uploadName: 'Article Image' | 'Newsletter Attachment',
    assetType: 'Image' | 'Attachment',
    fileType: string[] = [],
    captionMaxlength: number = null
  ) {
    const uploadOptions: UploadOptions = {
      filePath: this.imageService.getNewsletterAssetFilePath(
        siteId,
        newsletterId
      ),
      fileTypeOverride: fileType,
      imageType: 'newsletterAsset',
      intermediarySteps: ['fileName', 'caption'],
      siteId: siteId,
      uploadName: uploadName,
      assetType: assetType,
      captionRequired: false,
      captionMaxLength: captionMaxlength,
    };

    const dialogRef = this.dialog.open(ImageUploadComponent, {
      data: uploadOptions,
    });
    return dialogRef;
  }
}
