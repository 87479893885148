import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Article } from '@models/article';

@Component({
  selector: 'app-view-article',
  templateUrl: './view-article.component.html',
  styleUrls: ['./view-article.component.scss'],
})
export class ViewArticleComponent implements OnInit {
  @Input() article: Article;
  @Output() public hide: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public triggerHideComponent(): void {
    this.hide.next(true);
  }
}
