import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SummaryComponent } from './announcements/summary/summary.component';
import { AddAnnouncementComponent } from '../manage/announcements/add-announcement/add-announcement.component';
import { ActivitiesComponent } from './activities/activities.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { RoomsComponent } from './rooms/rooms.component';
import { MobileUseComponent } from './mobile-use/mobile-use.component';
import { NewslettersComponent } from './newsletters/newsletters.component';
import { ViewNewsletterComponent } from './newsletters/view-newsletter/view-newsletter.component';
import { EditArticleCanDeactivateGuard } from '../core/guards/edit-article.can-deactivate.guard';
import { MenusComponent } from './menus/menus.component';

const routes: Routes = [
  {
    path: 'activities',
    component: ActivitiesComponent,
  },
  {
    path: 'announcements',
    component: SummaryComponent,
  },
  {
    path: 'announcements/add',
    component: AddAnnouncementComponent,
  },
  {
    path: 'announcements/edit/:announcementId',
    component: AddAnnouncementComponent,
  },
  {
    path: 'mobile-use',
    component: MobileUseComponent,
  },
  {
    path: 'newsletters',
    component: NewslettersComponent,
  },
  {
    path: 'newsletters/:newsletterId',
    component: ViewNewsletterComponent,
    canDeactivate: [EditArticleCanDeactivateGuard],
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
  },
  {
    path: 'rooms',
    component: RoomsComponent,
  },
  {
    path: 'menus',
    component: MenusComponent,
  },
  {
    path: '',
    redirectTo: 'announcements',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [EditArticleCanDeactivateGuard],
})
export class ManageRoutingModule {}
