<div class="loading-wrapper">
  <div class="loading-spinner" *ngIf="paginatedDataSource.loading$ | async">
    <app-material-loader [fillContainer]="true"></app-material-loader>
  </div>
  <div
    class="loading-overlay"
    *ngIf="paginatedDataSource.loading$ | async"
  ></div>
  <table
    mat-table
    matSort
    matSortActive="lastLogin"
    matSortDirection="desc"
    [dataSource]="paginatedDataSource"
  >
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        <i>{{
          this.filters.controls.search?.value?.length > 0
            ? 'No users match this search.'
            : (paginatedDataSource.loading$ | async)
            ? ''
            : 'No active mobile users.'
        }}</i>
      </td>
    </tr>
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        First Name
      </th>
      <td mat-cell *matCellDef="let participant">
        {{ participant.user.firstName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Last Name
      </th>
      <td mat-cell *matCellDef="let participant">
        {{ participant.user.lastName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="property">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Property
      </th>
      <td mat-cell *matCellDef="let participant">
        {{ participant.site?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Role
      </th>
      <td mat-cell *matCellDef="let participant">
        {{ participant.user.role.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Email
      </th>
      <td mat-cell *matCellDef="let participant">
        {{ participant.user.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>
        <span class="sticky-heading">Phone</span>
      </th>
      <td mat-cell *matCellDef="let participant">{{ participant.phone }}</td>
    </ng-container>

    <ng-container matColumnDef="birthday">
      <th mat-header-cell *matHeaderCellDef disableClear>
        <span class="sticky-heading">Birthday</span>
      </th>
      <td mat-cell *matCellDef="let participant">
        {{ participant.birthdate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastLogin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Last Login
      </th>
      <td mat-cell *matCellDef="let participant">
        {{
          participant.lastLogin ? (participant.lastLogin | date : 'short') : ''
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalLogins">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Total Logins
      </th>
      <td mat-cell *matCellDef="let participant">
        {{ participant.totalLogins ? participant.totalLogins : 0 }}
      </td>
    </ng-container>

    <ng-container *ngIf="userStyle === 'org'" matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let user" class="action-wrapper">
        <button
          mat-icon-button
          color="accent"
          (click)="editParticipant(user)"
          matTooltip="Edit User Details"
        >
          <mat-icon>edit_pencil</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [length]="paginatedDataSource?.totalCount"
    [pageSize]="10"
    [pageSizeOptions]="[10, 20, 30, 50]"
    showFirstLastButtons
  ></mat-paginator>
</div>
