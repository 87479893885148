<div class="flex justify-between items-start content-start">
  <h1 mat-dialog-title class="mat-dialog-title text-2xl">Add New Property</h1>
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <app-site-form [form]="siteForm"></app-site-form>
</mat-dialog-content>
<mat-dialog-actions class="flex !justify-center items-end content-end pb-4">
  <button mat-button (click)="onCancel()">Cancel</button>
  <app-progress-button
    color="accent"
    text="Save"
    (click)="onSave()"
    [disabled]="siteForm.invalid"
    [submitting]="submitting"
  >
  </app-progress-button>
</mat-dialog-actions>
