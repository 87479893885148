<div class="flex flex-col justify-start items-start content-start gap-[10px]">
  <h1 mat-dialog-title>Upload {{ uploadOptions.uploadName }}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content
    class="flex flex-col gap-4"
    [class.no-y-scroll]="uploading"
  >
    <div class="flex flex-col gap-[10px]">
      <p class="overview-info">Step 1: Select {{ uploadOptions.assetType }}</p>
      <h3>{{ uploadOptions.uploadHelper }}</h3>
      <input
        type="file"
        [accept]="inputTypesAllows"
        (change)="fileChangeListener($event)"
      />
    </div>

    <ng-container *ngIf="fileNameStepEnabled">
      <p class="overview-info">Step 2: Confirm File Name</p>
      <mat-form-field color="accent" appearance="outline" class="w-full">
        <mat-label>File Name</mat-label>
        <input matInput required [(ngModel)]="fileName" [disabled]="!image" />
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="captionStepEnabled">
      <p class="overview-info">
        Step 3: Write {{ uploadOptions.assetType }} Caption
      </p>
      <mat-form-field color="accent" appearance="outline" class="w-full">
        <mat-label>Caption</mat-label>
        <input
          matInput
          [(ngModel)]="imageCaption"
          [disabled]="!image"
          [required]="uploadOptions.captionRequired"
          [maxlength]="uploadOptions.captionMaxLength"
        />
        <mat-hint *ngIf="uploadOptions.captionMaxLength && image"
          >{{ imageCaption?.length || 0 }}/{{
            uploadOptions.captionMaxLength
          }}
          characters</mat-hint
        >
      </mat-form-field>
    </ng-container>

    <p class="overview-info">Step {{ 2 + intermediaryStepsCount }}: Upload</p>

    <div class="flex justify-center items-center content-center">
      <button mat-button mat-dialog-close>Cancel</button>
      <app-progress-button
        color="accent"
        text="Upload"
        (click)="save()"
        [disabled]="
          !image ||
          (captionStepEnabled &&
            uploadOptions.captionRequired &&
            !imageCaption?.length)
        "
        [submitting]="uploading"
      >
      </app-progress-button>
    </div>
  </mat-dialog-content>
</div>
