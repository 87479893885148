import { Component, OnInit, Inject } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user.service';
import { EmailFormComponent } from 'src/app/shared/forms/email-form/email-form.component';
import { Participant } from '@models/participant';

@Component({
  selector: 'app-edit-participant',
  templateUrl: './edit-participant.component.html',
  styleUrls: ['./edit-participant.component.scss'],
})
export class EditParticipantComponent implements OnInit {
  public emailForm = EmailFormComponent.formModel();
  public submitting = false;

  constructor(
    private alertService: AlertService,
    private dialogRef: MatDialogRef<EditParticipantComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public participant: Participant
  ) {}

  ngOnInit() {
    this.emailForm.setValue({ email: this.participant.user.email });
  }

  public async onSave(): Promise<void> {
    this.submitting = true;

    this.userService
      .updateParticipantEmail(this.participant, this.emailForm.value.email)
      .then(() => {
        this.alertService.success('Participant saved.');
        this.onCancel();
      })
      .catch((error) => {
        const message =
          error?.error?.code === 'auth/email-already-exists'
            ? 'This email is already associated with an account.'
            : 'Unable to add user. Please try again.';
        this.alertService.error(message);
      })
      .finally(() => (this.submitting = false));
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
