import React from 'react';
import { Eventcalendar as MbscEventCalendar } from '@mobiscroll/react';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { EventType } from '../../../../../../../../../core/enums/event-type';
import { Announcement } from '@models/announcement';

type DailyAgendaProps = {
  timezone;
  activeDay;
  activeEvent: Announcement;
  selectedDate: Date;
  loading: boolean;
  events: any[];
};

export function DailyAgenda({
  timezone,
  activeDay,
  activeEvent,
  selectedDate,
  loading,
  events,
}: DailyAgendaProps) {
  function renderHeaderTemplate() {
    return activeDay?.monthLong;
  }

  function renderAgendaListTemplate(data) {
    if (loading) {
      return (
        <div className="w-full h-full flex justify-center items-center">
          <CircularProgress color="secondary" />
        </div>
      );
    }
    return (
      <>
        {data.map((day) => (
          <ul key={day.timestamp}>
            {day.events?.map((event: any) => (
              <li key={event.id}>
                <div className="mbsc-event">
                  <div
                    className={`agenda-wrapper ${
                      event.original.activeEvent ? 'active-event' : ''
                    }`}
                  >
                    <Tooltip title={EventType[event.original.eventType]}>
                      <div
                        className="mbsc-event-type-indicator"
                        style={{ backgroundColor: event.color }}
                      ></div>
                    </Tooltip>

                    {event.allDay ? (
                      <div className="mbsc-event-time italic">
                        All Day Event
                      </div>
                    ) : (
                      <div className="mbsc-event-time">
                        {event.start} - {event.end}
                      </div>
                    )}
                    <div className="mbsc-event-content">
                      <div className="event-title">
                        {event.original.title}
                        <span className="event-location">
                          ({event.original.room.long}
                          {event.original.room.short
                            ? `, ${event.original.room.short}`
                            : ''}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                  {event.original?.recurrence && (
                    <span
                      className="material-icons"
                      title={`Repeats ${event.original?.recurrence}`}
                    >
                      refresh
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ))}
      </>
    );
  }
  return (
    <>
      <MbscEventCalendar
        data={events}
        selectedDate={activeDay}
        renderHeader={renderHeaderTemplate}
        renderAgenda={renderAgendaListTemplate}
        view={{ agenda: { type: 'day' } }}
        dateFormatLong="DDDD, MMM D, YYYY"
        noEventsText="No scheduled events"
      />
      {/* <mbsc-eventcalendar
  [data]="events.value"
  [options]="options"
  [selectedDate]="activeDay"
  [headerTemplate]="customHeader"
  [agendaTemplate]="agendaList"
>
  <ng-template #customHeader>
    {{ activeDay?.monthLong }}
  </ng-template>
  <ng-template #agendaList let-data>
    <div class="agenda-content-wrapper">
      <ng-container *ngIf="loading">
        <mat-spinner diameter="60" color="accent" class="loader"></mat-spinner>
      </ng-container>

      <ng-container *ngIf="!loading">
        <ng-container>
          <ul
            *ngFor="let day of data"
            mbsc-event-list-day
            [timestamp]="day.timestamp"
          >
            <li *ngFor="let event of day.events">
              <div class="mbsc-event">
                <div
                  [ngClass]="{
                    'agenda-wrapper': true,
                    'active-event': event.original.activeEvent
                  }"
                >
                  <div
                    [matTooltip]="eventTypes[event.original.eventType]"
                    class="mbsc-event-type-indicator"
                    [style.backgroundColor]="event.color"
                  ></div>
                  <div
                    class="mbsc-event-time"
                    *ngIf="!event.allDay; else allDay"
                  >
                    {{ event.start }} - {{ event.end }}
                  </div>
                  <ng-template #allDay>
                    <div class="mbsc-event-time italic">All Day Event</div>
                  </ng-template>
                  <div class="mbsc-event-content">
                    <div class="event-title">
                      {{ event.original.title }}
                      <span class="event-location"
                        >({{ event.original.room.long
                        }}{{
                          event.original.room.short
                            ? ', ' + event.original.room.short
                            : ''
                        }})</span
                      >
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="event.original?.recurrence">
                  <span
                    class="material-icons"
                    [matTooltip]="'Repeats ' + event.original?.recurrence"
                  >
                    refresh
                  </span>
                </ng-container>
              </div>
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="events.value.length === 0">
          No Events Scheduled This Day
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</mbsc-eventcalendar> */}
    </>
  );
}
