import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisplayEventComponent } from './display-event/display-event.component';
import { MarkdownModule } from 'ngx-markdown';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DpDatePickerModule } from 'ng2-date-picker';

import { DisplayIndividualSpotlightComponent } from './display-individual-spotlight/display-individual-spotlight.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ClockComponent } from './clock/clock.component';
import { CoreModule } from '../core/core.module';
import { LoadingComponent } from './loading/loading.component';
import { SlideTypeIconComponent } from './slide-type-icon/slide-type-icon.component';
import { PrintModalComponent } from './modals/print-modal/print-modal.component';
import { EventDownloadModalComponent } from './modals/event-download-modal-component/event-download-modal.component';
import { DisplayTitleSlideComponent } from './display-title-slide/display-title-slide.component';
import { DisplayNameInLightsSlideComponent } from './display-name-in-lights-slide/display-name-in-lights-slide.component';
import { NavComponent } from './nav/nav.component';
import { RouterModule } from '@angular/router';
import { SiteFormComponent } from './forms/site-form/site-form.component';
import { MaterialModule } from './material/material.module';
import { ProgressButtonComponent } from './progress-button/progress-button.component';
import { ConfirmationDialogComponent } from './modals/confirmation-dialog/confirmation-dialog.component';
import { DeleteRecurrenceDialogComponent } from './modals/delete-recurrence-modal/delete-recurrence-dialog.component';
import { EditRecurrenceDialogComponent } from './modals/edit-recurrence-modal/edit-recurrence-dialog.component';
import { UserFormComponent } from './forms/user-form/user-form.component';
import { AuthFormComponent } from './forms/auth-form/auth-form.component';
import { EmailFormComponent } from './forms/email-form/email-form.component';
import { RoomFormComponent } from './forms/room-form/room-form.component';
import { FaqFormComponent } from './forms/faq-form/faq-form.component';
import { DisplayAnnouncementComponent } from './display-announcement/display-announcement.component';
import { CategorySelectComponent } from './category-select/category-select.component';
import { TagSelectComponent } from './tag-select/tag-select.component';
import { MultiDatePickerComponent } from './multi-date-picker/multi-date-picker.component';
import { ImageFormComponent } from './forms/image-form/image-form.component';
import { NotificationFormComponent } from './forms/notification-form/notification-form.component';
import { MaterialLoaderComponent } from './material-loader/material-loader.component';
import { AddRoomComponent } from './forms/add-room/add-room.component';
import { EditRoomComponent } from './forms/edit-room/edit-room.component';
import { RoomsTableComponent } from './rooms-table/rooms-table.component';
import { EventTypeSelectComponent } from './event-type-select/event-type-select.component';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { CalendarOverflowWarningComponent } from './calendar-overflow-warning/calendar-overflow-warning.component';

import { MobileUseTableComponent } from './mobile-use-table/mobile-use-table.component';
import { NewsletterTableComponent } from './newsletter-table/newsletter-table.component';
import { NewsletterFormComponent } from './forms/newsletter-form/newsletter-form.component';
import { ArticlesTableComponent } from './articles-table/articles-table.component';
import { SingleItemFormModalComponent } from './modals/single-item-form-modal/single-item-form-modal.component';
import { AttachmentCardComponent } from './attachment-card/attachment-card.component';
import { ImageComponent } from './image/image.component';
import { PropertyManagementTableComponent } from './property-management-table/property-management-table.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DisplayCollageComponent } from './display-collage/display-collage.component';
import { MenuTableComponent } from './menu-table/menu-table.component';
import { MenuPreviewComponent } from './menu-preview/menu-preview.component';
import { MenuPreviewLineComponent } from './menu-preview-line/menu-preview-line.component';
import { MenuPrintModalComponent } from './modals/menu-print-modal/menu-print-modal.component';
import { DisplayGifSlideComponent } from './display-gif-slide/display-gif-slide.component';
import { InputMaskModule } from '@ngneat/input-mask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot(),
    CoreModule,
    RouterModule,
    MaterialModule,
    NgxFileDropModule,
    InputMaskModule.forRoot({ inputSelector: 'input', isAsync: false }),
    DpDatePickerModule,
  ],
  exports: [
    AddRoomComponent,
    ArticlesTableComponent,
    AttachmentCardComponent,
    AuthFormComponent,
    CalendarOverflowWarningComponent,
    CategorySelectComponent,
    ClockComponent,
    ConfirmDialogComponent,
    CoreModule,
    DisplayAnnouncementComponent,
    DisplayCollageComponent,
    DisplayEventComponent,
    DisplayIndividualSpotlightComponent,
    DisplayNameInLightsSlideComponent,
    DisplayTitleSlideComponent,
    EditRoomComponent,
    EmailFormComponent,
    EventDownloadModalComponent,
    EventTypeSelectComponent,
    FaqFormComponent,
    FormsModule,
    ImageComponent,
    ImageFormComponent,
    ImageUploadComponent,
    LoadingButtonComponent,
    LoadingComponent,
    MarkdownModule,
    MaterialLoaderComponent,
    MaterialModule,
    MobileUseTableComponent,
    MultiDatePickerComponent,
    NavComponent,
    NewsletterFormComponent,
    NewsletterTableComponent,
    NgxFileDropModule,
    NotificationFormComponent,
    PrintModalComponent,
    ProgressButtonComponent,
    PropertyManagementTableComponent,
    ReactiveFormsModule,
    RoomFormComponent,
    RoomsTableComponent,
    SiteFormComponent,
    SlideTypeIconComponent,
    InputMaskModule,
    UserFormComponent,
    MenuTableComponent,
    MenuPreviewComponent,
    MenuPreviewLineComponent,
    DisplayGifSlideComponent,
  ],
  declarations: [
    DisplayEventComponent,
    DisplayIndividualSpotlightComponent,
    ImageUploadComponent,
    ClockComponent,
    LoadingComponent,
    SlideTypeIconComponent,
    PrintModalComponent,
    EventDownloadModalComponent,
    DisplayTitleSlideComponent,
    DisplayNameInLightsSlideComponent,
    NavComponent,
    SiteFormComponent,
    ProgressButtonComponent,
    ConfirmationDialogComponent,
    DeleteRecurrenceDialogComponent,
    EditRecurrenceDialogComponent,
    UserFormComponent,
    AuthFormComponent,
    EmailFormComponent,
    RoomFormComponent,
    AddRoomComponent,
    EditRoomComponent,
    RoomsTableComponent,
    PropertyManagementTableComponent,
    FaqFormComponent,
    DisplayAnnouncementComponent,
    CategorySelectComponent,
    TagSelectComponent,
    MultiDatePickerComponent,
    ImageFormComponent,
    NotificationFormComponent,
    MaterialLoaderComponent,
    MobileUseTableComponent,
    NewsletterTableComponent,
    NewsletterFormComponent,
    ArticlesTableComponent,
    SingleItemFormModalComponent,
    EventTypeSelectComponent,
    AttachmentCardComponent,
    LoadingButtonComponent,
    CalendarOverflowWarningComponent,
    ImageComponent,
    ConfirmDialogComponent,
    DisplayCollageComponent,
    MenuTableComponent,
    MenuPreviewComponent,
    MenuPreviewLineComponent,
    MenuPrintModalComponent,
    DisplayGifSlideComponent,
  ],
})
export class SharedModule {}
