<div class="loading-wrapper">
  <div class="loading-spinner" *ngIf="paginatedDataSource.loading$ | async">
    <app-material-loader [fillContainer]="true"></app-material-loader>
  </div>
  <div
    class="loading-overlay"
    *ngIf="paginatedDataSource.loading$ | async"
  ></div>
  <table
    mat-table
    matSort
    matSortActive="approvalDueOn"
    matSortDirection="desc"
    [dataSource]="paginatedDataSource"
  >
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        <i>{{
          this.filters.controls.search?.value?.length > 0
            ? 'No matches for this search.'
            : (paginatedDataSource.loading$ | async)
            ? ''
            : 'No newsletters available.'
        }}</i>
      </td>
    </tr>
    <ng-container matColumnDef="property">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Property
      </th>
      <td mat-cell *matCellDef="let newsletter">{{ newsletter.site?.name }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Name
      </th>
      <td mat-cell *matCellDef="let newsletter">
        <div class="flex justify-start items-center content-center">
          <span
            class="pointer editable-field"
            (click)="editNewsletter(newsletter)"
            >{{ newsletter.name }}</span
          >
          <mat-icon
            *ngIf="
              newsletter.hasNotification(
                authService.currentUser?.id,
                newsletterService.lastViewedOn(newsletter.id)
              )
            "
            class="notification-icon"
          >
            error
          </mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="articleCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Articles
      </th>
      <td mat-cell *matCellDef="let newsletter">
        {{ newsletter.articleCount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="characterCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Characters
      </th>
      <td mat-cell *matCellDef="let newsletter">
        {{ newsletter.characterCount | number }}
      </td>
      <td></td
    ></ng-container>

    <ng-container matColumnDef="printQuantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Print Quantity
      </th>
      <td mat-cell *matCellDef="let newsletter">
        {{ newsletter.printQuantity | number }}
      </td>
      <td></td
    ></ng-container>

    <ng-container matColumnDef="submissionDueOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Submission Due
      </th>
      <td mat-cell *matCellDef="let newsletter">
        {{ newsletter.submissionDueOn | date : 'shortDate' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="approvalDueOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Approval Due
      </th>
      <td mat-cell *matCellDef="let newsletter">
        {{ newsletter.approvalDueOn | date : 'shortDate' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Status
      </th>
      <!-- If not Org Admin, can't edit status -->
      <ng-container *ngIf="!authService.currentUser.isOrgAdmin">
        <td mat-cell *matCellDef="let newsletter">
          {{ newsletter.status | enum : 'newsletterStatus' }}
        </td>
        <td></td>
      </ng-container>
      <!-- If Org Admin, can edit status -->
      <ng-container *ngIf="authService.currentUser.isOrgAdmin">
        <td mat-cell *matCellDef="let newsletter">
          <span
            class="pointer editable-field"
            (click)="editStatus(newsletter)"
            >{{ newsletter.status | enum : 'newsletterStatus' }}</span
          >
        </td>
        <td></td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let newsletter">
        <button
          *ngIf="filesProcessing !== newsletter.id; else processing"
          mat-icon-button
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <ng-template #processing>
          <button mat-icon-button [disabled]="true" class="spinner">
            <mat-spinner color="accent" diameter="16"></mat-spinner></button
        ></ng-template>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editNewsletter(newsletter)">
            <mat-icon>
              {{ getNewsletterIsDraft(newsletter) ? 'edit' : 'visibility' }}
            </mat-icon>
            <span
              >{{
                getNewsletterIsDraft(newsletter) ? 'Edit' : 'View'
              }}
              Newsletter</span
            >
          </button>
          <button
            mat-menu-item
            (click)="openFinalDraftAttachment(newsletter.finalDraftAttachment)"
            [disabled]="!newsletter.finalDraftAttachment"
          >
            <mat-icon>preview</mat-icon>
            <span>View Final Draft</span>
          </button>
          <button
            mat-menu-item
            (click)="uploadFinalDraft(newsletter)"
            *ngIf="authService.currentUser.isOrgAdmin"
          >
            <mat-icon>backup</mat-icon>
            <span>Upload Final Draft</span>
          </button>
          <button
            mat-menu-item
            (click)="exportNewsletterFiles(newsletter)"
            [disabled]="filesProcessing"
            *ngIf="authService.currentUser.isOrgAdmin"
          >
            <mat-icon>send</mat-icon>
            <span>Export All Files</span>
          </button>
          <button
            mat-menu-item
            *ngIf="authService?.currentUser.isOrgAdmin"
            (click)="deleteNewsletter(newsletter)"
          >
            <mat-icon>delete</mat-icon>
            <span>Delete Newsletter</span>
          </button>
        </mat-menu>
        <!-- <div>
        <button mat-icon-button color="accent" (click)="editNewsletter(newsletter)" matTooltip="Edit Newsletter">
          <mat-icon>edit_pencil</mat-icon>
        </button>
        <button mat-icon-button color="accent" (click)="deleteNewsletter(newsletter)" matTooltip="Delete Newsletter">
          <mat-icon>delete</mat-icon>
        </button>
      </div> -->
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [length]="paginatedDataSource?.totalCount"
    [pageSize]="10"
    [pageSizeOptions]="[10, 20, 30, 50]"
    showFirstLastButtons
  ></mat-paginator>
</div>
