import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

import { ImageService } from 'src/app/core/services/image.service';
import { ManageService } from 'src/app/core/services/manage.service';
import { AlertService } from 'src/app/core/services/alert.service';

import { Announcement } from '@models/announcement';
import { Image } from '@models/image';
import {
  convertBytesToMb,
  formatBytes,
} from 'src/app/core/validators/format-bytes';

export interface FormValues {
  title: string;
  description: string;
  numberOfImages: number;
  image1: Image;
  image2: Image;
  image3: Image;
  image4: Image;
}

@Component({
  selector: 'app-slide-description-collage',
  templateUrl: './slide-description-collage.component.html',
  styleUrls: ['./slide-description-collage.component.scss'],
})
export class SlideDescriptionCollageComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  @Input() public announcement: Announcement;
  public maxTitleLength: number;
  public maxDescriptionLength: number;
  public numberOfImagesOptions: number[] = [1, 2, 3, 4];
  public firstMissingImage = 1;
  public progress = 0;
  public previousNumberOfImages: number;

  static deserializeToForm(data: Announcement): FormValues {
    return {
      title: data.title,
      description: data.content,
      image1: data.secondaryImages.find((image: Image) => image.position === 1),
      image2: data.secondaryImages.find((image: Image) => image.position === 2),
      image3: data.secondaryImages.find((image: Image) => image.position === 3),
      image4: data.secondaryImages.find((image: Image) => image.position === 4),
      numberOfImages: data?.secondaryImages?.length || 1,
    };
  }

  static serialize(form: FormValues) {
    return {
      title: form.title,
      description: form.description,
      image1: form.image1,
      image2: form.image2,
      image3: form.image3,
      image4: form.image4,
      numberOfImages: form.numberOfImages,
    };
  }

  static formModel(announcementToEdit?: Announcement) {
    return {
      title: new UntypedFormControl(announcementToEdit?.title || ''),
      description: new UntypedFormControl(announcementToEdit?.content || ''),
      numberOfImages: new UntypedFormControl(
        announcementToEdit?.secondaryImages?.length || 1,
        Validators.required,
      ),
      image1: new UntypedFormControl(
        announcementToEdit?.secondaryImages?.find(
          (image: Image) => image.position === 1,
        ),
        Validators.required,
      ),
      image2: new UntypedFormControl(
        announcementToEdit?.secondaryImages?.find(
          (image: Image) => image.position === 2,
        ),
      ),
      image3: new UntypedFormControl(
        announcementToEdit?.secondaryImages?.find(
          (image: Image) => image.position === 3,
        ),
      ),
      image4: new UntypedFormControl(
        announcementToEdit?.secondaryImages?.find(
          (image: Image) => image.position === 4,
        ),
      ),
    };
  }

  constructor(
    private imageService: ImageService,
    public manageService: ManageService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.maxTitleLength = this.manageService.getMaxTitleChars(
      this.announcement.styleId,
    );
    this.maxDescriptionLength = this.manageService.getMaxDescriptionChars(
      this.announcement.styleId,
    );
    const existingImages = this.announcement?.secondaryImages?.length;
    let numberOfImages;
    if (existingImages) {
      numberOfImages = existingImages;
      this.firstMissingImage = existingImages;
    } else {
      numberOfImages = 1;
    }
    // Saving this to compare to future value changes
    this.previousNumberOfImages = numberOfImages;
    this.form.patchValue({ numberOfImages });
    this.updateFormRequiredFields(numberOfImages);
  }

  private getFirstMissingImage(): number | null {
    const numberOfImages = this.form.controls['numberOfImages'].value;
    for (let i = 1; i < numberOfImages + 1; i++) {
      const fieldName = `image${i}`;
      const imageExists = this.form.controls[fieldName].valid;
      if (!imageExists) {
        return i;
      }
    }
    return null;
  }

  public dropped(files: NgxFileDropEntry[]) {
    const droppedFile = files[0]; // Only support one file upload
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.handleImageFileChange(file);
      });
    }
  }

  private async handleImageNumberReduced(currentNumber, previousNumber) {
    let imagesToDelete;
    let positionsToDelete;
    switch (currentNumber) {
      case 3:
        imagesToDelete = 'image 4';
        positionsToDelete = [4];
        break;

      case 2:
        if (previousNumber === 4) {
          imagesToDelete = 'images 3 and 4';
          positionsToDelete = [3, 4];
        } else {
          imagesToDelete = 'image 3';
          positionsToDelete = [3];
        }
        break;

      default:
        if (previousNumber === 4) {
          imagesToDelete = 'images 2, 3 and 4';
          positionsToDelete = [2, 3, 4];
        } else if (previousNumber === 3) {
          imagesToDelete = 'images 2 and 3';
          positionsToDelete = [2, 3];
        } else {
          imagesToDelete = 'image 2';
          positionsToDelete = [2];
        }
    }

    const confirm = await this.alertService.confirm({
      title: 'Delete images?',
      message: `This will delete ${imagesToDelete}.\n\n`,
      textConfirm: 'Delete',
      warn: true,
    });
    if (confirm) {
      positionsToDelete.forEach((position) => {
        this.deleteImage(position);
      });
      this.updateFormRequiredFields(currentNumber);
      this.firstMissingImage = this.getFirstMissingImage();
    } else {
      this.form.patchValue({ numberOfImages: previousNumber });
    }
  }

  async imageNumberChanged(event) {
    const selectedNumber = event.value;
    if (this.previousNumberOfImages > selectedNumber) {
      this.handleImageNumberReduced(
        selectedNumber,
        this.previousNumberOfImages,
      );
    } else {
      // Don't want to remove required fields until after confirmation
      // in the above case
      this.updateFormRequiredFields(selectedNumber);
      this.firstMissingImage = this.getFirstMissingImage();
    }
  }

  private updateFormRequiredFields(numberOfImages: number): void {
    switch (numberOfImages) {
      case 4:
        this.form.controls['image4'].setValidators(Validators.required);
        this.form.controls['image3'].setValidators(Validators.required);
        this.form.controls['image2'].setValidators(Validators.required);
        break;

      case 3:
        this.form.controls['image4'].removeValidators(Validators.required);
        this.form.controls['image3'].setValidators(Validators.required);
        this.form.controls['image2'].setValidators(Validators.required);
        break;

      case 2:
        this.form.controls['image4'].removeValidators(Validators.required);
        this.form.controls['image3'].removeValidators(Validators.required);
        this.form.controls['image2'].setValidators(Validators.required);
        break;

      default:
        this.form.controls['image4'].removeValidators(Validators.required);
        this.form.controls['image3'].removeValidators(Validators.required);
        this.form.controls['image2'].removeValidators(Validators.required);
    }
    this.form.controls['image2'].updateValueAndValidity();
    this.form.controls['image3'].updateValueAndValidity();
    this.form.controls['image4'].updateValueAndValidity();
  }

  public uploadInputChanged(event) {
    const file: File = event.target.files[0];
    this.handleImageFileChange(file);
  }

  // Image resize accounts for roughly 2/3rd of the upload time
  public handleLoading = (value) => {
    this.progress = value * (2 / 3);
  };

  async deleteImage(imagePosition: number) {
    const formFieldName = `image${imagePosition}`;
    this.form.patchValue({ [formFieldName]: null });
    this.form.controls[formFieldName].updateValueAndValidity();
    this.firstMissingImage = imagePosition;
    this.firstMissingImage = this.getFirstMissingImage();
  }

  // Uploads an image and decides if it should be resized or not (determined by file type)
  async handleImageFileChange(file: File) {
    if (await this.imageService.isAllowedFileType(file)) {
      const isGif = file.type === 'image/gif';

      if (isGif) {
        this.uploadWithoutResize(file);
      } else {
        this.uploadWithResize(file);
      }
    } else {
      this.progress = 0;
      this.alertService.error(
        'Please choose an allowed file type: jpg, png, gif or tif.',
      );
    }
  }

  async uploadWithoutResize(file: File) {
    const sizeInMB = convertBytesToMb(file.size);
    try {
      // check file size to make sure it isn't over 5 mb.
      if (sizeInMB > 5) {
        // oops.  time to throw an error.
        throw new Error(
          `Cannot upload a file larger than 5mb. Current file size is ${formatBytes(
            file.size,
          )}. Please reduce the size of your file and try again.`,
        );
      }
      this.progress = 80;

      const path = await this.imageService.uploadToFirestore(
        this.imageService.getSiteBackgroundImageFilePath(
          this.announcement.siteId,
        ),
        file,
      );

      if (path) {
        this.getImageDownloadUrl(path);
      }
    } catch (error) {
      this.alertService.error(error.message);
    }
  }

  // File size for regular images is limited in size
  async uploadWithResize(file: File) {
    const myReader: FileReader = new FileReader();
    // If a gif is resized through this method, it just becomes a still image
    const resize = await this.imageService.resizeFile(file, {
      maxWidthOrHeight: 3840, // should not be a higher resolution than 4k for digital signage
      maxSizeMB: 4,
      onProgress: this.handleLoading,
    });
    myReader.readAsDataURL(resize);

    myReader.onloadend = async (loadEvent: any) => {
      this.progress = 80;

      const imageBlob = this.imageService.dataURItoBlob(
        loadEvent.currentTarget.result,
      );

      const path = await this.imageService.uploadToFirestore(
        this.imageService.getSiteBackgroundImageFilePath(
          this.announcement.siteId,
        ),
        imageBlob,
      );

      if (path) {
        this.getImageDownloadUrl(path);
      }
    };
  }

  async getImageDownloadUrl(path: string) {
    const downloadUrl = await this.imageService.getDownloadUrl(path);
    const response = await this.manageService.createImageRecord(
      downloadUrl,
      path,
      this.announcement.siteId,
      this.announcement.styleId,
      this.announcement.id,
      this.firstMissingImage,
    );
    const formFieldName = `image${this.firstMissingImage}`;
    this.form.patchValue({ [formFieldName]: response });
    this.progress = 100;
    this.firstMissingImage = this.getFirstMissingImage();
    setTimeout(() => (this.progress = 0), 100);
  }
}
