<section
  class="display-cont is-success collage is-bold"
  [ngStyle]="
    announcement?.background &&
    !announcement?.background.deleted && {
      background: 'url(' + announcement.background.downloadUrl + ')'
    }
  "
>
  <div class="content-container">
    <div class="dark-area">
      <div class="middle-align">
        <h1>
          <markdown [data]="announcement?.title"></markdown>
        </h1>
        <h2>
          <markdown [data]="announcement?.content"></markdown>
        </h2>
      </div>
    </div>
    <div class="collage-area">
      <div class="collage-column">
        <!-- EMPTY FIRST IMAGE FRAME: only show if first image is missing -->
        <div
          [ngClass]="{
            'image-container': true,
            'half-height': numberOfImages > 3,
            'full-height': numberOfImages < 3
          }"
          *ngIf="announcement && !collageImages[0]"
        >
          1
        </div>

        <!-- FIRST IMAGE FRAME: only show if first image is present -->
        <img
          [ngClass]="{
            'image-container': true,
            'half-height': numberOfImages > 3,
            'full-height': numberOfImages <= 3
          }"
          *ngIf="collageImages[0]"
          [src]="collageImages[0].downloadUrl"
        />

        <!-- EMPTY FOURTH IMAGE FRAME: only show if fourth image is missing -->
        <div
          class="image-container half-height"
          *ngIf="announcement && numberOfImages === 4 && !collageImages[3]"
        >
          4
        </div>

        <!-- FOURTH IMAGE FRAME: only show if fourth image is present -->
        <img
          class="image-container half-height"
          *ngIf="collageImages[3]"
          [src]="collageImages[3].downloadUrl"
        />
      </div>
      <div *ngIf="numberOfImages > 1" class="collage-column">
        <!-- EMPTY SECOND IMAGE FRAME: only show if second image is missing -->
        <div
          [ngClass]="{
            'image-container': true,
            'half-height': numberOfImages > 2,
            'full-height': numberOfImages === 2
          }"
          *ngIf="announcement && numberOfImages > 1 && !collageImages[1]"
        >
          2
        </div>

        <!-- SECOND IMAGE FRAME: only show if second image is present -->
        <img
          [ngClass]="{
            'image-container': true,
            'half-height': numberOfImages > 2,
            'full-height': numberOfImages === 2
          }"
          *ngIf="collageImages[1]"
          [src]="collageImages[1].downloadUrl"
        />

        <!-- EMPTY THIRD IMAGE FRAME: only show if third image is missing -->
        <div
          class="image-container half-height"
          *ngIf="announcement && numberOfImages > 2 && !collageImages[2]"
        >
          3
        </div>

        <!-- THIRD IMAGE FRAME: only show if third image is present -->
        <img
          class="image-container half-height"
          *ngIf="collageImages[2]"
          [src]="collageImages[2].downloadUrl"
        />
      </div>
    </div>
  </div>
  <app-clock *ngIf="showClock"></app-clock>
</section>
