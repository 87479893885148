<section
  class="display-cont is-success event is-bold image-centric"
  [ngStyle]="
    announcement?.background &&
    !announcement?.background.deleted && {
      background: 'url(' + announcement.background.downloadUrl + ')'
    }
  "
>
  <ng-container *ngIf="announcement?.title || announcement?.content">
    <div class="dark-area bottom left">
      <h1 [innerHtml]="announcement?.title"></h1>
      <h2 [innerHtml]="announcement?.content"></h2>
      <app-clock *ngIf="showClock"></app-clock>
    </div>
  </ng-container>
</section>
