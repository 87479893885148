import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewsletterCommentDto } from '@interfaces/newsletterComment';
import { Newsletter } from '@models/newsletter';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NewsletterService } from 'src/app/core/services/newsletter.service';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogValues,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-newsletter-comments',
  templateUrl: './newsletter-comments.component.html',
  styleUrls: ['./newsletter-comments.component.scss'],
})
export class NewsletterCommentsComponent implements OnInit {
  @Input() newsletter: Newsletter;
  @Output() updated: EventEmitter<boolean> = new EventEmitter();
  public commentContent = '';
  public submitting = false;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private dialog: MatDialog,
    private newsletterService: NewsletterService,
  ) {}

  ngOnInit(): void {}

  public get trimmedComment(): string {
    return this.commentContent.trim();
  }

  public addComment(): void {
    if (!this.trimmedComment.length) {
      return;
    }
    this.submitting = true;

    const commentDto = {
      content: this.trimmedComment,
      user: this.authService.currentUser.id,
      newsletter: this.newsletter.id,
    };

    this.saveComment(commentDto);
  }

  public confirmDeleteComment(comment: NewsletterCommentDto): void {
    const confirmData: ConfirmationDialogValues = {
      message: 'Do you want to delete this comment? This cannot be undone.',
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        comment.deleted = true;
        this.deleteComment(comment);
      }
    });
  }

  public isCommentAuthor(comment: NewsletterCommentDto): boolean {
    return comment.user.id === this.authService.currentUser.id;
  }

  private deleteComment(comment) {
    this.newsletterService
      .deleteComment(comment.id)
      .then(() => {
        this.updated.next(true);
      })
      .catch((error) => {
        this.alertService.error('Unable to delete comment. Please try again.');
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  private saveComment(comment): void {
    this.newsletterService
      .saveNewsletterComment(comment, this.newsletter)
      .then((savedComment: NewsletterCommentDto) => {
        this.commentContent = '';
        this.updated.next(true);
      })
      .catch((error) => {
        this.alertService.error(`Unable to Save comment. Please try again.`);
        console.error(error);
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
