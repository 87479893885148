import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnCamelCasePipe } from './pipes/un-camel-case.pipe';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { EnumPipe } from './pipes/enum.pipe';
import { ImageHideOnFailDirective } from './directives/image-hide-on-fail.directive';
import { BoolPipe } from './pipes/bool.pipe';

@NgModule({
  imports: [CommonModule, AngularFirestoreModule],
  declarations: [UnCamelCasePipe, EnumPipe, ImageHideOnFailDirective, BoolPipe],
  exports: [UnCamelCasePipe, EnumPipe, ImageHideOnFailDirective, BoolPipe],
  providers: [],
})
export class CoreModule {}
