import { Injectable } from '@angular/core';
import packageJson from 'package.json';
const { version } = packageJson;
import * as Sentry from '@sentry/browser';
@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor() {}

  public setSentryVersion(): void {
    Sentry.setTag('version', version);
  }

  public setSentrySiteId(siteId): void {
    Sentry.setTag('siteId', siteId);
  }

  public setSentryUser(userId): void {
    Sentry.configureScope((scope) => scope.setUser({ id: userId }));
  }
}
