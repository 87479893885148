<mat-form-field
  color="accent"
  floatLabel="never"
  appearance="outline"
  [hintLabel]="inputHint"
>
  <mat-label floatLabel="never">Category/Subcategory</mat-label>
  <input
    (input)="filterSubcategories($event.target.value)"
    type="text"
    matInput
    required
    placeholder="Category"
    [matAutocomplete]="auto"
    [formControl]="categoryControl"
    data-qa="event-input-category"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onCategorySelected($event.option)"
    [displayWith]="getOptionName"
    data-qa="event-select-category"
  >
    <mat-optgroup
      *ngFor="let category of this.filteredCategories | async"
      [label]="category ? category.name : ''"
    >
      <mat-option
        *ngFor="let subcategory of category.subcategories"
        [value]="subcategory"
        data-qa="event-select-category-option"
      >
        {{ subcategory ? subcategory.name : '' }}
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>
  <mat-hint
    ><span
      *ngIf="inputControl?.hasError('required') && inputControl.touched"
      class="error"
    >
      Category is required.
    </span></mat-hint
  >
</mat-form-field>
