import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ManageService } from 'src/app/core/services/manage.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { SiteService } from 'src/app/core/services/site.service';
import {
  ConfirmationDialogValues,
  ConfirmationDialogComponent,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { ImageService } from 'src/app/core/services/image.service';
import { Image } from '@models/image';
import { EditImageComponent } from '../edit-image/edit-image.component';
import { AddImageComponent } from '../add-image/add-image.component';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  throttleTime,
} from 'rxjs/operators';
import { ImageTableComponent } from '../components/image-table/image-table.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
  @ViewChild('imageTable') set tableRef(ref: ImageTableComponent) {
    if (ref) {
      this.imageTableRef = ref;
    }
  }
  public updateTrigger = 0;
  public imageTableRef: ImageTableComponent;
  public isTable = true; // GRID VS TABLE
  private searchFilter$ = new Subject();
  public searchFilter = '';
  public imageCount = 0;
  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    public imageService: ImageService,
    public manage: ManageService,
    private siteService: SiteService,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.searchFilter$.complete();
  }

  public toggleTableGrid(): void {
    this.isTable = !this.isTable;
  }

  public addImage = () => {
    const dialogRef = this.dialog.open(AddImageComponent, {
      data: {
        siteId: this.siteService.currentSiteId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.success) {
        this.updateTrigger = this.updateTrigger + 1;
      }
    });
  };

  public editImage = (image: Image) => {
    const dialogRef = this.dialog.open(EditImageComponent, {
      data: image,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.success) {
        this.updateTrigger = this.updateTrigger + 1;
      }
    });
  };

  public markImageDeleted = (image: Image) => {
    const confirmData: ConfirmationDialogValues = {
      message:
        'Do you want to delete this background image? This cannot be undone.',
      messageDisclaimer:
        'Please note: this only prevents the image from being used in the future. Existing content will not be altered.',
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.imageService
          .setBackgroundImageDeleted(image)
          .then(async () => {
            this.imageService.refreshAllBackgrounds(); // necessary to update available subcategories across the app
            this.updateTrigger = this.updateTrigger + 1;
            window.setTimeout(
              () => this.alertService.success('Image deleted.'),
              0,
            );
          })
          .catch((error) => {
            this.alertService.error('Error deleting image. Please try again.');
          });
      }
    });
  };

  /**
   * Search value is passed into the background image table.
   * Debounce is low for now because this doesn't currently trigger a network request.
   */
  public search(value): void {
    if (value == '') {
      this.updateTrigger = this.updateTrigger + 1;
    }
    if (this.searchFilter$.observers.length === 0) {
      this.searchFilter$
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((filterQuery: string) => {
          this.searchFilter = filterQuery;
        });
    }
    this.searchFilter$.next(value);
  }

  public setImageCount(value) {
    this.imageCount = value;
  }
}
