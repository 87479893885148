import { Injectable } from '@angular/core';

import { IndividualSpotlight } from '@interfaces/individualSpotlight';
import { VirtualAnnouncement } from '@interfaces/virtualAnnouncement';
import { ManageService } from './manage.service';
import { Announcement } from '@models/announcement';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { EventTag } from '@models/eventTag';

@Injectable({
  providedIn: 'root',
})
export class DisplayService {
  public defaultInterval = 15000; // default interval, in milliseconds
  public announcement: BehaviorSubject<Announcement> = new BehaviorSubject(
    null,
  ); // active announcement
  public announcements: any[] = [];
  public individualSpotlights: IndividualSpotlight[] = [];
  public virtualAnnouncements: VirtualAnnouncement[] = [];
  public paused = false;
  public pauseCommand = 'Pause';
  public propertyId: number;
  public nextRestart = moment(); // date of when to restart app (refresh browser)
  private nextTimeout: any;
  private currentSlide = 0;
  private lastTime: moment.Moment;

  constructor(private manageService: ManageService) {
    this.listenForAnnouncements();
    this.nextRestart.add(1, 'hour');
  }

  public loadData(propertyId: number): void {
    if (moment().isAfter(this.nextRestart)) {
      // restart app once an hour
      window.location.reload();
    } else {
      this.propertyId = propertyId;
      this.manageService.refreshAnnouncements(this.propertyId, 'active', false);
    }
  }

  private listenForAnnouncements() {
    this.manageService.announcements.subscribe(
      (announcements: Announcement[]) => {
        this.announcements = announcements;
        this.rotate(moment());
      },
    );
  }

  private rotate(lastTime: moment.Moment) {
    const lastSlide = this.currentSlide;
    if (!this.announcements) {
      this.currentSlide = -1;
      this.announcement.next(this.manageService.defaultAnnouncement);
      return;
    }
    this.currentSlide =
      lastSlide === this.announcements.length - 1 ? 0 : lastSlide + 1;
    this.announcement.next(this.announcements[this.currentSlide]);
    const now = moment();
    if (this.announcement.value) {
      console.info(
        `Slide rotated in ${now.diff(
          this.lastTime,
        )} milliseconds. Scheduled duration: ${
          this.announcement.value?.displayDuration
        }`,
      );
    }
    this.lastTime = now;
    let nextFunction = () => this.rotate(now);
    if (this.currentSlide === 0 || !this.announcements) {
      nextFunction = () => this.loadData(this.propertyId);
    }
    this.nextTimeout = setTimeout(
      nextFunction,
      this.announcement.value?.displayDuration
        ? this.announcement.value?.displayDuration * 1000
        : this.defaultInterval,
    );
  }

  public showNextSlide() {
    if (this.nextTimeout) {
      clearTimeout(this.nextTimeout);
    }
    this.rotate(moment());
  }

  public showPreviousSlide() {
    if (this.nextTimeout) {
      clearTimeout(this.nextTimeout);
    }
    this.currentSlide -= 2;
    this.rotate(moment());
  }

  public pauseSlides() {
    if (this.nextTimeout) {
      clearTimeout(this.nextTimeout);
    }
  }

  public resumeSlides() {
    this.showNextSlide();
  }

  public getTagIconUrl(iconName: string) {
    return `assets/icons/event-tag/${iconName}-white.svg`;
  }

  public getEventTagIconUrl(event: Announcement) {
    if (
      event &&
      event.eventTags &&
      event.eventTags[0] &&
      (event.eventTags[0] as EventTag).icon
    ) {
      return this.getTagIconUrl((event.eventTags[0] as EventTag).icon);
    } else {
      return null;
    }
  }
}
