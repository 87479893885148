import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

export type MatColor = 'primary' | 'accent' | 'warn';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss'],
})
export class ProgressButtonComponent implements OnInit {
  @Input() public disabled: boolean;
  @Input() public color: MatColor = 'primary';
  @Input() public submitting: boolean;
  @Input() public text = 'Submit';

  @Output() public submit = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public onSubmit() {
    if (!this.disabled) {
      this.submit.emit('click');
    }
  }
}
