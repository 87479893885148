// Keys used as reference values in DB, do not change.
// Values can be updated for UI purposes
export enum EventType {
  Generic = 'Regular', // default value must be first
  SpecialEvent = 'Special Event',
  Outing = 'Outing', // stale in database as of 03/02/2023 - failed migration
  NewProgram = 'New Program',
}

export enum EventColors {
  Regular = '#6BA4B8',
  Generic = '#6BA4B8',
  SpecialEvent = '#F08080',
}

export const eventTypeKeys = Object.keys(EventType);
export const defaultEventKey = eventTypeKeys[0];
