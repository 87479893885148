import { Injectable } from '@angular/core';
import { ReplaySubject, lastValueFrom } from 'rxjs';
import { SiteService } from './site.service';
import { environment } from 'src/environments/environment';
import { StaffDto } from '@interfaces/staff';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StaffService {
  private _staff = new ReplaySubject<StaffDto[]>();
  public readonly staff = this._staff.asObservable();

  constructor(private http: HttpClient, private siteService: SiteService) {}

  /**
   * Manually force Staff refresh. They're only referenced in the EditSite modal,
   * so no reason to always sync them, even when properties are changing.
   */
  public refreshStaff(): void {
    this.siteService.siteId.pipe(take(1)).subscribe((siteId: number) => {
      if (!siteId) {
        this.updateActiveStaff([]);
        return;
      }

      this.loadStaff(siteId).then((staff: StaffDto[]) =>
        this.updateActiveStaff(staff),
      );
    });
  }

  /**
   * Pass in array of Staff. Create or update records.
   */
  public updateStaff(staff: Partial<StaffDto>[]): Promise<StaffDto> {
    const url = environment.apiUrl.concat('/api/v1/staff');

    try {
      return lastValueFrom(this.http.post<StaffDto>(url, staff));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  private updateActiveStaff(staff: StaffDto[]): void {
    const f = staff && staff.length ? staff : [];
    this._staff.next(f);
  }

  public loadStaff(siteId: number): Promise<StaffDto[]> {
    const url = environment.apiUrl.concat('/api/v1/staff/', siteId.toString());

    try {
      return lastValueFrom(this.http.get<StaffDto[]>(url));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }
}
