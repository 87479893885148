import { ArticleTypeDto } from '../interfaces/articleType';
import { ArticleDto } from '../interfaces/article';
import { ImageDto } from '../interfaces/image';
import { OptionalArticleTypeName } from '../enums/article-type-names';

export const articleCountDictionary = {
  6: 'sixArticleCharacterCount',
  7: 'sevenArticleCharacterCount',
  8: 'eightArticleCharacterCount',
};

export const relatedImageCopy = 'GiGi to decide';
export const naCopy = 'n/a'; // standardized way to present 'n/a' and check for it (imported elsewhere)
export const characterCountLeeway = 0.2; // 20% leeway to be considered valid
export const characterCountPerImage = 350; // # of characters that each image takes from total
export const maxNumImages = 8;
export class Article implements ArticleDto {
  // Base properties
  id: number;
  createdOn: Date;
  updatedOn: Date;
  deleted: boolean;

  // Specific properties
  content: string;
  customImages: boolean;
  headline: string;
  newsletterId: number;
  frontPage: boolean;

  // Joined properties
  image: ImageDto[];
  articleType: ArticleTypeDto;

  constructor(data: Partial<ArticleDto>) {
    Object.assign(this, data);
  }

  get isBackpage(): boolean {
    return this.articleType?.backpage || false;
  }

  get imageCount(): number {
    return this.image && this.image.length ? this.image.length : 0;
  }

  get imageCountSummary(): string {
    return this.isBackpage
      ? naCopy
      : !this.customImages
      ? relatedImageCopy
      : `${this.imageCount}/${maxNumImages}`;
  }

  get characterCount(): number {
    const content = this.content || '';
    const contentLength = content.length;
    const imageLength = !this.customImages
      ? 350
      : this.imageCount * characterCountPerImage;
    return contentLength + imageLength;
  }

  get headlineNullText(): string {
    return this.isBackpage ? this.articleType.name : 'Insert Headline Here';
  }

  public articleValid(newsletterArticleCount: number): boolean {
    if (!this.articleType) {
      return false;
    }
    return !this.articleType.required
      ? true
      : this.characterCountValid(newsletterArticleCount);
  }

  /**
   * Only pass in customCharacterCount if not referencing article.characterCount (e.g. creating/editing text)
   */
  public characterCountValid(
    newsletterArticleCount: number,
    customCharacterCount?: number,
  ): boolean {
    const characterCount = customCharacterCount
      ? customCharacterCount
      : this.characterCount;

    // 06/01/21: too many characters are allowed now
    if (characterCount > this.idealArticleLength(newsletterArticleCount)) {
      return true;
    }
    const charactersOffFromIdeal = Math.abs(
      characterCount - this.idealArticleLength(newsletterArticleCount),
    );
    const maximumOff =
      characterCountLeeway * this.idealArticleLength(newsletterArticleCount);

    return charactersOffFromIdeal <= maximumOff;
  }

  public characterCountSummary(newsletterArticleCount: number): string {
    return this.articleType.backpage
      ? naCopy
      : `${this.characterCount}/${this.numberWithCommas(
          this.idealArticleLength(newsletterArticleCount),
        )}`;
  }

  // https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  private numberWithCommas(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public idealArticleLength(newsletterArticleCount: number): number {
    return this.articleType[articleCountDictionary[newsletterArticleCount]];
  }

  public minArticleLength(newsletterArticleCount: number): number {
    return (
      this.idealArticleLength(newsletterArticleCount) *
      (1 - characterCountLeeway)
    );
  }

  public maxArticleLength(newsletterArticleCount: number): number {
    return (
      this.idealArticleLength(newsletterArticleCount) *
      (1 + characterCountLeeway)
    );
  }

  public optionalArticle(articleName): boolean {
    const optional =
      articleName === OptionalArticleTypeName.birthdays ||
      articleName === OptionalArticleTypeName.inLovingMemory ||
      articleName === OptionalArticleTypeName.newResidents ||
      articleName === OptionalArticleTypeName.teamMembers;

    return optional;
  }
}
