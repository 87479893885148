import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { take, map, tap } from 'rxjs/operators';
import { User } from '@models/user';
import { AlertService } from '../services/alert.service';
import { SiteService } from '../services/site.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationGuard {
  constructor(
    private alertService: AlertService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private siteService: SiteService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.userReplay.pipe(
      take(1),
      map((user: User) => {
        const orgId = next.params.orgId;
        const associatedWithOrg = String(user.orgId) === String(orgId);
        return associatedWithOrg && user.isOrgAdmin;
      }),
      tap((haveAccessToOrg) => {
        if (!haveAccessToOrg) {
          this.alertService.warning(
            'Unable to access that organization. If you believe this is an error, refresh browser and try again.',
          );
          this.router.navigate(['/session/sign-in']);
          return;
        }

        // Ensure there's an active Site set; if not, set it based on user's defaultSiteId
        if (!this.siteService.currentSiteId) {
          this.siteService.setActiveSite(this.auth.currentUser.defaultSiteId);
        }
      }),
    );
  }
}
