<section
  class="display-cont is-success event is-bold"
  [ngStyle]="
    announcement?.background &&
    !announcement?.background.deleted && {
      background: 'url(' + announcement.background.downloadUrl + ')'
    }
  "
>
  <div class="dark-area">
    <img
      class="top-icon"
      *ngIf="displayService.getEventTagIconUrl(announcement)"
      [src]="displayService.getEventTagIconUrl(announcement)"
    />
    <h1>
      <markdown [data]="announcement?.title"></markdown>
    </h1>
    <h2 *ngIf="announcement?.content">
      <markdown [data]="announcement?.content"></markdown>
    </h2>
    <ul>
      <li *ngIf="date">
        <markdown [data]="date"></markdown>
      </li>

      <li *ngIf="!announcement.allDay && announcement?.timeRange; else allDay">
        <markdown [data]="announcement.timeRange"></markdown>
      </li>
      <ng-template #allDay>
        <li>
          <markdown [data]="'All Day Event'"></markdown>
        </li>
      </ng-template>
      <li *ngIf="announcement && announcement.place">
        <markdown [data]="announcement.place"></markdown>
      </li>
    </ul>
  </div>
  <app-clock *ngIf="showClock"></app-clock>
</section>
