import { HttpParams } from '@angular/common/http';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { Announcement } from '@models/announcement';
import { ManageService } from 'src/app/core/services/manage.service';
import { SiteService } from 'src/app/core/services/site.service';
import bootstrapTable from 'src/app/shared/pagination-server-side/bootstrap-table';
import {
  GenericBEDataSource,
  PaginationInformation,
} from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';

interface AnnouncementRow {
  type: string;
  title: string;
  startShowing: Date;
  stopShowing: Date;
  description: string;
}
@Component({
  selector: 'app-slide-grid',
  templateUrl: './slide-grid.component.html',
  styleUrls: ['./slide-grid.component.scss'],
})
export class SlideGridComponent implements OnInit {
  @Input() set filter(filterValue: string) {
    if (filterValue !== this.searchFilter$.value) {
      this.searchFilter$.next(filterValue);
    }
  }
  @Input() set timeframe(timeframe: string) {
    if (timeframe !== this.timeframeFilter$.value) {
      this.timeframeFilter$.next(timeframe);
    }
  }
  @Input() set refresh(refresh: number) {
    if (refresh !== this.manualRefreshTrigger$.value) {
      this.manualRefreshTrigger$.next(refresh);
    }
  }
  private searchFilter$ = new BehaviorSubject('');
  private timeframeFilter$ = new BehaviorSubject('active');
  private manualRefreshTrigger$ = new BehaviorSubject(0);

  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  public gridData = [];
  public length = 0;
  public pageSize = 10;
  public pageSizeOptions: number[] = [5, 10, 15, 20];
  public pageIndex = 0;

  public dataSource: MatTableDataSource<AnnouncementRow>;
  public paginatedDataSource: GenericBEDataSource<Announcement>;
  private destroyed = new Subject<boolean>();

  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(this.searchFilter$.value),
    status: new UntypedFormControl(this.timeframeFilter$.value),
    refreshTrigger: new UntypedFormControl(0),
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  sort: MatSort = new MatSort();

  constructor(
    private manageService: ManageService,
    public siteService: SiteService,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);

    this.paginatedDataSource = new GenericBEDataSource<Announcement>(
      this.getAnnouncementsPaginated.bind(this),
    );
  }

  private getAnnouncementsPaginated(
    queryParams: HttpParams,
    pagination: PaginationInformation,
  ) {
    return this.manageService.getSiteAnnouncementsPaginated(
      queryParams,
      pagination,
    );
  }

  ngAfterViewInit(): void {
    // Must wait until we have an active site to load this data
    this.siteService.site
      .pipe(
        filter((site) => site !== null),
        tap((site) => {
          bootstrapTable<Announcement[]>(
            this.sort,
            this.paginator,
            this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
            this.filters,
          )
            .pipe(takeUntil(this.destroyed))
            .subscribe();
          combineLatest([
            this.searchFilter$,
            this.timeframeFilter$,
            this.manualRefreshTrigger$,
          ])
            .pipe(takeUntil(this.destroyed))
            .subscribe(([search, timeframe, refresh]) => {
              this.filters.controls['search'].setValue(search);
              this.filters.controls['status'].setValue(timeframe);
              if (refresh > 0) {
                this.filters.controls['refreshTrigger'].setValue(refresh);
              }
            });
        }),
        takeUntil(this.destroyed),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  deleteSlide(announcement) {
    this.delete.emit(announcement);
  }
}
