<div class="flex justify-between items-start content-start">
  <h1 mat-dialog-title class="mat-dialog-title text-2xl">Edit User</h1>
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="p-4 min-w-[400px]">
    <h2 class="text-lg">Authentication Details</h2>
    <app-email-form [form]="emailForm"></app-email-form>
    <mat-divider class="has-y-margin"></mat-divider>
    <h2 class="mat-h2">User Details</h2>
    <app-user-form [form]="userForm"></app-user-form>
    <app-progress-button
      color="accent"
      text="Save"
      (submit)="onSave()"
      [disabled]="this.userForm.invalid || this.emailForm.invalid"
      [submitting]="submitting"
    >
    </app-progress-button>
  </div>
</mat-dialog-content>
