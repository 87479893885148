import { Component } from '@angular/core';
import { filter } from 'rxjs/operators';

import { SiteService } from 'src/app/core/services/site.service';

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
})
export class MenusComponent {
  public site = this.siteService.site.pipe(filter((site) => site !== null));

  constructor(public siteService: SiteService) {}
}
