<div class="fixed-height-dialog">
  <h2 mat-dialog-title>Preview Menus</h2>
  <mat-dialog-content>
    <div class="flex justify-around">
      <button mat-button (click)="dayChange(-1)">
        <mat-icon>chevron_left</mat-icon>
      </button>

      <button mat-button (click)="openDatePicker()">
        {{ displayDate | date : 'shortDate' }}
      </button>

      <button mat-button (click)="dayChange(1)">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>

    <div class="flex justify-center">
      <input
        class="invisible-date-picker"
        matInput
        [matDatepicker]="picker"
        [formControl]="dateInput"
      />
    </div>
    <mat-datepicker #picker></mat-datepicker>

    <div>
      <div class="progress-container flex flex-col justify-center">
        <mat-progress-bar
          *ngIf="loading"
          mode="indeterminate"
        ></mat-progress-bar>
        <div class="flex justify-center p-4" *ngIf="!siteMenu && !loading">
          <p>There is no menu for this location today.</p>
        </div>
      </div>

      <div *ngIf="siteMenu">
        <app-menu-preview-line
          title="Lunch"
          [singleItem]="siteMenu.menu?.lunchEntree"
          [itemCollection]="siteMenu.menu?.lunchSides"
        ></app-menu-preview-line>

        <app-menu-preview-line
          title="Dinner Option 1"
          [singleItem]="siteMenu.menu?.dinnerOpt1"
          [itemCollection]="siteMenu.menu?.dinnerOpt1Sides"
        ></app-menu-preview-line>

        <app-menu-preview-line
          title="Dinner Option 2"
          [singleItem]="siteMenu.menu?.dinnerOpt2"
          [itemCollection]="siteMenu.menu?.dinnerOpt2Sides"
        ></app-menu-preview-line>
      </div>
    </div>
  </mat-dialog-content>

  <div class="spacer"></div>

  <mat-dialog-actions class="flex !justify-center items-end content-end !p-4">
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>
