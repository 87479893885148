import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Faq } from '@models/faq';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export interface FaqFormValues {
  question: string;
  answer: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-faq-form',
  templateUrl: './faq-form.component.html',
  styleUrls: ['./faq-form.component.scss'],
})
export class FaqFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup;

  constructor() {}

  ngOnInit() {}

  static formModel(formValues = null) {
    return new UntypedFormGroup({
      question: new UntypedFormControl(
        formValues ? formValues.question : null,
        Validators.required
      ),
      answer: new UntypedFormControl(
        formValues ? formValues.answer : null,
        Validators.required
      ),
    });
  }

  static serialize(
    formValues: FaqFormValues,
    siteId: number,
    faqId: number = null,
  ): Partial<Faq> {
    const serializedForm = {

      question: formValues.question,
      answer: formValues.answer,
      site: {
        id: siteId
      },
      id: faqId,
    };

    return serializedForm;
  }

  static deserialize(faq: Faq): FaqFormValues {
    return {
      question: faq.question,
      answer: faq.answer,
    };
  }
}
