<div class="flex flex-col gap-6">
  <div class="freeze-row flex justify-between items-center">
    <div class="month-actions flex justify-end items-center gap-4">
      <button
        mat-raised-button
        matTooltip="Add new event"
        color="accent"
        class="add-fab"
        (click)="addEvent()"
        data-qa="event-button-add"
      >
        Add Event
      </button>

      <button
        mat-raised-button
        matTooltip="Download event data"
        color="accent"
        (click)="showDownloadDialog()"
      >
        <mat-icon>save_alt</mat-icon>
      </button>
      <button
        mat-raised-button
        matTooltip="Download this month's calendar as a PDF"
        color="accent"
        (click)="showPrintDialog(selectedMonth)"
        data-qa="event-calendar-download"
      >
        <mat-icon>cloud_download</mat-icon>
      </button>
      <button
        mat-raised-button
        matTooltip="Show calendar view"
        color="accent"
        (click)="openCalendarPdf()"
        data-qa="event-calendar-preview"
      >
        <mat-icon>date_range</mat-icon>
      </button>
    </div>
  </div>
  <div>
    <app-event-page-agenda
      *ngIf="selectedMonth && printCalendarRestrictions?.maxEventRows"
      [months]="months"
      [timezone]="timezone"
      [activeMonth]="selectedMonth"
      [printCalendarRestrictions]="printCalendarRestrictions"
      (changeMonth)="handleMonthSelect($event)"
      (save)="saveSingle($event)"
    ></app-event-page-agenda>
  </div>
</div>
