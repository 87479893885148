<mat-card
  appearance="outlined"
  color="primary"
  style="overflow-y: auto; max-height: 85vh"
>
  <mat-card-header class="flex justify-between items-center content-center">
    <mat-card-title
      >Mobile Users ({{
        mobileUseTableRef?.paginatedDataSource?.totalCount
          ? mobileUseTableRef?.paginatedDataSource?.totalCount
          : 0
      }})</mat-card-title
    >
    <div
      class="control-buttons buttons flex justify-between items-baseline place-content-stretch gap-[10px]"
    >
      <button
        mat-raised-button
        *ngIf="mobileUseTableRef?.paginatedDataSource?.totalCount > 0"
        matTooltip="Export as CSV file"
        color="accent"
        (click)="exportCsv()"
        [disabled]="!mobileUseTableRef?.paginatedDataSource?.totalCount"
      >
        <mat-icon>save_alt</mat-icon>
      </button>
      <mat-form-field color="accent">
        <input
          matInput
          placeholder="Search"
          (input)="search($event.target.value)"
          [value]="searchFilter"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </mat-card-header>
  <mat-card-content style="padding-top: 16px">
    <app-mobile-use-table
      #orgTable
      class="flex flex-col"
      userStyle="org"
      [search]="searchFilter"
      (edit)="editParticipant($event)"
      [refresh]="refreshTrigger"
      showProperty="true"
    ></app-mobile-use-table>
  </mat-card-content>
</mat-card>
