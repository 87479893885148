import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Newsletter } from '@models/newsletter';

export interface NewsletterFormValues {
  approvalDueOn: Date;
  name: string;
  printQuantity: number;
  submissionDueOn: Date;
  articleCount: number;
}

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup;

  static formModel(formValues = null) {
    return new UntypedFormGroup({
      approvalDueOn: new UntypedFormControl(
        formValues ? formValues.approvalDueOn : null,
        Validators.required
      ),
      name: new UntypedFormControl(
        formValues ? formValues.name : null,
        Validators.required
      ),
      printQuantity: new UntypedFormControl(
        formValues ? formValues.name : null,
        Validators.required
      ),
      submissionDueOn: new UntypedFormControl(
        formValues ? formValues.approvalDueOn : null,
        Validators.required
      ),
      articleCount: new UntypedFormControl(
        formValues ? formValues.articleCount : null,
        Validators.required
      ),
    });
  }

  static serialize(
    formValues: NewsletterFormValues,
    newsletter: Partial<Newsletter> = {}
  ): Partial<Newsletter> {
    const serializedForm = {
      approvalDueOn: formValues.approvalDueOn,
      name: formValues.name,
      printQuantity: formValues.printQuantity,
      submissionDueOn: formValues.submissionDueOn,
      articleCount: formValues.articleCount,
    };

    return Object.assign(newsletter, serializedForm);
  }

  static deserialize(newsletter: Newsletter): NewsletterFormValues {
    return {
      approvalDueOn: newsletter.approvalDueOn,
      name: newsletter.name,
      printQuantity: newsletter.printQuantity,
      submissionDueOn: newsletter.submissionDueOn,
      articleCount: newsletter.articleCount,
    };
  }

  constructor() {}

  ngOnInit() {}
}
