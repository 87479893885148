import { StyleDto } from "../interfaces/style";

export class Style implements StyleDto {
    id: number;
    createdOn: Date;
    updatedOn: Date;
    deleted: boolean;
    name: string;
    description: string;

    constructor(data: StyleDto) {
      Object.assign(this, data);
    }

  }
  