import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingButtonComponent implements OnInit {
  public spinnerColor = 'accent';

  @Input() label = '';
  @Input() loading = false;
  @Input() disabled = false;
  @Input() color: 'primary' | 'accent' = 'primary';
  @Input() onClick = () => {};

  constructor() {}

  ngOnInit() {
    switch (this.color) {
      case 'accent':
        this.spinnerColor = 'primary';
        break;
      case 'primary':
        this.spinnerColor = 'accent';
        break;
      default:
        break;
    }
  }
}
