import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { EventType } from '../../../../../core/enums/event-type';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-event-type-select',
  templateUrl: './event-type-select.component.html',
  styleUrls: ['./event-type-select.component.scss'],
})
export class EventTypeSelectComponent implements OnInit {
  @Input() eventTypeControl: UntypedFormControl;
  public eventTypes;
  public eventTypeKeys;
  public selectedType;

  constructor() {}

  ngOnInit() {
    // both values saved for template reference purposes
    this.eventTypes = EventType;
    this.eventTypeKeys = Object.keys(EventType);

    if (this.eventTypeControl.value) {
      this.selectedType = this.eventTypeControl.value;
    }
  }

  public eventTypeChanged(eventType) {
    this.eventTypeControl.setValue(eventType.value);
  }
}
