<section
  class="display-cont is-success announcement is-bold"
  [class.background-forest]="announcement?.isGenerated"
  [ngStyle]="
    announcement?.background &&
    !announcement?.background.deleted && {
      background: 'url(' + announcement.background.downloadUrl + ')'
    }
  "
>
  <div class="dark-area">
    <div class="middle-align">
      <h1 [innerHtml]="announcement?.title"></h1>
      <h2 [innerHtml]="announcement?.content"></h2>
    </div>
  </div>
  <app-clock *ngIf="showClock"></app-clock>
</section>
