<h1 mat-dialog-title *ngIf="data.title">{{ data.title }}</h1>
<mat-dialog-content [formGroup]="eventDeleteForm" class="dialog-content">
  <p>{{ data.message }}</p>

  <mat-radio-group formControlName="delete">
    <mat-radio-button value="single" data-qa="delete-recurrence-radio-single">
      Delete just this event
    </mat-radio-button>
    <mat-radio-button value="future" data-qa="delete-recurrence-radio-future">
      Delete this and future events
    </mat-radio-button>
    <mat-radio-button value="all" data-qa="delete-recurrence-radio-all">
      Delete all events in this series</mat-radio-button
    >
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    (click)="onNoClick()"
    data-qa="delete-recurrence-button-cancel"
  >
    {{ data.noText || 'Cancel' }}
  </button>
  <app-progress-button
    data-qa="delete-recurrence-button-confirm"
    color="accent"
    [text]="data.yesText || 'Confirm'"
    (submit)="onConfirmClick($event)"
    [submitting]="isLoading.getValue()"
    cdkFocusInitial
  >
  </app-progress-button>
</mat-dialog-actions>
