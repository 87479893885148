<div class="flex justify-between items-start content-start">
  <h1 mat-dialog-title class="mat-dialog-title text-2xl">Edit Property</h1>
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <mat-tab-group color="accent" dynamicHeight>
    <mat-tab label="DETAILS">
      <div class="has-padding">
        <app-site-form [form]="siteForm"></app-site-form>
        <div
          class="flex flex-col justify-center items-center content-center gap-4"
        >
          <app-progress-button
            color="accent"
            text="Save"
            (submit)="onSaveSiteDetails()"
            [disabled]="!siteForm.valid"
            [submitting]="submitting"
          >
          </app-progress-button>
          <mat-hint class="center italic max-w-400">
            These details appear in the mobile application and the address is
            used to display accurate weather information in the digital signage.
          </mat-hint>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="MANAGEMENT">
      <div class="has-padding">
        <div
          class="flex flex-col justify-start items-center content-center gap-4"
        >
          <app-property-management-table
            [site]="site.id"
            class="w-full min-w-600"
          ></app-property-management-table>
        </div></div
    ></mat-tab>

    <mat-tab label="TEAM MEMBERS">
      <div class="has-padding">
        <div
          class="flex flex-col justify-start items-center content-center gap-4"
        >
          <app-edit-staff></app-edit-staff>
          <mat-hint class="center italic max-w-400">
            Updates made to this page are referenced for the Team Members
            section of the monthly printed newsletter. Please keep this
            information up to date.
          </mat-hint>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="LOGOS">
      <div class="has-padding">
        <div class="section-wrapper top">
          <h2 class="heading">GiGi Mobile App Logo</h2>
          <div class="mobile-logo">
            <div class="image-wrapper">
              <img [src]="logoSrc" hideOnFail />
            </div>
            <label>GiGi Mobile App Logo</label>
          </div>
          <div
            class="flex justify-between items-center content-center"
            style="width: 200px"
          >
            <button mat-raised-button color="accent" (click)="editLogo()">
              Upload
            </button>
            <button
              mat-raised-button
              color="accent"
              (click)="removeLogo('gigi')"
            >
              Remove
            </button>
          </div>
          <mat-hint class="center italic max-w-400">
            The GiGi Mobile App logo appears on the My Account and Community
            pages of the app.
          </mat-hint>
        </div>

        <div class="section-wrapper">
          <h2 class="heading">Printed Calendar Logo</h2>
          <div class="print-logo">
            <div class="image-wrapper">
              <img [src]="printLogoSrc" hideOnFail />
            </div>
            <label>Printed Calendar Logo</label>
          </div>
          <div
            class="flex justify-between items-center content-center"
            style="width: 200px"
          >
            <button mat-raised-button color="accent" (click)="editPrintLogo()">
              Upload
            </button>
            <button
              mat-raised-button
              color="accent"
              (click)="removeLogo('print')"
            >
              Remove
            </button>
          </div>
          <mat-hint class="center italic max-w-400">
            The Printed Calendar logo appears on the print calendar. (If
            unspecified, print calendar will use the mobile app logo.)
          </mat-hint>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="PORTRAIT">
      <div class="has-padding">
        <div
          class="flex flex-col justify-start items-center content-center gap-4"
        >
          <img class="max-w-200" [src]="imageSrc" hideOnFail />
          <div
            class="flex justify-between items-center content-center"
            style="width: 200px"
          >
            <button mat-raised-button color="accent" (click)="editImage()">
              Upload
            </button>
            <button
              mat-raised-button
              color="accent"
              (click)="removeLogo('portrait')"
            >
              Remove
            </button>
          </div>
          <mat-hint class="center italic max-w-400">
            The Property portrait image currently appears exclusively in the
            mobile app in the Community tab.
          </mat-hint>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="ROOMS">
      <div class="has-padding">
        <div
          class="flex flex-col justify-start items-center content-center gap-4"
        >
          <mat-hint class="center bold max-w-600">
            <p>Room names appear in digital signage and the mobile app.</p>
            <p>
              Room short names appear on the printed calendar. A max of 15 rooms
              will appear on the printed calendar.
            </p>
          </mat-hint>
          <app-rooms-table class="w-full min-w-600"></app-rooms-table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="FAQs">
      <div class="has-padding">
        <div
          class="flex flex-col justify-start items-center content-center gap-4"
        >
          <app-faqs-table class="w-full min-w-600"></app-faqs-table>
          <mat-hint class="center italic max-w-400">
            Frequently Asked Questions (FAQs) appear in the mobile application.
          </mat-hint>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
