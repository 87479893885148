import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Menu, MenuSlide } from 'src/app/core/interfaces/api';
import { MenuSlideService } from 'src/app/core/services/menu-slide.service';
import { SiteService } from 'src/app/core/services/site.service';
import { Image } from '@models/image';
import { MenuService } from 'src/app/core/services/menu.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-manage-menu-slide-modal',
  templateUrl: './manage-menu-slide-modal.component.html',
  styleUrls: ['./manage-menu-slide-modal.component.scss'],
})
export class ManageMenuSlideModalComponent implements OnInit {
  destroy$ = new Subject<void>();

  showMenuSlide = new UntypedFormControl(false);
  form = this.menuSlideForm();
  menu: Menu;
  today = new Date();
  siteId: number;
  menuSlide: MenuSlide;
  loading = false;
  constructor(
    private siteService: SiteService,
    private menuSlideService: MenuSlideService,
    private menuService: MenuService,
    private materialDialogRef: MatDialogRef<ManageMenuSlideModalComponent>,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: { siteId: number },
  ) {
    this.siteId = data.siteId;
  }

  ngOnInit(): void {
    this.siteService.getSite(this.siteId).subscribe((site) => {
      if (site) {
        this.showMenuSlide.setValue(site.showMenu);
      }
    });
    this.menuService
      .getMenuBySiteId(this.siteId, this.today)
      .subscribe((menu) => {
        this.menu = menu;
      });
    this.menuSlideService
      .getMenuSlideBySiteId(this.siteId)
      .subscribe((menuSlide) => {
        if (menuSlide) {
          this.menuSlide = menuSlide;
          this.form = this.menuSlideForm(menuSlide);
        }
      });
  }

  async saveMenuSlide() {
    this.loading = true;
    const currentSite = this.siteService.currentSite;
    const { displayDuration } = this.form.value;
    try {
      await this.siteService.updateSite(currentSite.id, {
        showMenu: this.showMenuSlide.value,
      });

      const data = {
        displayDuration,
      };

      if (this.menuSlide) {
        await this.menuSlideService.updateMenuSlide(this.menuSlide.id, data);
      } else {
        await this.menuSlideService.createMenuSlide({
          siteId: this.siteId,
          ...data,
        });
      }
      this.materialDialogRef.close();
    } catch (error) {
      this.alertService.error(error.message || 'Error saving menu slide');
    } finally {
      this.loading = false;
    }
  }

  menuSlideForm(menuSlide?: MenuSlide) {
    return new UntypedFormGroup({
      displayDuration: new UntypedFormControl(menuSlide?.displayDuration || 15),
    });
  }

  setBackgroundImage(backgroundImage: Image) {
    this.form.patchValue({ backgroundImage });
  }
}
