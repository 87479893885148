<div [formGroup]="form" class="flex flex-col gap-12">
  <div class="flex flex-col gap-1.5">
    <div class="flex flex-col gap-1.5">
      <div class="flex gap-12">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>First Name</mat-label>
          <input matInput autofocus required formControlName="firstName" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput required formControlName="lastName" />
        </mat-form-field>
      </div>
      <div class="flex gap-12">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>User Role</mat-label>
          <mat-select formControlName="roleId" required>
            <mat-option
              *ngFor="let role of userService.userRoles | async"
              [value]="role.id"
              [matTooltip]="
                role.id === 3
                  ? 'Property Admins only have access to selected Properties and cannnot manage other account users.'
                  : 'Organization Admins have access to all Properties and can manage all other account users.'
              "
            >
              {{ role.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent" *ngIf="!isOrgAdmin">
          <mat-label>Properties</mat-label>
          <mat-select
            formControlName="sites"
            multiple
            [disabled]="isOrgAdmin"
            panelClass="wide-select"
          >
            <mat-option
              *ngFor="let site of siteService.sites | async"
              [value]="site.id"
            >
              {{ site.name }}
            </mat-option>
          </mat-select>
          <mat-hint *ngIf="isOrgAdmin"
            >Organization Admins have access to all Properties.</mat-hint
          >
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
