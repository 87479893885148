import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnDestroy,
  AfterViewInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Newsletter } from '@models/newsletter';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NewsletterService } from 'src/app/core/services/newsletter.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogValues,
  ConfirmationDialogComponent,
} from '../modals/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  SingleItemFormModalConfig,
  SingleItemFormModalComponent,
} from '../modals/single-item-form-modal/single-item-form-modal.component';
import { SiteService } from 'src/app/core/services/site.service';
import { NewsletterStatus } from '../../../../../core/enums/newsletter-status';
import { ImageDto } from '@interfaces/image';
import { NewsletterAttachmentsComponent } from '../../manage/newsletters/newsletter-attachments/newsletter-attachments.component';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { GenericFEDataSource } from '../pagination-client-side/datasources/genericFE.datasource';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import bootstrapTable from '../pagination-server-side/bootstrap-table';

interface NewsletterRow {
  name: string;
  articleCount: number;
  characterCount: number;
  printQuantity: number;
  submissionDueOn: string;
  approvalDueOn: string;
  status: string;
}

@Component({
  providers: [NewsletterAttachmentsComponent, ImageUploadComponent],
  selector: 'app-newsletter-table',
  templateUrl: './newsletter-table.component.html',
  styleUrls: ['./newsletter-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterTableComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() set showProperty(show: boolean) {
    if (show) {
      this.displayedColumns.unshift('property'); // Display as first column
    }
  }

  @Input() set filter(filterValue: string) {
    if (this.dataSource) {
      this.searchFilter$.next(filterValue);
    }
  }

  // used to select which service observable to subscribe to
  @Input() dataStyle: 'site' | 'org';

  @Output() public updated: EventEmitter<boolean> = new EventEmitter();
  @Output() public newsletterCount: EventEmitter<number> = new EventEmitter();

  private searchFilter$ = new Subject<string>();
  private destroyed = new Subject<boolean>();

  public dataSource: MatTableDataSource<NewsletterRow>;
  public paginatedDataSource: GenericFEDataSource<Newsletter>;
  public filesProcessing = null;

  public displayedColumns = [
    // 'property', dynamically added here if @Input() showProperty is set (for org table)
    'name',
    'articleCount',
    'characterCount',
    'printQuantity',
    'submissionDueOn',
    'approvalDueOn',
    'status',
    'actions',
  ];
  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(''),
    verified: new UntypedFormControl(false),
    showDeleted: new UntypedFormControl(false),
  });

  constructor(
    private alertService: AlertService,
    public authService: AuthService,
    private dialog: MatDialog,
    private newsletterAttachmentsComponent: NewsletterAttachmentsComponent,
    public newsletterService: NewsletterService,
    private router: Router,
    private siteService: SiteService,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);

    this.paginatedDataSource = new GenericFEDataSource<Newsletter>(
      this.dataStyle === 'org'
        ? this.newsletterService.orgNewsletters
        : this.newsletterService.siteNewsletters,
      {
        sortingDataAccessor: this.sortingDataAccessor,
        filterBySearch: this.searchFilter,
      },
    );

    this.paginatedDataSource.$totalCount
      .pipe(takeUntil(this.destroyed))
      .subscribe((value) => {
        this.newsletterCount.emit(value);
      });
  }

  ngAfterViewInit(): void {
    bootstrapTable<Newsletter[]>(
      this.sort,
      this.paginator,
      this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
      this.filters,
    )
      .pipe(takeUntil(this.destroyed))
      .subscribe();

    this.searchFilter$.pipe(takeUntil(this.destroyed)).subscribe((value) => {
      this.filters.controls['search'].setValue(value);
    });
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  getNewsletterIsDraft(newsletter: Newsletter) {
    if (newsletter.status !== NewsletterStatus.draft.toLowerCase()) {
      return false;
    }
    if (this.authService.currentUser.isOrgAdmin || newsletter.isDraft) {
      return true;
    }
    return false;
  }

  /**
   * Ensure that the current Site is the same as the Newsletter to be edited; otherwise this could result in downstream
   * bugs. This is necessary because this component is being used in multiple pages. Specifically, if viewing the table
   * as an Org Admin, you see all Newsletters across all Org Sites. When they click into a Newsletter, we need to
   * update the Site that's currently active in the CMS.
   * @param newsletter
   */
  public editNewsletter(newsletter: Newsletter) {
    const newsletterSiteId =
      newsletter && newsletter.site
        ? newsletter.site.id
        : this.siteService.currentSiteId;

    if (newsletterSiteId !== this.siteService.currentSiteId) {
      this.siteService.setActiveSite(newsletterSiteId);
    }

    this.router.navigate([
      'manage',
      'content',
      this.siteService.currentSiteId,
      'newsletters',
      `${newsletter.id}`,
    ]);
  }

  public deleteNewsletter(newsletter: Newsletter) {
    const confirmData: ConfirmationDialogValues = {
      message: 'Do you want to delete this Newsletter? This cannot be undone.',
      onConfirm: async () => {
        const newsletterCopy = Object.assign({}, newsletter);
        newsletterCopy.deleted = true;
        await this.newsletterService.saveNewsletter(newsletterCopy);
        this.updated.next(true);
        this.alertService.success('Newsletter deleted.');
      },
      onError: (error) => {
        this.alertService.error(
          'Unable to delete Newsletter. Please try again.',
        );
        console.error(error);
      },
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
  }

  public editStatus(newsletter: Newsletter): void {
    const config: SingleItemFormModalConfig = {
      name: 'Newsletter Status',
      value: newsletter.status,
      inputType: 'select',
      required: true,
      enumInput: 'newsletterStatus',
      enumKeys: Object.keys(NewsletterStatus),
      onConfirm: async (result: any) => {
        const newsletterCopy = Object.assign({}, newsletter);
        newsletterCopy.status = result;
        await this.newsletterService.saveNewsletter(newsletterCopy);
        this.updated.next(true);
        this.alertService.success('Newsletter status updated.');
      },
      onError: (error) => {
        this.alertService.error(
          'Unable to save updated status. Please try again.',
        );
        console.error(error);
      },
    };

    const dialogRef = this.dialog.open(SingleItemFormModalComponent, {
      data: config,
    });
  }

  public openFinalDraftAttachment(attachment: ImageDto): void {
    window.open(attachment.downloadUrl, '_blank');
  }

  /**
   * Referencing this function from newsletter-attachments.component instead of
   * refactoring the function to a service since it references another component
   * (image-upload.component), and those aren't accessible (at least without
   * headache) from services.
   */
  public uploadFinalDraft(newsletter): void {
    this.newsletterAttachmentsComponent.addAttachment(newsletter, true);
  }

  public exportNewsletterFiles = (value) => {
    this.filesProcessing = value.id;
    this.newsletterService
      .exportNewsletterFiles(value.id)
      .then((response) => {
        this.alertService.success(
          'Request processing. Please check your email in a few minutes.',
        );
      })
      .catch((error) => {
        this.alertService.error(
          'There was a problem processing your request. Please try again.',
        );
      })
      .finally(() => {
        // Update loading spinner on UI
        this.filesProcessing = null;
        this.updated.next(true);
      });
  };

  sortingDataAccessor = (item, property) => {
    switch (property) {
      case 'name': {
        return `${item.name ? item.name.toLowerCase() : ''}`;
      }
      case 'articleCount': {
        return item.articleCount ? Number(item.articleCount) : 0;
      }
      case 'charcterCount': {
        return item.characterCount ? Number(item.characterCount) : 0;
      }
      case 'submissionDueOn': {
        return item.submissionDueOn ? new Date(item.submissionDueOn) : '';
      }
      case 'approvalDueOn': {
        return item.approvalDueOn ? new Date(item.approvalDueOn) : '';
      }
      case 'property': {
        return `${item.site ? item.site.name.toLowerCase() : ''}`;
      }
      default:
        return item[property];
    }
  };

  private searchFilter(data: Newsletter, filter): boolean {
    const lowercaseFilter = filter.toLowerCase();
    const propertyNameMatch = data.site.name
      ? data.site.name.toLowerCase().indexOf(lowercaseFilter)
      : -1;
    const newsletterNameMatch = data.name
      ? data.name.toLowerCase().indexOf(lowercaseFilter)
      : -1;

    return propertyNameMatch !== -1 || newsletterNameMatch !== -1;
  }
}
