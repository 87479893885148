import { Injectable } from '@angular/core';
import {
  // HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable, from, switchMap, take } from 'rxjs';
import { Auth } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: Auth, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.handle(req, next);
  }

  handle(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.auth.currentUser) {
      return next.handle(req);
    }
    return from(this.auth.currentUser.getIdToken()).pipe(
      take(1),
      switchMap((token) => {
        this.authService.setSessionTimeout(this.auth.currentUser);
        if (token) {
          const authReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });

          return next.handle(authReq);
        }
        return next.handle(req);
      }),
    );
  }
}
