import { Component, OnInit, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Site } from '@interfaces/announcement';
import { generateCsv, download, mkConfig } from 'export-to-csv';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { ActivityService } from 'src/app/core/services/activity.service';
import { AlertService } from 'src/app/core/services/alert.service';

import { PdfService } from 'src/app/core/services/pdf.service';
import { EventType } from '../../../../../../core/enums/event-type';

/**
 * Modal component to download a property's events in a given range
 * as either a PDF or CSV file.
 */

@Component({
  selector: 'app-event-download-modal',
  templateUrl: './event-download-modal.component.html',
  styleUrls: ['./event-download-modal.component.scss'],
})
export class EventDownloadModalComponent implements OnInit {
  public form: UntypedFormGroup;
  public csvLoading = new BehaviorSubject(false);
  public pdfLoading = new BehaviorSubject(false);

  constructor(
    private pdfService: PdfService,
    private activityService: ActivityService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<EventDownloadModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      selectedMonthIndex: number;
      selectedYear: number;
      timezone: string;
      site: Site;
    },
  ) {
    this.downloadCSV = this.downloadCSV.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
  }

  ngOnInit() {
    if (this.data) {
      // initialize date range as active month/year, timezone irrelevant
      const month = DateTime.local(
        this.data.selectedYear,
        this.data.selectedMonthIndex,
      );
      const startOfMonth = month.startOf('month').toJSDate();
      const endOfMonth = month.endOf('month').toJSDate();

      this.form = new UntypedFormGroup({
        startDate: new UntypedFormControl(startOfMonth, Validators.required),
        endDate: new UntypedFormControl(endOfMonth, Validators.required),
      });
    } else {
      this.form = new UntypedFormGroup({
        startDate: new UntypedFormControl(null, Validators.required),
        endDate: new UntypedFormControl(null, Validators.required),
      });
    }
  }

  public downloadPdf(): void {
    const startDay = this.form.get('startDate').value;
    const endDay = this.form.get('endDate').value;

    const startDate = DateTime.fromJSDate(startDay).startOf('day');

    const endDate = DateTime.fromJSDate(endDay).endOf('day');
    this.pdfLoading.next(true);
    this.activityService
      .getTimeRangeEvents(
        this.data.site.id,
        DateTime.fromJSDate(startDay)
          .setZone(this.data.timezone)
          .set({
            day: startDate.day,
            month: startDate.month,
            year: startDate.year,
          })
          .startOf('day'),
        DateTime.fromJSDate(endDay)
          .setZone(this.data.timezone)
          .set({
            day: endDate.day,
            month: endDate.month,
            year: endDate.year,
          })
          .endOf('day'),
      )
      .then((dataResponse) => {
        this.pdfService
          .downloadPdfEventList(
            dataResponse,
            startDate,
            endDate,
            this.data.site,
          )
          .then(() => {
            this.pdfLoading.next(false);
            this.dialogRef.close();
          });
      })
      .catch((error) => {
        this.pdfLoading.next(false);
        this.alertService.error('There was a problem downloading the PDF.');
      });
  }

  public downloadCSV(): void {
    const startDay = this.form.get('startDate').value;
    const endDay = this.form.get('endDate').value;
    const startDate = DateTime.fromJSDate(startDay).startOf('day');
    const endDate = DateTime.fromJSDate(endDay).endOf('day');

    this.csvLoading.next(true);
    this.activityService
      .getTimeRangeEvents(
        this.data.site.id,
        DateTime.fromJSDate(startDay)
          .setZone(this.data.timezone)
          .set({
            day: startDate.day,
            month: startDate.month,
            year: startDate.year,
          })
          .startOf('day'), // timezone data necessary for query
        DateTime.fromJSDate(endDay)
          .setZone(this.data.timezone)
          .set({
            day: endDate.day,
            month: endDate.month,
            year: endDate.year,
          })
          .endOf('day'), // timezone data necessary for query
      )
      .then((dataResponse) => {
        if (dataResponse.length === 0) {
          this.alertService.error('No events for the selected timeframe.');
          this.csvLoading.next(false);
          this.dialogRef.close();
          return;
        }
        const startString = `${startDate.monthShort}_${startDate.day}_${startDate.year}`;
        const endString = `${endDate.monthShort}_${endDate.day}_${endDate.year}`;
        const options = mkConfig({
          filename: `GiGi_Event_Export_${startString.replace(
            /\s/g,
            '',
          )}-${endString.replace(/\s/g, '')}.csv`,
          useBom: true,
          columnHeaders: [
            { key: 'date', displayLabel: 'Date' },
            { key: 'event', displayLabel: 'Event' },
            { key: 'location', displayLabel: 'Location' },
            { key: 'startTime', displayLabel: 'StartTime' },
            { key: 'endTime', displayLabel: 'EndTime' },
            { key: 'keywords', displayLabel: 'Keywords' },
            { key: 'type', displayLabel: 'Type' },
            { key: 'category', displayLabel: 'Category' },
            { key: 'subcategory', displayLabel: 'Subcategory' },
          ],
        });

        const dataList = [];

        for (const data of dataResponse) {
          const date = DateTime.fromISO(data.eventStart)
            .setZone(this.data.timezone)
            .toLocaleString(DateTime.DATE_SHORT);
          const startTime = DateTime.fromISO(data.eventStart)
            .setZone(this.data.timezone)
            .toFormat('h:mm a');
          const endTime = DateTime.fromISO(data.eventEnd)
            .setZone(this.data.timezone)
            .toFormat('h:mm a');
          let location = '';

          if (data.room?.name) {
            location = data.room.name;
          }

          if (data.room?.shortName) {
            location = location
              ? `${location}, ${data.room.shortName}`
              : data.room.shortName;
          }

          if (data.location) {
            location = location
              ? `${location}, ${data.location}`
              : data.location;
          }
          const formattedData = {
            date,
            event: data.title,
            location: location,
            startTime,
            endTime,
            keywords: `${data.content ? `${data.content}` : ''}`,
            type: EventType[data.eventType],
            category: data.category?.name,
            subcategory: data.subcategory?.name,
          };

          dataList.push(formattedData);
        }
        const csv = generateCsv(options)(dataList);
        download(options)(csv);

        this.csvLoading.next(false);
        this.dialogRef.close();
      })
      .catch((error) => {
        console.error('error downloading the CSV', error);
        this.csvLoading.next(false);
        this.alertService.error('There was a problem downloading the CSV.');
      });
  }
}
