import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Room } from '@models/room';
import { ReplaySubject, lastValueFrom } from 'rxjs';
import { SiteService } from './site.service';
import { take } from 'rxjs/operators';
import { sortBy } from 'lodash';

export interface CalendarObject {
  days: CalendarDay[];
}

export interface CalendarDay {
  events: string[];
}

@Injectable({
  providedIn: 'root',
})
export class RoomService {
  private _rooms = new ReplaySubject<Room[]>();
  public readonly rooms = this._rooms.asObservable();

  constructor(private http: HttpClient, private siteService: SiteService) {
    this.listenForSiteUpdates();
  }

  /**
   * Update rooms whenever site changes
   */
  private listenForSiteUpdates(): void {
    this.siteService.siteId.subscribe((siteId: number) => {
      this.processSiteUpdate(siteId);
    });
  }

  private processSiteUpdate(siteId): void {
    if (!siteId) {
      this.updateActiveRooms([]);
      return;
    }

    this.loadRooms(siteId).then((rooms: Room[]) => {
      const customRooms = sortBy(
        rooms.filter((room) => room.id < 3),
        ['room', 'id'],
      );
      const otherRooms = sortBy(
        rooms.filter((room) => room.id > 2),
        ['room', 'name'],
      );
      const allRooms = [...customRooms, ...otherRooms];
      this.updateActiveRooms(allRooms);
    });
  }

  /**
   * Force refresh of rooms
   */
  public refreshRooms(): void {
    this.siteService.siteId.pipe(take(1)).subscribe((siteId: number) => {
      this.processSiteUpdate(siteId);
    });
  }

  public updateRoom(room: Partial<Room>): Promise<Room> {
    const url = environment.apiUrl.concat('/api/v1/room');
    const roomTrimmed = {
      ...room,
      name: room?.name.trim(),
      shortName: room?.shortName.trim(),
    };
    try {
      return lastValueFrom(this.http.post<Room>(url, roomTrimmed));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  private updateActiveRooms(rooms: Room[]): void {
    const r = rooms && rooms.length ? rooms : [];
    this._rooms.next(r);
  }

  /**
   * TODO: This should be updated to use API v3
   * Results should be sorted with room ids 1 and 2 displayed first
   * Then the rest of the rooms sorted alphabetically by name
   */
  public loadRooms(siteId: number): Promise<Room[]> {
    const url = environment.apiUrl.concat('/api/v1/rooms/', siteId.toString());

    try {
      return lastValueFrom(this.http.get<Room[]>(url));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public deleteRoom(roomId: number): Promise<any> {
    const url = environment.apiUrl.concat(`/api/v1/room/${roomId}`);

    try {
      return lastValueFrom(this.http.put(url, {}));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public findRoomByName(name: string, rooms: Room[]): Room {
    const room = rooms.find((r) => r.name === name);
    return room;
  }

  public findRoomById(id: number, rooms: Room[]): Room {
    const room = rooms.find((r) => Number(r.id) === Number(id));
    return room;
  }
}
