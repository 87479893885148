<section
  class="display-cont individual-spotlight"
  [ngStyle]="
    spotlight?.background &&
    !spotlight.background.deleted && {
      background: 'url(' + spotlight.background?.downloadUrl + ')'
    }
  "
>
  <div class="dark-area">
    <div
      class="spotlight-img"
      *ngIf="spotlight && spotlight.image && !spotlight.image?.deleted"
      [ngStyle]="
        spotlight?.image && {
          background: 'url(' + spotlight.image?.downloadUrl + ')'
        }
      "
    ></div>
    <h1>
      <markdown [data]="spotlight.title"></markdown>
    </h1>
    <markdown [data]="spotlight.content"></markdown>
  </div>
  <app-clock *ngIf="showClock"></app-clock>
</section>
