<button
  mat-raised-button
  [color]="color"
  (click)="onClick()"
  [disabled]="disabled"
>
  <span class="inner-button">
    {{ label }}
    <mat-spinner
      *ngIf="loading"
      [color]="spinnerColor"
      diameter="20"
      class="inner-spinner"
    ></mat-spinner>
  </span>
</button>
