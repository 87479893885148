import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { NewsletterService } from 'src/app/core/services/newsletter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Newsletter } from '@models/newsletter';
import { Subject, lastValueFrom } from 'rxjs';
import { SiteService } from 'src/app/core/services/site.service';
import { Article } from '@models/article';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogValues,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Site } from '@models/site';

@Component({
  selector: 'app-view-newsletter',
  templateUrl: './view-newsletter.component.html',
  styleUrls: ['./view-newsletter.component.scss'],
})
export class ViewNewsletterComponent implements OnInit, OnDestroy {
  private destroyed = new Subject<boolean>();
  public newsletter: Newsletter;
  public selectedArticle: Article;
  public showEditArticle = false;
  public showViewArticle = false;
  public articleHasChanges = false;
  public newsletterId;
  public site: Site;

  constructor(
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private location: Location,
    public newsletterService: NewsletterService,
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.newsletterId = +this.route.snapshot.paramMap.get('newsletterId');

    // Exit page if no param
    if (!this.newsletterId) {
      this.backToSiteNewsletters();
      return;
    }

    this.fetchNewsletter();

    this.newsletterService
      .saveUserNewsletterViewedOn(this.newsletterId)
      .then(() => this.newsletterService.refreshUserNewsletterViewedOn());

    this.siteService.site.subscribe((site) => {
      this.site = site;
    });
  }

  public async fetchNewsletter(): Promise<void> {
    if (!this.newsletterId) {
      return;
    }
    try {
      const newsletter = await this.newsletterService.fetchNewsletter(
        this.newsletterId,
      );

      // if newsletter has been removed, return to newsletter overview
      if (!newsletter) {
        this.backToSiteNewsletters();
        return;
      }
      this.newsletter = newsletter;
    } catch (error) {}
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  // called by guard to confirm behavior with unsaved changes
  async canDeactivate(): Promise<boolean> {
    if (!this.showEditArticle || !this.articleHasChanges) {
      return true;
    }
    const confirmData: ConfirmationDialogValues = {
      title: `You have unsaved changes`,
      message: 'Are you sure you want to leave without saving?',
      yesText: 'Yes',
      noText: 'No',
      onConfirm: async () => {},
      onError: (error) => {},
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
    const response = await lastValueFrom(dialogRef.afterClosed());
    return response === 'click';
  }

  get siteId(): number {
    return this.siteService.currentSiteId;
  }

  public backToSiteNewsletters() {
    this.router.navigate(['manage', 'content', this.siteId, 'newsletters']);
  }

  /**
   * Implement with location.back() to support the user arriving at this screen from multiple pages.
   * Keeping backToSiteNewsletters() for the use case when someone directly loads this URL;
   * with goBack() they would exit the app entirely.
   */
  public goBack() {
    this.location.back();
  }

  /**
   * Using cdr to force change detection to ensure the app-edit-article component is always destroyed
   * before switching states. This is to avoid the component being open, a user clicking Edit for another
   * Article (or Add New Article), and issues arising. There's likely a better way to handle this, but this
   * appears to be working for now. -BH 9/14/2020
   */
  public async updateEditArticleComponent(article: Article = null) {
    if (this.showEditArticle) {
      const confirmData: ConfirmationDialogValues = {
        title: `You have unsaved changes`,
        message: 'Are you sure you want to leave without saving?',
        yesText: 'Yes',
        noText: 'No',
        onConfirm: async () => {
          this.showEditArticle = false;
          this.cdr.detectChanges();
          this.selectedArticle = article;
          this.showEditArticle = true;
          this.showViewArticle = false;
        },
        onError: (error) => {},
      };
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: confirmData,
      });
      const response = await lastValueFrom(dialogRef.afterClosed());
    } else {
      this.selectedArticle = article;
      this.showEditArticle = true;
      this.showViewArticle = false;
    }
  }

  public updateViewArticleComponent(article: Article): void {
    if (this.showViewArticle) {
      this.showViewArticle = false;
      this.cdr.detectChanges();
    }
    this.showViewArticle = true;
    this.showEditArticle = false;
    this.selectedArticle = article;
  }

  // necessary to control 'unsaved changes' triggers
  public hasChanges(value) {
    if (this.articleHasChanges !== value) {
      this.articleHasChanges = value;
    }
  }

  public async openNewsletterTemplates() {
    this.newsletterService.openNewsletterTemplates(this.site.trainingGuideUrl);
  }
}
