<mat-card appearance="outlined" color="primary">
  <mat-card-header class="w-full">
    <div class="flex w-full justify-between items-center">
      <mat-card-title color="primary">
        Users ({{
          paginatedDataSource?.totalCount ? paginatedDataSource?.totalCount : 0
        }})
      </mat-card-title>
      <div class="flex items-center gap-[15px]">
        <mat-slide-toggle (change)="toggleInactive($event)"
          >Show Inactive</mat-slide-toggle
        >
        <button mat-raised-button color="accent" (click)="addUser()">
          Add New User
        </button>
        <button
          mat-raised-button
          matTooltip="Export as CSV file"
          color="accent"
          (click)="downloadCSV()"
          [disabled]="!paginatedDataSource?.totalCount"
        >
          <mat-icon>save_alt</mat-icon>
        </button>
        <mat-form-field color="accent">
          <input
            #search="ngModel"
            matInput
            placeholder="Search"
            [(ngModel)]="filter"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="loading-wrapper">
      <div class="loading-spinner" *ngIf="paginatedDataSource.loading$ | async">
        <app-material-loader [fillContainer]="true"></app-material-loader>
      </div>
      <div
        class="loading-overlay"
        *ngIf="paginatedDataSource.loading$ | async"
      ></div>
      <table
        mat-table
        matSort
        matSortActive="lastLogin"
        matSortDirection="desc"
        [dataSource]="paginatedDataSource"
      >
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9999">
            <i>{{
              search?.value?.length
                ? 'No matches for this search.'
                : (paginatedDataSource.loading$ | async)
                ? ''
                : 'No users have been created.'
            }}</i>
          </td>
        </tr>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            ID
          </th>
          <td mat-cell *matCellDef="let user">{{ user.id }}</td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            First Name
          </th>
          <td mat-cell *matCellDef="let user">{{ user.firstName }}</td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Last Name
          </th>
          <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Email
          </th>
          <td mat-cell *matCellDef="let user">{{ user.email }}</td>
        </ng-container>

        <ng-container matColumnDef="sitesManaged">
          <th mat-header-cell *matHeaderCellDef disableClear>
            Properties Managed
          </th>
          <td
            mat-cell
            *matCellDef="let user"
            [matTooltip]="siteNames(user.sites || [])"
          >
            {{ user.sites?.length || 0 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="adminType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Admin
          </th>
          <td mat-cell *matCellDef="let user">
            {{ user.isOrgAdmin ? 'Organization' : 'Site' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastLogin">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Last Login
          </th>
          <td mat-cell *matCellDef="let user">
            {{ user.lastLogin ? (user.lastLogin | date : 'short') : '' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="totalLogins">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Total Logins
          </th>
          <td mat-cell *matCellDef="let user">
            {{ user.totalLogins || 0 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let user" class="action-wrapper">
            <div>
              <button
                mat-icon-button
                color="accent"
                (click)="editUser(user)"
                matTooltip="Edit User Details"
              >
                <mat-icon>edit_pencil</mat-icon>
              </button>
              <button
                *ngIf="!user.deleted; else reactivate"
                mat-icon-button
                color="accent"
                (click)="deleteUser(user)"
                matTooltip="Deactivate User"
              >
                <mat-icon>no_accounts</mat-icon>
              </button>
              <ng-template #reactivate
                ><button
                  mat-icon-button
                  color="accent"
                  (click)="reactivateUser(user)"
                  matTooltip="Reactivate User"
                >
                  <mat-icon>person_add</mat-icon>
                </button></ng-template
              >
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [length]="paginatedDataSource?.totalCount"
        [pageSize]="10"
        [pageSizeOptions]="[10, 20, 30, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
