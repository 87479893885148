<section class="h-screen">
  <div class="h-full w-full min-h-full min-w-full">
    <div class="flex items-center justify-center px-12 py-6 h-full w-full">
      <div class="w-1/3">
        <mat-card>
          <mat-card-content>
            <div
              class="flex flex-col justify-center items-center content-center"
            >
              <img src="assets/TGG_GiGi_Blue.png" width="150px" />
              <h3 class="title">Admin Login</h3>
              <router-outlet></router-outlet>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</section>
