import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';
import { SubcategoryService } from 'src/app/core/services/subcategory.service';

@Component({
  selector: 'app-category-select-modal',
  templateUrl: './category-select-modal.component.html',
  styleUrls: ['./category-select-modal.component.scss'],
})
export class CategorySelectModalComponent implements OnInit, OnDestroy {
  categories = [];
  selectedCategories = new UntypedFormControl(['All']);
  destroy$ = new Subject();

  constructor(private subcategoryService: SubcategoryService) {}

  ngOnInit(): void {
    this.subcategoryService.categoryList
      .pipe(takeUntil(this.destroy$))
      .subscribe((categories) => {
        this.categories = categories;
      });

    this.selectedCategories.valueChanges
      .pipe(startWith([]), pairwise(), takeUntil(this.destroy$))
      .subscribe(([prev, next]) => {
        // handles the selected and deselecting of categories when "All" is selected
        if (next?.includes('All') && next.length > 1) {
          // if previous has a length of 1 and includes "All", we know we're deselecting "All"
          if (prev.length === 1 && prev.includes('All')) {
            // if we select another category that isn't all, let's remove "All" from the list
            this.selectedCategories.setValue(next.filter((c) => c !== 'All'));
          } else {
            this.selectedCategories.setValue(['All']);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
