import { ArticleDto } from '../interfaces/article';
import { NewsletterDto } from '../interfaces/newsletter';
import { NewsletterStatus } from '../enums/newsletter-status';
import { SiteDto } from '../interfaces/site';
import { NewsletterCommentDto } from '../interfaces/newsletterComment';
import { ImageDto } from '../interfaces/image';
import { Article } from './article';
import { UserDto } from '../interfaces/user';
export class Newsletter implements NewsletterDto {
  // Base properties
  id: number;
  createdOn: Date;
  updatedOn: Date;
  deleted: boolean;

  // Specific properties
  approvalDueOn: Date;
  name: string;
  siteId: number;
  status: NewsletterStatus;
  submissionDueOn: Date;
  printQuantity: number;
  articleCount: number;
  lastNotificationOn?: Date; // timestamp of when the last notification-triggering event

  // Always joined properties
  article: ArticleDto[];
  newsletterComment: NewsletterCommentDto[];
  image: ImageDto[]; // referred ot as "attachments" in UI
  lastNotificationBy?: UserDto; // user that caused last notification-triggering event

  // Sometimes joined properties
  site?: SiteDto;

  constructor(data: Partial<NewsletterDto>) {
    Object.assign(this, data);
  }

  /**
   * Return true if no Article is marked as invalid
   */
  get validForDleToSubmit(): boolean {
    const allArticlesMeetCharacterCount =
      this.article
        .map((article) => new Article(article))
        .findIndex((article) => !article.articleValid(this.articleCount)) ===
      -1;
    const statusIsPendingDleApproval = this.isPendingDleApproval;
    return allArticlesMeetCharacterCount || statusIsPendingDleApproval;
  }

  get charactersRemaining(): number {
    return this.idealCharacterCount - this.characterCount;
  }

  get idealCharacterCount(): number {
    return this.article
      .map((article) => new Article(article))
      .filter((article) => !article.isBackpage)
      .reduce((acc, article) => {
        return acc + article.idealArticleLength(this.articleCount);
      }, 0);
  }

  get characterCount(): number {
    return this.article.reduce((acc, articleDto: ArticleDto) => {
      const article = new Article(articleDto);
      return acc + article.characterCount;
    }, 0);
  }

  get isDraft(): boolean {
    return this.statusIndex === 0;
  }

  get isPendingDleApproval(): boolean {
    return this.statusIndex === 2;
  }

  get dleCanEdit(): boolean {
    return this.isDraft;
  }

  get statusIndex(): number {
    return Object.keys(NewsletterStatus).indexOf(this.status);
  }

  get finalDraftAttachment(): ImageDto {
    return this.image.find((attachment) => attachment.isPrimary);
  }

  get otherAttachments(): ImageDto[] {
    // Make copy to not alter original array
    const attachments = [...this.image];
    return attachments.filter((attachments) => !attachments.isPrimary);
  }

  // Can only have two frontPage Articles per Newsletter
  get frontPageLimitReached(): boolean {
    const articles = this.article || [];
    return articles.filter((article) => article.frontPage).length >= 2;
  }

  /**
   * Return true if there is an outstanding notification the user has not yet viewed
   */
  public hasNotification(userId: number, lastViewedOn: Date): boolean {
    const hasBeenNotifyingEvent = this.lastNotificationBy || null;
    const notifyingEventByOtherUser = hasBeenNotifyingEvent
      ? this.lastNotificationBy.id !== userId
      : null;
    const userHasNotViewedYet = !lastViewedOn
      ? true
      : this.lastNotificationOn > lastViewedOn;
    return (
      hasBeenNotifyingEvent && notifyingEventByOtherUser && userHasNotViewedYet
    );
  }
}
