import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

export interface AuthFormValues {
  email: string;
  password: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.scss'],
})
export class AuthFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  public hide = true; // password input hide/show

  constructor() {}

  ngOnInit() {}

  static formModel(formValues = null) {
    return new UntypedFormGroup({
      email: new UntypedFormControl(
        formValues ? formValues.email : null,
        Validators.compose([Validators.required, Validators.email])
      ),
      password: new UntypedFormControl(
        formValues ? formValues.password : null,
        Validators.compose([Validators.required, Validators.minLength(6)])
      ),
    });
  }

  // Using any type since sites here is an array of siteIds, whereas elsewhere in the app it's an array of sites
  static serialize(formValues: AuthFormValues): AuthFormValues {
    const serializedForm = {
      email: formValues.email,
      password: formValues.password,
    };

    return serializedForm;
  }
}
