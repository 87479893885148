import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { parse } from 'date-fns';

export function dateAfterValidator(
  getDate,
  parseFormat = 'yyyy-MM-dd',
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const compareValue = getDate();
    if (!value || !compareValue) {
      return null;
    }
    if (
      parse(compareValue, parseFormat, new Date()).getTime() <
      parse(value, parseFormat, new Date()).getTime()
    ) {
      return null;
    } else {
      return { dateAfterValidator: true };
    }
  };
}
