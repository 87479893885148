import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';

import { EditSiteComponent } from '../edit-site/edit-site.component';
import { AddSiteComponent } from '../add-site/add-site.component';
import { SiteService } from 'src/app/core/services/site.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { Site } from '@models/site';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogValues,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import {
  GenericBEDataSource,
  PaginationInformation,
} from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';
import bootstrapTable from 'src/app/shared/pagination-server-side/bootstrap-table';

interface SiteRow {
  id: number;
  name: string;
  state: string;
  type: string;
  activeForMobile: boolean;
}
@Component({
  selector: 'app-sites-table',
  templateUrl: './sites-table.component.html',
  styleUrls: ['./sites-table.component.scss'],
})
export class SitesTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() set filter(filterValue: string) {
    if (this.dataSource) {
      this.searchFilter$.next(filterValue);
    }
  }

  private searchFilter$ = new BehaviorSubject('');
  private destroyed = new Subject<boolean>();

  public dataSource: MatTableDataSource<SiteRow>;
  public paginatedDataSource: GenericBEDataSource<Site>;
  private manualRefreshTrigger$ = new BehaviorSubject(0);
  public displayedColumns = [
    'id',
    'name',
    'state',
    'type',
    'activeForMobile',
    'actions',
  ];

  public filters = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    searchBy: new UntypedFormControl(this.searchFilter$.value),
    verified: new UntypedFormControl(false),
    showDeleted: new UntypedFormControl(false),
    refreshTrigger: new UntypedFormControl(0),
  });

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private dialog: MatDialog,
    public siteService: SiteService,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);

    this.paginatedDataSource = new GenericBEDataSource<Site>(
      this.getSitesPaginated.bind(this),
    );
  }

  private getSitesPaginated(
    queryParams: HttpParams,
    pagination: PaginationInformation,
  ) {
    return this.siteService.getSitesPaginated(queryParams, pagination);
  }

  ngAfterViewInit(): void {
    // Must wait until we have an active site to load this data
    this.siteService.site
      .pipe(
        filter((site) => site !== null),
        take(1),
        tap((site) => {
          bootstrapTable<Site[]>(
            this.sort,
            this.paginator,
            this.paginatedDataSource.loadData.bind(this.paginatedDataSource),
            this.filters,
          )
            .pipe(takeUntil(this.destroyed))
            .subscribe();

          combineLatest([this.searchFilter$, this.manualRefreshTrigger$])
            .pipe(
              // check to see if search has a value and if refresh is truthy
              filter((val) => val[0].trim().length > 0 || !!val[1]),
              takeUntil(this.destroyed),
            )
            .subscribe(([search, refresh]) => {
              this.filters.controls['search'].setValue(search);
              this.filters.controls['refreshTrigger'].setValue(refresh);
            });
        }),
        takeUntil(this.destroyed),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  public editSite(site: Site): void {
    // Set the activeSite to the site being edited
    // This ensures that roomService (and long-term others) have the correct site's information
    this.siteService.setActiveSite(site.id);
    const dialogRef = this.dialog.open(EditSiteComponent, {
      data: new Site(site),
      maxWidth: '70vw',
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) {
          this.manualRefreshTrigger$.next(this.manualRefreshTrigger$.value + 1);
        }
      });
  }

  public deleteSite(site: Site): void {
    const confirmData: ConfirmationDialogValues = {
      message: 'Do you want to delete this property? This cannot be undone.',
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.siteService.deleteSite(String(site.id)).then(() => {
          this.siteService.refreshSites();
          this.authService.refreshUser();
          this.alertService.success('Property deleted.');
          this.manualRefreshTrigger$.next(this.manualRefreshTrigger$.value + 1);
        });
      }
    });
  }

  public addSite(): void {
    const dialogRef = this.dialog.open(AddSiteComponent, {});
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) {
          this.manualRefreshTrigger$.next(this.manualRefreshTrigger$.value + 1);
        }
      });
  }
}
