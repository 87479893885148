<h2 mat-dialog-title>New Meal Plan</h2>
<mat-dialog-content>
  <div class="flex justify-center" *ngIf="submitting">
    <mat-spinner></mat-spinner>
  </div>
  <div [hidden]="submitting">
    <form [formGroup]="form" class="flex flex-col gap-6">
      <mat-form-field appearance="outline" color="accent" class="w-full">
        <mat-label>Sites</mat-label>
        <mat-select formControlName="siteIds" multiple>
          <mat-option *ngFor="let site of sites | async" [value]="site.id">
            {{ site.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.siteIds.invalid">
          Select one or more sites.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent" class="w-full">
        <mat-label>Start Date</mat-label>
        <input
          matInput
          required
          formControlName="startDate"
          [matDatepicker]="startDatePicker"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
        <mat-error *ngIf="form.controls.startDate.invalid">
          Select a start date for the meal plan.
        </mat-error>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        color="accent"
        class="w-full"
        matTooltip="This is the number of cycles the 35 day meal plan will repeat for consecutively."
        [matTooltipShowDelay]="1000"
      >
        <mat-label># of Cycles</mat-label>
        <input matInput type="number" min="1" formControlName="cycleCount" />
      </mat-form-field>
    </form>

    <div *ngIf="cycleEndDate !== null" class="flex flex-col gap-[4px]">
      <p class="info-header">Meal plan will repeat until</p>
      <p>{{ cycleEndDate | date : 'fullDate' }}</p>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close [disabled]="submitting">
    Cancel
  </button>
  <button
    mat-raised-button
    cdkFocusInitial
    color="accent"
    [disabled]="form.invalid || submitting"
    (click)="clickSubmit()"
  >
    Submit
  </button>
</mat-dialog-actions>
