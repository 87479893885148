<mat-card appearance="outlined" color="primary" class="attachments-card">
  <mat-card-header>
    <mat-card-title> Proofs & Finals </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-card-subtitle
      ><i
        >Only Org Admins and Editors can upload Proofs & Finals.</i
      ></mat-card-subtitle
    >
    <mat-card-subtitle> Final Draft </mat-card-subtitle>
    <ng-container *ngIf="newsletter?.finalDraftAttachment">
      <app-attachment-card
        [attachment]="newsletter.finalDraftAttachment"
        (attachmentDeleted)="attachmentDeleted()"
      ></app-attachment-card>
    </ng-container>
    <div
      *ngIf="
        authService.currentUser.isOrgAdmin && !newsletter?.finalDraftAttachment
      "
      class="flex justify-center items-center content-center"
    >
      <button
        mat-button
        (click)="addAttachment(newsletter, true)"
        color="accent"
      >
        Upload Final Draft
      </button>
    </div>

    <br />

    <mat-card-subtitle> Proofs </mat-card-subtitle>
    <div class="flex justify-center items-center content-center">
      <button
        mat-button
        (click)="addAttachment(newsletter, false)"
        color="accent"
        *ngIf="authService.currentUser.isOrgAdmin"
      >
        Upload Attachment
      </button>
    </div>

    <!-- List of all attachments that aren't the Final Draft -->
    <app-attachment-card
      *ngFor="let attachment of newsletter?.otherAttachments"
      [attachment]="attachment"
      (attachmentDeleted)="attachmentDeleted()"
      [marginTopClass]="true"
    ></app-attachment-card>
  </mat-card-content>
</mat-card>
