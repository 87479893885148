import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Announcement } from '@models/announcement';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-display-name-in-lights-slide',
  templateUrl: './display-name-in-lights-slide.component.html',
  styleUrls: ['./display-name-in-lights-slide.component.scss'],
})
export class DisplayNameInLightsSlideComponent implements OnInit {
  @Input() announcement: Announcement;

  constructor() {}

  ngOnInit() {}
}
