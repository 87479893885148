<div class="flex justify-between items-start content-start">
  <h1 mat-dialog-title class="mat-dialog-title text-2xl">Add New User</h1>
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <h2 class="mat-h2">Authentication Details</h2>
  <app-auth-form [form]="authForm"></app-auth-form>
  <mat-divider class="has-y-margin"></mat-divider>

  <h2 class="mat-h2">User Details</h2>
  <app-user-form [form]="userForm"></app-user-form>
</mat-dialog-content>
<mat-dialog-actions class="flex !justify-center items-end content-end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <app-progress-button
    color="accent"
    text="Save"
    (click)="onSave()"
    [disabled]="userForm.invalid || authForm.invalid"
    [submitting]="submitting"
  >
  </app-progress-button>
</mat-dialog-actions>
