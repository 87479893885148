<app-loading *ngIf="!displayService?.announcement"></app-loading>
<div [@fadeIn] *ngIf="displayService?.announcement">
  <div
    (click)="displayService.showNextSlide()"
    [ngSwitch]="activeAnnouncement?.styleId"
  >
    <!-- Individual Spotlight View -->
    <div *ngSwitchCase="style.IndividualSpotlight" [@fadeIn]>
      <app-display-individual-spotlight
        [spotlight]="activeAnnouncement"
        [showClock]="true"
      ></app-display-individual-spotlight>
    </div>

    <!-- Image Centric Slide -->
    <div *ngSwitchCase="style.ImageCentric" [@fadeIn]>
      <app-display-title-slide
        [announcement]="activeAnnouncement"
        [showClock]="true"
      ></app-display-title-slide>
    </div>

    <!-- Name In Lights Slide -->
    <div *ngSwitchCase="style.NameInLights" [@fadeIn]>
      <app-display-name-in-lights-slide
        [announcement]="activeAnnouncement"
      ></app-display-name-in-lights-slide>
    </div>

    <!-- Activities and Events View -->
    <div *ngSwitchCase="style.ActivityAndEvents" [@fadeIn]>
      <app-display-event
        [announcement]="activeAnnouncement"
        [showClock]="true"
      ></app-display-event>
    </div>

    <!-- Collage Slide -->
    <div *ngSwitchCase="style.Collage" [@fadeIn]>
      <app-display-collage
        [announcement]="activeAnnouncement"
        [showClock]="true"
      ></app-display-collage>
    </div>

    <!-- Default (Announcement, Special Announcement) View -->
    <div *ngSwitchDefault [@fadeIn]>
      <app-display-announcement
        [announcement]="activeAnnouncement"
        [showClock]="true"
      ></app-display-announcement>
    </div>
  </div>
</div>
