import { DateTime } from 'luxon';

import { AnnouncementDto } from '../interfaces/announcement';
import { Style } from '../models/style';
import { Image } from '../models/image';
import { Room } from './room';
import { EventTag } from './eventTag';
import { SiteDto } from '../interfaces/site';
import { CategoryDto } from '../interfaces/category';
import { SubcategoryDto } from '../interfaces/subcategory';
import { EventType } from '../enums/event-type';
import { SlideStyleType } from '../enums/slide-style-type';
import { RecurrenceDto } from '../interfaces/recurrence';

export class Announcement implements AnnouncementDto {
  id: number;
  createdOn: Date;
  updatedOn: Date;
  deleted: boolean;
  title: string;
  content: string;
  startShowing: Date;
  stopShowing: Date;
  eventStart?: Date;
  eventEnd?: Date;
  location: string; // Only set if custom room
  displayDuration: number;
  site: SiteDto;
  siteId: number;
  style: Style;
  styleId: number;
  image: Image;
  imageId: number;
  background: Image;
  backgroundId: number;
  room: Room;
  roomId: number;
  eventTags: EventTag[] | number[];
  eventType: EventType;
  isSpecialEvent: boolean;
  isGenerated = false;
  category: CategoryDto;
  categoryId: number;
  subcategory: SubcategoryDto;
  subcategoryId: number;
  allDay: boolean;

  videoUrl?: string; // URL of video for video events
  recurrence?: RecurrenceDto;
  secondaryImages?: Image[];
  numberOfImages?: number;
  showOnPrintCalendar: boolean;
  showOnDigitalSignage: boolean;

  constructor(data: Partial<AnnouncementDto>) {
    this.id = data.id;
    this.createdOn = data.createdOn;
    this.updatedOn = data.updatedOn;
    this.deleted = data.deleted;
    this.title = data.title;
    this.content = data.content;
    this.startShowing = data.startShowing;
    this.stopShowing = data.stopShowing;
    this.eventStart = data.eventStart;
    this.eventEnd = data.eventEnd;
    this.location = data.location;
    this.displayDuration = data.displayDuration;
    this.site = data.site;
    this.siteId = data.siteId;
    this.style = data.style;
    this.styleId = data.styleId;
    this.image = data.image;
    this.imageId = data.imageId;
    this.background = data.background;
    this.backgroundId = data.backgroundId;
    this.room = data.room;
    this.roomId = data.roomId;
    this.eventTags = data.eventTags;
    this.eventType = data.eventType;
    this.isSpecialEvent = data.isSpecialEvent;
    this.isGenerated = data.isGenerated;
    this.category = data.category;
    this.categoryId = data.categoryId;
    this.subcategory = data.subcategory;
    this.subcategoryId = data.subcategoryId;
    this.allDay = data.allDay;
    this.videoUrl = data.videoUrl;
    this.recurrence = data.recurrence;
    this.numberOfImages = data.numberOfImages;
    this.showOnPrintCalendar = data.showOnPrintCalendar;
    this.showOnDigitalSignage = data.showOnDigitalSignage;

    if (data.secondaryImages?.length) {
      this.secondaryImages = data.secondaryImages.map(
        (image) => new Image(image),
      );
    } else {
      this.secondaryImages = data.secondaryImages;
    }
  }

  get timezone(): string {
    return this.site && this.site.timezone ? this.site.timezone : null;
  }

  /**
   * Get timeRange in property's timezone (e.g. "11:59pm - 12:59am")
   */
  get timeRange(): string {
    if (!this.eventStart || !this.eventEnd) {
      return null;
    }
    return `${this.getTime(this.eventStart)} - ${this.getTime(this.eventEnd)}`;
  }

  /**
   * Get eventDate in property's timezone (e.g. "Wed, Mar 4")
   */
  get eventDate(): string {
    if (!this.eventStart) {
      return null;
    }
    return DateTime.fromISO(this.eventStart.toString(), {
      zone: this.timezone,
    }).toFormat('EEE, MMM d');
  }

  // @TODO REFACTOR THESE
  get styleType(): string {
    return SlideStyleType[this.styleId];
  }

  get isIndividualSpotlight(): boolean {
    return this.styleId === 1;
  }

  get isEvent(): boolean {
    return this.styleId === 3;
  }

  get isAnnouncement(): boolean {
    return this.styleId === 4;
  }

  get isSpecialAnnouncement(): boolean {
    return this.styleId === 5;
  }

  get isNameInLights(): boolean {
    return this.styleId === 7;
  }

  // Image-Centric Slide
  get isTitleSlide(): boolean {
    return this.styleId === 12;
  }

  get isCollage(): boolean {
    return this.styleId === 13;
  }

  get isGif(): boolean {
    return this.styleId === 30;
  }

  get isPublished(): boolean {
    if (!this.startShowing || !this.stopShowing) {
      return false;
    }

    if (this.startShowing < new Date()) {
      return false;
    }

    if (this.stopShowing < new Date()) {
      return false;
    }

    return true;
  }

  get isPastEvent(): boolean {
    if (!this.isEvent || !this.eventStart) {
      return false;
    }

    const start = DateTime.fromISO(this.eventEnd.toString());
    const now = DateTime.local();
    return start < now;
  }

  get isCustomRoom(): boolean {
    return this.room && this.room.id && Number(this.room.id) === 1;
  }

  get isVideoRoom(): boolean {
    return this.room && this.room.id && Number(this.room.id) === 2;
  }

  get isDefinedRoom(): boolean {
    return !this.isCustomRoom && !this.isVideoRoom;
  }

  // If event, return place (place where event is happening), based on whether it's a Custom Room or not.
  get place(): string {
    if (!this.isEvent) {
      return null;
    }
    if (this.isCustomRoom) {
      return this.location;
    }
    return this.room?.name;
  }

  // Cleanup function to remove unecessary data if announcement type is changed
  public clearUnusedSlideData() {
    if (!this.isEvent) {
      this.eventEnd = null;
      this.eventStart = null;
      this.location = null;
      this.videoUrl = null;
      this.room = null;
    }
  }

  /**
   * Set time to property's timezone (e.g. 12:30pm). Set .toLowerCase() since Luxon only has capitalized AM/PM
   */
  private getTime(date: Date): string {
    return DateTime.fromISO(date.toString(), { zone: this.timezone })
      .toFormat('h:mma')
      .toLowerCase();
  }
}
