import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Announcement } from '@models/announcement';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-display-gif-slide',
  templateUrl: './display-gif-slide.component.html',
  styleUrls: ['./display-gif-slide.component.scss'],
})
export class DisplayGifSlideComponent implements OnInit {
  @Input() set announcement(value: Announcement) {
    this.currentAnnouncement = value;
    if (value.secondaryImages?.length) {
      this.image = value.secondaryImages[0].downloadUrl;
    }
  }

  currentAnnouncement: Announcement;
  image: string;

  constructor() {}

  ngOnInit(): void {}
}
