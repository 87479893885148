import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  ReplaySubject,
  firstValueFrom,
  lastValueFrom,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
  Auth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  User as FirebaseUser,
} from '@angular/fire/auth';

import { User } from '@models/user';
import { environment } from 'src/environments/environment';
import { UserDto } from '@interfaces/user';
import { UtilityService } from './utility.service';
import { ROUTES } from '../enums/routes';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authState = new BehaviorSubject<FirebaseUser>(null);
  public readonly authState = this._authState.asObservable();

  private _user = new BehaviorSubject<User>(null);
  public readonly user = this._user.asObservable();

  // Using this exclusively for the property.guard since the _user BehaviorSubject
  // needs to emit an initial value. Probably a better way to merge these two...
  // -BH 3/7/19
  private _userReplay = new ReplaySubject<User>(1);
  public readonly userReplay = this._userReplay.asObservable();

  public firebaseAuthToken: string;

  private _sessionTimeout;

  constructor(
    private auth: Auth,
    private router: Router,
    private http: HttpClient,
    private utilityService: UtilityService,
    private alertService: AlertService,
    private zone: NgZone,
  ) {
    // hook in to angular/fire/auth's auth state observeable. Use the variant that watches for token refresh
    auth.onIdTokenChanged(async (user: FirebaseUser) => {
      // Make sure something has changed
      if (this._authState.value === user) {
        if (user) {
          // if only token changed, no need to update everything, still update the session
          this.setSessionTimeout(user);
        }
        return;
      }
      // Fires when user was signed in, signed out, or token was refreshed
      let systemUser;

      this._authState.next(user);
      if (user) {
        systemUser = await this.getUserRecord(user.uid);
      }

      if (
        !systemUser ||
        !systemUser.id ||
        !systemUser.isSiteAdmin ||
        systemUser.deleted
      ) {
        // Does not apply to the display app
        if (!this.router.url.includes(ROUTES.display)) {
          // sign out directly rather than using this.signOut due to route intercept
          await signOut(this.auth);
          this._user.next(null);
          // warn deactivated users to get admin help
          if (systemUser?.deleted) {
            this.alertService.error('Please contact your administrator.');
          }
        }
      } else {
        this.userUpdated(systemUser, user);
        this.setSessionTimeout(user);
      }
    });
  }

  get currentUser(): User {
    return this.authenticated ? this._user.getValue() : null;
  }

  // Returns true if the user is logged in
  get authenticated(): boolean {
    return this.currentAuthUser !== null;
  }

  // Returns current user firebase id
  get firebaseId(): string {
    return this.authenticated ? this.currentAuthUser.uid : null;
  }

  // Returns current user UID
  get email(): string {
    return this.authenticated ? this.currentAuthUser.email : null;
  }

  get currentAuthUser(): FirebaseUser {
    return this.auth ? this.auth.currentUser : null;
  }

  get currentOrgId(): number {
    return this.currentUser && this.currentUser?.orgId
      ? this.currentUser.orgId
      : null;
  }

  public refreshUser(id?: string): void {
    this.getUserRecord(id || this.firebaseId).then((user: User) => {
      this.userUpdated(user);
    });
  }

  public async userUpdated(user: User, firebaseUser?: FirebaseUser) {
    if (firebaseUser) {
      this.firebaseAuthToken = await firebaseUser.getIdToken();
    }
    if (this._user.value === null) {
      // prevents marking login on token refresh
      this.saveUserLogin(user);
    }
    this._user.next(user);
    this._userReplay.next(user);
    this.utilityService.setSentryUser(user.id);
  }

  /**
   * Users are logged out after one hour of inactivity.
   * Timeout is reset when the the session changes, and on the http intercept
   * @param user
   */
  public setSessionTimeout = (user) => {
    // clear existing timeout
    if (this._sessionTimeout) {
      clearTimeout(this._sessionTimeout);
    }

    if (user) {
      // User is logged in.
      const sessionDurationInMS = 1000 * 60 * 60; // session length is one hour
      // const sessionDurationInMS = 1000 * 60;
      this._sessionTimeout = setTimeout(
        () =>
          this.zone.run(() => {
            this.signOut(
              'You were logged out due to inactivity. Please sign in again.',
            );
          }),
        sessionDurationInMS,
      );
    }
  };

  public signIn(email: string, password: string): any {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  public forgotPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  public async signOut(message?: string) {
    if (message) {
      // window alert is used here because we want to refresh in the background, which would close the alert created by the app
      window.alert(message);
    }
    await this.auth.signOut();
    location.reload();
  }

  private async getUserRecord(firebaseId: string): Promise<UserDto> {
    const url = `${environment.apiv3Url}/user/firebase/${firebaseId}`;
    try {
      return firstValueFrom(
        this.http.get(url).pipe(
          map((user: UserDto) => {
            return new User(user);
          }),
        ),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Record user's login for tracking/reporting purposes. Should return 200 w/o any response body.
   */
  private saveUserLogin(user: User): Promise<null> {
    const url = `${environment.apiv3Url}/user/login/${user.id}`;

    try {
      return <Promise<null>>(
        lastValueFrom(this.http.post(url, { platform: 'cms' }))
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }
}
