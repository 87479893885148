<div [formGroup]="form" class="flex flex-col gap-12">
  <div class="flex flex-col gap-1.5">
    <div class="flex flex-col gap-1.5">
      <mat-hint class="center italic" *ngIf="!isEditing"
        >This Community Update will be sent to any residents' mobile phones and
        tablets with the GiGi app installed. It will also appear in the app's
        Community Updates tab.</mat-hint
      >
      <mat-hint class="center italic" *ngIf="isEditing"
        >This will update the Community Update within the app. The push
        notification has already been sent and cannot be edited. This will not
        send a new push notification.</mat-hint
      >
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Title</mat-label>
        <input
          matInput
          autofocus
          required
          maxLength="35"
          formControlName="title"
        />
        <mat-hint
          >{{ form?.controls?.title.value?.length || 0 }}/35 (optimal character
          count is 26 or less)</mat-hint
        >
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Text</mat-label>
        <input matInput autofocus required formControlName="text" />
        <mat-hint
          >{{ form?.controls?.text.value?.length || 0 }}/140
          characters</mat-hint
        >
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Link for Additional Information (optional)</mat-label>
        <input matInput autofocus formControlName="moreInfoUrl" type="url" />
        <mat-error
          *ngIf="
            form.controls['moreInfoUrl'].hasError('pattern') &&
            form.controls['moreInfoUrl'].touched
          "
        >
          Please insert valid website link (e.g.
          https://www.thegoodmangroup.com)
        </mat-error>
        <!-- <mat-hint>{{form?.controls?.moreInfoUrl.value?.length || 0}}/140 characters</mat-hint> -->
      </mat-form-field>
      <!-- <mat-hint class="center italic">{{expirationHint}}</mat-hint>
      <mat-form-field appearance="outline">
        <mat-label>Notification Expiration Date</mat-label>
        <input matInput formControlName="expirationDate" required type="date">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Notification Expiration Time</mat-label>
        <input matInput formControlName="expirationTime" required placeholder="00:00 PM" type="time">
      </mat-form-field> -->
    </div>
  </div>
</div>
