import { OrganizationDto } from '../interfaces/organization';
import { TagDto } from '../interfaces/tag';
import { CategoryDto } from '../interfaces/category';
import { SubcategoryDto } from '../interfaces/subcategory';

export class Category implements CategoryDto {
  id: number;
  createdOn: Date;
  updatedOn: Date;
  deleted: boolean;
  name: string;
  organization: OrganizationDto;
  subcategories: SubcategoryDto[];
  tag: TagDto;

  constructor(data: Partial<CategoryDto>) {
    Object.assign(this, data);
  }
}
