<mat-card
  appearance="outlined"
  class="attachment-item flex justify-between items-center content-center"
  [class.margin-top]="marginTopClass"
>
  <div (click)="openAttachment(attachment)" style="cursor: pointer">
    <p>{{ imageService.getImageName(attachment) }}</p>
    <p>Uploaded {{ attachment.createdOn | date : 'short' }}</p>
    <p *ngIf="attachment.caption">
      <i>{{ attachment.caption }}</i>
    </p>
  </div>
  <button
    mat-icon-button
    (click)="deleteAttachment(attachment)"
    *ngIf="authService.currentUser.isOrgAdmin"
  >
    <mat-icon>delete</mat-icon>
  </button>
</mat-card>
