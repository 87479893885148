import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FaqFormComponent } from 'src/app/shared/forms/faq-form/faq-form.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { AddFaqComponent } from '../add-faq/add-faq.component';
import { FaqService } from 'src/app/core/services/faq.service';
import { SiteService } from 'src/app/core/services/site.service';
import { Faq } from '@models/faq';

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.scss'],
})
export class EditFaqComponent implements OnInit {
  public faqForm = FaqFormComponent.formModel();
  public submitting = false;

  constructor(
    private alertService: AlertService,
    private dialogRef: MatDialogRef<AddFaqComponent>,
    private faqService: FaqService,
    @Inject(MAT_DIALOG_DATA) public faq: Faq
  ) {}

  ngOnInit() {
    this.faqForm.setValue(FaqFormComponent.deserialize(this.faq));
  }

  public async onSave(): Promise<void> {
    this.submitting = true;
    const faq = FaqFormComponent.serialize(this.faqForm.value, this.faq.siteId, this.faq.id);

    this.faqService
      .updateFaq(faq)
      .then(() => {
        this.alertService.success('FAQ saved.');
        this.faqService.refreshFaqs();
        this.onCancel();
      })
      .catch((error) => {
        this.alertService.error('Unable to save FAQ. Please try again.');
        console.error(error);
      })
      .finally(() => (this.submitting = false));
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
