<form [formGroup]="form">
  <label class="form-question">Name</label>
  <div
    class="label-icon"
    matTooltip="Who is this spotlight for? Limit {{
      maxTitleLength
    }} characters."
  >
    <mat-icon>help</mat-icon>
  </div>

  <div class="form-group">
    <mat-form-field appearance="outline" color="accent">
      <input
        matInput
        required
        formControlName="title"
        placeholder="Enter their name"
        type="text"
        [maxLength]="maxTitleLength"
      />
    </mat-form-field>
  </div>
  <label class="form-question">Personal Facts</label>
  <div
    class="label-icon"
    matTooltip="What are some interesting facts about this person? Limit 30 characters per fact."
  >
    <mat-icon>help</mat-icon>
  </div>
  <div class="form-group">
    <div class="field">
      <div class="control">
        <mat-form-field appearance="outline" color="accent">
          <input
            matInput
            formControlName="factOne"
            placeholder="Fact #1"
            type="text"
            [maxLength]="50"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <input
            matInput
            formControlName="factTwo"
            placeholder="Fact #2"
            type="text"
            [maxLength]="50"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <input
            matInput
            formControlName="factThree"
            placeholder="Fact #3"
            type="text"
            [maxLength]="50"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <input
            matInput
            formControlName="factFour"
            placeholder="Fact #4"
            type="text"
            [maxLength]="50"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <input
            matInput
            formControlName="factFive"
            placeholder="Fact #5"
            type="text"
            [maxLength]="50"
          />
        </mat-form-field>
      </div>
    </div>
    <div>
      <div class="flex justify-between mb-2">
        <div>
          <label class="form-question">Square Image</label>
          <div
            class="label-icon"
            matTooltip="Add a photo of this person. It will be converted to a circular image; uploading square photos produces the best results."
          >
            <mat-icon>help</mat-icon>
          </div>
        </div>
        <button
          mat-button
          type="button"
          (click)="deleteImage()"
          mat-raised-button
          color="warn"
          *ngIf="downloadUrl"
        >
          Delete
        </button>
      </div>
      <div class="form-group image-upload">
        <div class="field">
          <div class="control" *ngIf="!downloadUrl">
            <input
              class="input"
              type="file"
              id="file"
              accept=".png,.jpg,.gif,.tif"
              (change)="uploadInputChanged($event)"
            />
            <label class="image-label" for="file">
              <ngx-file-drop
                [dropZoneClassName]="'ngx-file-drop-zone-square'"
                (onFileDrop)="dropped($event)"
                multiple="false"
                directory="false"
                accept=".png,.jpg,.gif,.tif"
              >
                <ng-template
                  ngx-file-drop-content-tmp
                  let-openFileSelector="openFileSelector"
                >
                  <mat-icon class="large" color="accent">cloud_upload</mat-icon>
                </ng-template>
              </ngx-file-drop>
              <mat-progress-bar
                *ngIf="progress !== 0"
                [value]="progress"
                color="accent"
              ></mat-progress-bar>
            </label>
          </div>
          <div class="image-container" *ngIf="downloadUrl">
            <img [src]="downloadUrl" />
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
