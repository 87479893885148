<div class="loading-wrapper">
  <div class="loading-spinner" *ngIf="paginatedDataSource.loading$ | async">
    <app-material-loader [fillContainer]="true"></app-material-loader>
  </div>
  <div
    class="loading-overlay"
    *ngIf="paginatedDataSource.loading$ | async"
  ></div>
  <table
    mat-table
    matSort
    matSortActive="daysRemaining"
    matSortDirection="asc"
    [dataSource]="paginatedDataSource"
  >
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        <i>{{
          this.filters.controls.search?.value?.length > 0
            ? 'No sites match this search.'
            : (paginatedDataSource.loading$ | async)
            ? ''
            : 'No sites available.'
        }}</i>
      </td>
    </tr>
    <ng-container matColumnDef="siteId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>ID</th>
      <td mat-cell *matCellDef="let site">{{ site.siteId }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Name
      </th>
      <td mat-cell *matCellDef="let site">{{ site.name }}</td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        State
      </th>
      <td mat-cell *matCellDef="let site">{{ site.state }}</td>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Start Date
      </th>
      <td mat-cell *matCellDef="let site">
        {{ site.startDate | date : 'shortDate' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        End Date
      </th>
      <td mat-cell *matCellDef="let site">
        {{ site.endDate | date : 'shortDate' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="daysRemaining">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Days Remaining
      </th>
      <td
        mat-cell
        *matCellDef="let site"
        [ngClass]="{
          warn: !site.daysRemaining || site.daysRemaining < 7
        }"
      >
        {{ site.daysRemaining ? site.daysRemaining : 0 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let site">
        <div>
          <button
            mat-icon-button
            color="accent"
            matTooltip="Preview Menus"
            [disabled]="buttonDisabled(site)"
            (click)="previewMenus(site.siteId)"
          >
            <mat-icon>event</mat-icon>
          </button>
          <button
            mat-icon-button
            [disabled]="buttonDisabled(site)"
            *ngIf="showDelete"
            color="accent"
            matTooltip="Delete All Menus"
            (click)="deleteSiteMenus(site)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <!-- <button
            [disabled]="site.daysRemaining < 8"
            mat-icon-button
            color="accent"
            matTooltip="Download WAG Menu"
            (click)="download(site)"
          >
            <mat-icon>download</mat-icon>
          </button> -->
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [length]="paginatedDataSource?.totalCount"
    [pageSize]="10"
    [pageSizeOptions]="[10, 20, 30, 50]"
    showFirstLastButtons
  ></mat-paginator>
</div>
