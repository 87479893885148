import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayComponent } from './display/display.component';
import { DisplayRoutingModule } from './display-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [CommonModule, DisplayRoutingModule, SharedModule],
  declarations: [DisplayComponent],
})
export class DisplayModule {}
