import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { ManageRoutingModule } from './manage-routing.module';
import { SummaryComponent } from './announcements/summary/summary.component';
import { AddAnnouncementComponent } from './announcements/add-announcement/add-announcement.component';
import { SharedModule } from '../shared/shared.module';
import { CalendarModule } from './activities/calendar/calendar.module';
import { SlideFormatComponent } from './announcements/slide-format/slide-format.component';
import { SlideDescriptionDefaultComponent } from './announcements/slide-description-default/slide-description-default.component';
import { SlidePreferencesComponent } from './announcements/slide-preferences/slide-preferences.component';
import { RoomSelectComponent } from './activities/room-select/room-select.component';
import { SlideImageGridComponent } from './announcements/components/slide-image-grid/slide-image-grid.component';
// tslint:disable-next-line:max-line-length
import { SlideDescriptionIndividualSpotlightComponent } from './announcements/slide-description-individual-spotlight/slide-description-individual-spotlight.component';
import { ActivitiesComponent } from './activities/activities.component';
import { BatchEventFormComponent } from './activities/batch-event-form/batch-event-form.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationTableComponent } from './notifications/notification-table/notification-table.component';
import { AddNotificationComponent } from './notifications/add-notification/add-notification.component';
import { EditNotificationComponent } from './notifications/edit-notification/edit-notification.component';
import { SlideTableComponent } from './announcements/components/slide-table/slide-table.component';
import { SlideGridComponent } from './announcements/components/slide-grid/slide-grid.component';
import { RoomsComponent } from './rooms/rooms.component';
import { MobileUseComponent } from './mobile-use/mobile-use.component';
import { NewslettersComponent } from './newsletters/newsletters.component';
import { AddNewsletterComponent } from './newsletters/add-newsletter/add-newsletter.component';
import { ViewNewsletterComponent } from './newsletters/view-newsletter/view-newsletter.component';
import { EditArticleComponent } from './newsletters/edit-article/edit-article.component';
import { NewsletterOverviewComponent } from './newsletters/newsletter-overview/newsletter-overview.component';
import { ViewArticleComponent } from './newsletters/view-article/view-article.component';
import { NewsletterCommentsComponent } from './newsletters/newsletter-comments/newsletter-comments.component';
import { NewsletterAttachmentsComponent } from './newsletters/newsletter-attachments/newsletter-attachments.component';
import { EventFormModalComponent } from './activities/event-form-modal-component/event-form-modal.component';
import { EventDetailsComponent } from './activities/event-form-modal-component/forms/event-details/event-details.component';
import { EventTimingComponent } from './activities/event-form-modal-component/forms/event-timing/event-timing.component';
import { SlideDescriptionCollageComponent } from './announcements/slide-description-collage/slide-description-collage.component';
import { MenusComponent } from './menus/menus.component';
import { SlideDescriptionGifComponent } from './announcements/slide-description-gif/slide-description-gif.component';
import { CategorySelectModalComponent } from './activities/category-select-modal/category-select-modal.component';
import { ManageMenuSlideModalComponent } from './announcements/components/manage-menu-slide-modal/manage-menu-slide-modal.component';
import { MenuSlideComponent } from './announcements/components/menu-slide/menu-slide.component';
import { MenuItemComponent } from './announcements/components/menu-slide/menu-item/menu-item.component';
import { ManageEventSlideModalComponent } from './announcements/components/manage-event-slide-modal/manage-event-slide-modal.component';
import { TextFieldModule } from '@angular/cdk/text-field';

@NgModule({
  imports: [
    CommonModule,
    ManageRoutingModule,
    SharedModule,
    CalendarModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    TextFieldModule,
  ],
  declarations: [
    SummaryComponent,
    AddAnnouncementComponent,
    SlideFormatComponent,
    SlideDescriptionDefaultComponent,
    SlidePreferencesComponent,
    RoomSelectComponent,
    SlideDescriptionIndividualSpotlightComponent,
    ActivitiesComponent,
    BatchEventFormComponent,
    NotificationsComponent,
    NotificationTableComponent,
    AddNotificationComponent,
    EditNotificationComponent,
    SlideTableComponent,
    SlideGridComponent,
    RoomsComponent,
    MobileUseComponent,
    NewslettersComponent,
    AddNewsletterComponent,
    ViewNewsletterComponent,
    EditArticleComponent,
    NewsletterOverviewComponent,
    ViewArticleComponent,
    NewsletterCommentsComponent,
    NewsletterAttachmentsComponent,
    EventFormModalComponent,
    EventDetailsComponent,
    EventTimingComponent,
    EventFormModalComponent,
    SlideImageGridComponent,
    SlideDescriptionCollageComponent,
    MenusComponent,
    SlideDescriptionGifComponent,
    CategorySelectModalComponent,
    ManageMenuSlideModalComponent,
    MenuSlideComponent,
    MenuItemComponent,
    ManageEventSlideModalComponent,
  ],
})
export class ManageModule {}
